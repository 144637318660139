import React from 'react'
import {CSelect} from '@coreui/react'


export default (props)=>{

    const changeFunc = (e)=>{
        var val = e.target.options[e.target.selectedIndex].value
        var loc = {...props.localFilters}
        loc['structuralDamage'] = val
        props.setLocalFilters(loc)
    }

    return(
        <CSelect value={props.localFilters['structuralDamage']} onChange={changeFunc} className="mr-2" size="lg">
            <option value="">Structural Status&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
            <option value="1">Structural Damage</option>
            <option value="0">No Structural Damage</option>
        </CSelect>
    )
}

