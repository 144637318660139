import React from "react";
import { CSelect } from "@coreui/react";

export default (props) => {
  const changeFunc = (e) => {
    var val = e.target.options[e.target.selectedIndex].value;
    var loc = { ...props.localFilters };
    loc["drivetrain"] = val;
    props.setLocalFilters(loc);
  };

  return (
    <CSelect
      value={props.localFilters["drivetrain"]}
      onChange={changeFunc}
      className="mr-2"
      size="lg"
    >
      {props.channel === "LiveBlock" ? (
        <>
          <option value="">Drivetrain</option>
          <option value="0">2 Wheel Drive</option>
          <option value="1">4 Wheel Drive</option>
          <option value="2">All Wheel Drive</option>
          <option value="3">Front Wheel Drive</option>
          <option value="4">Rear Wheel Drive</option>
        </>
      ) : (
        <>
          <option value="">Drivetrain</option>
          <option value="0">2 Wheel Drive</option>
          <option value="1">4 Wheel Drive</option>
          <option value="2">All Wheel Drive</option>
        </>
      )}
    </CSelect>
  );
};
