import React, {useState} from 'react';
import {CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle} from '@coreui/react';
import ShiftOfferConfirm from '../ShiftOfferConfirm';
import { Loader } from 'semantic-ui-react';

const CustomerPreviewModal = (props) => {

    // const [show, setShow] = useState(false);
    const {action, show, setShow, superUser, claimAppraisal, saveOfferToAppraisalDB, isShiftData, loading, setLoading , shiftLoading, setshiftLoading} = props;

    const [showConfirmation, setShowConfirmation] = useState(false);

    return (
    <>
        {/* <CButton onClick={() => setShow(!show)}>Launch static backdrop modal</CButton> */}
        <CModal show={show} onClose={() => setShow(false)} size={'lg'}>
        <CModalHeader style={{background: '#00aced', color: 'white'}}>
            <CModalTitle>Preview Selections</CModalTitle>
        </CModalHeader>
        <CModalBody>
            <table style={{width: '95%'}}>
                <thead>
                    <tr>
                        <th style={{width: '40%'}}>Metric</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><strong>First Name:</strong></td>
                        <td>{props.firstName}</td>
                    </tr>
                    <tr>
                        <td><strong>Last Name:</strong></td>
                        <td>{props.lastName}</td>
                    </tr>
                    <tr>
                        <td><strong>Telephone:</strong></td>
                        <td>{props.phone}</td>
                    </tr>
                    <tr>
                        <td><strong>Email:</strong></td>
                        <td>{props.email}</td>
                    </tr>
                    <tr>
                        <td><strong>Address:</strong></td>
                        <td>{props.address}</td>
                    </tr>
                    <tr>
                        <td><strong>Zipcode:</strong></td>
                        <td>{props.zipcode}</td>
                    </tr>
                    <tr>
                        <td><strong>Engine:</strong></td>
                        <td>{props.engineType}</td>
                    </tr>
                    <tr>
                        <td><strong>Color:</strong></td>
                        <td>{props.color}</td>
                    </tr>
                    <tr>
                        <td><strong>Appraisal Value:</strong></td>
                        <td>{props.appraisalValue}</td>
                    </tr>
                    
                    <tr>
                        <td><strong>Odor:</strong></td>
                        <td>{props.odor}</td>
                    </tr>
                    {isShiftData ? <>
                        <tr>
                            <td><strong>Starting/Driving Issues:</strong></td>
                            <td>{props.hasIssuesInStartingOrDriving}</td>
                        </tr>
                        <tr>
                            <td><strong>Mechanical issues/Persistent Dashboard Warning Lights:</strong></td>
                            <td>{props.mechanicalIssues && props.mechanicalIssues.join(', ')}</td>
                        </tr>
                        <tr>
                            <td><strong>Any Loan/ Lease: </strong></td>
                            <td>{props.hasLoanOrLease == 'Yes' ? props.loanOrLease : props.hasLoanOrLease == 'No' ? 'No' : 'N.A'}</td>
                        </tr>
                        <tr>
                            <td><strong>Exterior Damaged Panels</strong></td>
                            <td>{props.exteriorDamagedPanel && props.exteriorDamagedPanel.join(', ')}</td>
                        </tr>
                        <tr>
                            <td><strong>Accidents</strong></td>
                            <td>{props.hasBeenInAccident == 'Yes' ? props.accidentCount : props.hasBeenInAccident == 'No' ? 0 : 'N.A'}</td>
                        </tr>
                        <tr>
                            <td><strong>Hail/ Dents</strong></td>
                            <td>{props.hasHailOrDents}</td>
                        </tr>
                        <tr>
                            <td><strong>Interior Damages (Seating/ Missing Parts)</strong></td>
                            <td>{props.interiorDamagedPanel && props.interiorDamagedPanel.join(', ')}</td>
                        </tr>
                        <tr>
                        <td><strong>Mods:</strong></td>
                        <td>{props.mods && props.mods.join(", ")}</td>
                    </tr>
                    </> : null}
                    {!isShiftData ? <>
                        <tr>
                        <td><strong>Odometer:</strong></td>
                        <td>{props.odometer}</td>
                    </tr>
                    <tr>
                        <td><strong>FWD:</strong></td>
                        <td>{props.fwd}</td>
                    </tr>
                    <tr>
                        <td><strong>Transmission:</strong></td>
                        <td>{props.transmission}</td>
                    </tr>
                    <tr>
                        <td><strong>Seat Rips:</strong></td>
                        <td>{props.seatRips}</td>
                    </tr>
                    <tr>
                        <td><strong>Wheels:</strong></td>
                        <td>{props.wheels}</td>
                    </tr>
                    <tr>
                        <td><strong>Seat Stains:</strong></td>
                        <td>{props.seatStains}</td>
                    </tr>
                    <tr>
                        <td><strong>Carpet:</strong></td>
                        <td>{props.carpet}</td>
                    </tr>
                        <tr>
                        <td><strong>Paint:</strong></td>
                        {
                            props.action == "View" ? 
                            <td>{props.paint && props.paint}</td> :
                            <td>{props.paint && props.paint.join(', ')}</td>
                        }
                    </tr>
                    <tr>
                        <td><strong>Glass:</strong></td>
                        {
                            props.action == "View" ? 
                            <td>{props.glass && props.glass}</td> :
                            <td>{props.glass && props.glass.join(', ')}</td>
                        }
                    </tr>
                    <tr>
                        <td><strong>Vehicle Features:</strong></td>
                        {
                            props.action == "View" ? 
                            <td>{props.vehicle_features && props.vehicle_features}</td> :
                            <td>{props.vehicle_features && props.vehicle_features.join(', ')}</td>
                        }
                    </tr>
                    <tr>
                        <td><strong>Mods:</strong></td>
                        <td>{props.mods}</td>
                    </tr>
                    </> : null}
                    <tr>
                        <td><strong>KBB:</strong></td>
                        <td>{props.kbb}</td>
                    </tr>
                    {/* <tr>
                        <td><strong>J.D Power:</strong></td>
                        <td>{props.jdPower}</td>
                    </tr>
                    <tr>
                        <td><strong>MMR:</strong></td>
                        <td>{props.mmr}</td>
                    </tr> */}
                    <tr>
                        <td><strong>Oil Leaks:</strong></td>
                        <td>{props.oilLeaks}</td>
                    </tr>
                    
                    <tr>
                        <td><strong>Notes:</strong></td>
                        <td>{props.notes}</td>
                    </tr>
                </tbody>
            </table>
        </CModalBody>
        <CModalFooter>
            <CButton color="secondary" onClick={() => {
                setShow(false);
            }}>
            Close
            </CButton>
            {!isShiftData ? 
                action != "View" && (
                    <CButton color="primary" 
                        onClick={() => {
                            if (superUser){
                                claimAppraisal();
                                saveOfferToAppraisalDB();
                            } else {
                                setLoading && setLoading(true);
                                saveOfferToAppraisalDB();
                            }
                        }}
                    >
                        Save Offer {loading && <Loader inline active/> }
                    </CButton>
                )
            :
                action != "View" && (
                    <CButton color="primary" 
                        onClick={() => {
                                // props.saveShiftOfferToAppraisalDB();
                                setShowConfirmation(true);
                                setShow(false);
                        }}
                    >
                        Save Offer
                    </CButton>
                )
            }
        
        </CModalFooter>
        </CModal>

        {
            <ShiftOfferConfirm
                shiftLoading={shiftLoading}
                setshiftLoading={setshiftLoading}
                show={showConfirmation}
                setShow={setShowConfirmation}
                appraisalValue={props.appraisalValue}
                make={props.make}
                model={props.model}
                year={props.year}
                trim={props.trim}
                saveShiftOfferToAppraisalDB={props.saveShiftOfferToAppraisalDB}
                sendOfferMail={props.sendOfferMail}
                sendOfferNote={props.sendOfferNote}
            />
        }
    </>
    )
}

export default CustomerPreviewModal;
