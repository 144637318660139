import React, {useState, useEffect} from 'react'
import {CCard, CCardHeader, CCardBody, CCol, CRow, CListGroup, CListGroupItem, CButton} from '@coreui/react';
import RunListManager from '../../services/RunListManager';
import RunLists from './RunLists/RunLists';
import {Loader} from 'semantic-ui-react';
import {useHistory} from 'react-router-dom';
import DatePicker from "react-datepicker";
import Error from '../generic/Error/Error';
import Inventory from '../Home/Inventory/Inventory';
import "react-datepicker/dist/react-datepicker.css";

export default (props)=>{

    const [runLists, setRunLists] = useState({"SIMULCAST":[], "LiveBlock":[]});
    const [loading, setLoading] = useState(false);

    const history = useHistory();
    const {addError} = Error();

    useEffect( ()=>{
        var ignore = false;
        var RL = new RunListManager();
        setLoading(true);
        RL.getRunLists().then( (response)=>{
            if(!ignore){
                if(response.error) return addError(response.message, 500);
                var rlArray = processRunListArray(response.data.runlists)
                setRunLists(rlArray);
                setLoading(false);
                
            }
        }).catch((e)=>{
            console.log(e);
            addError(e.message, 500)
            setLoading(false)
        });
    },[])

    const processRunListArray = (runlists)=>{
        var rl = {"SIMULCAST":[], "LiveBlock":[]}
        runlists.forEach( (item)=>{
            rl[item.channel].push(item)
        })
        return rl;
    }

    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    return (
       <div>
        <section>
        <CRow>
            <CCol md="10" className="offset-md-1">
                <div className="py-5">
                    <CRow>
                        <CCol md="6" className='mb-2'>                    
                            <h1>Pricing and Preview Dashboard</h1>
                            <p>Select a Run List to continue.</p>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol md="6">
                            <RunLists 
                                venue="Manheim" 
                                channel="SIMULCAST" 
                                runLists={runLists} 
                                loading={loading}
                                advance={(ch,id)=>{
                                    history.push(`/runlist/${ch}/${id}/stage`);
                                }}
                            />
                        </CCol>
                        <CCol md="6">
                            <RunLists 
                                venue="Adesa" 
                                channel="LiveBlock" 
                                runLists={runLists} 
                                loading={loading}
                                advance={(ch,id)=>{
                                    history.push(`/runlist/${ch}/${id}/stage`);
                                }}
                            />
                        </CCol>
                    </CRow>
                </div>
            </CCol>
        </CRow>
        </section>
        <section>
            <CRow>

                <CCol md="10" className="offset-md-1">
                    <Inventory/>
                </CCol>
            </CRow>    
        </section>
                                
    </div>
    )

}

