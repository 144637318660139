import React, {useState, useEffect} from 'react'
import {CCard, CCardHeader, CCardBody, CCardFooter, CButton, CSelect, CBadge} from '@coreui/react';
import MakeFilter from './MakeFilter/MakeFilter'
import BodyStyleFilter from './BodyStyleFilter/BodyStyleFilter'
import ModelFilter from './ModelFilter/ModelFilter'
import FrameFilter from './FrameFilter/FrameFilter'
import CRScoreMin from './CRScore/CRScore_min';
import CRScoreMax from './CRScore/CRScore_max'
import YearMin from './Year/Year_min';
import YearMax from './Year/Year_max';
import StructuralFilter from './StructuralFilter/StructuralFilter';
import DaySupply from './DaySupply/DaySupply';
import AdjMmrMin from './AdjMmr/AdjMmr_min';
import AdjMmrMax from './AdjMmr/AdjMmr_max';
import TrimFilter from './TrimFilter/TrimFilter';
import DrivetrainFilter from './DrivetrainFilter/DrivetrainFilter';
import ShoppingListFilter from './ShoppingListFilter/ShoppingListFilter';
import DaySupply_min from './DaySupplyValue/DaySupply_min';
import DaySupply_max from './DaySupplyValue/DaySupply_max';
import MinMileage from './MileageFilter/MinMileage';
import MaxMileage from './MileageFilter/MaxMileage';
import CarfaxFilter from './CarfaxFilter/CarfaxFilter';

export default ({filters, setFilters, resetPrequalifiedListings, channel})=>{
    
    const [localFilters, setLocalFilters] = useState({})

    useEffect( ()=>{setLocalFilters({...filters})}, [filters])

    const applyFilters = ()=>{setFilters({...localFilters})}
    const clearFilters = ()=>{
        setFilters({}); 
        setLocalFilters(
            {
                make: "",
                model: "",
                drivetrain: "",
                frameDamage: "",
                CRScore_min: "",
                CRScore_max: "",
                year_min: "",
                year_max: "",
                mmr_min: "",
                mmr_max: "",
                trim: "",
                shoppingList: "",
                mileage_min:"",
                mileage_max: "",
                bodyStyle: "",
                carfax_status: "",
                daySupply_min: "",
                daySupply_max: ""
            },          
        );
        var trim = document.getElementById('trim_filter');
        if (trim) trim.value = '';
        resetPrequalifiedListings();
    }
    return(
        <div>
            <CCard>
                <CCardHeader>
                    Lane Filters &nbsp; &nbsp;
                    <CButton color="dark" className="mr-2" onClick={applyFilters}>Apply</CButton> 
                    <CButton color="light" onClick={clearFilters}>Clear</CButton>

                </CCardHeader>
                <CCardBody>
                    <div className="d-flex">
                        <div className="mr-2 flex-grow-1">
                            <MakeFilter localFilters={localFilters} setLocalFilters={setLocalFilters} />
                        </div>
                        <div className="mr-2 flex-grow-1">
                            <ModelFilter localFilters={localFilters} setLocalFilters={setLocalFilters}/>
                        </div>
                        <div className="mr-2 flex-grow-1">
                            <DrivetrainFilter channel={channel} localFilters={localFilters} setLocalFilters={setLocalFilters}/>
                        </div>
                        
                        <div className="mr-2">
                            <YearMin localFilters={localFilters} setLocalFilters={setLocalFilters}/>
                        </div>
                        <div className="mr-2">
                            <YearMax localFilters={localFilters} setLocalFilters={setLocalFilters}/>
                        </div>

                        <div className="mr-1">
                            <CRScoreMin localFilters={localFilters} setLocalFilters={setLocalFilters}/>
                        </div>
                        <div className="mr-1">
                            <CRScoreMax localFilters={localFilters} setLocalFilters={setLocalFilters}/>
                        </div>
                    </div>
                    <div className="d-flex pt-2">
                        <TrimFilter  localFilters={localFilters} setLocalFilters={setLocalFilters}/>

                        <div className="mr-1">
                            <FrameFilter localFilters={localFilters} setLocalFilters={setLocalFilters}/>
                        </div>
                        <div className='mr-2'>
                            <MinMileage localFilters={localFilters} setLocalFilters={setLocalFilters} />
                        </div>
                        <div className='mr-2'>
                            <MaxMileage localFilters={localFilters} setLocalFilters={setLocalFilters}  />
                        </div>
                        <div className="mr-2 flex-grow-1">
                            <BodyStyleFilter localFilters={localFilters} setLocalFilters={setLocalFilters} />
                        </div>
                        <div className="mr-2 flex-grow-1">
                            <CarfaxFilter channel={channel} localFilters={localFilters} setLocalFilters={setLocalFilters}/>
                        </div>
                        <div className="mr-1">
                            <StructuralFilter localFilters={localFilters} setLocalFilters={setLocalFilters}/>
                        </div>
                    </div>
                    <div className="d-flex pt-3">
                        <div className="mr-1">
                            <DaySupply_min localFilters={localFilters} setLocalFilters={setLocalFilters}/>
                        </div>
                        <div className="mr-1">
                            <DaySupply_max localFilters={localFilters} setLocalFilters={setLocalFilters}/>
                        </div>
                        <div className="mr-1">
                            <DaySupply localFilters={localFilters} setLocalFilters={setLocalFilters}/>
                        </div>
                        <div className="mr-1">
                            <ShoppingListFilter localFilters={localFilters} setLocalFilters={setLocalFilters}/>
                        </div>
                        <div className="mr-1">
                            <AdjMmrMin localFilters={localFilters} setLocalFilters={setLocalFilters}/>
                        </div>
                        <div className="mr-1">
                            <AdjMmrMax localFilters={localFilters} setLocalFilters={setLocalFilters}/>
                        </div>
                    </div>

                </CCardBody>
            </CCard>
        </div>
    )
}