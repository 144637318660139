import React, {useState, useEffect} from 'react'
import {CSelect} from '@coreui/react'
import {RL_Instance} from '../../../../services/RunListManager';

export default (props)=>{
    const [check, setCheck] = useState(0);
    const [hasData, setHasData] = useState(false);
    useEffect(()=>{
        if (RL_Instance.makesModelsMap) {
            setHasData(true);
        } else {
            setTimeout(tryOneMoreCheck, 1000);
        }
    },[check])

    const tryOneMoreCheck =()=> {
        console.log("makes", RL_Instance.makesModelsMap);
        setCheck(check + 1);
    }

    const changeFunc = (e)=>{
        var val = e.target.options[e.target.selectedIndex].value
        var loc = {...props.localFilters}
        loc['make'] = val
        props.setLocalFilters(loc)
    }

    return(
        <CSelect value={props.localFilters['make']} onChange={changeFunc} className="mr-2" size="lg">
            <option value="" key="nada">Make</option>
            { hasData &&
            Object.keys(RL_Instance.makesModelsMap).map((item)=>{
                return <option value={item} key={item}>{item}</option>
            })}
        </CSelect>
    )
}

