import React, {useState} from 'react'
import {CPagination} from '@coreui/react'
import updater from 'immutability-helper'




export default (props)=>{

    const activePage = (ob)=>{return ob['offset'] ? Math.floor(ob['offset']/50)+1 : 1}
    const pageChange = (i)=>{
        var newSettings = updater(props.settings, {$apply:(u)=>{
            u['offset'] = Math.max((i-1)*50,0)
            return {...u};
        }})
        props.setSettings(newSettings)
    }
    return (
        <div className="mt-2 d-flex justify-content-center">
            <CPagination
                activePage={activePage(props.settings)}
                pages={Math.ceil(props.totalListings/50)}
                onActivePageChange={pageChange}
            />
        </div>
    )
}