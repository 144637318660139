import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { CButton, CDataTable, CSelect, CBadge } from "@coreui/react";
import LaneAssigner from "./LaneAssigner/LaneAssigner";
import DisqualifyLane from "./DisqualifyLane/DisqualifyLane";
import RunListManager from "../../../services/RunListManager";
import { flagSet } from "@coreui/icons";
import update from "immutability-helper";
import _ from "lodash";
import Error from "../../generic/Error/Error";

const numPadding = (num, hr = false) => {
  if (!isNaN(num)) {
    num = hr && num > 12 ? num - 12 : num;
    return num < 10 ? "0" + num : num;
  }
  return num;
};

export default ({lanes, setLanes, buyers, channel, filters, runListId, loading}) => {
  const history = useHistory();
  var RL = new RunListManager();
  const { addError } = Error();


  const assignBuyer = (lane, buyer) => {
    var payload = {
      auction_id: lane.auctionId,
      auction_location: lane.auction_location,
      auction_lane: lane.auction_lane,
      auction_date: lane.auction_date,
      user_id: buyer,
    };

    if (channel == "LiveBlock") payload["start_time"] = lane.auctionStartDate;

    RL.assignLane(payload, channel)
      .then((data) => {
        if (data.error) return addError(data.message, 500);
      })
      .catch((e) => {
        console.log(e);
        addError(e.message, 500);
    });

    var newLane = update(lanes, {
      $apply: (l) => {
        var ind = _.findIndex(l, (o) => {
          return (
            o.auction_location === lane.auction_location &&
            o.auction_lane === lane.auction_lane
          );
        });
        l[ind]["user_id"] = buyer;
        
        //this adds the buyer name to the state so it updates the Lane Counter at the top of the page
        for(var i = 0; i < buyers.length; i++){
          if(buyers[i].user_id == buyer){
            l[ind]["buyer_name"] = buyers[i].first_name + " " + buyers[i].last_name;
            break;
          }
        }
        return [...l];
      },
    });

    setLanes(newLane);
  };

  return (
    
    <CDataTable
      fields={[
        { key: "auction_location", label: "Auction Location", sorter: true },
        "auction_lane",
        { key: "vehicles", label: "Pre-Qualified Listings" },
        { key: "reviewed_vehicles", label: "Reviewed Listings" },
        { key: "auctionStartDate", label: "Start Time" },
        { key: "buyer_name", label: "Assigned" },
        { key: "actions"},
      ]}
      hover
      sorter
      onRowClick={(item) => {
        var link = `/runlist/${channel}/${runListId}/review?auction_date=${item.auction_date}&auctionLocation=${item.auction_location}&auction_lane=${item.auction_lane}&sortBy=make_ASC,model_ASC,year_DESC,CRScore_DESC`;

        link +=
          channel == "LiveBlock"
            ? `&start_time=${item.auctionStartDate}`
            : "";

        for (var key in filters) {
          link += filters[key]
            ? "&" + key + "=" + filters[key]
            : "";
        }

        history.push(link);
      }}
      items={lanes}
      loading={loading}
      columnFilter
      scopedSlots={{
        actions: (item) => {
          return <DisqualifyLane lane={item} channel={channel} filters={filters}/>;
        },
        auction_lane: (item) => {
          var laneInt = parseInt(item.auction_lane);
          var auctionLane = isNaN(laneInt) ? item.auction_lane : laneInt;
          return <td>{auctionLane}</td>;
        },
        buyer_name: (item) => {
          return (
            <LaneAssigner
              item={{ ...item }}
              buyers={buyers}
              assignBuyer={assignBuyer}
            />
          );
        },
        auctionStartDate: (item) => {
          if (!item.auctionStartDate) {
            return <td>&nbsp;</td>;
          }
          var startTime = item.auctionStartDate;
          startTime += channel == "LiveBlock" ? "+00:00" : "";
          var d = new Date(startTime);
          return (
            <td>{`${d.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}`}</td>
          );
        },
        reviewed_vehicles: (item) => {
          if (item.reviewed_vehicles) {
            return (
              <td>
                {item.reviewed_vehicles}/{item.vehicles}
              </td>
            );
          } else {
            return (
              <td>
                <CBadge color="primary">Open</CBadge>
              </td>
            );
          }
        },
      }}
    ></CDataTable>
  );
};
