import React, {useState, useEffect, useRef}from 'react'
import {CRow,CCol, CButton, CCard, CCardBody, CContainer, CSpinner} from '@coreui/react';
import { CIcon } from '@coreui/icons-react';
import {cilReload} from '@coreui/icons';
import DatePicker from "react-datepicker";
import RunListManager, {getCookie} from '../../services/RunListManager'
import { AppraisalQueue } from '../VAS_Dashboard/AppraisalQueue';
import jwt from 'jsonwebtoken';
import { Button } from 'semantic-ui-react';
import Error from '../generic/Error/Error';
import OfferModalContainer from '../AppraiserView/OfferModalContainer/index';
import { AppraisalTool } from '../AppraisalTool/AppraisalTool';
import CustomerOffer from '../CustomerOffer';
import {OfferSheetContent} from '../OfferSheetContent';
import NoOfferConfirm from '../../components/Shift/NoOfferConfirm';
import { SurveyContent } from './Survey/SurveyContent';
import {DateTime} from 'luxon';
import YearFilter from './ShiftFilter/YearFilter';
import MinMileageFilter from './ShiftFilter/MinMileageFilter';
import MaxMileageFilter from './ShiftFilter/MaxMileageFilter';
import LeadTypeFilter from './ShiftFilter/LeadTypeFilter';
import MileageFilter from './ShiftFilter/MileageFilter';
import YearsFilter from './ShiftFilter/YearsFilter';
import BrandFilter from './ShiftFilter/BrandFilter';
import RooftopFilter from './ShiftFilter/RooftopFilter';
import AppointmentFilter from './ShiftFilter/AppointmentFilter';
import VehicleTypeFilter from './ShiftFilter/VehicleTypeFilter';


const shiftAppraisalFields = [
    { key: 'first_name', label: 'First Name', _style: { width: '6%'} },
    { key: 'last_name', label: 'Last Name', _style: { width: '6%'} },
    { key: 'email', label: 'Email', _style: { width: '10%' , paddingLeft: '15px'} },
    { key: 'vin', label: 'VIN', _style: { width: '10%', paddingLeft: '15px'} },
    // { key: 'store', label: 'DS Lead Bucket', _style: { width: '6%'} },
    { key: 'mileage', label: 'Mileage', _style: { width: '6%'} },
    { key: 'adjMmr', label: 'MMR', _style: { width: '5%'} },
    // { key: 'blackBook', label: '', _style: { width: '6%'}},
    { key: 'shift_brand', label: 'Brand', _style: { width: '5%'}},
    { key: 'shift_rooftop', label: 'Appt. Location', _style: { width: '6%'}},
    { key: 'shift_appointment', label: 'Appt. Date/Time', _style: { width: '8%'}},
    { key: 'date_offered', label: 'Created', _style: { width: '8%'} },
    { key: 'offer_value', label: 'Offer', _style: { width: '5%'} },
    // { key: 'vehicle_details', label: '', _style: { width: '10%'} },
    { key: 'vehicle_survey', label: '', _style: { width: '3%'} },
    { key: 'discard', label: '', _style: { width: '3%'} },
    // { key: 'add_to_central_appraiser', label: '', _style: { width: '8%'} },
    { key:'comment_indicator', label:'', _style:{ width: '2%'}},
    { key: 'shift_offer', label: '', _style: { width: '2%'} }
];

const initialFilter = {
    min_mileage: null,
    max_mileage: null,
    years: null,
    lead_type: null,
    brand: null,
    rooftop: null,
    appointment: null,
    vehicle_type: null
}

export const Shift = (usgroup) => {

    var RL = new RunListManager();
    const {addError} = Error();
    const initialStartdt = DateTime.now().setZone("America/Chicago").minus({days: 10}).toJSDate();
    const initialEndDt = DateTime.now().setZone("America/Chicago").toJSDate();
    const currentDt = DateTime.now().setZone("America/Chicago").toJSDate();
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(initialStartdt);
    const [endDate, setEndDate] = useState(initialEndDt);
    const [shiftRecords, setShiftRecords] = useState([]);
    const [userName, setUserName] = useState('');
    const [filters, setFilters] = useState(initialFilter);
    const [showViewModal, setShowViewModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [showPreview, setShowPreview] = useState(false);
    const [showSurvey, setShowSurvey] = useState(false);
    const [showManuallyQuotedOffers, setShowManuallyQuotedOffers] = useState(false);
    const [viewVehicleDetails, setViewVehicleDetails] = useState({ show: false, vehicle: {} });
    const [confirmLoading, setConfirmLoading ] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [discardingVehicleDetail, setDiscardingVehicleDetail] = useState({});

    const getShiftAppraisals = (start_date, end_date) => {
        RL.getShiftAppraisalRecordsForAppraiser(start_date, end_date, filters)
                    .then(res => {
                        if(res && res.data && res.data.shift_appraisal_record_lists) {
                            const recordLists = res.data.shift_appraisal_record_lists;

                            const updatedRecordLists = recordLists.map(record => {
                                let recordObj = {
                                    ...record
                                }

                                const dateTime = new Date(record.created_date);


                                const hours = parseInt(("0" + dateTime.getHours()).slice(-2));
                                const updatedHours = (hours + 11) % 12 + 1;
                                const suffix = hours > 12 ? 'PM' : 'AM';

                                recordObj['modified_created_date'] 
                                    = dateTime.getFullYear() + "-" +
                                ("0" + (dateTime.getMonth()+1)).slice(-2) + "-" +
                                ("0" + dateTime.getDate()).slice(-2) + "\n" +
                                (updatedHours) + ":" +
                                ("0" + dateTime.getMinutes()).slice(-2) + " " + suffix;

                                return recordObj
                            })
                            setShiftRecords(updatedRecordLists);
                            setLoading(false)
                        } else {
                            setShiftRecords([]);
                            setLoading(false)
                        }                        
                    })
                    .catch(error => {
                        console.error('Could not retrieve shift appraisals' + error);
                        addError(error.message)
                        setLoading(false)
                    })
    }

    const getPreferredUserName = () => {
        var rawToken = getCookie('AD_idToken');
        const decodedToken = jwt.decode(rawToken, {complete: true});

        if (decodedToken && decodedToken.payload && decodedToken.payload.preferred_username) {
            setUserName(decodedToken.payload.preferred_username);
        }
    }

        useEffect(() => {
            getPreferredUserName();
        })

        useEffect(() => {
            if (usgroup.usgroup.includes("Appraiser")){
                setEdit(true)
            } else {
                setEdit(false)
            }
            setLoading(true)
            // setCountLoading(true)
            // getShiftAppraisalsCount();
            // getShiftAppraisals();
        },[userName])

        useEffect(() => {
            if(showManuallyQuotedOffers) setShiftRecords(getManuallyQuotedOffers)
            else getShiftAppraisals(
                (startDate.getFullYear() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getDate()),
                (endDate.getFullYear() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getDate()), {...filters}
                );
        },[showManuallyQuotedOffers])
    
        useEffect(() => {
            const shiftInterval = setInterval(() => {
                if(showManuallyQuotedOffers) setShiftRecords(getManuallyQuotedOffers) 
                else getShiftAppraisals(
                    (startDate.getFullYear() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getDate()),
                    (endDate.getFullYear() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getDate()), {...filters}
                    );
                }, 10000);
                return () => {
                    clearInterval(shiftInterval);    
            }
        }, [startDate, endDate, filters, showManuallyQuotedOffers])

        // useEffect(() => {
        //     const shiftCountInterval = setInterval(() => {
        //         setCountLoading(true)
        //         getShiftAppraisalsCount();
        //         }, 3600000);
        //         return () => {
        //             clearInterval(shiftCountInterval);    
        //     }
        // })

        const getManuallyQuotedOffers = () => {
            // setShowManuallyQuotedOffers(true)
            return shiftRecords && shiftRecords.filter(record => record.shift_lead_type === 'manual_quote' && record.appraisal_value !== null)
        }

  return (
    <>
         <CRow>
                <CCol className="p-5 col-md-11 mt-5" style={{ margin: "0px auto", background: "white" }}>
                    <div className="d-flex justify-content-between align-items-center">
                    <h3 className='pb-2'>Instant Cash Offer Appraisals</h3>
                    <div className="d-flex flex-wrap justify-content-end align-items-center col-md-10">
                        <div className="runlistLabel">From Date</div>
                            <div>
                                <DatePicker maxDate={currentDt} selected={startDate} onChange={date=> setStartDate(date)} wrapperClassName="runlist-datepicker-wrapper" />
                            </div>
                            <div className='endDate'>To Date</div>
                            <div>
                                <DatePicker maxDate={currentDt} selected={endDate} onChange={date=> setEndDate(date)} wrapperClassName="runlist-datepicker-wrapper" />
                            </div> 
                            <div className='m-1'>
                            <CButton style={{height: '35px', minWidth:'90px'}} color='info' variant= 'outline' active={showManuallyQuotedOffers} onClick={()=> setShowManuallyQuotedOffers(true)}>
                                    Manually Quoted Offers
                                </CButton>
                            </div>           
                            
                            <div className='m-1'>
                                <Button 
                                    style={{height: '38px'}}
                                    onClick={() => {
                                        // setCountLoading(true);
                                        getShiftAppraisals();
                                        setStartDate(initialStartdt);
                                        setEndDate(initialEndDt);
                                    }}
                                >
                                    <CIcon content={cilReload}
                                        style={{ 
                                            width: '20px', 
                                            height: '18px', 
                                        }} 
                                    />
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div style={{marginBottom: '-41px'}} className="d-flex flex-wrap justify-content-end align-items-center col-md-12">
                            <div className='m-1'>
                                <BrandFilter localFilters={filters} setLocalFilters={setFilters}/>
                            </div>
                            <div className='m-1'>
                                <RooftopFilter localFilters={filters} setLocalFilters={setFilters}/>
                            </div>
                            <div className='m-1'>
                                <AppointmentFilter localFilters={filters} setLocalFilters={setFilters}/>
                            </div>
                            <div className='m-1'>
                                <LeadTypeFilter localFilters={filters} setLocalFilters={setFilters}/>
                            </div>
                            <div className='m-1'>
                                <MileageFilter localFilters={filters} setLocalFilters={setFilters}/>
                            </div>
                            <div className='m-1'>
                                <YearsFilter localFilters={filters} setLocalFilters={setFilters} />
                            </div>
                            <div className='m-1'>
                                <VehicleTypeFilter localFilters={filters} setLocalFilters={setFilters} />
                            </div>
                            <div className='m-1'>
                                <MinMileageFilter localFilters={filters} setLocalFilters={setFilters} />
                            </div>
                            <div className='m-1'>
                                <MaxMileageFilter localFilters={filters} setLocalFilters={setFilters} />
                            </div>
                            <div className='m-1'>
                                <YearFilter localFilters={filters} setLocalFilters={setFilters} />
                            </div>
                            
                            <div className='m-1'>
                                <CButton style={{height: '38px'}} color="primary" onClick={()=>{ setLoading(true); getShiftAppraisals((startDate.getFullYear() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getDate()), (endDate.getFullYear() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getDate()), filters)} }>
                                    GET
                                </CButton>
                            </div>
                            <div className='m-1'>
                                <CButton style={{height: '38px', minWidth:'90px'}} color="info" onClick={()=> {setFilters(initialFilter); setLoading(true); setShowManuallyQuotedOffers(false); getShiftAppraisals()}}>
                                    Clear Filters
                                </CButton>
                            </div> 
                    </div>
                    {
                        edit ? <AppraisalQueue 
                                    appraisalRecords={shiftRecords}
                                    shiftLoading={loading}
                                    badgeField={[`inspection_status`, `offer_status`]}
                                    search={true}
                                    offerButtons={[
                                        {
                                            id: 3,
                                            text: 'View Offer',
                                            setShowViewModal,
                                            setModalContent,
                                            setShowPreview
                                        },
                                        {
                                            id: 4,
                                            text: 'Edit Offer',
                                            setShowViewModal,
                                            setModalContent
                                        }
                                    ]}
                                    setModalContent={setModalContent}
                                    setShowSurvey = {setShowSurvey}
                                    setShowConfirmation = {setShowConfirmation}
                                    setDiscardingVehicleDetail = {setDiscardingVehicleDetail}
                                    recordsPerPage={10}
                                    dropdownField={`appraiser`}
                                    dropDownoptions={['Unclaimed', userName]}
                                    fields={shiftAppraisalFields}
                                    getUnclaimedAppraisals={getShiftAppraisals}
                                    roleType={"Appraiser"}
                                    setViewVehicleDetails={setViewVehicleDetails}
                                /> : <AppraisalQueue 
                                appraisalRecords={shiftRecords}
                                shiftLoading={loading}
                                badgeField={[`inspection_status`, `offer_status`]}
                                search={true}
                                offerButtons={[
                                    {
                                        id: 3,
                                        text: 'View Offer',
                                        setShowViewModal,
                                        setModalContent,
                                        setShowPreview
                                    }
                                ]}
                                setModalContent={setModalContent}
                                setShowSurvey = {setShowSurvey}
                                setShowConfirmation = {setShowConfirmation}
                                setDiscardingVehicleDetail = {setDiscardingVehicleDetail}
                                recordsPerPage={10}
                                dropdownField={`appraiser`}
                                dropDownoptions={['Unclaimed', userName]}
                                fields={shiftAppraisalFields}
                                getUnclaimedAppraisals={getShiftAppraisals}
                                roleType={"Appraiser"}
                                setViewVehicleDetails={setViewVehicleDetails}
                                />
                    }
                    
                </CCol>
            </CRow>

            {
                <NoOfferConfirm
                    user={userName}
                    error={addError}
                    vehicleDetail={discardingVehicleDetail}
                    shiftLoading={confirmLoading}
                    setshiftLoading={setConfirmLoading}
                    show={showConfirmation}
                    setShow={setShowConfirmation}
                    getMyAppraisals={getShiftAppraisals}
                />
            }

            <OfferModalContainer show={viewVehicleDetails.show} setShow={() => setViewVehicleDetails({show: false, vehicle: {}})}>
                {
                    viewVehicleDetails && viewVehicleDetails.show && (
                        <AppraisalTool 
                            vin={viewVehicleDetails.vehicle.vin}
                            mileage={viewVehicleDetails.vehicle.mileage}
                            created_date={viewVehicleDetails.vehicle.created_date}
                            view={`Appraiser`}
                            appraisalValue={viewVehicleDetails.vehicle.appraisal_value ? viewVehicleDetails.vehicle.appraisal_value : ''}
                            getMyAppraisals={getShiftAppraisals}
                        />
                    )
                }
            </OfferModalContainer>

            {
                (modalContent.action && modalContent.action == 'ViewSurvey') && (
                    <OfferModalContainer show={showSurvey} setShow={setShowSurvey} item={modalContent.item} saveSurveyButton={true}>
                        <SurveyContent 
                            item={modalContent.item} 
                        />
                    </OfferModalContainer>
                    
                )
            }

            {
                (modalContent.action && modalContent.action == 'View') && (
                    <OfferModalContainer isShiftData={true} show={showPreview} setShow={setShowPreview} item={modalContent.item} saveFileButton={true}>
                        <OfferSheetContent
                            isShiftData={true} 
                            item={modalContent.item} 
                        />
                    </OfferModalContainer>
                    
                )
            }
            <OfferModalContainer show={showViewModal} setShow={setShowViewModal} setModalContent={setModalContent}>
            
            {
                (modalContent.action && modalContent.action == `Edit`) && (
                    <CustomerOffer
                        isShiftData={true}
                        modalViewContent={modalContent.item}
                        user={userName}
                        action={`Edit`}
                        setShowViewModal={setShowViewModal}
                        getMyAppraisals={getShiftAppraisals}
                    />
                )
            }
            </OfferModalContainer>

            
    </>
  )
}
