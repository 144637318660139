import React, {useState, useEffect, Fragment} from 'react';
import { CCol, CRow, CWidgetProgress, CCard, CCardBody, CButton, CContainer, CTooltip} from '@coreui/react';
import { CIcon } from '@coreui/icons-react';
import { cilFile, cilInfo, cilNoteAdd, cilPen, cilCommentSquare} from '@coreui/icons';
import RunListManager from '../../../services/RunListManager';
import Error from '../../generic/Error/Error';



export default ({ runListId, channel, preQualifiedListingCount, lanesPerBuyer, reviewCount, maxBidCount, totalListings, shoppingListCount, reviewedShoppingListCount, maxBidShoppingListCount,  auctionDate }) => {
  const [runlist, setRunlist] = useState({});
  const [loading, setLoading] = useState(false);
  
  const { addError } = Error();
  const formatNum = (n) => { return n ? n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0' };

  const formatPercentage = (n) => { return n ? n.toFixed(1) : 0}
  
  useEffect(() => {
    let card1 = document.getElementsByClassName('card')[0];
    let card2 = document.getElementsByClassName('card')[1];
    if(card1 && card2){
      card1.removeAttribute('data-tooltip');
      card2.removeAttribute('data-tooltip');
    }
  }, [])
  

  const csvDownload = () => {
    var RL = new RunListManager()
    RL.downloadListingsCSV(auctionDate, channel).then((data) => {
      if (data.error) return addError(data.message, 500);
      saveData(data.data, "runlist_" + auctionDate + ".csv")
    })
  }
  
  const saveData = (function () {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    return function (data, fileName) {
      const blob = new Blob([data], { type: "octet/stream" }),
        url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    };
  }());

  return (
    <>
      <CRow className={"mb-3"}>
        <CCol md="12">
          <h1>Lane Review and Assignment: {runlist.auction_date}</h1>
          <p>Review listings and assign lanes for tomorrow.</p>
        </CCol>
      </CRow>
      <CRow>
        <CCol md="3">
          <CTooltip content={`Figure in this metric will adjust based on applied lane filters`}>
              <CWidgetProgress
                style={{ minHeight: "140px" }}
                value={(reviewCount / preQualifiedListingCount) * 100}
                color="success"
                header={`${formatNum(preQualifiedListingCount)}`}
                text="Prequalified listings"
                footer={`${formatNum(reviewCount)} (${formatPercentage((reviewCount * 100) / preQualifiedListingCount)}%) of ${formatNum(
                  preQualifiedListingCount
                )} listings have been reviewed.`}
              />
          </CTooltip>
        </CCol>
        <CCol md="3">
        <CTooltip content={`Figure in this metric will adjust based on applied lane filters`}>
          <CWidgetProgress
            style={{ minHeight: "140px" }}
            value={(maxBidCount / preQualifiedListingCount) * 100}
            color="info"
            header={`${formatNum(maxBidCount)}`}
            text="Listings have Max Bid applied"
            footer={`${formatNum(maxBidCount)} (${formatPercentage((maxBidCount * 100) / preQualifiedListingCount)}%) of ${formatNum(
              preQualifiedListingCount
            )} listings have been given a max bid.`}
          />
          </CTooltip >
        </CCol>
        <CCol md="2">
          <CCard style={{ minHeight: "140px" }}>
            <CCardBody style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'start'}}>
              Download a list of reviewed vehicles.
              <CButton size="sm" onClick={csvDownload} color="primary">
                Download
              </CButton>
            </CCardBody>
          </CCard>
        </CCol>
        
        <CCol md="4">
          <CCard
            style={{
              overflowX: "auto",
              fontSize: "11px",
              // minHeight: "140px",
              maxHeight: "140px",
              // paddingBottom: "10px !important",
            }}
          >
            <CCardBody>
              <b style={{ fontSize: "13px", marginBottom: '5px' }}>Lanes Per Buyer</b>
              <CContainer>
              <CRow>
                <CCol md={4}>
                  <b>Buyer</b>
                </CCol>
                <CCol md={3}><b>Lane Count</b></CCol>
                <CCol md={5}><b>Reviewed Listings</b></CCol>
              </CRow>
                {
                  lanesPerBuyer.map( (item)=> {
                    return (
                      <CRow key={item['user_id']}>
                        <CCol md={4}>
                          {item['buyer']}
                        </CCol>
                        <CCol md={3}>{item['laneCount']}</CCol>
                        <CCol md={5}>{formatNum(item['reviewed_listings'])}/{formatNum(item['total_listings'])}</CCol>
                      </CRow>
                    )

                  })
                }
              </CContainer>
            </CCardBody>
          </CCard>
        </CCol>
        
      </CRow>
      
      <CRow>
      <CCol md="4">
          <CWidgetProgress
            style={{ minHeight: "140px" }}
            value={(shoppingListCount / totalListings) * 100}
            color="success"
            header={`${formatNum(shoppingListCount)}`}
            text="Shopping List vehicles"
            footer={`${formatNum(shoppingListCount)} (${formatPercentage((shoppingListCount * 100) / totalListings)}%) of ${formatNum(
              totalListings
            )} pre qualified vehicles are in shopping list`}
          />
        </CCol>
        <CCol md="4">
          <CWidgetProgress
            style={{ minHeight: "140px" }}
            value={(reviewedShoppingListCount / shoppingListCount) * 100}
            color="success"
            header={`${formatNum(reviewedShoppingListCount)}`}
            text="Reviewed Shopping List vehicles"
            footer={`${formatNum(reviewedShoppingListCount)} (${formatPercentage((reviewedShoppingListCount * 100) / shoppingListCount)}%) of ${formatNum(
              shoppingListCount
            )} shopping list vehicles have been reviewed`}
          />
        </CCol>
        <CCol md="4">
          <CWidgetProgress
            style={{ minHeight: "140px" }}
            value={(maxBidShoppingListCount / shoppingListCount) * 100}
            color="info"
            header={`${formatNum(maxBidShoppingListCount)}`}
            text="Shopping List vehicles have Max Bid applied"
            footer={`${formatNum(maxBidShoppingListCount)} (${formatPercentage((maxBidShoppingListCount * 100) / shoppingListCount)}%) of ${formatNum(
              shoppingListCount
            )} shopping list vehicles have been given a max bid`}
          />
        </CCol>
      </CRow>
    </>
  );
}
