import React, {useState} from 'react'
import {CButton, CTooltip} from '@coreui/react'
import RunListManager from '../../../../services/RunListManager'
import styled from 'styled-components';

const TableDiv = styled.td`

    background:inherit;

    &.disqualified{
        background:#ff007f;
        color:#FFFFFF;
        font-weight:bold;
    }

`



export default (props)=>{

    const [disqualified, setDisqualified] = useState(false)
    const [tempDq, setTempDq] = useState(false)
    const [confirmed, setConfirmed] = useState(false);
    const buttonAction = (e)=>{
        e.preventDefault();
        e.stopPropagation();
        setTempDq(false);
        setDisqualified(true);
        console.log("props",props);
    }

    const disqualifyFiltered = (e)=>{
        e.preventDefault();
        e.stopPropagation();
        setTempDq(true);
        setDisqualified(true)
    }
    const confirmDQ = (e)=>{
        e.preventDefault();
        e.stopPropagation();
       // setDisqualified(false)
        var RL = new RunListManager();

        if (tempDq) {
            RL.disqualifyLaneFiltered({
                auctionLocation: props.lane.auction_location,
                auctionLane: props.lane.auction_lane,
                auctionDate: props.lane.auction_date,
                start_time:props.lane.auctionStartDate,
                ...props["filters"]
            }, props.channel)    
        } else {
            RL.disqualifyLane({
                auctionLocation: props.lane.auction_location,
                auctionLane: props.lane.auction_lane,
                auctionDate: props.lane.auction_date,
                start_time:props.lane.auctionStartDate,
                disqualify: true 
            }, props.channel)    
        }
        setConfirmed(true);
    }

    return(
         <TableDiv className={confirmed ? "disqualified" : ""}       onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
    >
        {(disqualified && !confirmed) && <CButton color="info" onClick={confirmDQ}>Confirm</CButton>}
        {(disqualified && confirmed) && "Lane Disqualified"}
        {!disqualified && 
        <CTooltip content={"This button will apply a 'Temporary DQ' status to all vehicles in the lane that currently match the current lane filters. These vehicles will NOT be biddable on the auction date from the buying dashboard. If a vehicle is re-listed, the vehicle will need to be reviewed with a max bid to enable bidding on auction day. If you click this button with no filters applied, it will temp DQ all vehicles in the lane"}>
        <CButton color="danger" onClick={disqualifyFiltered}>TEMP DQ Filtered</CButton>
        </CTooltip>
        }
</TableDiv>
    )
}