import React, {useState, useEffect} from 'react';
import { CContainer, CRow, CCol, CCard, CCardHeader, CCardBody, CSelect, CLink} from '@coreui/react';

import RunListManager from '../../../services/RunListManager';
import { getStyle, hexToRgba } from '@coreui/utils'
import {useLocation} from 'react-router-dom';
import InventoryChart from './ChartSegment/InventoryChart';
import ChartHelper from './ChartSegment/ChartHelper';

export default (props)=>{
    const location = useLocation();
    const [runOnce] = useState(0);
    const [chartData, setChartData] = useState([]);
    const [vehicleModels, setVehicleModels] = useState([]);
    const [modelYears, setModelYears] = useState([]);
    const [make, setMake] = useState("BMW");
    const [model, setModel] = useState("BMW");
    const [upperXAxis, setUpperXAxis] = useState(5);
    const [allData, setAllData] = useState([]);
    const [yHighlights, setYHighlights] = useState([]);
    const [hasChartData, setHasChartData] = useState(false);

    useEffect(()=>{
        let makeContext = ChartHelper.setMakeFromLocation(location, setMake);
        let modelContext = ChartHelper.setModelFromLocation(location, setModel);
        //console.log("make="+ makeContext + ",model=" + modelContext);

        if (!ChartHelper.models || ChartHelper.models.length===0) {
            (new RunListManager()).getDaysSupply({limit:5000, "sortBy": 'LiveInventory_DESC', date:ChartHelper.formatDate(new Date())}).then( (data)=>{
                //in case user is opening a direct link to this page and models is not populated yet
                ChartHelper.initModelsList(data, makeContext);
                loadYearsData(makeContext, modelContext);
            }).catch(function(e){
                console.log(e);
            })    

        } else {
            loadYearsData(makeContext, modelContext);
        }
    },[runOnce])

    const loadYearsData = (makeContext, modelContext)=> {
        var rl = new RunListManager();
        rl.getDaysSupplyByMakeModel(makeContext, modelContext).then( (data)=>{
            //console.log("data=", data);
            setAllData(data);
            ChartHelper.reshapeDataByYear(data, modelContext, setVehicleModels, setModelYears, setChartData, setHasChartData, setUpperXAxis, setYHighlights);
        }).catch(function(e){
            console.log(e);
        })

    }
    const yAxisLabeler =(value, index, values)=> {
        return modelYears[modelYears.length-value];
    }

    const tooltipsTitler =(tooltipItem, data)=> {
        return modelYears[modelYears.length-tooltipItem[0].yLabel];
    }
    const tooltipsLabeler =(tooltipItem, data)=> {
        return tooltipItem.xLabel;
    }
    const chartsClickConsumer =(e, chart)=> {
    }

    return (
        <CContainer fluid className="fullHeight">
            <CRow className="fullHeight">
                <CCol>
                    <CCard className="fullHeight">
                        <CCardHeader>
                            <div className="d-flex justify-content-between">
                                <div>Live Inventory and In Prep and 45 days supply for <b><CLink to={'/inventory/chart/model?make=' + make}>{make}</CLink> {model}</b></div>
                                <div>
                                    <CSelect onChange={
                                            (e)=>{ 
                                                var newModel = e.target.options[e.target.selectedIndex].value;
                                                setModel(newModel);
                                                loadYearsData(make, newModel);
                                            }}>
                                        <option value="0">Select {make} Model</option>
                                        {vehicleModels && vehicleModels.map( (m, k)=>{
                                            return <option value={m} key={m}>{m}</option>
                                        })}
                                    </CSelect>
                                </div>
                            </div>
                        </CCardHeader>
                        <CCardBody>
                            <div className="chart_legends">
                                <span className="chart_legends_live_inventory"></span> Live Inventory and In Prep &nbsp;&nbsp;
                                <span className="chart_legends_days_supply"></span> 45 Days Supply
                            </div>
                            { hasChartData && 
                            <InventoryChart
                                    chartData={chartData}
                                    yHighlights={yHighlights}
                                    yAxisLabeler={yAxisLabeler}  
                                    tooltipsTitler={tooltipsTitler}
                                    chartsClickConsumer={chartsClickConsumer}
                                    maxYAxis={modelYears.length}
                                    maxXAxis={upperXAxis}
                                    longestYLabel={ChartHelper.longestModel}
                                    yAxisLabel={'Year'} />
                                }
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </CContainer>
    )
}