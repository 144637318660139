import React, {useState, useEffect} from 'react';
import {CContainer, CRow, CCol, CWidgetSimple, CCard, CCardHeader, CCardBody, CDataTable, CCallout} from '@coreui/react';
import ImageGallery from '../../../../components/generic/ImageGallery/ImageGallery'
import FormattedNumber from '../../../FormattedNumber/FormattedNumber';
import Damages from '../Damages/Damages';
import OLOIventory from '../OLOInventory/OLOInventory';
import OLORecentSales from '../OLOSalesHistory/OLOSalesHistory';
import OLOSalesHistory from '../OLOSalesHistory/OLOSalesHistory';
import Tires from '../Tires/Tires';
import VehicleData from '../VehicleData/VehicleData';
import AuctionDetails from '../AuctionDetails/AuctionDetails'
import ShippingPrice from '../../../ShippingPrice/ShippingPrice';
import BlackBook from './BlackBook/BlackBook';
import Carfax from '../CarFax/CarFax';
import Comments from '../Comments/Comments';
import InventoryManagement from '../InventoryManagement/InventoryManagement';
import CompetitorData from '../CompetitorData/CompetitorData';
import BidHistory from '../BidHistory/BidHistory';
import RunListManager from '../../../../services/RunListManager';

/*
TODO: This is a parent component that pulls in many subcomponents. We need to update the folder structure to match the hierarchy.

*/

export default ({vehicle, channel})=>{
    const [hasComments, setHasComments] = useState(false);
    const [announcements, setAnnouncements] = useState("");
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const {addError} = Error();

    useEffect(()=>{
        if (channel==="LiveBlock") {
            setHasComments(false);
            setLoading(true);
            var RL = new RunListManager();
            RL.getVehicleDetail(vehicle.vin, 'listing', channel, vehicle.auction_date).then( (data)=>{
                if(data.error) return addError(data.message, 500);
                if(data.data.listing.announcements){
                    vehicle.announcements = data.data.listing.announcements;
                    setAnnouncements(data.data.listing.announcements);
                    setHasComments(true);
                }
                if(data.data.listing.images){
                    var imagesData = data.data.listing.images;
                    var newImages = [];
                    for (var i=0;i<imagesData.length;i++) {
                        newImages.push({
                            original: imagesData[i].largeUrl,
                            thumbnail: imagesData[i].smallUrl
                        });
                    }
                    setImages(newImages);
                }
                setLoading(false);

            }).catch( (e)=>{
                console.log(e); 
                setLoading(false);
            });
        } else {
            if(vehicle.announcements && vehicle.announcements != 'None') {
                setAnnouncements(vehicle.announcements);
                setHasComments(true);
            } else {
                setAnnouncements("");
                setHasComments(false);
            }
        }
    }, [vehicle.vin])


    return(
        <CContainer fluid className="fullHeight">
            <CRow >
                <CCol xl="7" md="12" sm="12" className="order-lg-2 order-md-2 order-sm-2 order-xl-1">
                    {hasComments && <Comments announcements={announcements} />}
                    <OLOSalesHistory vin={vehicle.vin} vehicle={vehicle} channel={channel}/>
                    <OLOIventory vin={vehicle.vin} vehicle={vehicle} channel={channel}/>
                    <CompetitorData vin={vehicle.vin} vehicle={vehicle} channel={channel}/>
                    <Damages 
                        vin={vehicle.vin} 
                        crURL={vehicle.crURL} 
                        crAPIEligible={vehicle.crAPIEligible} 
                        channel={channel}
                        auctionDate={vehicle.auction_date}
                    />
                    <BidHistory vin={vehicle.vin} vehicle={vehicle}/>
                    
                </CCol>
                <CCol xl="5" md="12" sm="12"  className="order-lg-1 order-md-1 order-sm-1 order-xl-2">
                    <InventoryManagement vin={vehicle.vin}  make={vehicle.make}/>
                    <VehicleData vehicle={vehicle}/>
                    <ImageGallery 
                        vin={vehicle.vin} 
                        auctionDate={vehicle.auction_date}
                        channel={channel}
                        outsideLoading={channel==="LiveBlock" ? true : false}
                        outsideLoadingFlag={loading}
                        outsideImages={images}
                    />
                    <Carfax vin={vehicle.vin}/>
                    <AuctionDetails vehicle={vehicle}/>
                    <CCard>
                        <Tires
                            vin={vehicle.vin}
                            channel={channel}
                            auctionDate={vehicle.auction_date}
                        />
                    </CCard>
                </CCol>
            </CRow>
        </CContainer>
    )
}
