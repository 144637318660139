

export default function CompetitorTree(){

}

CompetitorTree.prototype = {

    formatResults: function(items){
        var uniqueVins = {}
        var mappings = {
            "Under 10K":{count:0, prices:[], children:{}},
            "10K-20K": {count:0, prices:[], children:{}},
            "20K-30K": {count:0, prices:[], children:{}},
            "30K-40K":{count:0, prices:[], children:{}},
            "40K+":{count:0, prices:[], children:{}}
        }
        items.forEach((item)=>{
            var band = this.findMileageBand(item.mileage)
            if(uniqueVins[item.competitor_vin]) return;
            if (item.current_list_price && item.current_list_price > 0) {
                uniqueVins[item.competitor_vin] = 1
                mappings[band]['count'] +=1;
                mappings[band]['prices'].push(item.current_list_price)
                mappings[band]['children'] = this.addChild(mappings[band]['children'],item.competitorModelYear)
                mappings[band]['children'][item.competitorModelYear]['count'] +=1
                mappings[band]['children'][item.competitorModelYear]['prices'].push(item.current_list_price)
                mappings[band]['children'][item.competitorModelYear]['children'].push(item)
            }
        })  
        return mappings;
    },

    findMileageBand: function(mileage){
        if (!mileage || mileage.length==0) return "All";
        if(mileage < 10000) return "Under 10K";
        if(mileage < 20000) return "10K-20K";
        if(mileage < 30000) return "20K-30K";
        if(mileage < 40000) return "30K-40K";
        return "40K+"
    },

    addChild:function(base, child){
        if(!base[child]){
            base[child] = {
                count:0,
                prices:[],
                children:[]
            }
        }
        return base
    },

    buildTableRows: function(mapping,tree){
        var rows = [];
        var keys = ['year', 'seller'];
        var depthKey = 'band'
        var mapDepth = mapping

        if(mapping.length < 1) return [];

        tree.forEach( (item)=>{
            depthKey = keys.shift();
            mapDepth = mapDepth[item].children
        })

        if(Array.isArray(mapDepth)) return mapDepth;
        
        var listings = [];
        Object.keys(mapDepth).forEach( (item)=>{
            var listing = {}
            listing[depthKey] = item
            listing['count'] = mapDepth[item].count
            listing['price'] = this.getAverage(mapDepth[item].prices);
            listings.push(listing)    
        })
        return listings
    },

    getAverage: function(arr){
        var sum = 0;
        var denominator = 0
        arr.forEach((i)=>{
            //if theres a 0 value, dont include it in the average
            if(i > 0){
                sum += i
                denominator++
            }
          
        })
        return (denominator > 0) ?  Math.round(sum / denominator) : 0;
    },


    setFields: function(tree){
        return this.fieldMap[tree.length]
    },

    fieldMap: [
        [
            {key:"band", label:"Mileage Band"},
            {key:"count", label:"Vehicle Count"},
            {key:"price", label:"Average Price"}
        ],
        [
            {key:"year", label:"Model Year"},
            {key:"count", label:"Vehicle Count"},
            {key:"price", label:"Average Price"}
        ],
        [
            {key:'competitor_vin', label:'VIN'},
            {key:'seller', label:"Seller"}, 
            {key:'current_list_price', label:"List Price"}, 
        ]
    ]

}