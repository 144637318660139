import React, {useState, useEffect} from 'react'
import OLOContainer from '../OLOContainer/OLOContainer'
import Controls from '../Controls/Controls'
import Images from '../../generic/ImageGallery/ImageGallery'
import RecentSales from '../VehicleDetail/OLOSalesHistory/OLOSalesHistory2';
import Inventory from '../VehicleDetail/OLOInventory/OLOInventory2';
import CompData from '../VehicleDetail/CompetitorData/CompetitorData2';
import BidHistory from '../VehicleDetail/BidHistory/BidHistory2';
import Damages from '../VehicleDetail/Damages/Damages2';
import VehicleDetail from '../VehicleDetail/VehicleDetail2';
import Carfax from '../VehicleDetail/CarFax/Carfax2';
import '../main.css';
import FormattedNumber from '../../FormattedNumber/FormattedNumber';
import BlackBook from '../../Main/VehicleDetail/Valuations/BlackBook/BlackBook';
import ReviewControls from '../../Main/VehicleDetail/ReviewControls/ReviewControls3'
import InventoryManagement from '../VehicleDetail/InventoryManagement/InventoryManagement';
import Announcements from '../Announcements/Announcements'
import styled from 'styled-components'
import RunListManager from '../../../services/RunListManager'
import VehicleReturned from '../VehicleDetail/VehicleData/VehicleReturned/VehicleReturned';
import CopyToClipboard from '../VehicleDetail/VehicleData/CopyToClipboard/CopyToClipboard';
import Tires from '../VehicleDetail/Tires/Tires2';
import { ShoppingIndicator } from '../VehicleDetail/ShoppingIndicator/ShoppingIndicator'



const Wrapper = styled.div`
    position:absolute;
    height:100%;
    width:100%;
    padding:29px 0 0 0;
    top:0;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar{
      width:4px;
      background:#cccccc;
    }

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
`



export default ({vehicle, updater, currentIndex, channel})=>{
    const [hasComments, setHasComments] = useState(false);
    const [announcements, setAnnouncements] = useState("");
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasShoppingAvailability, setHasShoppingAvailability] = useState(false);
    const {addError} = Error();

    useEffect(() => {
        setHasShoppingAvailability(false)
        var RL = new RunListManager();
        RL.getShoppingListDetails(vehicle.vin).then((res) => {
            if(res.error) return addError(res.message, 500);
            if(res.data.matches[0].shopping_item === 1){
                vehicle.shopping_item = res.data.matches[0].shopping_item;
                vehicle.vehicle_rank = res.data.matches[0].vehicle_rank;
                vehicle.shopping_comments = res.data.matches[0].comments;
                setHasShoppingAvailability(true);
            }
        }).catch(err => console.log(err))
    }, [vehicle.vin])

    useEffect(()=>{
        if (channel==="LiveBlock") {
            setAnnouncements('')
            setLoading(true);
            var RL = new RunListManager();
            RL.getVehicleDetail(vehicle.vin, 'listing', channel, vehicle.auction_date).then( (data)=>{
                if(data.error) return addError(data.message, 500);
                if(data.data.listing.announcements){
                    vehicle.announcements = data.data.listing.announcements;
                    setAnnouncements(data.data.listing.announcements);
                    setHasComments(true);
                }
                if(data.data.listing.images){
                    var imagesData = data.data.listing.images;
                    var newImages = [];
                    for (var i=0;i<imagesData.length;i++) {
                        newImages.push({
                            original: imagesData[i].largeUrl,
                            thumbnail: imagesData[i].smallUrl
                        });
                    }
                    setImages(newImages);
                }
                setLoading(false);

            }).catch( (e)=>{
                console.log(e); 
                setLoading(false);
            });
        } else {
            if(vehicle.announcements && vehicle.announcements != 'None') {
                setAnnouncements(vehicle.announcements);
                setHasComments(true);
            } else {
                setAnnouncements("");
                setHasComments(false);
            }
        }
    }, [vehicle.vin])

    // console.log("Vehicle detail: ", vehicle)

/*
master header: #78909c
mast line list: #cfd8dd
inventory: #843287
background:#ebeff2
*/

    return(<>
        <div className="d-flex w-100 justify-content-left shadow-sm border-bottom-1 position-absolute zTop" style={{"zIndex":"5"}}>
            <OLOContainer style={{'background':'#f8f9fa', 'color':'#6c757d', 'flexGrow':'1', "width":"100%" }}>
                <ReviewControls 
                    vehicle={vehicle} 
                    currentIndex={currentIndex} 
                    updater={updater} 
                    channel={channel}
                />
            </OLOContainer>
        </div>

    <Wrapper>
        <div className="d-flex flex-fill">
            <div className="olo-main-column">
                <div style={{"paddingTop":"71px", "width": "100%",}} className="d-flex">
                    <div className="olo-column">
                        {vehicle.trim ? (
                            <OLOContainer header="VEHICLE DETAILS">
                                {vehicle.year} {vehicle.make} {vehicle.model} ({vehicle.trim})
                            </OLOContainer>
                        ): (
                            <OLOContainer header="VEHICLE DETAILS">
                                {vehicle.year} {vehicle.make} {vehicle.model} ({vehicle.extendedModel})
                            </OLOContainer>
                        )}
                        <div>
                            <Images 
                                vin={vehicle.vin} 
                                auctionDate={vehicle.auction_date}
                                channel={channel}
                                outsideLoading={channel==="LiveBlock" ? true : false}
                                outsideLoadingFlag={loading}
                                outsideImages={images}
                            />
                            
                        </div>
                        <OLOContainer header={"VIN"}>
                          {vehicle.vin} <CopyToClipboard vin={vehicle.vin}/>
                        </OLOContainer>  
                        <OLOContainer header={"Carfax"}>
                            <Carfax vin={vehicle.vin}/>
                        </OLOContainer>  
                        <OLOContainer header={"Seller"}>
                            {vehicle.sellerName} ({vehicle.sellerTypes})
                        </OLOContainer>
                        <InventoryManagement vin={vehicle.vin} make={vehicle.make}/>
                        {hasShoppingAvailability ?  
                            <ShoppingIndicator 
                                make={vehicle.make} 
                                model={vehicle.model} 
                                rank={vehicle.vehicle_rank} 
                                comments={vehicle.shopping_comments}
                            />
                         : null}
                    </div>
                    <div className="olo-50column" style={{'flexGrow':0}}>
                        {(vehicle.status_code == 'R') && <VehicleReturned/>}
                        <Announcements text={announcements} loading={loading}/>
                        <OLOContainer header={"DAMAGES"} collapse={true}>
                            <Damages 
                                vin={vehicle.vin} 
                                crURL={vehicle.crURL} 
                                crAPIEligible={vehicle.crAPIEligible} 
                                channel={channel}
                                auctionDate={vehicle.auction_date}
                            />
                        </OLOContainer>  

                        <OLOContainer header={"AUCTION HISTORY"} compFlag={true}>
                            <BidHistory vin={vehicle.vin} vehicle={vehicle}/>
                        </OLOContainer>  
                        <Tires vin={vehicle.vin} channel={channel} auctionDate={vehicle.auction_date}/>

                    </div>

                </div>
            </div>
            <div className="olo-small-column" style={{"paddingTop":"71px"}}>
                <RecentSales vin={vehicle.vin} vehicle={vehicle} channel={channel}/>
                <Inventory vin={vehicle.vin} vehicle={vehicle} channel={channel}/>
                <OLOContainer header={"COMPETITOR DATA"} compFlag={true}>
                    <CompData vin={vehicle.vin} vehicle={vehicle} channel={channel}/>
                </OLOContainer>
            </div>
            </div>
    </Wrapper>
    </>)
}