
const PreviewTicker = {
    tickListener: null, //there's only one listener at this point but this requires refactoring if we have more
    seconds: 0,
    minutes: 0,
    timerSeconds: 0,
    isPreviewingVehicle: false,
    interval: null,
    setTickListener(cb) {
        this.tickListener = cb;
    },
    startVehiclePreview() {
        this.minutes = 0;
        this.seconds = 0;
        this.isPreviewingVehicle = true;
        if (!this.interval && this.tickListener) {
            this.interval = setInterval(()=>{
                this.processTick();
            }, 250);
        }
        if (this.tickListener) this.tickListener({showTick: true, tickColor: 'primary', tickDisplay: this.getTickDisplay()});
    },
    stopPreviewing() {
        this.minute = 0;
        this.second = 0;
        if (this.tickListener) this.tickListener({showTick: false, tickColor: 'primary', tickDisplay: ''});
    },
    processTick() {
        var secondsNow = (new Date()).getSeconds();
        if (this.timerSeconds !== secondsNow) {
            this.timerSeconds = secondsNow;
            this.seconds++;
            if (this.seconds > 59) {
                this.minutes++;
                this.seconds = 0;
            }
            if (this.minutes > 3) {
                if (this.tickListener) this.tickListener({showTick: true, tickColor: 'danger', tickDisplay: this.getTickDisplay()});
            } else if (this.minutes > 1) {
                if (this.tickListener) this.tickListener({showTick: true, tickColor: 'warning', tickDisplay: this.getTickDisplay()});
            } else {
                if (this.tickListener) this.tickListener({showTick: true, tickColor: 'primary', tickDisplay: this.getTickDisplay()});
            }
        }
    },
    getTickDisplay() {
        return this.zeroPad(this.minutes) + ":" + this.zeroPad(this.seconds);
    },
    zeroPad(num) {
        if (num.toString().length===1) {
            return "0" + num.toString();
        } else {
            return num.toString();
        }
    }

}

export default PreviewTicker;
