import React from 'react';
import MasterCardView from './MasterCardView/MasterCardView';



export default (props)=>{

    const scrollToActive = ()=>{
       setTimeout(function(){
        var masterTiles = document.getElementsByClassName('olo-master-row-selected');
        if(masterTiles.length > 0){
            masterTiles[0].scrollIntoView()
        }
       },500) 
    }
    return <>
        {props.vehicles.map( (item, k)=>{
            return <MasterCardView 
            vehicle={item} 
            key={item.vin} 
            channel={props.channel}
            active={(props.currentIndex === k)}
            clickAction={
                (e)=>{
                    e.preventDefault();
                    props.setCurrentIndex(k);
                }
            }
            />
        })}
        {scrollToActive()}
    </>

}