import React, {useState, useEffect} from 'react';
import { CChart } from '@coreui/react-chartjs'

//export default (props)=>{
export default ({chartData, yHighlights, yAxisLabeler, tooltipsTitler, chartsClickConsumer, maxYAxis, maxXAxis, longestYLabel, yAxisLabel})=>{

    const options = (()=>{
        return {
            maintainAspectRatio: false,
            legend: { position: 'top', display: false},
            scales: {
                xAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: 'Number of Vehicles'
                    },
                    ticks: {
                        max: maxXAxis,
                        min: 0,
                        stepSize: maxXAxis > 200 ? 50: 5
                    }
                }],
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: yAxisLabel
                    },
                    ticks: {
                        max: maxYAxis,
                        min: 0,
                        stepSize: 1,
                        callback: yAxisLabeler
                    }
                }]
            },
            tooltips: {
                callbacks: {
                    title: tooltipsTitler,
                    label: function(tooltipItem, data) {
                        if (tooltipItem.datasetIndex===0) {
                            return tooltipItem.xLabel + ' vehicles - ' + data.datasets[tooltipItem.datasetIndex].label + ' and In Prep';
                        } else if(tooltipItem.datasetIndex===1) {
                            return tooltipItem.xLabel + ' vehicles - ' + data.datasets[tooltipItem.datasetIndex].label;
                        }
                        return "";
                    }
                }
            },
            onClick: function(e) {
                chartsClickConsumer(e, this);
            },
            elements: {
                point: {
                  radius: 6,
                  hitRadius: 6,
                  hoverRadius: 6,
                  hoverBorderWidth: 6
                }
            },
            animation: {
                onComplete: function () {
                    //return;
                    var ctx = this.chart.ctx;

                    var metrics = ctx.measureText(longestYLabel);
                    var labelWidth = metrics.width;

                    var numYLabels = yHighlights.length;
                    var canvasHeight = ctx.canvas.clientHeight - 35;
                    var labelHeight = canvasHeight / (numYLabels+1);
                    var offset = 7 + labelHeight/2;
                    var rectHeight = numYLabels > 30 ? labelHeight-2 : labelHeight-4;
                    ctx.fillStyle = "yellow";
                    ctx.globalAlpha = 0.15;

                    for(var i=0; i<numYLabels;i++) {
                        if (yHighlights[i]===1) {
                            var yPos = numYLabels - i;
                            ctx.fillRect(5, (i * labelHeight) + offset, labelWidth, rectHeight);
                        }
                    }
                    ctx.globalAlpha = 1.0;

                    /*
                    {"min": 1, "class": "success", tip:"Matches Inventory Need" },
                    {"min": 45, "class": "warning", tip:"Be Selective" },
                    {"min": 50, "class": "danger", tip:"Does Not Match Current Inventory Needs" }
                    */
                   
                    /* test
                    ctx.fillStyle = "black";
                    ctx.fillRect(0, 0, 50, 10);
                    ctx.fillStyle = "blue";
                    var yPos = numYLabels -3;
                    ctx.fillRect(5, (yPos * labelHeight) + offset, labelWidth, rectHeight);
                    */
                }
            }
        }
    })();

    return (
        <CChart
                {...{ style: {height: '95%', width: '95%'}}}
                type="scatter"
                datasets={chartData}
                options={options}
                />
    )
}