import React,{useState, useEffect} from 'react';
import {CWidgetSimple} from '@coreui/react';
import FormattedNumber from '../../../../FormattedNumber/FormattedNumber';
import {Loader} from 'semantic-ui-react';
import RunListManager from '../../../../../services/RunListManager';
import Error from '../../../../generic/Error/Error';

export default ({vin, channel='', source = '', page = ''})=>{

    const [blackBook, setBlackBook] = useState(0);
    const [loading, setLoading] = useState(false);
    const {addError} = Error();

    useEffect( ()=>{
        var ignore = false;
        var RL = new RunListManager();
        setLoading(true);
        setBlackBook(0);
        RL.getStaticVehicleDetail(vin, "blackbook", channel, source, page).then( (data)=>{
           if(!ignore){
               if(data.error) return addError(data.message, 500);
               if (page === 'Appraisal') {
                    var newPrice = (data.data.adjusted_whole_avg) ? data.data.adjusted_whole_avg : 0;
                    setBlackBook(newPrice);
               } else {
                    var newPrice = (data.data.blackbook) ? data.data.blackbook.blackBook : 0;
                    setBlackBook(newPrice);
               }
               
               setLoading(false)
           }
        }).catch( (e)=>{
            console.log(e); 
            addError(e.message, 500);
            setLoading(false)
        })

        return ()=>{ignore =true;}

    }, [vin])

    return(
        <>
           {loading && <center><Loader inline active /></center>}
           {!loading && <><FormattedNumber value={blackBook} price={true}/></>}
        </>
    )

}