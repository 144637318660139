import React, {useState} from 'react';
import {CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle} from '@coreui/react';
import {DocFormat} from "../../OfferSheetContent"
import { DocFormat as ShiftDocFormat } from '../../Shift/Survey/SurveyContent';
import { PDFDownloadLink } from '@react-pdf/renderer';

const CustomerPreviewModal = (props) => {

    // const [show, setShow] = useState(false);
    const {show, setShow, setModalContent, isShiftData, item, saveFileButton, saveSurveyButton} = props;

    const saveFile = (item) => {
        let pdfName = (item.first_name.toLowerCase() == "n.a" || item.last_name.toLowerCase() == "n.a") ? item.vin : item.vin+'_'+item.first_name+'_'+item.last_name;
        const isCarSquadBrand = (item.shift_brand && (item.shift_brand === 'car_squad' || item.shift_brand === 'Car Squad')) || (!isShiftData && item.store === 'HOU') ? true : false;

        return(
        <PDFDownloadLink document={<DocFormat item={item} isShiftData={isShiftData} isCarSquad = {isCarSquadBrand}/>} fileName={pdfName} style={{color:'white'}}>
            {({loading}) => (loading ? 'Loading document...' : 'Download now!')}
        </PDFDownloadLink>
  )
    }

    const saveShiftSurvey = (item) => {
        let pdfName = item.vin + '-Survey';
        return(
            <PDFDownloadLink document={<ShiftDocFormat item={item} />} fileName={pdfName} style={{color:'white'}}>
            {({loading}) => (loading ? 'Loading document...' : 'Download now!')}
        </PDFDownloadLink>
        )
    }

    return (
    <div style={{
            width: '60vw',
        }} 
    >
        <CModal 
            show={show} 
            onClose={() => setShow(false)} 
            // centered={true}
            size={'xl'}
            style={{
                // width: '80vw',
                margin: '0px auto',
                padding: '0px 20px',
                backgroundColor: '#ebedef',
                justifyContent: 'center'
            }}      
        >
            <CModalHeader>
                <CModalTitle>{props.title}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                {props.children}
            </CModalBody>
            <CModalFooter>
                {/* {(saveFileButton)?<CButton color="primary" onClick={() => {saveFile(item)}}>Save File</CButton>:''} */}
                {(saveFileButton)?<CButton color="primary">{saveFile(item)}</CButton>:''}
                
                {(saveSurveyButton)?<CButton color="primary">{saveShiftSurvey(item)}</CButton>:''}
                <CButton color="secondary" onClick={() => {
                    setShow(false);
                    if(setModalContent) setModalContent({})
                }}>
                Close
                </CButton>
                {/* <CButton color="primary" 
                    onClick={() => {
                        console.log('Onclick')
                        // saveOfferToAppraisalDB();
                    }}
                >
                    Save Offer
                </CButton> */}
            </CModalFooter>
        </CModal>
    </div>
    )
}

export default CustomerPreviewModal;
