import React, {useState} from 'react';
import {CButton, CCol, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle} from '@coreui/react';
import { Loader } from 'semantic-ui-react';

const ShiftOfferConfirm = (props) => {

    // const [show, setShow] = useState(false);
    const {show, setShow, shiftLoading, setshiftLoading} = props;

    const onSubmitShiftOffer = () => {
        props.sendOfferMail().then(res => {
            props.sendOfferNote().then(res => {
                props.saveShiftOfferToAppraisalDB().then(res => {
                    setShow(false);
                })
            });
        });
}

    return (
    <>
        {/* <CButton onClick={() => setShow(!show)}>Launch static backdrop modal</CButton> */}
        <CModal style={{width: '600px', margin: 'auto'}} show={show} onClose={() => setShow(false)} size={'lg'}>
        <CModalHeader style={{background: '#00aced', color: 'white'}}>
            <CModalTitle>Offer Confirmation</CModalTitle>
        </CModalHeader>
        <CModalBody>
            <CCol style={{display: 'flex', justifyContent: 'center', alignItems:'center', flexDirection: 'column'}}>
                <h4 style={{color: 'grey'}}>
                    You are about to send an official offer to a customer with the following details:
                </h4>
                
                    <h5 style={{margin: '10px auto 5px auto'}}><strong>Vehicle Details</strong></h5>
                    <span><h3>{props.year} {props.make} {props.model} {props.trim}</h3></span>
                    <div style={{marginTop: '40px', marginBottom: '5px'}}>
                        <h4><strong>Offer Price</strong></h4>
                        </div>
                        <CButton style={{borderRadius:'4px', cursor: 'default', fontWeight: 600}}
                                color="info"
                                className={"mb-4 pt-2 pb-2"}
                                variant="outline"
                                shape="square"
                                size="lg"
                                disabled={true} > $ {parseInt(props.appraisalValue).toLocaleString()}</CButton>
                    
                    <p>Press 'confirm' to complete. Press 'cancel' to go back</p>
                
            
            </CCol>
        </CModalBody>
        <CModalFooter>
            <CButton color="secondary" onClick={() => {
                setShow(false);
            }}>
            Cancel
            </CButton>
            {(
                    <CButton color="primary" 
                        onClick={() => {
                                // props.sendOfferMail();
                                // props.sendOfferNote();
                                // props.saveShiftOfferToAppraisalDB();
                                setshiftLoading && setshiftLoading(true);
                                onSubmitShiftOffer();
                        }}
                    >
                        Confirm {shiftLoading && <Loader inline active/> }
                    </CButton>
                )
            }
        
        </CModalFooter>
        </CModal>
    </>
    )
}

export default ShiftOfferConfirm;
