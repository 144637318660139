import React, {useState, useEffect} from 'react';
import {CSelect} from '@coreui/react';
import isLocked from '../../../../services/IsLocked';
import { set } from 'lodash';

export default ({
  item,
  buyers,
  assignBuyer
}) => {
  const [activeBuyer, setActiveBuyer] = useState(item.user_id);

  useEffect(() => {
    var newVal = item.user_id ? item.user_id : "";
    setActiveBuyer(newVal);
  }, [item.user_id, item.auctionId, item.auction_lane, item.auctionStartDate]);


  return (
    <td
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <CSelect
        value={activeBuyer == null ? 0 : activeBuyer}
        disabled={isLocked(item.auction_date)}
        onChange={(e) => {
          var buyer_id = e.target.options[e.target.selectedIndex].value;
          assignBuyer(item, buyer_id);
          setActiveBuyer(buyer_id);
        }}
      >
        <option value="0">Select Buyer</option>
        {buyers &&
          buyers.map((b, k) => {
            return (
              <option value={b.user_id} key={b.user_id}>
                {b.first_name} {b.last_name}
              </option>
            );
          })}
      </CSelect>
    </td>
  );
};
