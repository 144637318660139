import React from 'react';
import { CRow, CCol} from '@coreui/react';
import BuyersReportGenerator from './BuyersReportGenerator/BuyersReportGenerator';
import PurchasesReportGenerator from './PurchasesReportGenerator/PurchasesReportGenerator';
import SellersReportGenerator from './SellerReportGenerator/SellerReportGenerator';
import ManheimDQReportGenerator from './ManheimDQReportGenerator/ManheimDQReportGenerator';
import AdesaDQReportGenerator from './AdesaDQReportGenerator/AdesaDQReportGenerator';
import AdesaPurchasesReportGenerator from './AdesaPurchasesReportGenerator/AdesaPurchasesReportGenerator';
import DownloadPreviewedVehicles from './DownloadPreviewedVehicles/DownloadPreviewedVehicles';

export default (props)=>{

    return (
    <div className="py-3">
        <CRow>
            <CCol md="6" className="offset-md-3">
                <BuyersReportGenerator />  
            </CCol>
        </CRow>
        <CRow>
            <CCol md="6" className="offset-md-3">
                <PurchasesReportGenerator />  
            </CCol>
        </CRow>

        <CRow>
            <CCol md="6" className="offset-md-3">
                <SellersReportGenerator />  
            </CCol>
        </CRow>

        
        <CRow>
            <CCol md="6" className="offset-md-3">
                <ManheimDQReportGenerator />  
            </CCol>
        </CRow>

        <CRow>
            <CCol md="6" className="offset-md-3">
                <AdesaPurchasesReportGenerator />  
            </CCol>
        </CRow>
                
        <CRow>
            <CCol md="6" className="offset-md-3">
                <AdesaDQReportGenerator />  
            </CCol>
        </CRow>
        <CRow>
            <CCol md="6" className="offset-md-3">
                <DownloadPreviewedVehicles />  
            </CCol>
        </CRow>
    </div>
    )
}