import React, {useState, useEffect} from 'react';
import { CCard, CCardHeader, CCardBody, CDataTable, CButton, CBadge} from '@coreui/react';
import RunListManager from '../../../../services/RunListManager';
import FormattedNumber from '../../../FormattedNumber/FormattedNumber';
import {Form, Loader} from 'semantic-ui-react';
import BidHistoryTree from './BidHistoryTree';
import Error from '../../../generic/Error/Error';
import CompFlag from '../../OLOContainer/CompFlag/CompFlag';

export default ({vin, vehicle, source="", compFlag})=>{

    const [bidHistory, setBidHistory] = useState({});
    const [tree, setTree] = useState([])
    const [loading, setLoading] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const {addError} = Error();
    var cTree = new BidHistoryTree();

    useEffect( ()=>{
        var ignore = false;
        var RL = new RunListManager();
        setLoading(true);
        setTree([])
        setBidHistory([]);
        RL.getAuctionVehicles(vin, source).then( (data)=>{
           if(!ignore){
             //  if(data.error) return addError(data.message, 500);
               var compData = cTree.formatResults(data.data.matches);
               setBidHistory(compData);
               setLoading(false)
           }
        }).catch( (e)=>{
            console.log(e); 
         //   addError(e.message, 500);
            setLoading(false)})

        return ()=>{ignore =true;}

    }, [vin])


    useEffect(()=>{
        var t = []
        var crBand = cTree.findCRBand(vehicle.CRScore); 
        var band = cTree.findMileageBand(vehicle.mileage); 
        //console.log("current vehicle crBand, band,", crBand, band, vehicle.CRScore, vehicle.mileage, vehicle);
        if(bidHistory[crBand] != undefined){
            t.push(crBand)
        }
        if(t.length > 0){
            if(bidHistory[crBand]['children'][band] != undefined){
                t.push(band)
            }
        }
        setTree(t)
    }, [bidHistory, vehicle.mileage, vehicle.vehicleGrade])



    const toggleCollapsed = (e)=>{e.preventDefault();setCollapsed(!collapsed)}

    const addToTree = (val)=>{

        var newTree = [...tree];
        newTree.push(val)
        setTree(newTree)
    }

    const sliceTree = (index)=>{
        var newTree = [...tree]
        newTree = newTree.slice(0,index)
        setTree(newTree)
    }

    return(
        <CCard className={ collapsed ? "animateCard olo-collapse" : "animateCard"} 
            style={source==='Appraisal' ? !collapsed ? { height: '100%'} : {} : {}}
        >
            {/* style={{ height: '100%' }} */}
            <CCardHeader className="d-flex justify-content-between align-items-center">
                <div className="olo-header">
                    <span>Auction History</span>
                    <span>{compFlag && <CompFlag/>}</span>
                </div>
                <CButton color="info" onClick={toggleCollapsed}>{collapsed ? 'Expand' : 'Collapse'}</CButton>
            </CCardHeader>

            <CCardBody className={collapsed ? 'collapse' : ''}>
                <div className="d-flex pb-3">
                    <CBadge onClick={()=>{sliceTree(0)}} size="sm" color="secondary">All</CBadge>
                    {tree.map((item)=>{
                        return <CBadge onClick={()=>{sliceTree(1)}} size="sm" color="secondary" className="ml-1">{item}</CBadge>
                    })}
                </div>

                <CDataTable
                    fields={cTree.setFields(tree)}
                    items={cTree.buildTableRows(bidHistory, tree)}
                    loading={loading}
                    sorter
                    pagination
                    itemsPerPage={10}
                    sorterValue={{ column: 'sold_price', asc: 'true' }}
                    scopedSlots={{
                        'mileage': (i)=>{
                            if (i.mileage) {
                                return <td><FormattedNumber value={i.mileage}/></td>
                            } else {
                                return <td>Unknown</td>
                            }
                        },

                        'sold_price': (item)=>{
                            return <td><FormattedNumber value={item.sold_price} price={true}/></td>
                        },
                        'price': (item)=>{
                            if(isNaN(item.price)) return <td>-</td>;
                            return <td><FormattedNumber value={item.price} price={true}/></td>
                        },

                        'band': (item)=>{
                            return <td><a className="olo_link" onClick={()=>{addToTree(item.band)}}>{item.band}</a></td>
                        },
                        'crBand': (item)=>{
                            console.log("crBand field", item);
                            return <td><a className="olo_link" onClick={()=>{addToTree(item.crBand)}}>{item.crBand}</a></td>
                        },
                        'auction_vin': (item)=>{
                            return <td>
                                {item.auction_vin}
                                <div><small>{item.vehicleGrade ? item.vehicleGrade: 'Unknown CR'} / { item.mileage ? <FormattedNumber value={item.mileage}/> : 'Unknown'}  miles</small></div>
                            </td>
                        },
                        'auction_timestamp': (item)=> {
                            return <td>{item.auction_timestamp ? item.auction_timestamp.substr(0,10): item.auction_timestamp}</td>
                        }
                    }}
                    noItemsViewSlot='  ** No bid history available for the mileage and/or condition report band'
                />
            </CCardBody>
        </CCard>
    )
}
