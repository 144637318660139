import React from 'react';
import {CForm, CInput} from '@coreui/react';




export default ({markReviewed, priceField, bsrpField, setBSRPField, yipit})=>{
    const addCommas = (num)=>{
        var formattedNum = num.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return formattedNum
    }
    return(
        <div className="d-flex flex-column">
            <div className="d-flex justify-content-between">
                <div className="olo-control-label">BSRP 
                </div>
            </div>
            <div>
                <CForm 
                    onSubmit={(e)=>{
                        e.preventDefault();
                        markReviewed(priceField, bsrpField);
                    }}
                    inline
                >
                    <div>                   
                        <CInput 
                            id="bsrp" placeholder="Enter BSRP" value={bsrpField} 
                            onChange={
                                (e)=>{
                                    e.preventDefault();
                                    var newValue = e.target.value.replace(/\D/gi, '');
                                    setBSRPField(newValue);
                            }}
                            autoComplete="off"
                            className="olo-price-field"
                        />
                    </div>
                </CForm>                            
            </div>
        </div>
    )

}