import React, {useState} from 'react';
import {CButton, CCol, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle} from '@coreui/react';
import RunListManager from '../../services/RunListManager';
import { Loader } from 'semantic-ui-react';
import {DateTime} from 'luxon';

const NoOfferConfirm = (props) => {

    var RL = new RunListManager()
    const {show, setShow, shiftLoading, setshiftLoading, vehicleDetail} = props;
    const formatBinary = (statement) => {
        return statement == '1' ? true : false;
    }

    let sendinblue_status = ''
    let worknote_status = ''
    let worknote_note = ''
    let event_id = ''
    let customer_id = ''
    let quote = '0'
    let offer_price = '0'
    let lead_type = 'no_offer'
    let expiryDate = ''
    let parsedMechanicalIssues = vehicleDetail.b_mechanical_issues_warning_lights == true ? vehicleDetail.t_mechanical_issues_warning_lights.split(",") : [];
    let parsedExteriorDamagedPanel = vehicleDetail.b_panels_damaged == true ? vehicleDetail.t_panels_damaged.split(",") : [];
    let parsedInteriorDamagedPanel = vehicleDetail.b_interior_paneling_or_seating_damaged_or_missing_parts == true ? vehicleDetail.t_interior_paneling_or_seating_damaged_or_missing_parts.split(",") : [];
    let parsedMods = vehicleDetail.b_modifications == true ? vehicleDetail.modifications.split(",") : [];

    const saveShiftOfferToAppraisalDB = async () => {
        await RL.saveShiftOfferToDB(vehicleDetail.vin, vehicleDetail.created_date, props.user, formatBinary(vehicleDetail.b_odor), formatBinary(vehicleDetail.b_starting_driving_issues),formatBinary(vehicleDetail.b_mechanical_issues_warning_lights), parsedMechanicalIssues, formatBinary(vehicleDetail.b_loan_lease), vehicleDetail.t_loan_lease,formatBinary(vehicleDetail.b_panels_damaged),parsedExteriorDamagedPanel, vehicleDetail.q_panels_damaged, formatBinary(vehicleDetail.b_vehicle_accident), (vehicleDetail.b_vehicle_accident ? vehicleDetail.t_vehicle_accident: 0),
        formatBinary(vehicleDetail.b_hail_dents_on_sides), formatBinary(vehicleDetail.b_interior_paneling_or_seating_damaged_or_missing_parts), parsedInteriorDamagedPanel, formatBinary(vehicleDetail.b_modifications), parsedMods,
        vehicleDetail.color, vehicleDetail.email, vehicleDetail.phone, vehicleDetail.address, vehicleDetail.zipcode, '0', vehicleDetail.first_name, vehicleDetail.last_name, vehicleDetail.notes, vehicleDetail.engineType, vehicleDetail.kbb_value, sendinblue_status, worknote_status, worknote_note, event_id, customer_id, vehicleDetail.shift_id, vehicleDetail.e_make,
        vehicleDetail.e_model, vehicleDetail.e_modelYear, vehicleDetail.e_trim, vehicleDetail.mileage).then(res => {
                setshiftLoading(false);
                props.getMyAppraisals();
            })
            .catch(err => {
                console.log(err);
                if (err.message == 'Network Error') 
                    props.error(err.message);
            })
    }

    const sendOfferNote = async () => {

        let datetime = DateTime.now().setZone("America/Chicago").toFormat("MM/dd/yyyy hh:mm a")
        let note = `${props.user} determined no instant offer can be made for outright purchase on ${datetime}. Customer must come into store for trade-in offer`

        await RL.sendNoteShiftOffer(vehicleDetail.shift_id, vehicleDetail.shift_lead_type, quote, vehicleDetail.store, vehicleDetail.shift_brand, vehicleDetail.shift_rooftop,
            vehicleDetail.zipcode, vehicleDetail.vin, vehicleDetail.e_modelYear, vehicleDetail.e_make, vehicleDetail.e_model, vehicleDetail.mileage, vehicleDetail.e_trim, vehicleDetail.color, vehicleDetail.first_name, vehicleDetail.last_name,
            vehicleDetail.phone, vehicleDetail.email,  note, expiryDate, offer_price, lead_type).then(res=> {
            // console.log(res, 'sendoffrnote')
            if (res.status == 200){
                worknote_status = 'Success'
                worknote_note = res.data.Note
                customer_id = res.data.EntityId
                event_id = res.data.EventId
            } else {
                worknote_status = 'Failure'
            }
        })
        .catch(err => {
            console.log(err);
            if (err.message == 'Network Error') 
                props.error(err.message);
        })
    }

    const sendOfferMail = async () => { 
        await RL.sendMailShiftOffer(vehicleDetail.shift_id, vehicleDetail.shift_rooftop, vehicleDetail.shift_brand, vehicleDetail.vin, vehicleDetail.e_modelYear, vehicleDetail.e_make, vehicleDetail.e_model,
            vehicleDetail.mileage, vehicleDetail.first_name, vehicleDetail.last_name, vehicleDetail.email, expiryDate, offer_price, lead_type).then(res => {
                // console.log(res, 'sendoffermail')
                if (res.status == 200){
                    sendinblue_status = 'Success'
                } else {
                    sendinblue_status = 'Failure'
                }
            })
            .catch(err => {
                console.log(err);
                if (err.message == 'Network Error') 
                    props.error(err.message);
            })
    }

    let dt = DateTime.fromISO(vehicleDetail.last_updated_date, { zone: "America/Chicago", setZone: true })
    var updatedDt = dt.plus({ days: 7 });
    if(vehicleDetail.last_updated_date){
        expiryDate = (updatedDt.toFormat("MM/dd/yyyy hh:mm a"))
    }
    const onSubmitShiftOffer = () => {
        sendOfferMail().then(res => {
            sendOfferNote().then(res => {
                saveShiftOfferToAppraisalDB().then(res => {
                    setShow(false);
                })
            });
        });
}

    return (
    <>
        {/* <CButton onClick={() => setShow(!show)}>Launch static backdrop modal</CButton> */}
        <CModal style={{width: '600px', margin: 'auto'}} show={show} onClose={() => setShow(false)} size={'lg'}>
        <CModalHeader style={{background: '#00aced', color: 'white'}}>
            <CModalTitle>Offer Confirmation</CModalTitle>
        </CModalHeader>
        <CModalBody>
            <CCol style={{display: 'flex', justifyContent: 'center', alignItems:'center', flexDirection: 'column'}}>
                <h4 style={{color: 'grey'}}>
                    You are about to send a 'No Offer' email to a customer with the following details:
                </h4>
                
                    <h5 style={{margin: '10px auto 5px auto'}}><strong>Vehicle Details</strong></h5>
                    <span style={{marginBottom: '15px'}}><h3>{vehicleDetail.e_modelYear} {vehicleDetail.e_make} {vehicleDetail.e_model} {vehicleDetail.e_trim}</h3></span>
                    
                    <p>Press 'confirm' to complete. Press 'cancel' to go back</p>
                
            
            </CCol>
        </CModalBody>
        <CModalFooter>
            <CButton color="secondary" onClick={() => {
                setShow(false);
            }}>
            Cancel
            </CButton>
            {(
                    <CButton color="primary" 
                        onClick={() => {
                                // props.sendOfferMail();
                                // props.sendOfferNote();
                                // props.saveShiftOfferToAppraisalDB();
                                setshiftLoading && setshiftLoading(true);
                                onSubmitShiftOffer();
                        }}
                    >
                        Confirm {shiftLoading && <Loader inline active/> }
                    </CButton>
                )
            }
        
        </CModalFooter>
        </CModal>
    </>
    )
}

export default NoOfferConfirm;
