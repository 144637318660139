import React, {useState} from 'react';
import Hero from './Hero/Hero'
import Plot from './Plot/Plot'
import CurrentInventory from './CurrentInventory/CurrentInventory';
import {
    CButton,
    CRow,
    CCol
} from '@coreui/react';

import {useHistory} from 'react-router-dom';

export default (props)=>{

    const history = useHistory();

    const [edit, setEdit] = useState(false);

    return(
        <div>
            <CRow>
                <CCol md="10" className="offset-md-1">
                    <div className="py-3 d-flex justify-content-space-between">
                        <a className="breadcrumb d-block flex-grow-1" onClick={
                            ()=>{
                                history.push("/inventory/dashboard")
                            }
                        }>&lt; Dashboard</a> 
                        <CButton color={edit ? 'success' : 'dark'} onClick={()=>{setEdit(!edit)}}>{edit ? 'Save Targets' : 'Edit Targets'}</CButton>               
                    </div>
                    
                </CCol>
            </CRow>
    

            <CRow>
                <CCol md="10" className="offset-md-1">
                    <div className="d-flex">
                        <Hero edit={edit}/>
                    </div>
                </CCol>
            </CRow>
            <CRow>
                <CCol md="10" className="offset-md-1">
                    <Plot/>
                </CCol>
            </CRow>
            <CRow>
                <CCol md="10" className="offset-md-1">
                    <div className="d-flex">
                        <CurrentInventory />
                    </div>
                </CCol>
            </CRow>

        </div>
    )
}