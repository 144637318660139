import React from 'react'
import {CButton} from '@coreui/react'

const CARFAX_RETURN_URL = process.env.REACT_APP_CARFAX_RETURN_URL


export default (props)=>{

    const openCarfax = (e)=>{
        e.preventDefault();
        const carfaxid= 'DUW2t0ohVsDu2H230pMZmw2x9ArIo3bd';
        const returnUrl = encodeURIComponent(CARFAX_RETURN_URL);
        const nonce = makeid(12);
        var url =`https://auth.carfax.com/authorize?client_id=${carfaxid}&redirect_uri=${returnUrl}
&state=${nonce}&response_type=token&audience=https%3A%2F%2Fconnect.carfax.com&scope=offline_access`
        window.open(url, 'carfaxWindow');
    }

    function makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     }


    return(
        <>
            <p>Click the button below to login to Carfax and begin displaying Carfax data</p>
            <CButton onClick={openCarfax} color="primary">Login to Carfax</CButton>
            &nbsp;&nbsp;
            <CButton onClick={()=>{props.setLoadTrigger(props.loadTrigger+1)}} color="info">Reload Carfax Widget</CButton>
        </>
    )
}