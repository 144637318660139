import React, {useState} from 'react';
import {CCol, CRow, CCard, CCardHeader, CCardBody, CNav, CNavItem, CNavLink, CBadge} from '@coreui/react'
import PricingTable from './PricingTable/PricingTable';

export default (props)=>{

    const [index, setIndex] = useState(0);



    return(
        <CRow>
            <CCol md="10" className="offset-md-1">
                <h1 className="pt-5 pb-3">Vehicle Pricing</h1>
                <CCard>
                    <CCardHeader>
                        <CNav variant="pills" fill inCard>
                            <CNavItem>
                                <CNavLink active={index === 0} onClick={ ()=>{setIndex(0)}}>
                                    Require Attention &nbsp;&nbsp;<CBadge color="light">13</CBadge>
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink active={index === 1} onClick={ ()=>{setIndex(1)}}>
                                    0-15 Days
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink active={index === 2} onClick={ ()=>{setIndex(2)}}>
                                    15-30 Days
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink active={index === 3} onClick={ ()=>{setIndex(3)}}>
                                    30-60 Days 
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink active={index === 4} onClick={ ()=>{setIndex(4)}}>
                                    60-90 Days
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink active={index === 5} onClick={ ()=>{setIndex(5)}}>
                                    90 Days+
                                </CNavLink>
                            </CNavItem>
                        </CNav>
                        
                    </CCardHeader>
                    <CCardBody>
                        <PricingTable activeIndex={index}/>
                    </CCardBody>
                </CCard>

            </CCol>

        </CRow>
    )

}