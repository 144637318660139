import React from 'react';
import {CRow, CCol, CButton} from '@coreui/react';
import MainGrid from './MainGrid/MainGrid';
import RightRail from './RightRail/RightRail';

export default (props)=>{

    return(
        <CRow>
            <CCol md="10" className="offset-md-1">
               <CRow>
                   <CCol className="py-3">
                        <div className="d-flex justify-content-end">
                            <CButton color="dark" size="lg" to="/inventory/detail/audi/q5">More Filters</CButton>
                        </div>
                   </CCol>
               </CRow>
               <CRow>
                    <CCol md="12">
                        <MainGrid />
                    </CCol>
                </CRow> 
            </CCol>
        </CRow>
    )


}
/*
                    <CCol md="3">
                        <RightRail />
                    </CCol>
*/