import React from 'react'



export default (props)=>{

    return <div className="compFlag">
        <span className="questionMark">?</span>
        <div className="compDetails">
            Comparisons are based on Year, Make, Model, StyleName, Trim, BodyType, EngineType, FuelType, and Driving Wheels
        </div>
    </div>
}