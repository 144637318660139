/* eslint-disable import/no-anonymous-default-export */
export default (auctionDate)=>{
    return false;

    var tms = (new Date().toLocaleString("en-US", {timeZone: "America/New_York"})).split(',')[1].split(':');
    var hours = parseInt(tms[0]);
    var minutes = parseInt(tms[1]);

    //console.log("hours, mins", hours, minutes);
    var locked = ((tms[2].indexOf("PM") > 0) || (hours > 8) || (hours===8 && minutes > 45));    
    return locked;
}