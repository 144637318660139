import React from 'react';
import {CContainer, CRow, CCol, CCard, CCardHeader, CCardBody, CCallout} from '@coreui/react';


export default ({vehicle})=>{


    return(
        <CRow>
        <CCol md="12" className="mt-3">
            <CCard> 
                <CCardHeader>Auction Details</CCardHeader>
                <CCardBody>
                    <CRow >
                        <CCol md="12">
                            <CCallout color="info">
                                <small className="text-muted">Seller</small>
                                <br />
                                <strong className="h4">{vehicle.sellerName} ({vehicle.sellerTypes})</strong>
                            </CCallout>
                        </CCol>
                        <CCol md="12">
                            <CCallout color="info">
                                <small className="text-muted">Pickup Location</small>
                                <br />
                                <strong className="h4">{vehicle.pickupLocation} {vehicle.pickupLocationZip && `Zip Code: ${vehicle.pickupLocationZip}`}</strong>
                            </CCallout>
                        </CCol>
                        <CCol md="12">
                            <CCallout color="info">
                                <small className="text-muted">Title Status</small>
                                <br />
                                <strong className="h4">{vehicle.titleStatus || "-"}</strong>
                            </CCallout>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
    )
}