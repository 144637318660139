import React from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image, Font } from '@react-pdf/renderer';
import {OfferDetails} from './OfferDetails';
import {AppraisalValue} from './AppraisalValue';
import OloLogo from '../../assets/images/olo_logo.png';
import carSquadLogo from '../../assets/images/carsquad_logo.png'
import ITCAvantGardeBold from '../../assets/fonts/ITC_Avant_Garde_Gothic_Bold.otf';

Font.register(
  { family: 'ITCAVGBold', src: ITCAvantGardeBold }
);

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'col',
    backgroundColor: 'white',
    width: '500'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  headerBackground: {
    flex: 3, 
  }

});

const Header = ({item, isShiftData, isCarSquad}) => {

  return (
    <View 
        style={{flexDirection: 'row', height: '80px', position: 'relative', backgroundColor: isCarSquad ? '#47389d': '#922486'}}
    >
      <View style={{
              height: '90%',
              position: 'absolute',
              marginLeft: 'auto',
              marginRight: 'auto',
              left: 0,
              right: 0,
              textAlign: 'center',
              top: '10%',
              justifyContent: 'center'
            }}>
          <Image src={isCarSquad ? carSquadLogo : OloLogo} 
            style={{width: '20%', height: '44%', margin: '1px auto'}}
          />
          <Text 
              style={{fontFamily: 'ITCAVGBold', 
                fontSize: '26px', color: 'white', marginTop: '0px', textTransform: 'uppercase'
              }}>
              Appraisal Value
            </Text>
        </View>
    </View>
)};


export const DocFormat = ({item, isShiftData, isCarSquad}) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <Header item={item} isShiftData={isShiftData} isCarSquad = {isCarSquad}/>
            <OfferDetails item={item} isShiftData={isShiftData} isCarSquad = {isCarSquad}/>
            <AppraisalValue item={item} isShiftData={isShiftData} isCarSquad = {isCarSquad}/>
        </Page>
    </Document>
    
);

export const OfferSheetContent = ({item, isShiftData}) => {

  const isCarSquadBrand = (item.shift_brand && (item.shift_brand === 'car_squad' || item.shift_brand === 'Car Squad')) || (!isShiftData && item.store === 'HOU') ? true : false

    return (
      <div>
        <PDFViewer showToolbar={false} style={{ width: '100%', height: '75vh', backgroundColor: 'white'}}>
            <DocFormat item={item} isShiftData={isShiftData}  isCarSquad = {isCarSquadBrand}/>
        </PDFViewer>
  </div>
    )
};
