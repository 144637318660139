import { getStyle, hexToRgba } from '@coreui/utils';

const ChartHelper = {
    liveInventoryColor: '#20a8d8',
    daysSupplyColor: '#f86c6b',
    greenSupplyColor: '#20b820',
    yellowSupplyColor: '#f9b115',
    redSupplyColor: '#e55353',
    hiddenColor: '#FFFFFF',
    hiddenLineColor: '#909090',
    longestMake: "",
    longestModel: "",
    models: [],
    reshapeDataByMake(rawData, setVehicleMakes, setChartData, setHasChartData, setUpperXAxis, setYHighlights){
        this.rawData = rawData;
        if (rawData && rawData.data.days_supply && rawData.data.days_supply.length > 0) {
            var days_supply = rawData.data.days_supply;
            var byMakeData = [];
            for (var i=0;i<days_supply.length;i++) {
                var ds = days_supply[i];
                var makeList = byMakeData[ds.dimension1];
                if (makeList) {
                    makeList.liveInventory = makeList.liveInventory + ds.LiveInventory;
                    makeList.supply45Day = makeList.supply45Day + ds.Supply45Day;
                    makeList.models.push({model: ds.dimension2, liveInventory: ds.hiddenColor, supply45Day: ds.Supply45Day});
                } else {
                    makeList = {
                        make: ds.dimension1,
                        liveInventory: ds.LiveInventory,
                        supply45Day: ds.Supply45Day,
                        models: []
                    }
                    makeList.models.push({model: ds.dimension2, liveInventory: ds.LiveInventory, supply45Day: ds.Supply45Day});
                    byMakeData[ds.dimension1] = makeList;
                }
            }

            var makes = Object.keys(byMakeData);
            var hi = makes.length;
            var inventory = [];
            var daysSupply = [];
            var connectedDatasets = [];
            var maxX = 0;
            var yHighlights = [];
            this.longestMake = "";
            var longestMakeLen = 0;
            for(var i=0;i<hi;i++) {
                var makeName = makes[i];
                if (makeName.length > longestMakeLen) {
                    longestMakeLen = makeName.length;
                    this.longestMake = makeName;
                }
                var make = byMakeData[makeName];
                var ptInventory = {x: Math.round(make.liveInventory), y: hi-i};
                var ptDaysSupply = {x: Math.round(make.supply45Day), y: hi-i};
                inventory.push(ptInventory);
                daysSupply.push(ptDaysSupply);
                connectedDatasets.push(this.makeConnectedDataSet(ptInventory, ptDaysSupply));

                if (make.liveInventory > maxX) maxX = make.liveInventory;
                if (make.supply45Day > maxX) maxX = make.supply45Day;

                if ( ((make.supply45Day - make.liveInventory) / make.supply45Day) >= .3) {
                    yHighlights.push(1);
                } else {
                    yHighlights.push(0);
                }
            }
            var cData = [
                    this.makeDataSet('Live Inventory', this.liveInventoryColor, inventory),
                    this.makeDataSet('45 Days Supply', this.daysSupplyColor, daysSupply),
                    ...connectedDatasets
            ]
            //cData.push(...connectedDatasets);

            maxX = maxX + 100;
            maxX = Math.round(maxX/100) * 100;
            setUpperXAxis(maxX);
            setYHighlights(yHighlights);

            makes.unshift("");
            setVehicleMakes(makes);
            setChartData(cData);
            setHasChartData(true);
        }
    },

    reshapeDataByModel(rawData, makeContext, setVehicleMakes, setVehicleModels, setChartData, setHasChartData, setUpperXAxis, setYHighlights) {
        if (rawData && rawData.data.days_supply && rawData.data.days_supply.length > 0) {
            var days_supply = rawData.data.days_supply;
            var models = [];
            var makes = {};
            var modelsData = [];
            this.longestModel = "";
            var longestModelLen = 0;
            for (var i=0;i<days_supply.length;i++) {
                var ds = days_supply[i];
                if (ds.dimension1===makeContext) {
                    models.push(ds.dimension2);
                    modelsData.push({liveInventory: ds.LiveInventory, supply45Day: ds.Supply45Day});

                    if (ds.dimension2.length > longestModelLen) {
                        longestModelLen = ds.dimension2.length;
                        this.longestModel = ds.dimension2;
                    }
    
                }
                makes[ds.dimension1] = ds.dimension1;

            }
            this.models = [...models];

            var hi = models.length;
            var inventory = [];
            var daysSupply = [];
            var maxX = 0;
            var yHighlights = [];

            var connectedDatasets = [];
            for(var i=0;i<hi;i++) {
                var model = modelsData[i];
                var ptInventory = {x: Math.round(model.liveInventory), y: hi-i};
                var ptDaysSupply = {x: Math.round(model.supply45Day), y: hi-i};
                inventory.push(ptInventory);
                daysSupply.push(ptDaysSupply);
                connectedDatasets.push(this.makeConnectedDataSet(ptInventory, ptDaysSupply));

                if (model.liveInventory > maxX) maxX = model.liveInventory;
                if (model.supply45Day > maxX) maxX = model.supply45Day;

                if ( ((model.supply45Day - model.liveInventory) / model.supply45Day) >= .3) {
                    yHighlights.push(1);
                } else {
                    yHighlights.push(0);
                }

            }
            maxX = maxX + 10;
            maxX = Math.round(maxX/10) * 10;
            setUpperXAxis(maxX);


            var cData = [
                this.makeDataSet('Live Inventory', this.liveInventoryColor, inventory),
                this.makeDataSet('45 Days Supply', this.daysSupplyColor, daysSupply),
                ...connectedDatasets
            ]

            try {
                setVehicleMakes(Object.keys(makes));
                setYHighlights(yHighlights);
    
                models.unshift("");
                setVehicleModels(models);
                setChartData(cData);
                setHasChartData(true);
            } catch(err) {
                console.log("err", err);
            }
        }
    },

    reshapeDataByYear(rawData, model, setVehicleModels, setModelYears, setChartData, setHasChartData, setUpperXAxis, setYHighlights) {
        if (rawData && rawData.data.days_supply && rawData.data.days_supply.length > 0) {
            var days_supply = rawData.data.days_supply;

            var hi = days_supply.length;
            var years = [];
            var inventory = [];
            var daysSupply = [];
            var maxX = 0;
            var yHighlights = [];

            var connectedDatasets = [];
            for(var i=0;i<hi;i++) {
                var model = days_supply[i];
                var ptInventory = {x: Math.round(model.LiveInventory), y: hi-i};
                var ptDaysSupply = {x: Math.round(model.Supply45Day), y: hi-i};
                years.push(model.dimension3.toString());
                inventory.push(ptInventory);
                daysSupply.push(ptDaysSupply);
                connectedDatasets.push(this.makeConnectedDataSet(ptInventory, ptDaysSupply));

                if (model.LiveInventory > maxX) maxX = model.LiveInventory;
                if (model.Supply45Day > maxX) maxX = model.Supply45Day;

                if ( ((model.Supply45Day - model.LiveInventory) / model.Supply45Day) >= .3) {
                    yHighlights.push(1);
                } else {
                    yHighlights.push(0);
                }

            }
            maxX = maxX + 10;
            maxX = Math.round(maxX/10) * 10;
            setUpperXAxis(maxX);

            var cData = [
                this.makeDataSet('Live Inventory', this.liveInventoryColor, inventory),
                this.makeDataSet('45 Days Supply', this.daysSupplyColor, daysSupply),
                ...connectedDatasets
            ]

            try {
                setYHighlights(yHighlights);
                years.unshift("");

                if (this.models.length > 0 && this.models[0].length===0) {
                    this.models.shift();
                }
                //console.log("years=", years);
                setVehicleModels(this.models);
                setModelYears(years);
                setChartData(cData);
                setHasChartData(true);
            } catch(err) {
                console.log("err", err);
            }
        }
    },

    initModelsList(rawData, makeContext) {
        if (rawData && rawData.data.days_supply && rawData.data.days_supply.length > 0) {
            var days_supply = rawData.data.days_supply;
            var models = [];
            for (var i=0;i<days_supply.length;i++) {
                var ds = days_supply[i];
                if (ds.dimension1===makeContext) {
                    models.push(ds.dimension2);
                }
            }
            this.models = [...models];
        }
    },

    makeDataSet(title, color, data){
        return {
            label: title,
            backgroundColor: hexToRgba(color, 100),
            borderColor: color,
            pointHoverBackgroundColor: color,
            borderWidth: 2,
            axis: 'y',
            showLine: false,
            data: data               
        };
    },
    makeConnectedDataSet(pt1, pt2) {
        return {
            borderWidth: 1,
            borderColor: this.hiddenLineColor,
            showLine: true,
            fill: false,
            data: [pt1, pt2]               
        };
    },
    formatDate(d){
        var dStr = d.getFullYear() +"-"
        var mn = d.getMonth()
        dStr += (mn < 9) ? '0'+(mn+1) : (mn+1)
        dStr += '-'
        var day = d.getDate()
        dStr += (day < 10) ? '0'+day : day;
        return dStr;
    },

    setMakeFromLocation(location, setMake) {
        let search = location.search;
        let makePos = search.indexOf("make=");
        let makeContext = '';
        if (makePos >= 0) {
            makeContext = search.substring(makePos + 5);
            if (makeContext.indexOf("&") > 0) {
                makeContext = makeContext.substring(0, makeContext.indexOf("&"));
            }
            makeContext = makeContext.replace("%20", " ");
            makeContext = makeContext.trim();
            if (makeContext && makeContext.length > 0) {
                setMake(makeContext);
            }
        }
        return makeContext;
    },
    setModelFromLocation(location, setModel) {
        let search = location.search;
        let modelPos = search.indexOf("model=");
        let modelContext = '';
        if (modelPos >= 0) {
            modelContext = search.substring(modelPos + 6);
            modelContext = modelContext.replace("%20", " ");
            modelContext = modelContext.trim();
            if (modelContext && modelContext.length > 0) {
                setModel(modelContext);
            }
        }
        return modelContext;
    }

}

export default ChartHelper;
