import React, {useState, useEffect} from 'react';
import {
    CRow,CCol,CDropdown,CDropdownToggle,
    CDropdownItem,CDropdownMenu,CInput, CButton,
    CCard, CCardHeader, CCardBody, CTextarea, CTooltip
} from '@coreui/react';
import {DateTime} from 'luxon';
import { CIcon } from '@coreui/icons-react';
import {cilInfo} from '@coreui/icons';
import {withRouter} from 'react-router-dom';
import CustomerPreviewModal from '../CustomerPreviewModal';
import RunListManager from '../../services/RunListManager';
import Error from '../generic/Error/Error';
import {MultiSelectDropdown} from '../MultiSelectDropDown';
import { paintOptions, modificationItem, interiorDamagesItem, exteriorDamagesItem, mechanicalIssuesItem, glassOptions, vehicleFeaturesOptions } from '../../constants/vehicleParts';
import { CommentBox } from './CommentBox/CommentBox';

const reference = {
    'Low Mileage/Great Conditon': 'This vehicle has low mileage and is in great condition.',
'Low Mileage/Average Condition': 'This vehicle has low mileage and has a few damages.',
'Low Mileage/Poor Condition' : 'This vehicle has low mileage and has several damages.',
'Average Mileage/Great Condition':  'This vehicle has an average amount of miles and is in great condition.',
'Average Mileage/Average Condition': 'This vehicle has an average amount of miles and has a few damages.',
'Average Mileage/Poor Condition' : ' This vehicle has an average amount of miles and has several damages.',
'High Mileage/Great Condition' : ' This vehicle has a high amount of miles but is in great condition.',
'High Mileage/Average Condition' : ' This vehicle has a high amount of miles and a few damages.',
'High Mileage/Poor Condition' : ' This vehicle has a high amount of miles and several damages.',
}

const handleInputChange = (e, setterFn, limit='40') => {
    const updatedValue = e.target.value;

    if (updatedValue) {
        let updatedArray = updatedValue.split(" ");

        for (let i = 0; i < updatedArray.length; i++) {
            if (updatedArray[i]) {
                updatedArray[i] = updatedArray[i][0].toUpperCase() + updatedArray[i].substr(1);
            }            
        }

        const formattedValue = updatedArray.join(" ");

        if (formattedValue.length <= limit) {
            setterFn(formattedValue)
        }        
    } else {
        setterFn(updatedValue)
    }
    
}

const DropDownElement = ({label, values, value='', disabled=false, onChangeHandler}) => {
    return (
        <div style={{margin: '5px 0px'}}>
            {label && <strong>{label}:</strong>}
            <CDropdown>
                <CDropdownToggle color="secondary" style={{background: 'white', width: '100%', textAlign: 'left'}}>
                    {value != '' ? value : 'Select'}
                </CDropdownToggle>
                <CDropdownMenu style={{background: 'white', width: '80%'}}
                >
                    {
                        values.map(value => {
                            return <CDropdownItem href="#" onClick={(e) => {
                        onChangeHandler(e.target.innerText)
                    }}>{value}</CDropdownItem>
                        })
                    }
                </CDropdownMenu>
            </CDropdown>
        </div>
    )
}

const VehicleDetailsComponent = ({label, value, disabled=false, onChangeHandler}) => {
    return (
        <div style={{margin: '5px 0px'}}>
            <>{label}:</>
            {/* <>{`  ${value}`}</> */}
            <CInput 
                type="text" 
                placeholder={label} 
                value={value} 
                disabled={disabled}
                style={{background: 'white'}}
                onChange={(e) => handleInputChange(e, onChangeHandler)}
            />
        </div>
    )
}

const CustomerOffer = (props) => {
    var RL = new RunListManager();
    // const history = useHistory();
    // const {state: {item: vehicle}} = props.location;
    const {modalViewContent: vehicle, action, setShowViewModal, getMyAppraisals, superUser, user, claimAppraisal, isShiftData } = props;

    const [loading, setLoading ] = useState(false);
    const [expiryDate, setExpiryDate] = useState('');
    const [odometer, setOdomter] = useState('');
    const [fwd, setFwd] = useState('');
    const [seatStains, setSeatStains] = useState('');
    const [seatRips, setSeatRips] = useState('');
    const [transmission, setTransmission] = useState('');
    const [tires, setTires] = useState('');
    const [wheels, setWheels] = useState('');
    const [carpet, setCarpet] = useState('');
    const [engine, setEngine] = useState('');
    const [odor, setOdor] = useState('');
    const [paint, setPaint] = useState([]);
    const [parsedPaint, setParsedPaint] = useState('');
    const [glass, setGlass] = useState([]);
    const [parsedGlass, setParsedGlass] = useState('');
    const [vehicleFeatures, setVehicleFeatures] = useState([]);
    const [parsedVehicleFeatures, setParsedVehicleFeatures] = useState('');
    const [mods, setMods] = useState([]);
    const [kbb, setKbb] = useState('');
    const [kbbBorder, setKbbBorder] = useState(false)
    const [appraisalBorder, setAppraisalBorder] = useState(false)
    const [appraisalBorder2, setAppraisalBorder2] = useState(false)
    const [appraisalRequiredMsg, setAppraisalRequiredMsg] = useState(false)
    // const [jdPower, setJDPower] = useState('');
    // const [mmr, setMMR] = useState('');
    const [appraisalValue, setAppraisalValue] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [color, setColor] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [email, setEmail] = useState('');
    const [notes, setNotes] = useState('');
    const [notesRemChars, setNotesRemChars] = useState(0);
    const [engineType, setEngineType] = useState('')
    const [e_make, setMake] = useState('')
    const [e_model, setModel] = useState('')
    const [e_modelYear, setModelYear] = useState('')
    const [e_trim, setTrim] = useState('')
    const [mileage, setMileage] = useState('')
    const [oilLeaks, setOilLeaks] = useState('No')

    //New shift fields
    const [persistentOdor, setPersistentOdor ] = useState('No')
    const [hasIssuesInStartingOrDriving, setHasIssuesInStartingOrDriving] = useState('No')
    const [hasMechanicalIssues, setHasMechanicalIssues] = useState('No');
    const [mechanicalIssues, setMechanicalIssues] = useState([]);
    const [parsedMechanicalIssues, setParsedMechanicalIssues] = useState('');
    const [hasLoanOrLease, setHasLoanOrLease] = useState('No');
    const [loanOrLease, setLoanOrLease] = useState('');
    const [isExteriorPanelDamaged, setIsExteriorPanelDamaged] = useState('No')
    const [exteriorDamagedPanel, setExteriorDamagedPanel] = useState([])
    const [parsedExteriorDamagedPanel, setParsedExteriorDamagedPanel] = useState('')
    const [exteriorDamagedPanelCount, setExteriorDamagedPanelCount] = useState(0)
    const [hasBeenInAccident, setHasBeenInAnAccident] = useState('No')
    const [accidentCount, setAccidentCount] = useState(0)
    const [hasHailOrDents, setHasHailOrDents] = useState('No');
    const [isInteriorPanellingDamaged, setIsInteriorPanellingDamaged] = useState('No');
    const [interiorDamagedPanel, setInteriorDamagedPanel] = useState([])
    const [parsedInteriorDamagedPanel, setParsedInteriorDamagedPanel] = useState('')
    const [hasModifications, setHasModifications] = useState('No')
    const [modifications, setModifications] = useState([])
    const [parsedModifications, setParsedModifications] = useState('');

    const [showPreview, setShowPreview] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const {addError} = Error();

    const [vehicleComment, setVehicleComment] = useState('')

    useEffect(() => {
      setNotes(reference[`${vehicleComment}`])
    }, [vehicleComment])
    
    
    const saveOfferToAppraisalDB = () => {
        RL.saveAppraisalOfferToDB(vehicle.vin, vehicle.created_date, user, odometer, fwd, wheels, seatStains, transmission, 
            tires, seatRips, carpet, engine, odor, parsedPaint, parsedGlass, mods, parsedVehicleFeatures, 
            color, email, phone, address, zipcode, appraisalValue, firstName, lastName, notes, engineType, kbb, e_make,
            e_model, e_modelYear, e_trim, mileage, formatBooleans(oilLeaks))
            .then(res => {
                setShowPreview(false);
                setShowViewModal(false);
                getMyAppraisals();
            })
            .catch(err => {
                console.log(err);
                if (err.message == 'Network Error') 
                    addError(err.message);
            })
    }

    const formatBooleans = (statement) => {
        return statement === 'Yes' ? true : false;
    }

    let sendinblue_status = ''
    let worknote_status = ''
    let worknote_note = ''
    let event_id = ''
    let customer_id = ''

    const saveShiftOfferToAppraisalDB = async () => {
        await RL.saveShiftOfferToDB(vehicle.vin, vehicle.created_date, user, formatBooleans(persistentOdor), formatBooleans(hasIssuesInStartingOrDriving),formatBooleans(hasMechanicalIssues), parsedMechanicalIssues, formatBooleans(hasLoanOrLease), loanOrLease,formatBooleans(isExteriorPanelDamaged),parsedExteriorDamagedPanel, exteriorDamagedPanelCount, formatBooleans(hasBeenInAccident), accidentCount,formatBooleans(hasHailOrDents), formatBooleans(isInteriorPanellingDamaged), parsedInteriorDamagedPanel, formatBooleans(hasModifications), parsedModifications,
            color, email, phone, address, zipcode, appraisalValue, firstName, lastName, notes, engineType, kbb, sendinblue_status, worknote_status, worknote_note, event_id, customer_id, vehicle.shift_id, e_make,
            e_model, e_modelYear, e_trim, mileage).then(res => {
                setLoading(false)
                setShowPreview(false)
                setShowViewModal(false)
                getMyAppraisals()
            })
            .catch(err => {
                console.log(err);
                if (err.message == 'Network Error') 
                    addError(err.message);
            })
    }

    const sendOfferNote = async () => {
        let datetime = DateTime.now().setZone("America/Chicago").toFormat("MM/dd/yyyy hh:mm a")
        let note = ``
        if (vehicle.shift_lead_type == 'manual_quote') {
            note = `${user} added manual offer of $${parseInt(appraisalValue).toLocaleString()} for ${e_modelYear} ${e_make} ${e_model} on ${datetime}`
        } else if(vehicle.shift_lead_type == 'instant_quote') {
            note = `${user} changed instant offer. New offer is $${parseInt(appraisalValue).toLocaleString()} for ${e_modelYear} ${e_make} ${e_model} on ${datetime}`
        }
        await RL.sendNoteShiftOffer(vehicle.shift_id, vehicle.shift_lead_type, appraisalValue*100, vehicle.store, vehicle.shift_brand, vehicle.shift_rooftop,
        zipcode, vehicle.vin, e_modelYear, e_make, e_model, mileage, e_trim, color, firstName, lastName, phone, email, note, expiryDate, parseInt(appraisalValue).toLocaleString(), vehicle.shift_lead_type).then(res=> {
            // console.log(res, 'sendoffrnote')
            if (res.status == 200){
                worknote_status = 'Success'
                worknote_note = res.data.Note
                customer_id = res.data.EntityId
                event_id = res.data.EventId
            } else {
                worknote_status = 'Failure'
            }
        })
        .catch(err => {
            console.log(err);
            if (err.message == 'Network Error') 
                addError(err.message);
        })
    }

    const sendOfferMail = async () => { 
        await RL.sendMailShiftOffer(vehicle.shift_id, vehicle.shift_rooftop, vehicle.shift_brand, vehicle.vin, e_modelYear, e_make, e_model,
            mileage, firstName, lastName, email, expiryDate, parseInt(appraisalValue).toLocaleString(), vehicle.shift_lead_type).then(res => {
                // console.log(res, 'sendoffermail')
                if (res.status == 200){
                    sendinblue_status = 'Success'
                } else {
                    sendinblue_status = 'Failure'
                }
            })
            .catch(err => {
                console.log(err);
                if (err.message == 'Network Error') 
                    addError(err.message);
            })
    }

    function formatPhoneNumber(phoneNumberString) {
        let phone = phoneNumberString.replace(/\D/g, '');
        const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
        if (match) {
        phone = `(${match[1]}${match[2] ? ') ' : ''}${match[2]}${match[3] ? '-' : ''}${match[3]}`;
        }
        return phone
    }

    const numericChangeHandler = (e, setterFunc, limit) => {
        const updatedValue = e.target.value;

        if(updatedValue) {
            if (!isNaN(updatedValue) && limit >= updatedValue.length) {
                setterFunc(updatedValue)
            }
        } else {
            setterFunc('');
        }
    }

    const phoneHandleChange = (e) => {
        if(e.length <= 14) {
            const formattedNumber = formatPhoneNumber(e);
            setPhone(formattedNumber);
        }
    }

    const multiSelectHandleChange = (selected, setterFunc, parsedSetterFunc) => {
        let updatedSelection;
        let updatedArray = [];
        if (Array.isArray(selected)) {
            updatedSelection = selected.map(element => {     
                return element.value;
            });
        }

        for (let element of updatedSelection) {
            const selectionItem = element.split('-')[0];            
            const filteredValues = updatedSelection.filter(item => {

                if(item.indexOf(selectionItem) > -1) {
                    return item;
                } else {
                    return false
                }                 
            });
            updatedArray.push(filteredValues.pop());          
        }

        // console.log(updatedSelection);
        // console.log(updatedArray);
        // console.log(new Set(updatedArray));
        updatedArray = [...new Set(updatedArray)];
        let updatedSelected = updatedArray.map(element => ({label: element, value: element}))

        setterFunc(updatedSelected);
        parsedSetterFunc(updatedArray);
    }

    const notesChangeHandler = e => {
        const updatedValue = e.target.value;
        
        if(updatedValue.length <= 340) {
            setNotes(updatedValue);
            setNotesRemChars(340 - updatedValue.length);
        }
    }


    const AppraisalChangeHandler = (e) => {
        const apprValue = e.target.value
        if(apprValue === ""){
            setDisableButton(true)
            setAppraisalRequiredMsg(true)
        } else if( apprValue !== ""){
            setAppraisalRequiredMsg(false)
            setDisableButton(false)
            if(Number(apprValue) < Number(kbb)) {
                setAppraisalBorder(true)
                setDisableButton(true)
            } else {
                setAppraisalBorder(false)
                setDisableButton(false)
            }
            if(Number(apprValue) > Number(100000)) setAppraisalBorder2(true)
            else setAppraisalBorder2(false)
    } 
    }

    const kbbChngeHandler = e => {
        const kValue = e.target.value;
        setKbb(kValue)
        if(Number(kValue) > Number(appraisalValue)) {
            setKbbBorder(true)
            setDisableButton(true)
        } else {
            setKbbBorder(false)
            setDisableButton(false)
        }
    }

    useEffect(() => {
        setMechanicalIssues(hasMechanicalIssues == 'No' ? [] : mechanicalIssues);
        setParsedMechanicalIssues(hasMechanicalIssues == 'No' ? '' : parsedMechanicalIssues);

        setExteriorDamagedPanel(isExteriorPanelDamaged == 'No' ? [] : exteriorDamagedPanel);
        setParsedExteriorDamagedPanel(isExteriorPanelDamaged == 'No' ? '' : parsedExteriorDamagedPanel);

        setInteriorDamagedPanel(isInteriorPanellingDamaged == 'No' ? [] : interiorDamagedPanel)
        setParsedInteriorDamagedPanel(isInteriorPanellingDamaged == 'No' ? '' : parsedInteriorDamagedPanel)

        setModifications(hasModifications == 'No' ? [] : modifications)
        setParsedModifications(hasModifications == 'No' ? '' : parsedModifications)

    }, [hasMechanicalIssues, isExteriorPanelDamaged, isInteriorPanellingDamaged, hasModifications])



    useEffect(() => {
        setOdomter(action == `Generate` ? 'Good' : vehicle.odometer);
        setFwd(action == `Generate` ? 'Good' : vehicle.t_4wd_awd);
        setSeatStains(action == `Generate` ? 'Good' : vehicle.seats_stains);
        setTransmission(action == `Generate` ? 'Good' : vehicle.transmission);
        setTires(action == `Generate` ? 'Good' : vehicle.tires);
        setSeatRips(action == `Generate` ? 'Good' : vehicle.seats_rips_tears);
        setWheels(action == `Generate` ? 'Good' : vehicle.wheels);
        // setFSeats(action == `Generate` ? '' : vehicle.front_seats);
        setCarpet(action == `Generate` ? 'Good' : vehicle.carpet);
        setEngine(action == `Generate` ? 'Good' : vehicle.engine);
        setOdor(action == `Generate` ? 'Good' : vehicle.odor);
        
        // setMods(action == `Generate` ? 'No' : vehicle.modifications);
        setKbb(action == `Generate` ? '' : vehicle.kbb_value);
        // setJDPower(action == `Generate` ? '' : vehicle.jd_power_value);
        // setMMR(action == `Generate` ? '' : vehicle.mmr);

        //Shift fields
        setPersistentOdor(vehicle.b_odor ? 'Yes' : 'No')
        setHasIssuesInStartingOrDriving(vehicle.b_starting_driving_issues ? 'Yes' : 'No')
        setHasMechanicalIssues(vehicle.b_mechanical_issues_warning_lights ? 'Yes' : 'No')

        let parsedMechanicalIssues = vehicle.b_mechanical_issues_warning_lights == true ? vehicle.t_mechanical_issues_warning_lights.split(",") : [];
        let mechanicalIssues = parsedMechanicalIssues.map(item => ({label: item.replace(/_/g, " "), value: item}))
        setParsedMechanicalIssues(parsedMechanicalIssues);
        setMechanicalIssues(mechanicalIssues)

        setHasLoanOrLease(vehicle.b_loan_lease ? 'Yes' : 'No')
        setLoanOrLease(vehicle.t_loan_lease)
        setIsExteriorPanelDamaged(vehicle.b_panels_damaged ? 'Yes' : 'No')

        let parsedExteriorDamagedPanel = vehicle.b_panels_damaged == true ? vehicle.t_panels_damaged.split(",") : [];
        let exteriorDamagedPanel = parsedExteriorDamagedPanel.map(item => ({label: item.replace(/_/g, " "), value: item}))
        setParsedExteriorDamagedPanel(parsedExteriorDamagedPanel);
        setExteriorDamagedPanel(exteriorDamagedPanel)

        setExteriorDamagedPanelCount(vehicle.q_panels_damaged);
        setHasBeenInAnAccident(vehicle.b_vehicle_accident ? 'Yes' : 'No')
        setAccidentCount(vehicle.b_vehicle_accident ? vehicle.t_vehicle_accident: 0)
        setHasHailOrDents(vehicle.b_hail_dents_on_sides ? 'Yes' : 'No');

        setIsInteriorPanellingDamaged(vehicle.b_interior_paneling_or_seating_damaged_or_missing_parts ? 'Yes' : 'No')

        let parsedInteriorDamagedPanel = vehicle.b_interior_paneling_or_seating_damaged_or_missing_parts == true ? vehicle.t_interior_paneling_or_seating_damaged_or_missing_parts.split(",") : [];
        let interiorDamagedPanel = parsedInteriorDamagedPanel.map(item => ({label: item.replace(/_/g, " "), value: item}))
        setParsedInteriorDamagedPanel(parsedInteriorDamagedPanel);
        setInteriorDamagedPanel(interiorDamagedPanel)

        setHasModifications(vehicle.b_modifications ? 'Yes' : 'No')

        let parsedMods = vehicle.b_modifications == true ? vehicle.modifications.split(",") : [];
        let modificationsObj = parsedMods.map(item => ({label: item.replace(/_/g, " "), value: item}))
        setParsedModifications(parsedMods);
        setModifications(modificationsObj);


        let parsedPaint = action == `Generate` ? [] : vehicle.paint_body.split(', ');
        let paint = parsedPaint.map(item => ({label: item, value: item})) 
        setParsedPaint(parsedPaint);               
        setPaint(paint);
        // setPaint(action == `Generate` ? 'Good' : paint);

        let parsedGlass = action == `Generate` ? [] : vehicle.glass.split(', ');
        let glass = parsedGlass.map(item => ({label: item, value: item}))
        setParsedGlass(parsedGlass);
        setGlass(glass);

        let parsedVehFeatures = action == `Generate` ? [] : vehicle.vehicle_features.split(', ');
        let vehFeatures = parsedVehFeatures.map(item => ({label: item, value: item}))
        setParsedVehicleFeatures(parsedVehFeatures);
        setVehicleFeatures(vehFeatures);

        setAppraisalValue(vehicle.appraisal_value ? vehicle.appraisal_value : '');

        // newly enabled fields
        setFirstName(vehicle.first_name);
        setLastName(vehicle.last_name);
        setColor(action == `Generate` ? '' : vehicle.color);
        setPhone(vehicle.phone);
        setEmail(vehicle.email);
        setZipcode(vehicle.zipcode);
        setAddress(vehicle.address);
        setNotes(action == `Generate` ? '' : vehicle.notes);
        setEngineType(vehicle.engineType);
        setMake(vehicle.e_make ? vehicle.e_make : '');
        setModel(vehicle.e_model ? vehicle.e_model : '');
        setModelYear(vehicle.e_modelYear ? vehicle.e_modelYear : '');
        setTrim(vehicle.e_trim ? vehicle.e_trim : '');
        setMileage(vehicle.mileage);
        setNotesRemChars( vehicle.notes ? 500 - vehicle.notes.length : 0);     
        let dt = DateTime.fromISO(vehicle.last_updated_date, { zone: "America/Chicago", setZone: true })
        var updatedDt = dt.plus({ days: 7 });
        setExpiryDate(updatedDt.toFormat("MM/dd/yyyy hh:mm a"))  
        document.getElementById('offerTip').removeAttribute('data-tooltip')
    }, [vehicle])

    // useEffect(() => {   
    //     if (odometer && fwd && seatStains && transmission && seatRips && wheels && carpet && engine && odor &&
    //         paint && glass && mods && kbb && jdPower && mmr) {
    //         setDisableButton(false);
    //     } else {
    //         setDisableButton(true);
    //     }        
    // }, [odometer, fwd, seatStains,transmission, seatRips, wheels,  carpet, engine, odor,
    //     paint, glass, mods, kbb, jdPower, mmr])
    
    return (
        // <div style={{border: '1px solid yellow'}}>
        <>
            <CRow style={{marginBottom: '2rem'}}>
                <CCol 
                    className="pt-3 col-md-8 mt-5" 
                    style={{ margin: "0px auto", 
                        // background: "white" 
                    }}
                >
                    <h3 className='pt-1 pb-1'>Customer Offer Sheet Generator: {vehicle.vin}</h3>
                </CCol>
            </CRow>
            <CRow style={{display: 'flex', justifyContent: 'space-around', padding: '4px 8px'}}>                
                {/* <div 
                    className="pb-2 d-flex justify-content-between" 
                    style={{ margin: '1px auto', paddingTop: '10px', border: '1px solid red', background: '#ffffff', width: '100%' }}
                >   */}
                <>
                    <CCol 
                        xs={3} 
                        style={{ 
                            padding: '4px 0px', borderRadius: '8px', minWidth: '48%',
                            marginRight: '2px',
                            // background: '#d8dbe0',
                            marginBottom: '2rem'
                        }}
                    >
                       <CCard>
                        <CCardHeader style={{background: '#00aced', color: 'white'}}><h3>Vehicle Information</h3></CCardHeader>
                        <CCardBody className='pt-1 pb-1'>
                            <VehicleDetailsComponent label={"VIN"} value={vehicle.vin} disabled={true} />
                            <VehicleDetailsComponent label={"Odometer"} value={mileage} onChangeHandler={setMileage}/>
                            <VehicleDetailsComponent label={"Year"} value={e_modelYear} onChangeHandler={setModelYear}/>
                            <VehicleDetailsComponent label={"Make"} value={e_make} onChangeHandler={setMake}/>
                            <VehicleDetailsComponent label={"Model"} value={e_model} onChangeHandler={setModel}/>
                            <VehicleDetailsComponent label={"Series"} value={e_trim} onChangeHandler={setTrim}/>
                            <VehicleDetailsComponent 
                                label={"Color"} 
                                value={color} 
                                onChangeHandler={setColor}
                            />
                            <VehicleDetailsComponent 
                                label={"Engine"} 
                                value={engineType} 
                                onChangeHandler={setEngineType}
                            />
                        </CCardBody>
                       </CCard> 
                    </CCol>
                    <CCol 
                        xs={3} 
                        style={{ 
                            padding: '4px', borderRadius: '8px', minWidth: '48%',
                            marginLeft: '2px', 
                            // background: '#d8dbe0',
                            marginBottom: '2rem'
                        }}
                    >
                       <CCard>
                        <CCardHeader style={{background: '#00aced', color: 'white'}}>
                            <h3>Customer Information</h3>
                        </CCardHeader>
                        <CCardBody className='pt-1 pb-1'>
                            <VehicleDetailsComponent label={"First Name"} value={firstName} onChangeHandler={setFirstName} />
                            <VehicleDetailsComponent label={"Last Name"} value={lastName} onChangeHandler={setLastName} />
                            <VehicleDetailsComponent label={"Telephone"} value={phone} onChangeHandler={phoneHandleChange} />
                            <VehicleDetailsComponent label={"Email"} value={email} onChangeHandler={setEmail} />
                            <VehicleDetailsComponent label={"Address"} value={address} onChangeHandler={setAddress} />
                            {/* <VehicleDetailsComponent label={"Zip Code"} value={zipcode} /> */}
                            <div style={{margin: '5px 0px'}}>
                                <>Zip Code:</>
                                <CInput 
                                    type="text" 
                                    placeholder={"Zip Code"} 
                                    value={zipcode} 
                                    disabled={false}
                                    style={{background: 'white'}}
                                    onChange={e => numericChangeHandler(e, setZipcode, 5)}
                                />
                            </div>
                            <VehicleDetailsComponent label={"Date"} value={vehicle.created_date} disabled={true} />
                        </CCardBody>
                       </CCard> 
                    </CCol>
                </>
                <>
                    <CCol 
                        xs={3} 
                        style={{ 
                            padding: '4px 0px', borderRadius: '8px', minWidth: '48%',
                            marginRight: '2px',
                            // background: '#d8dbe0'
                        }}
                    >
                       <CCard>
                        <CCardHeader style={{background: '#00aced', color: 'white'}}>
                            <h3>Vehicle Condition</h3>
                        </CCardHeader>
                        {/* <h3 className='pl-5 pr-5 pt-1 pb-1'><u>Vehicle Condition</u></h3> */}
                        <CCardBody>
                            {
                                !isShiftData ? <>
                                    <DropDownElement label={"Odometer"} 
                                value={odometer}
                                values={['Good', 'Odometer Inop', 'Possible Rollback', 'Instrument Cluster Needs Repair']} 
                                disabled={true}
                                onChangeHandler={setOdomter}
                            />
                            <DropDownElement label={"Four Wheel Drive"} 
                                values={['Good', '4WD Inop', '4WD High needs service', '4WD low needs service']} 
                                disabled={true}
                                value={fwd}
                                onChangeHandler={setFwd}
                            />
                            <DropDownElement label={"Front/Rear Seats - Stains"} 
                                values={['Good', '3 or less', '3 or more']} 
                                disabled={true} 
                                value={seatStains}
                                onChangeHandler={setSeatStains}
                            />
                            <DropDownElement label={"Front/Rear Seats - Rips/Tears"} 
                                values={['Good', '3 or less', '3 or more']} 
                                disabled={true}
                                value={seatRips}
                                onChangeHandler={setSeatRips}
                            />
                            <DropDownElement label={"Transmission"} 
                                values={['Good', 'Delayed Shift', 'Shifts Hard into gear']} 
                                disabled={true}
                                value={transmission}
                                onChangeHandler={setTransmission}
                            />
                            <DropDownElement label={"Tires"} 
                                values={['Good', 'Replace Front 1 or 2', 
                                    'Replace Rear 1 or 2'
                                ]} 
                                disabled={true} 
                                value={tires}
                                onChangeHandler={setTires}
                            />
                            <DropDownElement label={"Wheels"} 
                                values={['Good', 'Rims scuffed and needs repair  1,2,3,4', 
                                    'Rim needs to be replaced 1,2,3,4',
                                    'Rim needs to be replaced 20in or larger'
                                ]} 
                                disabled={true} 
                                value={wheels}
                                onChangeHandler={setWheels}
                            />
                            {/* <DropDownElement label={"Front Seats"} 
                                values={['']} 
                                disabled={true} 
                                value={fSeats}
                                onChangeHandler={setFSeats}
                            /> */}
                            <DropDownElement label={"Carpet"} 
                                values={['Good', 'Stains', 'Rips/Tears', 'Replacment Needed']} 
                                disabled={true}
                                value={carpet}
                                onChangeHandler={setCarpet}
                            />
                            <DropDownElement label={"Engine"} 
                                values={['Good', 'Lower Engine Noise', 'Sludge']} 
                                disabled={true}
                                value={engine}
                                onChangeHandler={setEngine}
                            />
                                </> : null
                            }
                            {isShiftData ? (
                            <DropDownElement label={"Is there a persistent odor"} 
                                values={['Yes', 'No']} 
                                disabled={true}
                                value={persistentOdor}
                                onChangeHandler={setPersistentOdor}
                            />
                            ) : 
                            (
                            <DropDownElement label={"Odor"} 
                                values={['Good', 'Pet', 'Smoke', 'Mildew']} 
                                disabled={true}
                                value={odor}
                                onChangeHandler={setOdor}
                            />
                            )}
                            {
                                isShiftData ? (
                                    <>
                                        <DropDownElement label={"Are there issues in starting or driving"} 
                                            values={['Yes', 'No']} 
                                            disabled={true}
                                            value={hasIssuesInStartingOrDriving}
                                            onChangeHandler={setHasIssuesInStartingOrDriving}
                                        />
                                        <DropDownElement label={"Is there mechanical issues or dashboard lights that are on?"} 
                                            values={['Yes', 'No']} 
                                            disabled={true}
                                            value={hasMechanicalIssues}
                                            onChangeHandler={setHasMechanicalIssues}
                                        />
                                        {
                                            hasMechanicalIssues == 'Yes' ? <MultiSelectDropdown 
                                            options={mechanicalIssuesItem} 
                                            label="Select the following issues"
                                            optionSelected={mechanicalIssues}
                                            handleChange={(selected) => multiSelectHandleChange(selected, setMechanicalIssues, setParsedMechanicalIssues)}   
                                    /> : null
                                        }
                                        <DropDownElement label={"Is this a loan or lease vehicle?"} 
                                            values={['Yes', 'No']} 
                                            disabled={true}
                                            value={hasLoanOrLease}
                                            onChangeHandler={setHasLoanOrLease}
                                        />
                                        {
                                            hasLoanOrLease == 'Yes' ? <DropDownElement label={"Select loan or lease"} 
                                            values={['Loan', 'Lease']} 
                                            disabled={true}
                                            value={loanOrLease}
                                            onChangeHandler={setLoanOrLease}
                                        /> : null
                                        }
                                        <DropDownElement label={"Are any exterior panels damaged?"} 
                                            values={['Yes', 'No']} 
                                            disabled={true}
                                            value={isExteriorPanelDamaged}
                                            onChangeHandler={setIsExteriorPanelDamaged}
                                        />
                                        {
                                            isExteriorPanelDamaged == 'Yes' ? <>
                                                <MultiSelectDropdown 
                                            options={exteriorDamagesItem} 
                                            label="Select the exterior damages"
                                            optionSelected={exteriorDamagedPanel}
                                            handleChange={(selected) => multiSelectHandleChange(selected, setExteriorDamagedPanel, setParsedExteriorDamagedPanel)}   
                                    />
                                            <DropDownElement label={"Select the number of damaged panels"} 
                                            values={[1,2,3,4]} 
                                            disabled={true}
                                            value={exteriorDamagedPanelCount}
                                            onChangeHandler={setExteriorDamagedPanelCount}
                                        />
                                            </>
                                         : null
                                        }
                                        <DropDownElement label={"Has the vehicle been in an accident?"} 
                                            values={['Yes', 'No']} 
                                            disabled={true}
                                            value={hasBeenInAccident}
                                            onChangeHandler={setHasBeenInAnAccident}
                                        />
                                        {
                                            hasBeenInAccident == 'Yes' ? 
                                                <DropDownElement label={"Select the number of accidents"} 
                                            values={[1,2,3,4,5,6,7,8,9,10]} 
                                            disabled={true}
                                            value={accidentCount}
                                            onChangeHandler={setAccidentCount}
                                        />
                                            
                                         : null
                                        }
                                        <DropDownElement label={"Does the vehicle have hail or dents across sides?"} 
                                            values={['Yes', 'No']} 
                                            disabled={true}
                                            value={hasHailOrDents}
                                            onChangeHandler={setHasHailOrDents}
                                        />
                                        <DropDownElement label={"Is the interior damaged or have missing parts?"} 
                                            values={['Yes', 'No']} 
                                            disabled={true}
                                            value={isInteriorPanellingDamaged}
                                            onChangeHandler={setIsInteriorPanellingDamaged}
                                        />
                                        {isInteriorPanellingDamaged == 'Yes' ? 
                                        <MultiSelectDropdown 
                                            options={interiorDamagesItem} 
                                            label="Select the damages"
                                            optionSelected={interiorDamagedPanel}
                                            handleChange={(selected) => multiSelectHandleChange(selected, setInteriorDamagedPanel, setParsedInteriorDamagedPanel)}   
                                    />     
                                     : null
                                        }
                                        <DropDownElement label={"Are there any aftermarket modifications?"} 
                                            values={['Yes', 'No']} 
                                            disabled={true}
                                            value={hasModifications}
                                            onChangeHandler={setHasModifications}
                                        />
                                        {
                                            hasModifications == 'Yes'? 
                                            <MultiSelectDropdown 
                                                options={modificationItem} 
                                                label="Select the modifications"
                                                optionSelected={modifications}
                                                handleChange={(selected) => multiSelectHandleChange(selected, setModifications, setParsedModifications)}   
                                            />
                                        : null
                                        }
                                    </>
                                    ) : null
                            }
                            {/* <DropDownElement label={"Paint/Body"} 
                                values={['Scratch 1,2,3+', 'Ding  1,2,3+', 'Dent 1,2,3+']} 
                                disabled={true}
                                value={paint}
                                onChangeHandler={setPaint}
                            />
                            <DropDownElement label={"Glass"} 
                                values={['Chip 1,2,3+', 'Crack 1,2,3+', 'Missing 1,2,3+ ']} 
                                disabled={true}
                                value={glass}
                                onChangeHandler={setGlass}
                            /> */}
                            {!isShiftData ? <>
                                <MultiSelectDropdown 
                                options={paintOptions} 
                                label="Paint/Body"
                                optionSelected={paint}
                                handleChange={(selected) => multiSelectHandleChange(selected, setPaint, setParsedPaint)}   
                            />
                            <MultiSelectDropdown 
                                options={glassOptions} 
                                label="Glass"
                                optionSelected={glass}
                                handleChange={(selected) => multiSelectHandleChange(selected, setGlass, setParsedGlass)}
                            />
                            <MultiSelectDropdown 
                                options={vehicleFeaturesOptions} 
                                label="Vehicle Features"
                                optionSelected={vehicleFeatures}
                                handleChange={(selected) => multiSelectHandleChange(selected, setVehicleFeatures, setParsedVehicleFeatures)}
                            />
                            <DropDownElement label={"Oil leaks"} 
                                values={['Yes', 'No']} 
                                disabled={true}
                                value={oilLeaks}
                                onChangeHandler={setOilLeaks}
                            />
                            <DropDownElement label={"Modifications"} 
                                values={['Yes', 'No']} 
                                disabled={true}
                                value={mods}
                                onChangeHandler={setMods}
                            />
                            </>
                             : null}
                        </CCardBody     >
                       </CCard> 
                    </CCol>
                    <div 
                        style={{
                            padding: '4px 0px', borderRadius: '8px', minWidth: '48%',
                            marginLeft: '2px',
                            // background: '#d8dbe0'
                        }}
                    >                    
                        <CCol 
                            xs={3} 
                            style={{ 
                                padding: '4px 0px', 
                                // border: '2px solid black', 
                                borderRadius: '8px', 
                                minWidth: '100%',
                                // margin: '1rem auto',
                                // background: '#d8dbe0'
                            }}
                        >
                            
                            <CCard>
                                <CCardHeader style={{background: '#00aced', color: 'white'}}>
                                    <h3>Appraisal Value {vehicle.appraisal_last_edited_by !== null ? <span><CTooltip
                                        content={`A MANUAL OFFER WAS ADDED BY ${ vehicle.appraisal_last_edited_by.toUpperCase()} FOR THIS INSTANT CASH OFFER ON ${ vehicle.appraisal_last_edited_on.split('T')[0]}`}
                                    >
                                        <CIcon style={{backgroundColor: 'black'}} content={cilInfo} className="info" size='xl'/>
                                    </CTooltip></span> : null}</h3>
                                    
                                </CCardHeader>
                                <CCardBody>
                                <div style={{margin: '5px 0px'}}>
                                    <>Appraisal Value:</>
                                    <CInput 
                                        type="text" 
                                        placeholder={"Appraisal Value"} 
                                        value={appraisalValue}
                                        // value={appraisalValue && parseInt(appraisalValue).toLocaleString()}
                                        // value={appraisalValue && parseInt(appraisalValue).toLocaleString("en-US", {style:"currency", currency:"USD"})}
                                        disabled={false}
                                        style={{background: 'white', borderColor: appraisalBorder? 'red':''}}
                                        // onChange={e => numericChangeHandler(e, setAppraisalValue, 8)}
                                        onChange={e =>{numericChangeHandler(e, setAppraisalValue, 8); AppraisalChangeHandler(e)}}
                                    />
                                    {appraisalRequiredMsg ? <span style={{color:'red'}}>Appraisal Value can not be empty</span>: '' }
                                    {appraisalBorder?<span style={{color:'red'}}>Appraisal Value can not be smaller than KBB Value</span>: ''}
                                    {appraisalBorder2?<span style={{color:'orange'}}>Warning : Appraisal Value is greater than  $100,000</span>: ''}
                                </div>
                                    {/* <VehicleDetailsComponent 
                                        label={"Appraisal Value"}
                                        value={appraisalValue}
                                        onChangeHandler={setAppraisalValue}
                                        // disabled={true}
                                    /> */}
                                </CCardBody>
                            </CCard> 
                        </CCol>
                        <CCol 
                            xs={3} 
                            style={{ 
                                padding: '4px 0px', 
                                // border: '2px solid black', 
                                borderRadius: '8px', 
                                minWidth: '100%',
                                // margin: '1rem auto',
                                // background: '#d8dbe0'
                            }}
                        >
                            <CCard>
                                <CCardHeader style={{background: '#00aced', color: 'white'}}>
                                    <h3>KBB Fair Market Value</h3>
                                </CCardHeader>
                                <CCardBody>
                                <div style={{margin: '5px 0px'}}>
                                    <CInput 
                                        type="text" 
                                        placeholder="KBB Market Value" 
                                        value={kbb}
                                        style={{background: 'white', borderColor: kbbBorder? 'red':''}}
                                        onChange={kbbChngeHandler}
                                    />
                                    {kbbBorder?<span style={{color:'red'}}>KBB Value can not be greater than Appraisal Value</span>: ''}
                                </div>
                                </CCardBody>
                            </CCard> 
                        </CCol>
                        <CCol 
                            xs={3} 
                            className="m-8"
                            style={{ 
                                padding: '4px 0px', 
                                // border: '2px solid black', 
                                borderRadius: '8px', minWidth: '100%',
                                // margin: '1rem auto',
                                // background: '#d8dbe0'
                            }}  
                        >
                            <CCard>
                                <CCardHeader style={{background: '#00aced', color: 'white'}}>
                                    <h3>Predefined Vehicle Comments</h3>
                                </CCardHeader>
                                <CCardBody>
                                    <DropDownElement
                                        values={[
                                            "Low Mileage/Great Conditon",
                                            "Low Mileage/Average Condition",
                                            "Low Mileage/Poor Condition",
                                            "Average Mileage/Great Condition",
                                            "Average Mileage/Average Condition",
                                            "Average Mileage/Poor Condition",
                                            "High Mileage/Great Condition",
                                            "High Mileage/Average Condition",
                                            "High Mileage/Poor Condition",
                                        ]} 
                                    disabled={true}
                                    value={vehicleComment}
                                    onChangeHandler={setVehicleComment}
                                    />
                                </CCardBody>
                            </CCard>
                            <CCard>
                                <CCardHeader style={{background: '#00aced', color: 'white'}}>
                                    <h3>Notes for Customer Offer Sheet <span style={{fontSize:'14px', fontWeight: '550'}}><em>(Notes will appear on Customer's Offer Sheet)</em></span></h3>
                                </CCardHeader>
                                <CCardBody>
                                    <CTextarea 
                                        label='Add Notes'
                                        value={notes}
                                        onChange={notesChangeHandler}
                                        style={{height: '150px'}}
                                    />
                                    <span>Remaining Chars: {notesRemChars}</span>
                                    {/* <VehicleDetailsComponent
                                        key={"KBB.com"}
                                        label={"KBB Market Value"} 
                                        value={kbb}
                                        onChangeHandler={setKbb} 
                                    />
                                    <VehicleDetailsComponent 
                                        label={"J.D Power"} 
                                        value={jdPower} 
                                        onChangeHandler={setJDPower}
                                    />
                                    <VehicleDetailsComponent 
                                        label={"MMR"} 
                                        value={mmr}
                                        onChangeHandler={setMMR}  
                                    /> */}
                                </CCardBody>
                            </CCard> 
                        </CCol>
                        <CCol 
                            xs={3} 
                            className="m-8"
                            style={{ 
                                padding: '4px', minWidth: '80%',
                                margin: '10px auto', background: '#00aced', borderRadius: '8px',
                                marginBottom: '30px'
                            }}
                        >
                        {
                            vehicle.shift_lead_type == 'instant_quote' ?
                            <CTooltip content={`Editing this Instant Cash Offer will send an email to the customer with a new offer on their vehicle`}>
                                <CButton
                                    id='offerTip' 
                                    style={{ width:'100%', color: 'white' }} 
                                    onClick={() => appraisalValue === '' ? setAppraisalRequiredMsg(true) : setShowPreview(true)}
                                    disabled={disableButton}
                                >
                                    <strong>
                                        {
                                            action == `Generate` ? `GENERATE OFFER` : `EDIT OFFER`
                                        }
                                    </strong>
                                </CButton> 
                            </CTooltip>:
                                <CButton
                                id='offerTip' 
                                style={{ width:'100%', color: 'white' }} 
                                onClick={() => appraisalValue === '' ? setAppraisalRequiredMsg(true) : setShowPreview(true)}
                                disabled={disableButton}
                            >
                                <strong>
                                    {
                                        action == `Generate` ? `GENERATE OFFER` : `EDIT OFFER`
                                    }
                                </strong>
                            </CButton>
                        }
                        </CCol>
                        <CCol 
                            xs={3} 
                            style={{ 
                                padding: '4px', borderRadius: '8px', minWidth: '100%',
                                marginLeft: '2px', 
                                marginBottom: '2rem'
                            }}
                        >
                         <CommentBox vin={vehicle.vin} user={user} createdDate={vehicle.created_date} />
                        </CCol> 
                        
                    </div>                    
                {/* </div> */}
                </>
            </CRow>
            {
                isShiftData ? <CustomerPreviewModal 
                                    isShiftData = {isShiftData}
                                    shiftLoading={loading}
                                    setshiftLoading={setLoading}
                                    action={action}
                                    superUser={superUser}
                                    show={showPreview} 
                                    setShow={setShowPreview}
                                    firstName={firstName}
                                    lastName={lastName}
                                    engineType={engineType}
                                    make={e_make}
                                    model={e_model}
                                    year={e_modelYear}
                                    trim={e_trim}
                                    color={color}
                                    notes={notes}
                                    phone={phone}
                                    email={email}
                                    address={address}
                                    zipcode={zipcode}
                                    odor={persistentOdor}
                                    hasIssuesInStartingOrDriving={hasIssuesInStartingOrDriving}
                                    hasMechanicalIssues = {hasMechanicalIssues}
                                    mechanicalIssues ={parsedMechanicalIssues}
                                    hasLoanOrLease = {hasLoanOrLease}
                                    loanOrLease ={loanOrLease}
                                    isExteriorPanelDamaged ={isExteriorPanelDamaged}
                                    exteriorDamagedPanel ={parsedExteriorDamagedPanel}
                                    exteriorDamagedPanelCount ={ exteriorDamagedPanelCount}
                                    hasBeenInAccident = {hasBeenInAccident}
                                    accidentCount ={ accidentCount}
                                    hasHailOrDents ={ hasHailOrDents}
                                    isInteriorPanellingDamaged ={isInteriorPanellingDamaged}
                                    interiorDamagedPanel ={ parsedInteriorDamagedPanel }
                                    hasModifications ={ hasModifications}
                                    mods ={parsedModifications}
                                    // odometer={odometer}
                                    appraisalValue={appraisalValue}
                                    // fwd={fwd}
                                    // seatStains={seatStains}
                                    // transmission={transmission}
                                    // fTires={fTires}
                                    // wheels={wheels}
                                    // seatRips={seatRips}
                                    // carpet={carpet}
                                    // engine={engine}
                                    // odor={odor}
                                    // paint={parsedPaint}
                                    // glass={parsedGlass}
                                    // vehicle_features={parsedVehicleFeatures}
                                    // mods={mods}
                                    kbb={kbb}
                                    // jdPower={jdPower}
                                    // mmr={mmr}
                                    saveOfferToAppraisalDB={saveOfferToAppraisalDB}
                                    saveShiftOfferToAppraisalDB={saveShiftOfferToAppraisalDB}
                                    sendOfferMail={sendOfferMail}
                                    sendOfferNote={sendOfferNote}
                                    claimAppraisal={claimAppraisal}
            /> : <CustomerPreviewModal 
                    action={action}
                    superUser={superUser}
                    show={showPreview} 
                    setShow={setShowPreview}
                    firstName={firstName}
                    lastName={lastName}
                    engineType={engineType}
                    color={color}
                    notes={notes}
                    phone={phone}
                    email={email}
                    address={address}
                    zipcode={zipcode}
                    odometer={odometer}
                    appraisalValue={appraisalValue}
                    fwd={fwd}
                    seatStains={seatStains}
                    transmission={transmission}
                    // fTires={fTires}
                    wheels={wheels}
                    seatRips={seatRips}
                    carpet={carpet}
                    engine={engine}
                    odor={odor}
                    paint={parsedPaint}
                    glass={parsedGlass}
                    vehicle_features={parsedVehicleFeatures}
                    oilLeaks={oilLeaks}
                    mods={mods}
                    kbb={kbb}
                    // jdPower={jdPower}
                    // mmr={mmr}
                    saveOfferToAppraisalDB={saveOfferToAppraisalDB}
                    saveShiftOfferToAppraisalDB={saveShiftOfferToAppraisalDB}
                    claimAppraisal={claimAppraisal}
            />
            }
            {/* </div> */}
        </>
    )
}

export default withRouter(CustomerOffer)