import React from 'react';
import {
    CCard,
    CCardHeader,
    CCardBody,
    CTooltip
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import {freeSet} from '@coreui/icons'

export default ({details})=>{
    var msg = '';
    if (details.live_supply) {
        msg = 'We have ' + details.live_supply + ' vehicles live and in prep';
        if (details.vehicle_needs > 0) {
            msg = msg + ', and require ' + details.vehicle_needs + ' for a ' + details.max_days + ' days supply';
        }    
    }
    
    return <CCard className={details.cardClass}>
        <CCardHeader>Inventory Management &nbsp;&nbsp;
            {
                details && details.trend && details.trend.tip && 
                <CTooltip content={details.trend && details.trend.tip ? details.trend.tip : ''
                    + '<br/><br/>Days Supply calculations are based on Days Supply for the same Make/Model'}>
                    <span className="circle bg-info">
                    {
                        details.trend && details.trend.trend_icon &&
                        <CIcon className="text-white" content={details.trend.trend_icon} size="xl"/>
                    }                
                    </span>
                </CTooltip>
            }
        </CCardHeader>
        <CCardBody>We currently have {details.current_days_supply || "an Unknown" } days supply - {details.cardMessage}
            <br/>{details.live_supply && msg}
        </CCardBody>

    </CCard>   
}