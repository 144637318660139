import React from 'react'
import{
    CCard,
    CCardHeader,
    CCardBody,
    CRow,
    CCol,
    CButton,
    CBadge
} from '@coreui/react';
import {CChart} from '@coreui/react-chartjs'
import CopyVin from '../../../Main/VehicleDetail/VehicleData/CopyToClipboard/CopyToClipboard';

const options = {
    // tooltips: {
    //   enabled: false,
    //   custom: customTooltips
    // },
    legend:{
        display:true,
        labels:{
            filter: (l, d)=>{
                if(l.text == undefined) return false;
                return true;
            }
        }
    },
    responsive:true,
    maintainAspectRatio: true,
    options: {
        scales: {
            xAxes: [{
                type: 'linear',
                position: 'bottom'
            }]
        }
    },
    onClick:(e,item)=>{
        console.log(e);
        console.log(item);
    }
  }

  const line = {
    datasets: [
        {
        label: " < 60 Days Listed",
        backgroundColor:"#902a8d",
        pointBackgroundColor: '#902a8d',
        pointRadius:5,
        data: [

            {x:21500, y:24000},
            {x:19000, y:19500},
            {x:19600, y:19200},
            {x:24500, y:22500},
            {x:21800, y:22200}
        ],
        },
        {
            label: '61-90 Days Listed',
            backgroundColor: "orange",
            pointBackgroundColor: 'orange',
            pointRadius:5,
            data: [
                {x:19000, y:17000},
                {x:21000, y:19000},
                {x:23000, y:20500},
                {x:20100, y:19750},
                {x:23200, y:23600},
            ],
        },
        {
            label: '> 90 Days Listed',
            backgroundColor:"#e55353",
            pointBackgroundColor: '#e55353',
            pointRadius:5,
            data: [
                {x:21000, y:23000},
                {x:19500, y:21100},
                {x:20000, y:18400},
                {x:18000, y:16100}
            ],
        },
        {
            label: 'Target Price',
            data: [
                {x:16000, y:16000},
                {x:18000, y:18000},
                {x:20000, y:20000},
                {x:22000, y:22000},
                {x:24000, y:24000}
            ],
            showLine:true,
            backgroundColor:"rgba(255,255,255,0)"
        },
        {
            label: '+/- $500',
            data: [
                {x:16000, y:16500},
                {x:17500, y:18000},
                {x:19500, y:20000},
                {x:21500, y:22000},
                {x:23500, y:24000}
            ],
            showLine:true,
            backgroundColor:"rgba(255,255,255,0)",
            borderDash:[3,5]
        } , 
        {
            data: [
                {x:16500, y:16000},
                {x:18500, y:18000},
                {x:20500, y:20000},
                {x:22500, y:22000},
                {x:24500, y:24000}
            ],
            showLine:true,
            backgroundColor:"rgba(255,255,255,0)",
            borderDash:[3,5]
        }          
    ],
  }; 



export default (props)=>{

    return(
        <div className="d-flex">
                <CCard className="w-50 mr-2">
                    <CCardHeader>Click on a Point in the Chart to See Details</CCardHeader>
                    <CCardBody>
                       <div className="mb-5 d-flex align-items-center justify-content-between">
                           <div className="flex-grow-1">
                            <h2 className="mb-1">Audi Q5 (2.0T Premium Quattro)</h2>
                            <div>
                                        <CBadge color="danger">Overpriced</CBadge>
                                </div>
                           </div>

                           <CButton color="dark">Open in vAuto</CButton>
                       </div>
                       
                       <div className="mb-5">
                             <CRow>
                                <CCol md="5">
                                    <div className="inventory_label">LOCATION</div>
                                    <div className="inventory_small_value">BROWARD</div>
                                    <div className="pt-2">
                                        <CBadge color="light">Live</CBadge>
                                     </div>
                                </CCol>
                                <CCol md="3">
                                <div className="inventory_label">MILEAGE</div>
                                <div className="inventory_small_value">5,910</div>
                            </CCol>
                            <CCol md="4">
                                <div className="inventory_label">MODEL INPUT #N</div>
                                <div className="inventory_small_value">Model Value</div>
                            </CCol>
                            </CRow>
                       </div>
                        <div className="mb-5">
                        <CRow>
                            <CCol md="5">
                                <div className="inventory_label">VIN</div>
                                <div className="inventory_small_value">12VA21DV2241VD <CopyVin vin="12VA21DV2241VD"/>  </div>
                            </CCol>
                            <CCol md="3">
                                <div className="inventory_label">LIST PRICE</div>
                                <div className="inventory_small_value">$22,000</div>
                                <div className="pt-2">
                                    <CBadge color="danger">Overpriced</CBadge>
                                </div>

                            </CCol>
                            <CCol md="4">
                                <div className="inventory_label">TARGET PRICE</div>
                                <div className="inventory_small_value">$21,500</div>
                            </CCol>
                        </CRow>

                        </div>
       
                      
                    </CCardBody>  
                </CCard>
                <CCard className="w-50">
                    <CCardHeader>Retail Price to Model Price Distribution</CCardHeader>
                    <CCardBody>
                        <CChart type="scatter" datasets={line.datasets} options={options}/>
                    </CCardBody>
                </CCard>
        </div>

    )
    
}