import React, { useState, useEffect} from "react";
import {
  CDropdownToggle,
  CDropdown,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";

const dropdownOptions = [
    {
       label: "Appointment Preference",
       value: null
    },
    {
      label: "Appointments set",
      value: "true"
    },
    {
      label: "No Appointments set",
      value: "false"
    }
  ];

export default (props) => {

    const [appointmentPreference, setAppointmentPreference] = useState(dropdownOptions[0].label)

    useEffect(() => {
      var loc = { ...props.localFilters };
      if(loc["appointment"] === null || loc["appointment"] === '') setAppointmentPreference(dropdownOptions[0].label)
  })

  const changeFunc = (e) => {
      var val = e.target.innerText;
      setAppointmentPreference(val);
    let appointment = dropdownOptions.filter((item) => {
      if (item.label === val) return item;
    });
    appointment = appointment[0].value
    var loc = { ...props.localFilters };
    loc["appointment"] = appointment;
    props.setLocalFilters(loc);
  };

  return (
    <CDropdown>
      <CDropdownToggle
        caret
        color="white"
        style={{
          border: "1px solid lightgray",
          padding: "5.25px 10.5px",
          height: "35.88px",
          width: "auto",
          color: "gray",
          fontSize: "12.5px",
          fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
          fontWeight: "700",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* {appointmentPreference === '' ? 'Appointment Preference' : appointmentPreference} */}
        {appointmentPreference}
      </CDropdownToggle>
      <CDropdownMenu onClick={(e) => changeFunc(e)}>
        {dropdownOptions.map((item) => (
          <CDropdownItem>{item.label}</CDropdownItem>
        ))}
      </CDropdownMenu>
    </CDropdown>
  );
};
