import React, { useState, useEffect} from "react";
import {
  CDropdownToggle,
  CDropdown,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";

const dropdownOptions = [
    {
       label: "All Brands",
       value: null
    },
    {
      label: "Off Lease Only",
      value: "off_lease_only"
    },
    {
      label: "Car Squad",
      value: "car_squad"
    }
  ];

export default (props) => {

    const [brandSelection, setBrandSelection] = useState(dropdownOptions[0].label)

    useEffect(() => {
      var loc = { ...props.localFilters };
      if(loc["brand"] === null || loc["brand"] === '') setBrandSelection(dropdownOptions[0].label)
  })

  const changeFunc = (e) => {
      var val = e.target.innerText;
      setBrandSelection(val);
    let brand = dropdownOptions.filter((item) => {
      if (item.label === val) return item;
    });
    brand = brand[0].value
    var loc = { ...props.localFilters };
    loc["brand"] = brand;
    props.setLocalFilters(loc);
  };

  return (
    <CDropdown>
      <CDropdownToggle
        caret
        color="white"
        style={{
          border: "1px solid lightgray",
          padding: "5.25px 10.5px",
          height: "35.88px",
          maxWidth: "125px",
          color: "gray",
          fontSize: "12.5px",
          fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
          fontWeight: "700",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* {brandSelection === '' ? 'All Brands' : brandSelection} */}
        {brandSelection}
      </CDropdownToggle>
      <CDropdownMenu onClick={(e) => changeFunc(e)}>
        {dropdownOptions.map((item) => (
          <CDropdownItem>{item.label}</CDropdownItem>
        ))}
      </CDropdownMenu>
    </CDropdown>
  );
};
