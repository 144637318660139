import React, {useState} from 'react';
import {CRow, CCol, CButton, CCard, CCardHeader, CCardBody} from '@coreui/react';


export default ()=>{
    
    const [oldPreview, setOldPreview] = useState(getCookie('oldPreview'));
    

    
    const activateOldPreview = (active)=>{
        var cookieVal = (active) ? 1 : 0;
        setCookie('oldPreview', cookieVal, 999);
        setOldPreview(cookieVal);
    }


    function setCookie(name,value,days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    }

    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    return (
       <> 
       

        <CRow>
            <CCol className="mt-3 col-md-4 offset-md-4">
                <CCard>
                    <CCardHeader>Legacy Preview</CCardHeader>
                    <CCardBody>
                        <div className="py-3">Legacy Preview is <b>{oldPreview ? 'Activated' : 'Deactivated'}</b></div>
                        <br/><br/>
                        <CButton onClick={()=>{activateOldPreview(true)}} color="success">Activate Legacy Preview</CButton>&nbsp;
                        <CButton onClick={()=>{activateOldPreview(false)}} color="danger">Deactivate Legacy Preview</CButton>&nbsp;
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </>
    )
}