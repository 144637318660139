import React, {useState} from 'react';
import CIcon from '@coreui/icons-react'
import {freeSet} from '@coreui/icons'
import FEGCalculator from './FEGCalculator/FEGCalculator';


export default (props)=>{
    const [calcOpen, setCalcOpen] = useState(false);
    return(
        <div className={`calcWrapper ${calcOpen ? 'calcOpen' : ''}`}>
            <CIcon content={freeSet.cilCalculator} size="2xl" onClick={()=>{setCalcOpen(!calcOpen)}}/>
            <FEGCalculator 
                reconCost={props.reconCost || 0}
                transport={props.transport || 0}
                retailPrice={props.retailPrice || 0}
                buyerMaxBid={props.buyerMaxBid || 0}
                vin={props.vin}
                closeCalculator={
                    ()=>{setCalcOpen(false)}
                }
            />
        </div>
    )

}