import React, {useState, useEffect} from 'react';
import { CCard, CCardHeader, CCardBody, CDataTable, CButton, CBadge} from '@coreui/react';
import RunListManager from '../../../../services/RunListManager';
import FormattedNumber from '../../../FormattedNumber/FormattedNumber';
import {Loader} from 'semantic-ui-react';
import SalesHistoryTree from './OLOSalesHistoryTree';
import Error from '../../../generic/Error/Error';
import CompFlag from '../../OLOContainer/CompFlag/CompFlag';

/*
TODO: Comp Data, Sales History and Current Inventory are nearly identical components. Based on due dates we are focused on delivering a workable solution, but these components need to be refactored so they are more legible and remove duplicate code
*/

export default ({vin, vehicle, channel, source = '', page = '', compFlag})=>{

    const [recentSales, setRecentSales] = useState([]);
    const [loading, setLoading] = useState(false)
    const [collapsed, setCollapsed] = useState(false);
    const [tree, setTree] = useState([])
    const [recentSaleCount, setRecentSaleCount] = useState(0)
    const {addError} = Error();

    const shTree = new SalesHistoryTree();

    useEffect( ()=>{
        var ignore = false;
        var RL = new RunListManager();
        setLoading(true);
        setTree([]);
        setRecentSales([]);
        RL.getStaticVehicleDetail(vin, "sales_history", channel, source, page).then( (data)=>{
           if(!ignore){
               //if(data.error) return addError(data.message, 500);
               if (page === 'Appraisal') {
                    const shData = shTree.formatResults(data.data.matches);
                    setRecentSaleCount(data.data.matches.length)
                    setRecentSales(shData);
               } else {
                    var shData = shTree.formatResults(data.data.sales_history);
                    setRecentSaleCount(data.data.sales_history.length)
                    setRecentSales(shData);
               }               
               setLoading(false)
           }
        }).catch( (e)=>{
            console.log(e);
           // addError(e.message, 500); 
            setLoading(false)
        })

        return ()=>{ignore =true;}
    }, [vin, channel])

    useEffect(()=>{
        var t = []
        if(recentSales[vehicle.year] != undefined){
            t.push(vehicle.year)
        }
        if(t.length > 0){
           var band = shTree.findMileageBand(vehicle.mileage); 
           if(recentSales[vehicle.year]['children'][band] != undefined){
               t.push(band)
           }
        }
        setTree(t)
    }, [recentSales, vehicle.mileage, vehicle.year])

    const toggleCollapsed = (e)=>{e.preventDefault();setCollapsed(!collapsed)}

    const addToTree = (val)=>{

        var newTree = [...tree];
        newTree.push(val)
        setTree(newTree)
    }

    const sliceTree = (index)=>{
        var newTree = [...tree]
        newTree = newTree.slice(0,index)
        setTree(newTree)
    }

    return(
        <CCard className={ collapsed ? "animateCard olo-collapse" : "animateCard"}
            style={page==='Appraisal' ? !collapsed ? { height: '100%'} : {} : {}}
        >
            <CCardHeader className="d-flex justify-content-between align-items-center">
                <div className="olo-header">
                    <span>Recent Sales ({loading ? '...' : recentSaleCount})</span>
                    <span>{compFlag && <CompFlag/>}</span>
                </div>
                
                <CButton color="info" onClick={toggleCollapsed}>{collapsed ? 'Expand' : 'Collapse'}</CButton>
            </CCardHeader>
            <CCardBody className={collapsed ? 'collapse' : ''}>

                <div className="d-flex pb-3">
                        <CBadge onClick={()=>{sliceTree(0)}} size="sm" color="secondary">All</CBadge>
                        {tree.map((item)=>{
                            return <CBadge onClick={()=>{sliceTree(1)}} size="sm" color="secondary" className="ml-1">{item}</CBadge>
                        })}
                </div>

                <CDataTable
                    fields={shTree.setFields(tree)}
                    items={shTree.buildTableRows(recentSales,tree)}
                    loading={loading}
                    sorter
                    pagination
                    itemsPerPage={10}
                    scopedSlots={ {
                        'mileage': (item)=>{
                            return <td>
                                <FormattedNumber value={item.mileage}/>
                            </td>
                        },
                        'sold_date': (item)=>{
                            return <td>
                                {item.sold_date}<br/>
                                <small>Days Live: {item.days_live}</small>
                            </td>
                        },
                        'cash_price': (item)=>{
                            return <td>
                                <FormattedNumber value={item.cash_price} price={true}/>
                            </td>   
                        },
                        'extcolor': (item)=>{
                            return <td>
                                {item.extcolor ? item.extcolor : 'Unknown'}
                            </td>
                        },

                        'year': (item)=>{
                            return <td><a onClick={()=>{addToTree(item.year)}} className="olo_link">{item.year}</a></td>
                        },
        
                        'band': (item)=>{
                            return <td><a className="olo_link" onClick={()=>{addToTree(item.band)}}>{item.band}</a></td>
                        },
                        'price': (item)=>{
                            if(isNaN(item.price)) return <td>-</td>;
                            return <td><FormattedNumber value={item.price} price={true}/></td>
                        }
                    }}
                />
            </CCardBody>
        </CCard>
    )
}