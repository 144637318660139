import React from 'react';
import { components } from "react-select";
import { default as ReactSelect } from "react-select";

const MultiSelectOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};


export const MultiSelect = (props) => {
    return (
          <ReactSelect
            options={props.options}
            isMulti
            placeholder={"Year"}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
                MultiSelectOption
            }}
            onChange={props.handleChange}
            allowSelectAll={true}
            value={props.optionSelected}
          />
      );
}
