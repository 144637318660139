import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import { CButton, CDataTable, CBadge, CTooltip, CForm, CInput, CPopover,CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem,} from '@coreui/react';
import { CIcon } from '@coreui/icons-react';
import { cilFile, cilInfo, cilNoteAdd, cilPen, cilCommentSquare} from '@coreui/icons';
import RunListManager, {getCookie} from '../../../services/RunListManager';
import jwt from 'jsonwebtoken';
import {InspectionDropDown} from '../InspectionDropdown';
import {Timer} from './Timer';
import {validateEmail} from '../../../services/AppraisalUtils';
import Error from '../../generic/Error/Error';
import { after, flatMap } from 'lodash';
import { DateTime } from 'luxon';
import { formatTextBlock, formattedText, pickInitials } from '../../../services/utils';
// import {Appraisaldetails} from '../AppraisalDetails';
// import styles from './styles.css'

export const AppraisalQueue = (props) => {

    const [userName, setUserName] = useState('');

    var RL = new RunListManager()
    const {
        unclaimedLoading, shiftLoading ,appraisalRecords, fields, search, recordsPerPage, dropdownField, badgeField, dropDownoptions,
        getMyAppraisals, roleType, offerButtons, getUnclaimedAppraisals, shiftvins, unclaimedvins,
        appraiserRecordsView, setViewVehicleDetails, vasRecordsView, fetchArchivedRecords, setShowSurvey, setShowConfirmation, setDiscardingVehicleDetail, setModalContent
    } = props;

    // const [confirmLoading, setConfirmLoading ] = useState(false);
    // const [showConfirmation, setShowConfirmation] = useState(false);
    // const [discardingVehicleDetail, setDiscardingVehicleDetail] = useState({});
    let sortedappraisalRecords = appraisalRecords.sort((a,b) => new Date(b['created_date']) - new Date(a['created_date']));
    const {addError} = Error();

    // const [details, setDetails] = useState([])
    // const [items, setItems] = useState(usersData)

    // const toggleDetails = (index) => {
    //     const position = details.indexOf(index)
    //     let newDetails = details.slice()
    //     if (position !== -1) {
    //     newDetails.splice(position, 1)
    //     } else {
    //     newDetails = [...details, index]
    //     }
    //     setDetails(newDetails)
    // }

    const getAppraisalBadge = (status)=>{
        switch (status) {
        case 'Complete': return 'success'
        case 'In Progress': return 'info'
        case 'Not Started': return 'danger'
        case 'Incomplete': return 'danger'
        // case 'Incomplete': return 'secondary'
        // case 'Banned': return 'danger'
        default: return 'primary'
        }
    }

    const updateInspectionStatus = (vin, vas, created_date, status) => {
        RL.updateInspectionStatus(vin, vas, created_date, status, userName)
                    .then(res => {
                        getMyAppraisals();                        
                    })
                    .catch(error => {
                        console.error('Failed to update the Inspection Status ' + error);
                        if (error.message == 'Network Error') 
                            addError(error.message);
                    })
    }

    const claimAppraisalRecord = (vin, created_date, appraiser) => {
        RL.claimAppraisalRecord(vin, created_date, appraiser)
                    .then(res => {
                        getMyAppraisals();
                        getUnclaimedAppraisals();                        
                    })
                    .catch(error => {
                        console.error('Failed to assign record to  ' + error);
                        if (error.message == 'Network Error') 
                            addError(error.message);
                    })
    }

    const archiveRecordHandler = (vin, input_role, role_value, created_date, toggleValue) => {

        // make a call to the api
        RL.archiveAppraisalRecord(vin, input_role, role_value, created_date, toggleValue)
            .then(res => {
                // invoke getMyAppraisals on succesful return
                if (res.status == 200) 
                    getMyAppraisals();
                    fetchArchivedRecords();
            })
            .catch(err => {
                console.log(err);
                if (err.message == 'Network Error') 
                    addError(err.message);
            })
    }

    const capitalizeFirst = (str) => {
        let name = '';
        name = str.split(" ");
        if (name.length > 1){
            for (let i = 0; i < name.length; i++){
                if(name[i][0])
                    name[i] = name[i][0].toUpperCase() + name[i].substring(1).toLowerCase()
            }
            return(name.join(" "))
        } else {
            name = str[0].toUpperCase() + str.substring(1).toLowerCase();
            return(name)
        }
        
    }

    const parseTime = (date) => {
        const formattedDate = new Date(date);
        let formattedHours = formattedDate.getHours();
        const formatAMPM = formattedHours >= 12 && formattedHours <= 23 ? 'PM' : 'AM';
        formattedHours = formattedHours >= 13 && formattedHours <= 23 ? formattedHours - 12 : formattedHours;

        let formattedMinutes = formattedDate.getMinutes();
        formattedMinutes = formattedMinutes < 10 ? `0` + formattedMinutes : formattedMinutes;

        const formattedTime = formattedHours + `:` + formattedMinutes + 
            ` ${formatAMPM}`;
        return formattedTime
    }

    const matchedVins = shiftvins ? [...new Set(shiftvins.filter(element => unclaimedvins.includes(element.vin)))] : []


// const matchVins = () => {

//     let listVin = []
//     if (shiftvins) {
//         listVin = [...new Set(shiftvins.filter(element => unclaimedvins.includes(element)))]
//     } else {
//         listVin = []
//     }

//     return listVin
// }

    let badgeFieldsObj = {};

    badgeField.forEach(badgeField => {
        badgeFieldsObj[badgeField] = (item)=>{
                    return (
                    <td>
                        <div>
                            <CBadge color={getAppraisalBadge(item[badgeField])} 
                                style={{
                                    padding: "5.25px 10.5px", 
                                    height: "24px",
                                    width: "91.3px",
                                    fontSize: "12.5px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                {item[badgeField]}
                            </CBadge>
                            {
                                appraiserRecordsView && badgeField === `offer_status` && (
                                    // <Link 
                                    //     to={{
                                    //         pathname: `/appraisal/vinlookup/${item.vin}/${item.mileage}/${item.created_date}`,
                                    //         state: { vin: item.vin, mileage: item.mileage }
                                    //     }} 
                                    //     target="_blank" 
                                    // >
                                        <CButton
                                                color="info"
                                                className={"mt-2 mb-1"}
                                                variant="outline"
                                                shape="square"
                                                size="sm"
                                                onClick={() => {
                                                    setViewVehicleDetails(Object.create({show: true, vehicle: item}))
                                                }}
                                            >
                                            View Vehicle Details
                                        </CButton>
                                    // </Link>
                                )
                            }
                            {/* {
                                badgeField == 'inspection_status' 
                                && (
                                    <Timer item={item} key={`index_${item.vin}`} trackingField={'inspection_complete_date'} />
                                )
                            } */}
                            {/* {
                                badgeField == 'offer_status' && item['inspection_status'] === 'Complete' 
                                &&
                                item['appraisal_claim_date'] &&  (
                                    <Timer item={item} key={`index_${item.vin}`} trackingField={'appraisal_completion_date'} />
                                )
                            } */}
                        </div>
                    </td>
                )}
    })

    // Assumptions:
    //     If year 2019 - 2023 and is also <= 60K in mileage, then it is retail
    //     If vehicle is year 2017 or 2018 and is also <= 100K in mileage, then it is retail
    //     If mileage is > 100K miles, then it is wholesale
    //     If year is older then 2017, then it is wholesale.

    const getVehicleType = (mileage, year) => {
        if(year <= 2023 && year >= 2019 && mileage <= 60000) return 'Retail'
        if((year == 2017 || year == 2018 ) && mileage <= 100000) return 'Retail'
        if(mileage > 100000) return 'Wholesale'
        if(year < 2017) return 'Wholesale'
    }

    const getPreferredUserName = () => {
        var rawToken = getCookie('AD_idToken');
        const decodedToken = jwt.decode(rawToken, {complete: true});

        if (decodedToken && decodedToken.payload && decodedToken.payload.preferred_username) {
            setUserName(decodedToken.payload.preferred_username);
        }
    }

    useEffect(() => {
        getPreferredUserName();     
    })

    useEffect(() => {
        if(document.getElementsByClassName('d-tooltip').length > 0 && document.getElementsByClassName('d-tooltip')[0].hasAttribute('data-tooltip')){
            let tooltipElementsCollection = document.getElementsByClassName('d-tooltip');
            let tooltipElements = [...tooltipElementsCollection]
            let tooltipArr = tooltipElements.filter(element => element.hasAttribute('data-tooltip'));
            tooltipArr.map(element => element.removeAttribute('data-tooltip'));
        }
    },[appraisalRecords])

    useEffect(() => {
            let spanElementsCollection = document.getElementsByTagName('span');
            let spanArr = [...spanElementsCollection]
            let tooltipArr = spanArr.filter(element => element.hasAttribute('data-tooltip'));
            tooltipArr.map(element => element.removeAttribute('data-tooltip'));
    },[])
    
    return (
        <CDataTable
            // addTableClasses={styles.tableclass}
            // border
            // items={usersData}
            fields={fields}
            items={sortedappraisalRecords}
            // itemsPerPageSelect
            itemsPerPage={recordsPerPage}         
            pagination
            // sorter
            // striped
            tableFilter={(search) ? { label: ' ', placeholder: 'Search' }: false}
            loading={unclaimedLoading || shiftLoading}
            // sorterValue={{column: "modified_created_date", asc: false}}
            columnHeaderSlot={{
                ['blackBook']: <CTooltip content="Adjusted Wholesale BB"><span>Black Book</span></CTooltip>,
                ['adjMmr']: <CTooltip content="Adjusted Wholesale MMR"><span>MMR</span></CTooltip>
            }}
            scopedSlots = {{
                'first_name':
                (item) => {
                    return(
                        <td>
                            <div className="d-flex align-items-center">
                                <div>
                                {item.first_name.toLowerCase() == "n.a"?
                                <CPopover content={'The creator of this VAuto record has not inputted a name on this record'}>
                                    <CIcon content={cilInfo} className="info"/>
                                </CPopover>
                                :''
                                }
                                </div>
                                <div className='ml-1'>
                                    {item.first_name === "" ? 'N.A' : capitalizeFirst(item.first_name.slice(0,15))}
                                </div>
                            </div>
                        </td>
                    )
                },
                'last_name':
                (item) => {
                    return(
                        <td>
                            <div className="d-flex align-items-center">
                                <div>
                                {item.first_name.toLowerCase() == "n.a"?
                                <CPopover content={'The creator of this VAuto record has not inputted a name on this record'}>
                                    <CIcon content={cilInfo} className="info"/>
                                </CPopover>
                                :''
                                }
                                </div>
                                <div className='ml-1'>
                                    {item.last_name === "" ? 'N.A' : capitalizeFirst(item.last_name)}
                                </div>
                            </div>
                        </td>
                    )
                },
                'email': 
                (item) => {
                   let custEmail= item.email && item.email !== 'None' ? item.email : 'N.A'
                   return (<td>
                        {custEmail}
                   </td>)
                },
                'store_name':
                (item) => {
                    let badge = false;
                    const matchedObj = matchedVins.filter(({vin}) => vin === item.vin)
                    const leadType = matchedObj.length > 0 && matchedObj[0]["leadType"]
                    if (matchedVins.length != 0 && matchedObj.length > 0){
                        badge = true
                    }
                    else {
                        badge = false
                    }
                    return(
                        <td>
                            <h6>{(!item.store)? 'N.A': item.store}</h6>
                            <h6>{(badge)? <CButton style={{borderRadius:'4px', cursor: 'default', backgroundColor: '#4B0082', color: 'white'}}
                                shape="square"
                                size="sm"
                                disabled={true}>{`ICO - ${formattedText(leadType)}`}</CButton>
                            : ''}</h6>
                        </td>
                    )
                },
                'vin': 
                (item) => {
                    return (
                        <td>
                            <div className="d-flex align-items-center">
                                <div>
                                    <CTooltip
                                        content={`${item.e_modelYear} ${item.e_make} ${item.e_model} ${item.e_trim}`}
                                    >
                                        <CIcon content={cilInfo} className="info" />
                                    </CTooltip>
                                </div>
                                <div className="ml-1">
                                    <h6 className="vin m-0">{item.vin}</h6>
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <h6
                                style={{ color: "gray" }}
                                className="ml-4 mt-2"
                                >{`${item.e_modelYear} ${item.e_make} ${item.e_model}`}</h6>
                            </div>
                        </td>
                    )
                },
                'store':
                (item) => {
                    return (
                        <td>
                            <h6>{item.store && capitalizeFirst(item.store)}</h6>
                        </td>
                    )
                },
                'mileage':
                (item) => {
                    let vehicleTyp = getVehicleType(item.mileage, item.e_modelYear)
                    return (
                        <td>
                            <h6 style={{marginBottom: '3px'}}>{parseInt(item.mileage).toLocaleString()}</h6>
                            <CButton style={{borderRadius:'4px', cursor: 'default', backgroundColor: vehicleTyp === 'Wholesale' ? '#FFC0CB' : '#d1ffbd', color: 'black'}}
                                shape="square"
                                size="sm"
                                disabled={true}>{vehicleTyp}</CButton>
                        </td>
                    )
                },
                'blackBook': 
                (item) => {
                    return (
                        <td>
                            <CButton
                                style={{borderRadius:'4px', width: '80px', cursor: 'default'}}
                                color="info"
                                className={"mt-2 mb-1 pt-2 pb-2"}
                                variant="outline"
                                shape="square"
                                size="sm"
                                disabled={true}   
                            >
                                <h5>{item.blackBook ? `$ ${parseInt(item.blackBook).toLocaleString()}` : 'N.A'}</h5>
                            </CButton> 
                        </td>
                    )
                },
                'adjMmr': 
                (item) => {
                    return (
                        <td>
                            {/* <CButton
                                style={{borderRadius:'4px', width: '80px', cursor: 'default'}}
                                color="primary"
                                className={"mt-2 mb-1 pt-2 pb-2"}
                                variant="outline"
                                shape="square"
                                size="sm"
                                disabled={true}   
                            > */}
                                <h6>{item.adjMmr ? `$ ${parseInt(item.adjMmr).toLocaleString()}` : 'N.A'}</h6>
                            {/* </CButton> */}
                        </td>
                    )
                },
                'vas':
                (item) => {
                    const { vas } = item;

                    let parsedUserName = '';

                    // add logic for parsing name from email
                    if(validateEmail(vas)) {
                        const emailName = vas.split('@')[0];
                        const namesArray = emailName.split('.');                            

                        namesArray.forEach(element => {
                            parsedUserName += element.charAt(0).toUpperCase() + element.slice(1) + " ";
                        })
                    } else {
                        parsedUserName = vas;
                    }
                    
                        
                    return (
                        <td>
                            <h6>{parsedUserName}</h6>
                        </td>
                    )
                },
                'inspection_status':
                            (item, index)=>{
                                let placement = ''
                                if (index == (sortedappraisalRecords.length)-1){
                                    placement = "right-start";
                                } else {
                                    placement = "bottom-start";
                                }
                                return (
                                <td>
                                    <div>
                                    <CDropdown>
                                        <CDropdownToggle caret 
                                        style={{
                                            padding: "5.25px 10.5px", 
                                            height: "24px",
                                            width: "91.3px",
                                            fontSize: "12.5px",
                                            fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
                                            fontWeight: "700",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                            color={item['inspection_status'] == 'In Progress' ? 'info' : item['inspection_status'] == 'Complete' ? 'success' : 'secondary'}
                                        >
                                        {item['inspection_status']}
                                        </CDropdownToggle>
                                        <CDropdownMenu
                                            placement={placement} 
                                            onClick={(e) => updateInspectionStatus.bind(this, item.vin, item.vas, item.created_date)(e.target.innerText)}
                                        >
                                            {
                                                ['In Progress', 'Complete', 'Inactive'].map(item => (
                                                    <CDropdownItem>{item}</CDropdownItem>
                                                ))
                                            }
                                        </CDropdownMenu>
                                    </CDropdown>
                                    </div>
                                </td>
                            )},
                'appraiser':
                (item) => {
                    const { appraiser } = item;

                    let parsedUserName = '';

                    // add logic for parsing name from email
                    if(validateEmail(appraiser)) {
                        const emailName = appraiser.split('@')[0];
                        const namesArray = emailName.split('.');                            

                        namesArray.forEach(element => {
                            parsedUserName += element.charAt(0).toUpperCase() + element.slice(1) + " ";
                            // parsedUserName += element + " ";
                        })
                    } else {
                        parsedUserName = appraiser;
                    }
                    
                        
                    return (
                        <td>
                            <h6>{parsedUserName}</h6>
                        </td>
                    )
                },
                'modified_created_date':
                (item, index)=>{
                    // const {hh, mm, ss} = parseTimerValues(item);

                    return (
                        <td>
                            <div className="d-flex align-items-center">
                                <div className='mt-2'>
                                    <h6>{item.modified_created_date.substr(0,10)}</h6>
                                </div>
                                <div className='ml-2'>
                                    <Timer item={item} key={`index_${item.vin}`} 
                                        startingField={'created_date'}
                                        trackingField={'appraisal_completion_date'} 
                                    />
                                </div>
                            </div>
                        </td>
                    )
                },
                [dropdownField]:
                (item, index)=>{
                    let placement = ''
                    if (index == (sortedappraisalRecords.length)-1){
                        placement = 'right-start';
                    } else {
                        placement = "bottom-start";
                    }
                    return (
                    <td>
                        <InspectionDropDown 
                            currentSelection={item[dropdownField]}
                            id={item.id}
                            onChangeHandler={roleType == "VAS" ?
                                updateInspectionStatus.bind(this, item.vin, item.vas, item.created_date) :
                                claimAppraisalRecord.bind(this, item.vin, item.created_date)
                            }
                            dropDownoptions={dropDownoptions}
                            placement={placement}
                        />
                        {/* <div className='mt-2'>
                        {                            
                            dropdownField == 'inspection_status' 
                            && (
                                <Timer item={item} key={`index_${item.vin}`} trackingField={'inspection_complete_date'} />
                                )
                            }
                        </div>                                 */}
                    </td>
                )},
                ...badgeFieldsObj,
                'offer':
                (item, index)=>{
                    return (
                    <td className="py-2 d-flex flex-column">
                        {
                            offerButtons.map(button => (
                                // <Link
                                //     to={{
                                //         key:`${item.id}${item.vin}`,
                                //         pathname: button.id == 1 ? item.b_odometer == 1 ? '#' : '/appraisal/customeroffer' : 'Edit Offer',
                                //         // pathname: `/appraisal/customeroffer`,
                                //         search: `?vin=${item.vin}`,
                                //         state: { item },
                                //         customObject: { customValue: item },
                                //     }} 
                                //     // target="_blank"
                                // >
                                    <CButton
                                        color="dark"
                                        className={"mb-2"}
                                        variant="ghost"
                                        // shape="square"
                                        size="sm"
                                        onClick={button.id == 1 ? item.b_odometer == 1 ? () => {
                                                // button.setShowViewModal(true);
                                                button.setModalContent({action: 'View', item});
                                                button.setShowPreview(true);
                                            } : () => {
                                                button.setShowViewModal(true);
                                                button.setModalContent({action: 'Generate', item});
                                            } : () => {
                                                button.setShowViewModal(true);
                                                button.setModalContent({action: 'Edit', item});
                                            }
                                        }
                                        disabled={(vasRecordsView && !item.appraisal_completion_date) || (!item.appraisal_completion_date && button.id == 2) ? true : false}
                                    >
                                        {button.id == 1 ? vasRecordsView || item.b_odometer == 1 ? <CTooltip content={(vasRecordsView && !item.appraisal_completion_date) || (!item.appraisal_completion_date && button.id == 2) ? 'Offer not Generated' : 'View Offer'}><CIcon content={cilFile} size={'lg'}/></CTooltip>
                                        : <CTooltip content={"Generate Offer"}><CIcon content={cilNoteAdd} size={'lg'}/></CTooltip>
                                        : <CTooltip content={(vasRecordsView && !item.appraisal_completion_date) || (!item.appraisal_completion_date && button.id == 2) ? 'Offer not Generated' : 'Edit Offer'}><CIcon content={cilPen} size={'lg'}/></CTooltip>}
                                    </CButton>
                                // </Link>
                            ))
                        }
                    </td>
                    )
                },
                'date_offered': (item) => {

                    const parseDate = item.created_date.split('T')[0]
                    const createdTime = parseTime(item.created_date);
                    const isExpired = DateTime.fromISO(item.created_date, { zone: "America/Chicago", setZone: true }).plus({days:7}).toISO() < new Date().toISOString()
                    
                    return (
                        <td>
                            <h6>{createdTime}</h6>
                            <h6>{parseDate}</h6>
                            <h6>{(isExpired)? <CButton style={{borderRadius:'4px', cursor: 'default', backgroundColor: '#a34100', color: 'white'}}
                                shape="square"
                                size="sm"
                                disabled={true}>Offer Expired</CButton>
                            : ''}</h6>
                        </td>
                    )
                },
                'shift_offer': (item) => {
                    return (
                        <td className="py-2 d-flex flex-column">
                            {
                                offerButtons.map(button => (
                                        <CButton
                                            color="dark"
                                            className={"mb-2"}
                                            variant="ghost"
                                            size="sm"
                                            onClick={button.id == 3 ?  () => {
                                                    // button.setShowViewModal(true);
                                                    button.setModalContent({action: 'View', item});
                                                    button.setShowPreview(true);
                                                }  : () => {
                                                    button.setShowViewModal(true);
                                                    button.setModalContent({action: 'Edit', item});
                                                }
                                            }
                                        >
                                            {button.id == 3 ?  <CTooltip content="View Offer"><CIcon content={cilFile} size={'lg'}/></CTooltip>
                                            
                                            : <CTooltip content="Edit Offer"><CIcon content={cilPen} size={'lg'}/></CTooltip>}
                                        </CButton>
                                    // </Link>
                                ))
                            }
                        </td>
                        )
                },
                ...(appraiserRecordsView ? {
                    'appraiser_archive':
                        (item, index) => {
                            return (
                            <td>
                                {/* <CFormCheck id="flexCheckDefault" label="Default checkbox"/> */}
                                <CTooltip content={"Archive"}>
                                    <div className='archiveTtip'>
                                        <input 
                                            type="checkbox"
                                            checked={item.appraiser_archive ? true : false}
                                            onChange={(e) => {
                                                archiveRecordHandler(item.vin, 'appraiser', item.appraiser, item.created_date, !item.appraiser_archive)
                                            }}
                                            style={{
                                                height:"17px",
                                                width:"17px"
                                            }}
                                        />
                                    </div>
                                </CTooltip>
                                {/* <label for="scales">Scales</label> */}
                            </td>)
                        }
                } : {}),
                ...(vasRecordsView ? {
                    'vas_archive':
                        (item, index) => {
                            return (<td>
                                {/* <CFormCheck id="flexCheckDefault" label="Default checkbox"/> */}
                                <CTooltip content={"Archive"}>
                                    <div className='archiveTtip'>
                                        <input 
                                            type="checkbox" 
                                            checked={item.vas_archive ? true : false}
                                            onChange={(e) => {
                                                archiveRecordHandler(item.vin, 'vas', item.vas, item.created_date, !item.vas_archive)
                                            }}
                                            style={{
                                                height:"17px",
                                                width:"17px"
                                            }}
                                        />
                                    </div>
                                </CTooltip>
                                {/* <label for="scales">Scales</label> */}
                            </td>)
                        }
                } : {}),
                'offer_value': 
                (item) => {
                    return(
                        <td>
                            {item.appraisal_value ? item.shift_lead_type === 'manual_quote' ? item.appraisal_value == 0 ? <CButton
                                style={{borderRadius:'4px', width: '90px', cursor: 'default',backgroundColor:'#b5b5b5'}}
                                // color="success"
                                className={"mt-2 mb-1 pt-2 pb-2"}
                                variant="outline"
                                shape="square"
                                size="sm"
                                disabled={true} 
                        >
                         <CTooltip content={"Appraiser has marked this vehicle as trade-in only"}>
                            <p className='d-tooltip' style={{color: 'black', fontSize: '11px', fontWeight: '750'}}>Trade-in Only</p>
                            </CTooltip>
                    </CButton> : <CButton
                                style={{borderRadius:'4px', width: '90px', cursor: 'default',backgroundColor:'#9c3c94'}}
                                // color="success"
                                className={"mt-2 mb-1 pt-2 pb-2"}
                                variant="outline"
                                shape="square"
                                size="sm"
                                disabled={true} 
                        >
                         <CTooltip content={"Originally no offer - Appraiser added an offer to this record"}><h5 className='d-tooltip' style={{color: 'white', fontWeight: '600' }}> {`$ ${parseInt
                        (item.appraisal_value).toLocaleString()}`}</h5></CTooltip>
                    </CButton>: item.appraisal_value == 0 ? <CButton
                                style={{borderRadius:'4px', width: '90px', cursor: 'default',backgroundColor:'#b5b5b5'}}
                                // color="success"
                                className={"mt-2 mb-1 pt-2 pb-2"}
                                variant="outline"
                                shape="square"
                                size="sm"
                                disabled={true} 
                        >
                         <CTooltip content={"Appraiser has marked this vehicle as trade-in only"}>
                            <p className='d-tooltip' style={{color: 'black', fontSize: '11px', fontWeight: '750'}}>Trade-in Only</p>
                            </CTooltip>
                    </CButton> : item.appraisal_last_edited_by === null ? <CButton
                                style={{borderRadius:'4px', width: '90px', cursor: 'default' }}
                                color="success"
                                className={"mt-2 mb-1 pt-2 pb-2"}
                                variant="outline"
                                shape="square"
                                size="sm"
                                disabled={true} 
                        >
                         <h5 style={{color: '#003200', fontWeight: '600' }}> {`$ ${parseInt
                        (item.appraisal_value).toLocaleString()}`}</h5>
                    </CButton> : <CButton
                                style={{borderRadius:'4px', width: '90px', cursor: 'default', backgroundColor:'lightblue'}}
                                className={"mt-2 mb-1 pt-2 pb-2"}
                                variant="outline"
                                shape="square"
                                size="sm"
                                disabled={true} 
                        >
                             <CTooltip content={"Originally a different cash offer - A central appraiser changed the value of this instant offer"}><h5 className='d-tooltip' style={{color: 'black', fontWeight: '600' }}> {`$ ${parseInt
                        (item.appraisal_value).toLocaleString()}`}</h5></CTooltip>
                         
                    </CButton>  :  <CButton
                                style={{borderRadius:'4px', width: '90px', cursor: 'default'}}
                                color="dark"
                                className={"mt-2 mb-1 pt-2 pb-2"}
                                variant="outline"
                                shape="square"
                                size="sm"
                                disabled={true}   
                        >
                         <h5>N.A</h5>
                    </CButton> }                            
                        </td>
                    )
                },
                'source': 
                (item) => {
                    return(
                        <td>
                            <h6>{capitalizeFirst(`${item.source}`)}</h6>
                        </td>
                    )
                },
                'shift_brand': (item) => {
                    return(
                        <td>
                            <h6>{item.shift_brand ? pickInitials(`${item.shift_brand}`) : 'N.A'}</h6>
                        </td>
                    )
                },
                'shift_rooftop': (item) => {
                    return(
                        <td>
                            <h6>{item.shift_rooftop ? formatTextBlock(`${item.shift_rooftop}`) : 'N.A'}</h6>
                        </td>
                    )
                },
                'shift_appointment': (item) => {
                    const parseDate = item.shift_appointment ? item.shift_appointment.split('T')[0] : 'N.A'
                    const appointmentTime = item.shift_appointment ? parseTime(item.shift_appointment): '';
                    return(
                        <td>
                            <h6>{appointmentTime}</h6>
                            <h6>{parseDate}</h6>
                        </td>
                    )
                },
                'vehicle_details': 
                (item) => {
                    return (
                        <td>
                            <CButton
                                style={{borderRadius:'4px'}}
                                color="info"
                                className={"mt-2 mb-1 pt-2 pb-2"}
                                shape="square"
                                size="sm"
                                onClick={() => {
                                    setViewVehicleDetails(Object.create({show: true, vehicle: item}))
                                }}
                            >
                            View Vehicle Details
                        </CButton>
                        </td>

                    
                    )
                },
                'vehicle_survey': 
                (item) => {
                    return (
                        <td>
                            <CButton
                            style={{borderRadius: '5px',
                                    backgroundColor: '#8a8884',
                                    color: 'white',
                                    fontSize: '12px',
                                    fontWeight: '550',
                                    height: '35px',
                                    width: '80px'}}
                            // color="dark"
                            className={"mt-2 mb-1"}
                            // shape="square"
                            // size="sm"
                            onClick={() => {
                                setShowSurvey(true);
                                setModalContent({action: 'ViewSurvey', item})
                            }}
                        >
                        Survey
                    </CButton>
                        </td>
                    )
                },
                'discard': 
                (item) => {
                    return (
                        <td>
                            <CButton
                            style={{borderRadius: '5px',
                                    backgroundColor: '#F3ECCC',
                                    color: 'black',
                                    fontSize: '12px',
                                    fontWeight: '550',
                                    height: '34px',
                                    width: '80px'}}
                            // color="dark"
                            className={"mt-2 mb-1"}
                            // shape="square"
                            size="sm"
                            onClick={() => {
                                setDiscardingVehicleDetail(item);
                                setShowConfirmation(true);
                            }}
                        >
                        No Offer
                    </CButton>
                        </td>
                    )
                },
                'comment_indicator':
                (item) => {
                        return (
                            <td>
                                {
                                     item.b_appraisal_comments && <CTooltip content={'This record contain comments'}><CIcon content={cilCommentSquare} size={'xl'}/></CTooltip>
                                }
                            </td>
                        )
                },
                'add_to_central_appraiser': 
                (item) => {
                    return (
                        <td>
                        <CButton
                        style={{borderRadius:'4px'}}
                            color="success"
                            className={"mt-2 mb-1 pt-2 pb-2"}
                            variant="outline"
                            shape="square"
                            size="sm"
                            
                        >
                        Add to Central Appraiser View
                    </CButton>
                        </td>
                    )
                },
            }}
        />
    )
}