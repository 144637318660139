import React, {useState, useEffect}from 'react';
import {
    CRow,CCol,CDropdown,CDropdownToggle,
    CDropdownItem,CDropdownMenu,CInput, CButton,
    CCard, CCardHeader, CCardBody, CTextarea, CTooltip
} from '@coreui/react';
import RunListManager, {getCookie} from '../../services/RunListManager';
import jwt from 'jsonwebtoken';
import CustomerPreviewModal from '../CustomerPreviewModal';
import Error from '../generic/Error/Error';
import {MultiSelectDropdown} from '../MultiSelectDropDown';
import { paintOptions, glassOptions, vehicleFeaturesOptions } from '../../constants/vehicleParts';
import { OfferSheetContent } from '../OfferSheetContent';
import OfferModalContainer from '../AppraiserView/OfferModalContainer/index'

const reference = {
    'Low Mileage/Great Conditon': 'This vehicle has low mileage and is in great condition.',
    'Low Mileage/Average Condition': 'This vehicle has low mileage and has a few damages.',
    'Low Mileage/Poor Condition' : 'This vehicle has low mileage and has several damages.',
    'Average Mileage/Great Condition':  'This vehicle has an average amount of miles and is in great condition.',
    'Average Mileage/Average Condition': 'This vehicle has an average amount of miles and has a few damages.',
    'Average Mileage/Poor Condition' : ' This vehicle has an average amount of miles and has several damages.',
    'High Mileage/Great Condition' : ' This vehicle has a high amount of miles but is in great condition.',
    'High Mileage/Average Condition' : ' This vehicle has a high amount of miles and a few damages.',
    'High Mileage/Poor Condition' : ' This vehicle has a high amount of miles and several damages.',
}

const handleInputChange = (e, setterFn, limit='40') => {
    const updatedValue = e.target.value;
    if (updatedValue) {
        let updatedArray = updatedValue.split(" ");
        for (let i = 0; i < updatedArray.length; i++) {
            if (updatedArray[i]) {
                updatedArray[i] = updatedArray[i][0].toUpperCase() + updatedArray[i].substr(1);
            }            
        }
        const formattedValue = updatedArray.join(" ");
        if (formattedValue.length <= limit) {
            setterFn(formattedValue)
        }        
    } else {
        setterFn(updatedValue)
    }  
}

const DropDownElement = ({label, values, value='', disabled=false, onChangeHandler}) => {
    return (
        <div style={{margin: '5px 0px'}}>
            {label && <strong>{label}:</strong>}
            <CDropdown>
                <CDropdownToggle color="secondary" style={{background: 'white', width: '100%', textAlign: 'left'}}>
                    {value != '' ? value : 'Select'}
                </CDropdownToggle>
                <CDropdownMenu style={{background: 'white', width: '80%'}}
                >
                    {
                        values.map(value => {
                            return <CDropdownItem href="#" onClick={(e) => {
                        onChangeHandler(e.target.innerText)
                    }}>{value}</CDropdownItem>
                        })
                    }
                </CDropdownMenu>
            </CDropdown>
        </div>
    )
}

const VehicleDetailsComponent = ({label, value, disabled=false, onChangeHandler}) => { 
    return (
        <div style={{margin: '5px 0px'}}>
            <>{label}:</>
            <CInput 
                type="text" 
                placeholder={label} 
                value={value} 
                disabled={disabled}
                style={{background: 'white'}}
                onChange={(e) => handleInputChange(e, onChangeHandler)}
            />
        </div>
    )
}

export const ManualAppraisal = ({ isShiftData }) => {

    var RL = new RunListManager();
    const [userName, setUserName] = useState('');
    const [odometer, setOdomter] = useState('Good');
    const [vin, setVin] = useState('');
    const [vinBorder, setVinBorder] = useState(false);
    const [createdDate, setCreatedDate] = useState(new Date().toLocaleString("en-US", { year: "numeric", month: "long", day :'2-digit', timeZone: 'America/New_York' }));
    const [fwd, setFwd] = useState('Good');
    const [seatStains, setSeatStains] = useState('Good');
    const [seatRips, setSeatRips] = useState('Good');
    const [transmission, setTransmission] = useState('Good');
    const [tires, setTires] = useState('Good');
    const [wheels, setWheels] = useState('Good');
    const [carpet, setCarpet] = useState('Good');
    const [engine, setEngine] = useState('Good');
    const [odor, setOdor] = useState('Good');
    const [paint, setPaint] = useState([]);
    const [parsedPaint, setParsedPaint] = useState('');
    const [glass, setGlass] = useState([]);
    const [parsedGlass, setParsedGlass] = useState('');
    const [vehicleFeatures, setVehicleFeatures] = useState([]);
    const [parsedVehicleFeatures, setParsedVehicleFeatures] = useState('');
    const [mods, setMods] = useState('No');
    const [kbb, setKbb] = useState('');
    const [kbbBorder, setKbbBorder] = useState(false)
    const [appraisalBorder, setAppraisalBorder] = useState(false)
    const [appraisalRequiredMsg, setAppraisalRequiredMsg] = useState(false)
    const [appraisalValue, setAppraisalValue] = useState('');
    const [firstName, setFirstName] = useState('');
    const [firstNameBorder, setFirstNameBorder] = useState(false);
    const [lastName, setLastName] = useState('');
    const [lastNameBorder, setLastNameBorder] = useState(false);
    const [color, setColor] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [email, setEmail] = useState('');
    const [notes, setNotes] = useState('');
    const [notesRemChars, setNotesRemChars] = useState(0);
    const [engineType, setEngineType] = useState('')
    const [e_make, setMake] = useState('')
    const [e_makeBorder, setMakeBorder] = useState(false)
    const [e_model, setModel] = useState('')
    const [e_modelBorder, setModelBorder] = useState(false)
    const [e_modelYear, setModelYear] = useState('')
    const [e_modelYearBorder, setModelYearBorder] = useState(false)
    const [e_trim, setTrim] = useState('')
    const [mileage, setMileage] = useState('')
    const [mileageBorder, setMileageBorder] = useState(false)
    const [oilLeaks, setOilLeaks] = useState('No')
    const [vehicle, setVehicle] = useState({})
    const [brand, setBrand] = useState('Off Lease Only')

    const [showPreview, setShowPreview] = useState(false);
    const [showOfferPreview, setShowOfferPreview] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [vehicleComment, setVehicleComment] = useState('');
    const [loading, setLoading ] = useState(false);

    
    const {addError} = Error();

    const isValidated = () => {
        if(vin === '') {
            setVinBorder(true) 
            return false;
        }
        if(mileage === '') {setMileageBorder(true)
        return false}
        if(e_make === '') {setMakeBorder(true)
        return false}
        if(e_model === '') {setModelBorder(true)
        return false}
        if(e_modelYear === '') {setModelYearBorder(true)
        return false}
        if(firstName === '') {setFirstNameBorder(true)
        return false}
        if(lastName === '') {setLastNameBorder(true)
        return false}
        if(appraisalValue === '') {setAppraisalRequiredMsg(true)
        return false}
        return true
    }

    useEffect(() => {
      setNotes(reference[`${vehicleComment}`])
    }, [vehicleComment])

    const getPreferredUserName = () => {
        var rawToken = getCookie('AD_idToken');
        const decodedToken = jwt.decode(rawToken, {complete: true});

        if (decodedToken && decodedToken.payload && decodedToken.payload.preferred_username) {
            setUserName(decodedToken.payload.preferred_username);
        }
    }

        useEffect(() => {
            getPreferredUserName();
        })

        let myCreatedDate = ''

        const onSubmitGenerateManualOffer = () => {

            RL.enrichVehicleData(vin, mileage)
                .then(res => {
                        const response = res.data;
                        createAppraisalRecord(response);
                })
                .catch(error => {
                    if (error.message == 'Network Error') addError(error.message);
                    else {
                        console.log("Failed enriching data", error);
                        createAppraisalRecord()
                    }
                });
    }

    const claimAppraisalRecord = (vin, created_date, appraiser) => {
        RL.claimAppraisalRecord(vin, created_date, appraiser)
                    .then(res => {
                        console.log("Record claimed by appraiser")
                        saveOfferToAppraisalDB()                   
                    })
                    .catch(error => {
                        console.error('Failed to assign record to  ' + error);
                        if (error.message == 'Network Error') 
                            addError(error.message);
                    })
    }
          

    const createAppraisalRecord = () => {
        RL.createAppraisalRecord(vin,"Manual Appraiser",mileage,firstName,lastName)
            .then(res => {
                if (res.status == 200) {
                    if(Array.isArray(res.data[1])){
                        myCreatedDate = res.data[1][1];
                        setCreatedDate(myCreatedDate)
                        claimAppraisalRecord(vin, myCreatedDate, userName)
                    }
                    // saveOfferToAppraisalDB()
                }                
            })
            .catch(error => {
                console.log(error);
                if (error.message == 'Network Error') 
                    addError(error.message);
            });
    }

    const saveOfferToAppraisalDB = () => {
        RL.saveAppraisalOfferToDB(vin, myCreatedDate, userName, odometer, fwd, wheels, seatStains, transmission, 
            tires, seatRips, carpet, engine, odor, parsedPaint, parsedGlass, mods, parsedVehicleFeatures, 
            color, email, phone, address, zipcode, appraisalValue, firstName, lastName, notes, engineType, kbb, e_make,
            e_model, e_modelYear, e_trim, mileage, formatBooleans(oilLeaks), brand)
            .then(res => {
                setShowPreview(false);
                setVehicle(res.data[1].appraisal_record_lists[0])
                setLoading(false)
                setShowOfferPreview(true)
            })
            .catch(err => {
                console.log(err);
                if (err.message == 'Network Error') 
                    addError(err.message);
            })
    }

    const formatBooleans = (statement) => {
        return statement === 'Yes' ? true : false;
    }


    function formatPhoneNumber(phoneNumberString) {
        let phone = phoneNumberString.replace(/\D/g, '');
        const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
        if (match) {
        phone = `(${match[1]}${match[2] ? ') ' : ''}${match[2]}${match[3] ? '-' : ''}${match[3]}`;
        }
        return phone
    }

    const numericChangeHandler = (e, setterFunc, limit) => {
        const updatedValue = e.target.value;

        if(updatedValue) {
            if (!isNaN(updatedValue) && limit >= updatedValue.length) {
                setterFunc(updatedValue)
            }
        } else {
            setterFunc('');
        }
    }

    const phoneHandleChange = (e) => {
        if(e.length <= 14) {
            const formattedNumber = formatPhoneNumber(e);
            setPhone(formattedNumber);
        }
    }

    const multiSelectHandleChange = (selected, setterFunc, parsedSetterFunc) => {
        let updatedSelection;
        let updatedArray = [];
        if (Array.isArray(selected)) {
            updatedSelection = selected.map(element => {     
                return element.value;
            });
        }

        for (let element of updatedSelection) {
            const selectionItem = element.split('-')[0];            
            const filteredValues = updatedSelection.filter(item => {

                if(item.indexOf(selectionItem) > -1) {
                    return item;
                } else {
                    return false
                }                 
            });
            updatedArray.push(filteredValues.pop());          
        }
        updatedArray = [...new Set(updatedArray)];
        let updatedSelected = updatedArray.map(element => ({label: element, value: element}))

        setterFunc(updatedSelected);
        parsedSetterFunc(updatedArray);
    }

    const notesChangeHandler = e => {
        const updatedValue = e.target.value;
        
        if(updatedValue.length <= 340) {
            setNotes(updatedValue);
            setNotesRemChars(340 - updatedValue.length);
        }
    }


    const AppraisalChangeHandler = (e) => {
        const apprValue = e.target.value
        setAppraisalValue(apprValue)
        if(apprValue === ""){
            setDisableButton(true)
            setAppraisalRequiredMsg(true)
        } else if( apprValue !== ""){
            setAppraisalRequiredMsg(false)
            setDisableButton(false)
            if(Number(apprValue) < Number(kbb)) {
                setAppraisalBorder(true)  
        }} 
    }
    
    const vinChangeHandler = (vin) => {
        setVin(vin)
        if(vin === ""){
            setDisableButton(true)
            setVinBorder(true)
        } else {
            setVinBorder(false)
            setDisableButton(false) 
        }} 

    const makeChangeHandler = (make) => {
        setMake(make)
        if(make === ""){
            setDisableButton(true)
            setMakeBorder(true)
        } else {
            setMakeBorder(false)
            setDisableButton(false) 
        }} 

    const modelChangeHandler = (model) => {
        setModel(model)
        if(model === ""){
            setDisableButton(true)
            setModelBorder(true)
        } else {
            setModelBorder(false)
            setDisableButton(false) 
        }} 

    const yearChangeHandler = (year) => {
        setModelYear(year)
        if(year === ""){
            setDisableButton(true)
            setModelYearBorder(true)
        } else {
            setModelYearBorder(false)
            setDisableButton(false) 
        }} 

    const mileageChangeHandler = (mileage) => {
        setMileage(mileage)
        if(vin === ""){
            setDisableButton(true)
            setMileageBorder(true)
        } else {
            setMileageBorder(false)
            setDisableButton(false) 
        }} 

    const firstNameChangeHandler = (firstName) => {
        setFirstName(firstName)
        if(vin === ""){
            setDisableButton(true)
            setFirstNameBorder(true)
        } else {
            setFirstNameBorder(false)
            setDisableButton(false) 
        }} 

    const lastNameChangeHandler = (lastName) => {
        setLastName(lastName)
        if(vin === ""){
            setDisableButton(true)
            setLastNameBorder(true)
        } else {
            setLastNameBorder(false)
            setDisableButton(false) 
        }} 
    

    const kbbChngeHandler = e => {
        const kValue = e.target.value;
        setKbb(kValue)
        if(Number(kValue) > Number(appraisalValue)) {
            setKbbBorder(true)
            setDisableButton(true)
        } else {
            setKbbBorder(false)
            setDisableButton(false)
        }
    }


  return (
    <>
    <CRow style={{marginBottom: '1rem'}}>
        <CCol className="col-md-11 mt-2 ml-4">
            <h3 className='pt-1 pb-1'><strong>Customer Offer Sheet Generator</strong></h3>
        </CCol>
    </CRow>
    <CRow style={{display: 'flex', justifyContent: 'space-around', padding: '4px 8px'}}>                
        <>
            <CCol 
                xs={3} 
                style={{ 
                    padding: '4px 0px', borderRadius: '8px', minWidth: '48%',
                    marginRight: '2px',
                    marginBottom: '2rem', marginLeft: '20px'
                }}
            >
               <CCard>
                <CCardHeader style={{background: '#00aced', color: 'white'}}><h3>Vehicle Information</h3></CCardHeader>
                <CCardBody className='pt-1 pb-1'>
                    <DropDownElement label={"Brand"} 
                        values={['Off Lease Only', 'Car Squad']} 
                        disabled={true}
                        value={brand}
                        onChangeHandler={setBrand}
                    />
                    <VehicleDetailsComponent isRequired={true} label={"VIN"} value={vin} onChangeHandler={vinChangeHandler} />
                    <span style={{color: 'red'}}>{vinBorder ? `VIN is a required field` : null}</span>
                    <VehicleDetailsComponent isRequired={true} label={"Odometer"} value={mileage} onChangeHandler={mileageChangeHandler}/>
                    <span style={{color: 'red'}}>{mileageBorder ? `Odometer is a required field` : null}</span>
                    <VehicleDetailsComponent isRequired={true} label={"Year"} value={e_modelYear} onChangeHandler={yearChangeHandler}/>
                    <span style={{color: 'red'}}>{e_modelYearBorder ? `Year is a required field` : null}</span>
                    <VehicleDetailsComponent isRequired={true} label={"Make"} value={e_make} onChangeHandler={makeChangeHandler}/>
                    <span style={{color: 'red'}}>{e_makeBorder ? `Make is a required field` : null}</span>
                    <VehicleDetailsComponent isRequired={true} label={"Model"} value={e_model} onChangeHandler={modelChangeHandler}/>
                    <span style={{color: 'red'}}>{e_modelBorder ? `Model is a required field` : null}</span>
                    <VehicleDetailsComponent label={"Series"} value={e_trim} onChangeHandler={setTrim}/>
                    <VehicleDetailsComponent 
                        label={"Color"} 
                        value={color} 
                        onChangeHandler={setColor}
                    />
                    <VehicleDetailsComponent 
                        label={"Engine"} 
                        value={engineType} 
                        onChangeHandler={setEngineType}
                    />
                </CCardBody>
               </CCard> 
            </CCol>
            <CCol 
                xs={3} 
                style={{ 
                    padding: '4px', borderRadius: '8px', minWidth: '48%',
                    marginLeft: '2px', 
                    marginRight: '20px',
                    marginBottom: '2rem'
                }}
            >
               <CCard>
                <CCardHeader style={{background: '#00aced', color: 'white'}}>
                    <h3>Customer Information</h3>
                </CCardHeader>
                <CCardBody className='pt-1 pb-1'>
                    <VehicleDetailsComponent isRequired={true} label={"First Name"} value={firstName} onChangeHandler={firstNameChangeHandler} />
                    <span style={{color: 'red'}}>{firstNameBorder ? `First Name is a required field` : null}</span>
                    <VehicleDetailsComponent isRequired={true} label={"Last Name"} value={lastName} onChangeHandler={lastNameChangeHandler} />
                    <span style={{color: 'red'}}>{lastNameBorder ? `Last Name is a required field` : null}</span>

                    <VehicleDetailsComponent label={"Telephone"} value={phone} onChangeHandler={phoneHandleChange} />
                    <VehicleDetailsComponent label={"Email"} value={email} onChangeHandler={setEmail} />
                    <VehicleDetailsComponent label={"Address"} value={address} onChangeHandler={setAddress} />
                    <div style={{margin: '5px 0px'}}>
                        <>Zip Code:</>
                        <CInput 
                            type="text" 
                            placeholder={"Zip Code"} 
                            value={zipcode} 
                            disabled={false}
                            style={{background: 'white'}}
                            onChange={e => numericChangeHandler(e, setZipcode, 5)}
                        />
                    </div>
                    <VehicleDetailsComponent label={"Date"} value={createdDate} disabled={true} />
                </CCardBody>
               </CCard> 
            </CCol>
        </>
        <>
            <CCol 
                xs={3} 
                style={{ 
                    padding: '4px 0px', borderRadius: '8px', minWidth: '48%',
                    marginRight: '2px',
                    marginLeft: '20px'
                }}
            >
               <CCard>
                <CCardHeader style={{background: '#00aced', color: 'white'}}>
                    <h3>Vehicle Condition</h3>
                </CCardHeader>
                <CCardBody>
                    <>
                            <DropDownElement label={"Odometer"} 
                        value={odometer}
                        values={['Good', 'Odometer Inop', 'Possible Rollback', 'Instrument Cluster Needs Repair']} 
                        disabled={true}
                        onChangeHandler={setOdomter}
                    />
                    <DropDownElement label={"Four Wheel Drive"} 
                        values={['Good', '4WD Inop', '4WD High needs service', '4WD low needs service']} 
                        disabled={true}
                        value={fwd}
                        onChangeHandler={setFwd}
                    />
                    <DropDownElement label={"Front/Rear Seats - Stains"} 
                        values={['Good', '3 or less', '3 or more']} 
                        disabled={true} 
                        value={seatStains}
                        onChangeHandler={setSeatStains}
                    />
                    <DropDownElement label={"Front/Rear Seats - Rips/Tears"} 
                        values={['Good', '3 or less', '3 or more']} 
                        disabled={true}
                        value={seatRips}
                        onChangeHandler={setSeatRips}
                    />
                    <DropDownElement label={"Transmission"} 
                        values={['Good', 'Delayed Shift', 'Shifts Hard into gear']} 
                        disabled={true}
                        value={transmission}
                        onChangeHandler={setTransmission}
                    />
                    <DropDownElement label={"Tires"} 
                        values={['Good', 'Replace Front 1 or 2', 
                            'Replace Rear 1 or 2'
                        ]} 
                        disabled={true} 
                        value={tires}
                        onChangeHandler={setTires}
                    />
                    <DropDownElement label={"Wheels"} 
                        values={['Good', 'Rims scuffed and needs repair  1,2,3,4', 
                            'Rim needs to be replaced 1,2,3,4',
                            'Rim needs to be replaced 20in or larger'
                        ]} 
                        disabled={true} 
                        value={wheels}
                        onChangeHandler={setWheels}
                    />
                    <DropDownElement label={"Carpet"} 
                        values={['Good', 'Stains', 'Rips/Tears', 'Replacment Needed']} 
                        disabled={true}
                        value={carpet}
                        onChangeHandler={setCarpet}
                    />
                    <DropDownElement label={"Engine"} 
                        values={['Good', 'Lower Engine Noise', 'Sludge']} 
                        disabled={true}
                        value={engine}
                        onChangeHandler={setEngine}
                    />
                    </> 
                    
                    
                    <DropDownElement label={"Odor"} 
                        values={['Good', 'Pet', 'Smoke', 'Mildew']} 
                        disabled={true}
                        value={odor}
                        onChangeHandler={setOdor}
                    />
                    
                   
                    <>
                        <MultiSelectDropdown 
                        options={paintOptions} 
                        label="Paint/Body"
                        optionSelected={paint}
                        handleChange={(selected) => multiSelectHandleChange(selected, setPaint, setParsedPaint)}   
                    />
                    <MultiSelectDropdown 
                        options={glassOptions} 
                        label="Glass"
                        optionSelected={glass}
                        handleChange={(selected) => multiSelectHandleChange(selected, setGlass, setParsedGlass)}
                    />
                    <MultiSelectDropdown 
                        options={vehicleFeaturesOptions} 
                        label="Vehicle Features"
                        optionSelected={vehicleFeatures}
                        handleChange={(selected) => multiSelectHandleChange(selected, setVehicleFeatures, setParsedVehicleFeatures)}
                    />
                    <DropDownElement label={"Oil leaks"} 
                        values={['Yes', 'No']} 
                        disabled={true}
                        value={oilLeaks}
                        onChangeHandler={setOilLeaks}
                    />
                    <DropDownElement label={"Modifications"} 
                        values={['Yes', 'No']} 
                        disabled={true}
                        value={mods}
                        onChangeHandler={setMods}
                    />
                    </>
                </CCardBody     >
               </CCard> 
            </CCol>
            <div 
                style={{
                    padding: '4px 0px', borderRadius: '8px', minWidth: '48%',
                    marginLeft: '2px',
                    marginRight: '20px'
                }}
            >                    
                <CCol 
                    xs={3} 
                    style={{ 
                        padding: '4px 0px', 
                        borderRadius: '8px', 
                        minWidth: '100%',
                        marginRight: '20px'
                    }}
                >
                    
                    <CCard>
                        <CCardHeader style={{background: '#00aced', color: 'white'}}>
                            <h3>Appraisal Value</h3>
                            
                        </CCardHeader>
                        <CCardBody>
                        <div style={{margin: '5px 0px'}}>
                            <>Appraisal Value:</>
                            <CInput 
                                type="text" 
                                placeholder={"Appraisal Value"} 
                                value={appraisalValue}
                                disabled={false}
                                style={{background: 'white', borderColor: appraisalBorder? 'red':''}}
                                // onChange={e => numericChangeHandler(e, setAppraisalValue, 8)}
                                onChange={e =>{numericChangeHandler(e, setAppraisalValue, 8); AppraisalChangeHandler(e)}}
                            />
                            {appraisalRequiredMsg ? <span style={{color:'red'}}>Appraisal Value can not be empty</span>: '' }
                            {appraisalBorder?<span style={{color:'red'}}>Appraisal Value can not be smaller than KBB Value</span>: ''}
                        </div>
                        </CCardBody>
                    </CCard> 
                </CCol>
                <CCol 
                    xs={3} 
                    style={{ 
                        padding: '4px 0px', 
                        borderRadius: '8px', 
                        minWidth: '100%',
                        marginRight: '20px'
                    }}
                >
                    <CCard>
                        <CCardHeader style={{background: '#00aced', color: 'white'}}>
                            <h3>KBB Fair Market Value</h3>
                        </CCardHeader>
                        <CCardBody>
                        <div style={{margin: '5px 0px'}}>
                            <CInput 
                                type="text" 
                                placeholder="KBB Market Value" 
                                value={kbb}
                                style={{background: 'white', borderColor: kbbBorder? 'red':''}}
                                onChange={kbbChngeHandler}
                            />
                            {kbbBorder?<span style={{color:'red'}}>KBB Value can not be greater than Appraisal Value</span>: ''}
                        </div>
                        </CCardBody>
                    </CCard> 
                </CCol>
                <CCol 
                    xs={3} 
                    className="m-8"
                    style={{ 
                        padding: '4px 0px', 
                        borderRadius: '8px', minWidth: '100%',marginRight: '20px'

                    }}  
                >
                    <CCard>
                        <CCardHeader style={{background: '#00aced', color: 'white'}}>
                            <h3>Predefined Vehicle Comments</h3>
                        </CCardHeader>
                        <CCardBody>
                            <DropDownElement
                                values={[
                                    "Low Mileage/Great Conditon",
                                    "Low Mileage/Average Condition",
                                    "Low Mileage/Poor Condition",
                                    "Average Mileage/Great Condition",
                                    "Average Mileage/Average Condition",
                                    "Average Mileage/Poor Condition",
                                    "High Mileage/Great Condition",
                                    "High Mileage/Average Condition",
                                    "High Mileage/Poor Condition",
                                ]} 
                            // disabled={true}
                            value={vehicleComment}
                            onChangeHandler={setVehicleComment}
                            />
                        </CCardBody>
                    </CCard>
                    <CCard>
                        <CCardHeader style={{background: '#00aced', color: 'white'}}>
                            <h3>Notes for Customer Offer Sheet <span style={{fontSize:'14px', fontWeight: '550'}}><em>(Notes will appear on Customer's Offer Sheet)</em></span></h3>
                        </CCardHeader>
                        <CCardBody>
                            <CTextarea 
                                label='Add Notes'
                                value={notes}
                                onChange={notesChangeHandler}
                                style={{height: '150px'}}
                            />
                            <span>Remaining Chars: {notesRemChars}</span>
                        </CCardBody>
                    </CCard> 
                </CCol>
                <CCol 
                    xs={3} 
                    className="m-8"
                    style={{ 
                        padding: '4px', minWidth: '100%',
                        margin: '10px auto', background: '#00aced', borderRadius: '8px',
                        marginBottom: '30px', marginRight: '20px'
                    }}
                >
                    <CButton 
                        style={{ width:'100%', color: 'white' }} 
                        onClick={() => isValidated() ? setShowPreview(true): null
                    }
                        disabled={disableButton}
                    >
                        <strong>
                            GENERATE OFFER
                        </strong>
                    </CButton> 
                </CCol>
                <CCol 
                    xs={3} 
                    style={{ 
                        padding: '4px', borderRadius: '8px', minWidth: '100%',
                        marginLeft: '2px', 
                        marginBottom: '2rem', marginRight: '20px'
                    }}
                >
                </CCol> 
                
            </div>                    
        </>
    </CRow>
    <CustomerPreviewModal 
                    loading={loading}
                    setLoading={setLoading}
                    action={"Generate"}
                    // superUser={superUser}
                    show={showPreview} 
                    setShow={setShowPreview}
                    firstName={firstName}
                    lastName={lastName}
                    engineType={engineType}
                    color={color}
                    notes={notes}
                    phone={phone}
                    email={email}
                    address={address}
                    zipcode={zipcode}
                    odometer={mileage}
                    appraisalValue={appraisalValue}
                    fwd={fwd}
                    seatStains={seatStains}
                    transmission={transmission}
                    // fTires={fTires}
                    wheels={wheels}
                    seatRips={seatRips}
                    carpet={carpet}
                    engine={engine}
                    odor={odor}
                    paint={parsedPaint}
                    glass={parsedGlass}
                    vehicle_features={parsedVehicleFeatures}
                    oilLeaks={oilLeaks}
                    mods={mods}
                    kbb={kbb}
                    // jdPower={jdPower}
                    // mmr={mmr}
                    saveOfferToAppraisalDB={onSubmitGenerateManualOffer}
                    // claimAppraisal={claimAppraisal}
            />
            {vehicle !== {} && showOfferPreview ? <OfferModalContainer 
                    isShiftData={false} 
                    show={showOfferPreview} 
                    setShow={setShowOfferPreview}
                    item={vehicle}                     
                    saveFileButton={true}>
                <OfferSheetContent
                    isShiftData={false} 
                    item={vehicle} 
                />
            </OfferModalContainer>: null}
    </>
  )}