import React, {useState, useEffect} from 'react';
import { Text, View, StyleSheet, Link, Font } from '@react-pdf/renderer';
import {DateTime} from 'luxon';
import ITCAvantGardemedium from '../../assets/fonts/ITC_Avant_Garde_Gothic_Medium.otf';
import ITCAvantGardeBold from '../../assets/fonts/ITC_Avant_Garde_Gothic_Bold.otf';
import ITCAvantGardeStd from '../../assets/fonts/ITC_Avant_Garde_Std.ttf';
import ITCAVGLTBook from '../../assets/fonts/ITC_Avant_Garde_Gothic_LT_Book_Regular.otf';
import { formatPhoneNumber, formattedText, parseUserFromEmail } from '../../services/utils';


// Register fonts
Font.register(
  { family: 'ITCAVG', src: ITCAvantGardemedium }, 
);

Font.register(
  { family: 'ITCAVGBold', src: ITCAvantGardeBold }
);

Font.register(
  { family: 'ITCAVGStd', src: ITCAvantGardeStd }
);


Font.register(
  { family: 'ITCAVGLTBook', src: ITCAVGLTBook }
);


const styles = StyleSheet.create({
      container: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        backgroundColor: '#e9e9e9',
        alignItems: 'center',
        // 
        height: '45vh'
    },
      detailColumn: {
        flexDirection: 'row',
        flex: 0.31,
        padding: '0px 10px',
        justifySelf: 'flex-start',
        alignSelf: 'flex-start',
        width:'100%'
      },
      linkColumn: {
        flexDirection: 'row',
        flex: 0.69,
        alignSelf: 'flex-start',
        justifySelf: 'flex-start',
        padding: '2px 10px',
        width:'100%',
      },
      name: {
        fontSize: 10,
        margin: '2px 0px',
      },
      detailColumnHeader: {
        fontFamily: 'ITCAVGBold',
        margin: '2px 0px'              
      },
    detailColumnText: {fontFamily: 'ITCAVGLTBook'},
      
    assesmentContainer: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      margin: '0px 0px',      
    },
  });

  const Table = () => {
    return (
      <table>
        <tr>
          <td>Emil</td>
          <td>Tobias</td>
          <td>Linus</td>
        </tr>
      </table>
    )
  }

export const OfferDetails = ({item, isShiftData, isCarSquad}) => {
    const [appraiserName, setAppraiserName] = useState('');
    const [vehicleFeatures, setVehicleFeatures] = useState([]);
    // const [createdDate, setCreatedDate] = useState('');
    const [updatedDate, setUpdatedDate] = useState('');

    useEffect(() => {
      if (item.appraiser && item.appraiser != 'Shift') {
        const apprnameArray = item.appraiser.split('@')[0].split('.');
        const capitalizeFirst = first => {
          return first.charAt(0).toUpperCase() + first.slice(1);
        };
        const capitalizeLast = last => {
          return last.charAt(0).toUpperCase() + last.slice(1);
        };
        const first = apprnameArray[0]
        const last = apprnameArray[1]
        // setAppraiserName(`${apprnameArray[0]} ${apprnameArray[1]}`);
        setAppraiserName(`${capitalizeFirst(first)} ${capitalizeLast(last)}`);
      } else if(item.appraiser == 'Shift') {
        setAppraiserName(item.offer_last_edited_by != null ? parseUserFromEmail(item.offer_last_edited_by) : 'Instant Cash Offer');
      } else setAppraiserName(item.appraiser)

      // parse vehicle features
      if (item.vehicle_features) {
        const vehicleFeaturesArray = item.vehicle_features.split(',');
        setVehicleFeatures([...vehicleFeaturesArray]);
      }

      if(item.last_updated_date) {
        const updatedDt = DateTime.fromISO(item.last_updated_date, { zone: "America/Chicago", setZone: true });

        setUpdatedDate(`${updatedDt.month}/${updatedDt.day}/${updatedDt.year}`);
      }

    }, [item])

    return (
        <View 
            style={styles.container}
        >
            <View style={styles.detailColumn}>
              <View 
                style={{height: '100%', flex: '1', justifyContent: 'flex-start', padding: '4px 10px'}}
              >
                  <Text style={styles.name}>
                    <Text style={styles.detailColumnHeader}>
                      Date: {" "}
                    </Text>
                    <Text style={styles.detailColumnText}>
                      {updatedDate}
                    </Text>
                  </Text>
                  <Text style={styles.name}>
                    <Text style={styles.detailColumnHeader}>
                      Name: {" "}
                    </Text>
                    <Text style={styles.detailColumnText}>{item.first_name} {item.last_name}</Text>
                  </Text>
                  <Text style={styles.name}>
                    <Text style={styles.detailColumnHeader}>
                      Address: {" "}
                    </Text>
                    <Text style={styles.detailColumnText}>{item.address}</Text>
                    
                  </Text>
                  <Text style={styles.name}>
                    <Text style={styles.detailColumnHeader}>
                      Vehicle: {" "}
                    </Text>
                    <Text style={styles.detailColumnText}>{item.e_modelYear} {item.e_make} {item.e_model} {item.e_trim}</Text>
                    
                  </Text>
                  <Text style={styles.name}>
                    <Text style={styles.detailColumnHeader}>
                      Color: {" "}
                    </Text>
                    <Text style={styles.detailColumnText}>{item.color}</Text>
                    
                  </Text>
                  <Text style={styles.name}>
                    <Text style={styles.detailColumnHeader}>
                      Mileage: {" "}
                    </Text>
                    <Text style={styles.detailColumnText}>{parseInt(item.mileage).toLocaleString()}</Text>
                    
                  </Text>
                  <Text style={styles.name}>
                    <Text style={styles.detailColumnHeader}>
                      Engine: {" "}
                    </Text>
                    <Text style={styles.detailColumnText}>{item.engineType}</Text>
                    
                  </Text>
                  <Text style={styles.name}>
                    <Text style={styles.detailColumnHeader}>
                      VIN: {" "}
                    </Text>
                    <Text style={styles.detailColumnText}>{item.vin}</Text>
                    
                  </Text>
                </View>
                <View style={{ flex: '1', justifyContent: 'flex-start', fontSize: '11px'}}>
                  <View style={{justifyContent: 'flex-start', margin: '8px 20px'}}>
                    <Text style={{ fontFamily: 'ITCAVGBold', marginBottom: '2px'}}>
                        Contact:
                    </Text>
                    
                    <Text style={{ fontFamily: 'ITCAVGLTBook', fontSize: '9px'}}>
                      {item.first_name} {item.last_name}
                     </Text>
                    <Text style={{ fontFamily: 'ITCAVGLTBook', fontSize: '9px'}}>{formatPhoneNumber(item.phone)}</Text>
                  </View>
                  <View style={{justifyContent: 'flex-start', margin: '8px 20px'}}>
                    <Text style={{ fontFamily: 'ITCAVGBold', marginBottom: '2px'}}>
                        Your Appraiser:
                    </Text>
                    
                      <Text style={{ fontFamily: 'ITCAVGLTBook', fontSize: '9px'}}>
                        {appraiserName}
                      </Text>
                      <Text style={{ fontFamily: 'ITCAVGLTBook', fontSize: '9px'}}>{ isCarSquad ? 'CarSquad Certified Appraiser' : 'OLO Certified Appraiser'}</Text>
                      <Text style={{ fontFamily: 'ITCAVGLTBook', fontSize: '9px'}}>(866) 491-5831</Text>
                  </View>
                </View>
            </View>
            {!isShiftData ? <View style={styles.linkColumn}>                
                <View style={{ flex: '1', padding: '0px 10px'}}>
                    <Text style={{
                      fontSize: '10px', textTransform: 'uppercase',
                       backgroundColor: 'black', color: 'white',
                       margin: '4px 0px', textAlign: 'center',
                       fontFamily: 'ITCAVGBold'
                      }}
                    >
                        Vehicle Features
                    </Text>
                    <View style={{flexDirection: 'row'}}>
                      <View>
                        {
                          vehicleFeatures.map((feature, index) => (index < 13) && (
                            <Text style={{fontSize: '10px', margin: '2px 0px', fontFamily: 'ITCAVGLTBook'}}>
                                {feature.trim()}
                            </Text>
                          ))
                        }
                      </View>
                      <View style={{marginLeft: '4px'}}>
                        {
                          vehicleFeatures.map((feature, index) => (index >= 13) && (
                            <Text style={{fontSize: '10px', margin: '2px 0px', fontFamily: 'ITCAVGLTBook'}}>
                                {feature.trim()}
                            </Text>
                          ))
                        }
                      </View>
                    </View>
                </View>
                <View style={{ flex: '1', padding: '0px 10px'}}>
                    <Text 
                      style={{
                        fontSize: '10px', textTransform: 'uppercase', 
                        backgroundColor: 'black', color: 'white',
                        margin: '4px 0px', textAlign: 'center',
                        fontFamily: 'ITCAVGBold'
                      }}
                    >
                        Conditions Assessed
                    </Text>
                    <View style={styles.assesmentContainer}>
                      <View style={{width: '30%', fontSize: '9px'}}>
                        <Text style={styles.detailColumnHeader}>Engine: </Text>
                      </View>
                      <View style={{width: '70%', fontSize: '9px'}}>
                        <Text style={{fontSize: '10px', margin: '2px 0px', fontFamily: 'ITCAVGLTBook'}}>{item.engine}</Text>
                      </View>
                    </View>

                    <View style={styles.assesmentContainer}>
                      <View style={{width: '30%', fontSize: '9px'}}>
                        <Text style={styles.detailColumnHeader}>Glass: </Text>
                      </View>
                      <View style={{width: '70%', fontSize: '9px'}}>
                        <Text style={{fontSize: '10px', margin: '2px 0px', fontFamily: 'ITCAVGLTBook'}}>{item.glass}</Text>
                      </View>
                    </View>

                    <View style={styles.assesmentContainer}>
                      <View style={{width: '30%', fontSize: '9px'}}>
                        <Text style={styles.detailColumnHeader}>Carpet: </Text>
                      </View>
                      <View style={{width: '70%', fontSize: '9px'}}>
                        <Text style={{fontSize: '10px', margin: '2px 0px', fontFamily: 'ITCAVGLTBook'}}>{item.carpet}</Text>
                      </View>
                    </View>

                    <View style={styles.assesmentContainer}>
                      <View style={{width: '30%', fontSize: '9px'}}>
                        <Text style={styles.detailColumnHeader}>Transmission: </Text>
                      </View>
                      <View style={{width: '70%', fontSize: '9px'}}>
                        <Text style={{fontSize: '10px', margin: '2px 0px', fontFamily: 'ITCAVGLTBook'}}>{item.transmission}</Text>
                      </View>                     
                    </View>

                    <View style={styles.assesmentContainer}>
                      <View style={{width: '30%', fontSize: '9px'}}>
                        <Text style={styles.detailColumnHeader}>Tires: </Text>
                      </View>
                      <View style={{width: '70%', fontSize: '9px'}}>
                        <Text style={{fontSize: '10px', margin: '2px 0px', fontFamily: 'ITCAVGLTBook'}}>{item.tires}</Text>
                      </View>                      
                    </View>

                    <View style={styles.assesmentContainer}>
                     <View style={{width: '30%', fontSize: '9px'}}>
                        <Text style={styles.detailColumnHeader}>Wheels: </Text>
                      </View>
                      <View style={{width: '70%', fontSize: '9px'}}>
                        <Text style={{fontSize: '10px', margin: '2px 0px', fontFamily: 'ITCAVGLTBook'}}>{item.wheels}</Text>
                      </View>                      
                    </View>
                      
                    
                    <View style={styles.assesmentContainer}>
                      <View style={{width: '30%', fontSize: '9px'}}>
                        <Text style={styles.detailColumnHeader}>4WD: </Text>
                      </View>
                      <View style={{width: '70%', fontSize: '9px'}}>
                        <Text style={{fontSize: '10px', margin: '2px 0px', fontFamily: 'ITCAVGLTBook'}}>{item.t_4wd_awd}</Text>
                      </View>
                    </View> 
                      
                    
                    <View style={styles.assesmentContainer}>
                      <View style={{width: '30%',  fontSize: '9px'}}>
                        <Text style={styles.detailColumnHeader}>Seat Stains: </Text>
                      </View>
                      <View style={{width: '70%',  fontSize: '9px'}}>
                        <Text style={{fontSize: '10px', margin: '2px 0px', fontFamily: 'ITCAVGLTBook'}}>{item.seats_stains}</Text>
                      </View>
                    </View>
                    
                    
                    <View style={styles.assesmentContainer}>
                      <View style={{width: '30%',  fontSize: '9px'}}>
                        <Text style={styles.detailColumnHeader}>Seat Rips/Tears: </Text>
                      </View>
                      <View style={{width: '70%',  fontSize: '9px'}}>
                        <Text style={{fontSize: '10px', margin: '2px 0px', fontFamily: 'ITCAVGLTBook'}}>{item.seats_rips_tears}</Text>
                      </View>
                    </View>
                    
                    
                    <View style={styles.assesmentContainer}>
                      <View style={{width: '30%',  fontSize: '9px'}}>
                        <Text style={styles.detailColumnHeader}>Paint/Body: </Text>
                      </View>
                      <View style={{width: '70%',  fontSize: '9px'}}>
                        <Text style={{fontSize: '10px', margin: '2px 0px', fontFamily: 'ITCAVGLTBook'}}>{item.paint_body}</Text>
                      </View>
                    </View>
                    
                    <View style={styles.assesmentContainer}>
                      <View style={{width: '30%',  fontSize: '9px'}}>
                        <Text style={styles.detailColumnHeader}>Odor: </Text>
                      </View>
                      <View style={{width: '70%',  fontSize: '9px'}}>
                        <Text style={{fontSize: '10px', margin: '2px 0px', fontFamily: 'ITCAVGLTBook'}}>{item.odor}</Text>
                      </View>
                    </View>
                    
                    
                    <View style={styles.assesmentContainer}>
                      <View style={{width: '30%',  fontSize: '9px'}}>
                        <Text style={styles.detailColumnHeader}>Odometer: </Text>
                      </View>
                      <View style={{width: '70%',  fontSize: '9px'}}>
                        <Text style={{fontSize: '10px', margin: '2px 0px', fontFamily: 'ITCAVGLTBook'}}>{item.odometer}</Text>
                      </View>
                    </View>


                    <View style={styles.assesmentContainer}>
                      <View style={{width: '30%',  fontSize: '9px'}}>
                        <Text style={styles.detailColumnHeader}>Oil Leaks: </Text>
                      </View>
                      <View style={{width: '70%',  fontSize: '9px'}}>
                        <Text style={{fontSize: '10px', margin: '2px 0px', fontFamily: 'ITCAVGLTBook'}}>{item.b_oil_leaks == true ? 'Yes' : 'No'}</Text>
                      </View>
                    </View>
                    
                    
                    <View style={styles.assesmentContainer}>
                      <View style={{width: '30%',  fontSize: '9px'}}>
                        <Text style={styles.detailColumnHeader}>Mods: </Text>
                      </View>
                      <View style={{width: '70%',  fontSize: '9px'}}>
                        <Text style={{fontSize: '10px', margin: '2px 0px', fontFamily: 'ITCAVGLTBook'}}>{item.modifications}</Text>
                      </View>
                    </View>
                       
                </View>                
            </View> : 
              <View style={styles.linkColumn}>                
              <View style={{ flex: '1', padding: '0px 10px'}}>
                  <Text 
                    style={{
                      fontSize: '10px', textTransform: 'uppercase', 
                      backgroundColor: 'black', color: 'white',
                      margin: '4px 0px', textAlign: 'center',
                      fontFamily: 'ITCAVGBold'
                    }}
                  >
                      Conditions Assessed
                  </Text>
                  <View style={styles.assesmentContainer}>
                    <View style={{width: '45%', fontSize: '9px'}}>
                      <Text style={styles.detailColumnHeader}>Persistent Odor:</Text>
                    </View>
                    <View style={{width: '65%', fontSize: '9px'}}>
                      <Text style={{fontSize: '10px', margin: '2px 0px', fontFamily: 'ITCAVGLTBook'}}>{item.b_odor== true ? 'Yes' : 'No'}</Text>
                    </View>
                  </View>

                  <View style={styles.assesmentContainer}>
                    <View style={{width: '45%', fontSize: '9px'}}>
                      <Text style={styles.detailColumnHeader}>Starting/ Driving Issues: </Text>
                    </View>
                    <View style={{width: '65%', fontSize: '9px'}}>
                      <Text style={{fontSize: '10px', margin: '2px 0px', fontFamily: 'ITCAVGLTBook'}}>{item.b_starting_driving_issues == true ? 'Yes' : 'No'}</Text>
                    </View>
                  </View>

                  <View style={styles.assesmentContainer}>
                    <View style={{width: '45%', fontSize: '9px'}}>
                      <Text style={styles.detailColumnHeader}>Mechanical Issues/Dashboard Lights: </Text>
                    </View>
                    <View style={{width: '65%', fontSize: '9px'}}>
                      <Text style={{fontSize: '10px', margin: '2px 0px', fontFamily: 'ITCAVGLTBook'}}>{item.b_mechanical_issues_warning_lights == true ? formattedText(item.t_mechanical_issues_warning_lights) : 'No'}</Text>
                    </View>
                  </View>

                  <View style={styles.assesmentContainer}>
                    <View style={{width: '45%', fontSize: '9px'}}>
                      <Text style={styles.detailColumnHeader}>Loan / Lease: </Text>
                    </View>
                    <View style={{width: '65%', fontSize: '9px'}}>
                      <Text style={{fontSize: '10px', margin: '2px 0px', fontFamily: 'ITCAVGLTBook'}}>{item.b_loan_lease == true ? item.t_loan_lease : 'No'}</Text>
                    </View>                     
                  </View>

                  <View style={styles.assesmentContainer}>
                    <View style={{width: '45%', fontSize: '9px'}}>
                      <Text style={styles.detailColumnHeader}>Damaged Exterior Panels: </Text>
                    </View>
                    <View style={{width: '65%', fontSize: '9px'}}>
                      <Text style={{fontSize: '10px', margin: '2px 0px', fontFamily: 'ITCAVGLTBook'}}>{item.b_panels_damaged == true ? formattedText(item.t_panels_damaged) : 'No'}</Text>
                    </View>                      
                  </View>

                  <View style={styles.assesmentContainer}>
                   <View style={{width: '45%', fontSize: '9px'}}>
                      <Text style={styles.detailColumnHeader}>Vehicle Accidents: </Text>
                    </View>
                    <View style={{width: '65%', fontSize: '9px'}}>
                      <Text style={{fontSize: '10px', margin: '2px 0px', fontFamily: 'ITCAVGLTBook'}}>{item.b_vehicle_accident == true ? `${item.t_vehicle_accident} times` : 'No'}</Text>
                    </View>                      
                  </View>
                    
                  
                  <View style={styles.assesmentContainer}>
                    <View style={{width: '45%', fontSize: '9px'}}>
                      <Text style={styles.detailColumnHeader}>Hail/Dents: </Text>
                    </View>
                    <View style={{width: '65%', fontSize: '9px'}}>
                      <Text style={{fontSize: '10px', margin: '2px 0px', fontFamily: 'ITCAVGLTBook'}}>{item.b_hail_dents_on_sides == true? 'Yes' : 'No'}</Text>
                    </View>
                  </View> 
                    
                  
                  <View style={styles.assesmentContainer}>
                    <View style={{width: '45%',  fontSize: '9px'}}>
                      <Text style={styles.detailColumnHeader}>Interior Damages (Seating/Missing Parts): </Text>
                    </View>
                    <View style={{width: '65%',  fontSize: '9px'}}>
                      <Text style={{fontSize: '10px', margin: '2px 0px', fontFamily: 'ITCAVGLTBook'}}>{item.b_interior_paneling_or_seating_damaged_or_missing_parts == true ? formattedText(item.t_interior_paneling_or_seating_damaged_or_missing_parts) : 'No'}</Text>
                    </View>
                  </View>
                  
                  
                  <View style={styles.assesmentContainer}>
                    <View style={{width: '45%',  fontSize: '9px'}}>
                      <Text style={styles.detailColumnHeader}>Modifications: </Text>
                    </View>
                    <View style={{width: '65%',  fontSize: '9px'}}>
                      <Text style={{fontSize: '10px', margin: '2px 0px', fontFamily: 'ITCAVGLTBook'}}>{item.b_modifications == true ? formattedText(item.modifications) : 'No'}</Text>
                    </View>
                  </View>
                     
              </View>                
          </View>
            
            }
        </View>
    )
}