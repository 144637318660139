import React, {useState, useEffect} from 'react'
import {
    CCard,
    CCardHeader,
    CCardBody,
    CCallout
} from '@coreui/react';
import {Loader} from 'semantic-ui-react';
import InventoryMessage from './InventoryMessage/InventoryMessage';
import RunListManager from '../../../../services/RunListManager';
import Error from '../../../generic/Error/Error';
import InventoryHelper from './InventoryHelper';

export default ({vin, page='', make})=>{

    const [loading, setLoading] = useState(false);
    const [hasSettingsData, setHasSettingsData] = useState(false);
    const [netDemand, setNetDemand] = useState({});
    const {addError} = Error();

    useEffect( ()=>{
        var ignore = false;
        var RL = new RunListManager();

        var hasSettings = hasSettingsData;
        if (!hasSettings) {
            hasSettings = InventoryHelper.hasSettingsData();
            setHasSettingsData(hasSettings);
        }

        setLoading(true)
        RL.getNetDemand(vin, page).then( (data)=>{
            if(!ignore){
                if(data.error) return addError(data.message, 500);
                setNetDemand(data.data);
                setLoading(false);
            }
        }).catch( (e)=>{
            if(!ignore) setLoading(false);
            addError(e.message, 500);
            console.log(e);
        })
        return ()=>{ignore = true;}
    }, [vin])

    return(
        <>
        {
            page === 'Appraisal' ? 
            (!loading) && <InventoryMessage details={InventoryHelper.locateDaysSupplyMessageThreshold(netDemand, make)}/>
            :
            (!loading) && hasSettingsData && <InventoryMessage details={InventoryHelper.locateDaysSupplyMessageThreshold(netDemand, make)}/>
        }
        {/* {(!loading) && hasSettingsData && <InventoryMessage details={InventoryHelper.locateDaysSupplyMessageThreshold(netDemand, make)}/>} */}
        {
            page === 'Appraisal' ? 
            (loading) && <Loader inline={true} active/> :
            (loading || !hasSettingsData) && <Loader inline={true} active/>
        }
    </>
    )

}