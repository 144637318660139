import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CIcon } from "@coreui/icons-react";
import { freeSet } from "@coreui/icons";
import RunListSummary from "./RunListSummary/RunListSummary";
import RunListManager from "./../../services/RunListManager";
import LaneGrid from "./LaneGrid/LaneGrid";
import LaneFilters from "./LaneFilters/LaneFilters";
import {
  CCard,
  CCardHeader,
  CCardBody,
  CCol,
  CRow,
  CButton,
  CDataTable,
  CWidgetProgress,
  CSelect,
  CBadge,
} from "@coreui/react";
import Error from "../generic/Error/Error";

export default (props) => {
  const { runListId, channel } = useParams();
  const [filters, setFilters] = useState(false);
  const [pqCount, setPQCount] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [maxBidCount, setMaxBidCount] = useState(0);
  const [preQualifiedListingCount, setPreQualifiedListingCount] = useState(0);
  const [shoppingListCount, setShoppingListCount] = useState(0);
  const [reviewedShoppingListCount, setReviewedShoppingListCount] = useState(0);
  const [maxBidShoppingListCount, setMaxBidShoppingListCount] = useState(0)
  const [leaderboard, setLeaderboard] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lanes, setLanes] = useState([])
  const [buyers, setBuyers] = useState([]);
  const [runlist, setRunlist] = useState({})

  var RL = new RunListManager();
  const { addError } = Error();

  /* GET SAVED FILTERS FROM LOCAL STORAGE */
  useEffect(() => {
    var filterString = localStorage.getItem("laneFilters");
    var savedFilters = filterString ? JSON.parse(filterString) : {};
    if(!savedFilters) savedFilters = {};
    setFilters(savedFilters);
  }, []);


  /* LISTEN FOR FILTER CHANGES AND UPDATE LOCAL STORAGE */
  useEffect(() => {
    var filterString = JSON.stringify(filters);
    localStorage.setItem("laneFilters", filterString);
  }, [filters]);


  useEffect( ()=>{
    var ignore = false;
    setLoading(true)
    RL.getRunListSummary(runListId, channel).then((response)=>{
        if(!ignore){
            setRunlist(response.data);
            setPreQualifiedListingCount(response.data.total_listings);
            setShoppingListCount(response.data.shopping_list_count );
            setReviewedShoppingListCount(response.data.reviewed_shopping_list_count);
            setMaxBidShoppingListCount(response.data.max_bid_shopping_list_count);

            console.log("runlist", response.data);
            RL.initBodyStyle(response.data.channel, response.data.auction_date);
            setLoading(false);
        }
    }).catch(()=>{setLoading(false)})

    return ()=>{ignore = true;}
}, [runListId])

  /* GET THE LANE GRID DATA */
  useEffect(() => {
    var isMounted = true;
    setLoading(true);
    RL.getRunListLanes(runListId, filters, channel)
      .then((data) => {
        if (isMounted) {
          setLanes(data.data.lanes);
          setLoading(false);
        }
        if (data.error) return addError(data.message, 500);
      }).catch((e) => {
          addError(e.message, 500);
          console.log(e);
          setLoading(false);
        });
      return () => {
        isMounted = false;
      };
  }, [filters, runListId, channel]);


  /* GET ALL THE BUYERS */
  useEffect(() => {
    var ignore = false;
    
    RL.getBuyers().then((response) => {
      if (response.error) return addError(response.message, 500);
      if (!ignore) {
        setBuyers(response.data.buyers);
      }
    });
    return () => {
      ignore = true;
    };
  }, []);

  /* UPDATES THE LEADERBOARD AND MAX BID COUNTS */
  useEffect(()=>{

    //store the values as we iterate across the lanes
    var aggregators = {
      "buyerMap":{},
      "pqCount": 0,
      "maxBidCount": 0,
      "reviewCount": 0
    }

    //accumulate the data from each lane
    lanes.forEach((item)=>{
      aggregators['pqCount'] += item.vehicles;
      aggregators['maxBidCount'] += item.max_bid_listings;
      aggregators['reviewCount'] += item.reviewed_vehicles;

      //if there's no assigned buyer, stop here
      if(item.buyer_name == ' ') return;
      
      if(!aggregators['buyerMap'][item.buyer_name]) aggregators['buyerMap'][item.buyer_name] = {"user_id":0, "laneCount":0, "reviewed_listings":0, "total_listings":0};
      aggregators['buyerMap'][item.buyer_name]['laneCount'] += 1;
      aggregators['buyerMap'][item.buyer_name]['user_id'] = item.user_id;
      aggregators['buyerMap'][item.buyer_name]['reviewed_listings'] += item.reviewed_vehicles
      aggregators['buyerMap'][item.buyer_name]['total_listings'] += item.vehicles
    
    })

    //comment out the forEach below if you want to only show buyers in the Lane Counter that have lanes assigned
    buyers.forEach((item)=>{
      var name = item.first_name +' ' + item.last_name
      if(!aggregators['buyerMap'][name]) aggregators['buyerMap'][name] = {"user_id":item.user_id, "laneCount":0, "reviewed_listings":0, "total_listings":0};;
    })

    var sortedLeaderboard = [];
    Object.keys(aggregators['buyerMap']).map( (buyer) =>{
      sortedLeaderboard.push(
        {
          "buyer": buyer,
          "user_id": aggregators['buyerMap'][buyer].user_id,
          "laneCount": aggregators['buyerMap'][buyer].laneCount,
          "reviewed_listings": aggregators['buyerMap'][buyer].reviewed_listings,
          "total_listings": aggregators['buyerMap'][buyer].total_listings
       }
      )
    });
    sortedLeaderboard.sort( (a,b) =>  b.laneCount - a.laneCount);

    //update the state
    setReviewCount(aggregators.reviewCount);
    setPQCount(aggregators.pqCount);
    setMaxBidCount(aggregators.maxBidCount);
    setLeaderboard([...sortedLeaderboard]);

  }, [lanes, buyers, filters])


  return (
    <CRow>
      <CCol md="10" className="offset-md-1 my-3">
        <RunListSummary
          runListId={runListId}
          channel={channel}
          preQualifiedListingCount={pqCount}
          totalListings={preQualifiedListingCount}
          lanesPerBuyer={leaderboard}
          reviewCount={reviewCount}
          maxBidCount={maxBidCount}
          shoppingListCount = {shoppingListCount}
          reviewedShoppingListCount = {reviewedShoppingListCount}
          maxBidShoppingListCount = {maxBidShoppingListCount}
          auctionDate = {lanes[0] ? lanes[0].auction_date : "" }
        />
        <LaneFilters
          channel = {channel}
          filters={filters}
          setFilters={setFilters}
          resetPrequalifiedListings={() => setPQCount(0)}
        />
        <CCard>
          
          <CCardHeader>
            <b>Applied Filters</b>:&nbsp;&nbsp;
            {Object.keys(filters).map((item) => {
              if (filters[item]) {
                return (
                  <span>
                    <CBadge color="dark">
                      {item}: {filters[item]}
                    </CBadge>
                    &nbsp;
                  </span>
                );
              }
            })}
          </CCardHeader>

          <CCardBody>
            {filters !== false && (
              <LaneGrid
                runListId={runListId}
                channel={channel}
                filters={filters}
                lanes={lanes}
                setLanes={setLanes}
                buyers={buyers}
                loading={loading}
              />
            )}
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};
