import React , {useState, useEffect} from 'react'
import { MultiSelect } from './MultiSelect/MultiSelect'

export default (props)=>{

    const [years, setYears] = useState([])

    var localYears = {...props.localFilters}['years']
    useEffect(() => {
        if(localYears === null) setYears([])
    },[localYears])
    
    const multiSelectHandleChange = (selected, setterFunc) => {
        let updatedSelection;
        let updatedArray = [];
        if (Array.isArray(selected)) {
            updatedSelection = selected.map(element => {     
                return element.label;
            });
        }
        
        for (let element of updatedSelection) {
            const filteredValues = updatedSelection.filter(item => {
                if(item.indexOf(element) > -1) {
                    return item;
                } else {
                    return false
                }                 
            });
            updatedArray.push(filteredValues.pop());          
        }
        
        updatedArray = [...new Set(updatedArray)];
        let updatedSelected = updatedArray.map(element => ({label: element, value: parseInt(element)}))
        let yearsArr = updatedSelected.map(element => element.value)
        setterFunc(updatedSelected);
        var loc = {...props.localFilters}
        loc['years'] = yearsArr.toString()
        props.setLocalFilters(loc)
    }

    var options = []
    var maxYear = new Date().getFullYear();
    for(var i = maxYear; i > 2015; i-=1){
        options.push(
            {label: i.toString(), value: i }
            )
        }

        return(
                <MultiSelect 
                options={options} 
                optionSelected={years}
                handleChange={(selected) => multiSelectHandleChange(selected, setYears)}   
            />    
    )
}




                                   

