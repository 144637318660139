import React, {useState, useEffect} from 'react';
import {CRow, CCol, CButton, CCard, CCardHeader, CCardBody, CInput, CSelect} from '@coreui/react';
import RunListManager from '../../services/RunListManager';
import Error from '../generic/Error/Error';
import VINDetails from './VINDetails/VINDetails';
import {Loader} from 'semantic-ui-react';
import CopyToClipboard from '../Main/VehicleDetail/VehicleData/CopyToClipboard/CopyToClipboard';
//'../../Main/VehicleDetail/VehicleData/CopyToClipboard/CopyToClipboard';

export default ()=>{

    var RL = new RunListManager()
    const [vehicle, setVehicle] = useState({})
    const [vin, setVin] = useState('');
    const [loading, setLoading] = useState(false);
    const[channel, setChannel] = useState('SIMULCAST');
    const [refresh, setRefresh] = useState(0)

    const {addError} = Error()

    useEffect( ()=>{
        var ignore = false;
        if(vin && vin.length == 17){
            console.log("********* HERE");
            setLoading(true);
            RL.getQuickVIN(vin, channel).then( (data)=>{
                if(data.error) return addError(data.message, "500");
                if(!ignore){
                    if(channel == "LiveBlock"){
                        var d = new Date()
                        data.data.auction_date = d.getFullYear()+"-"+padNum(d.getMonth()+1)+"-"+padNum(d.getDate());
                    }
                    setVehicle(data.data);
                    setLoading(false);
                }
            }).catch((e)=>{
                if (e.response.status == 404)
                addError('Resource Not Found for fetching VIN Details, please verify the URL');
                else{
                    addError(e.message, 500);
                }
                setLoading(false);
            });
        }
        return ()=>{ignore = true;}
    }, [vin, channel, refresh])

    const padNum = (num)=>{
        if(num < 10) return "0"+num;
        return num;

    }

    const updateVin = ()=>{
        var vinbox = document.getElementById('vin');
        setVin(vinbox.value);
    }

    const updateChannel = (e)=>{
        var val = e.target.options[e.target.selectedIndex].value
        setVin('');
        setChannel(val);
    }

    return (
       <> 
       <CRow>
            <CCol className="pt-2 pb-2 col-md-10 offset-md-1">
                <CCard className="mb-0">
                    <CCardHeader>FirstLook Lookup: {vin + '  '} {vin && <CopyToClipboard vin={vehicle.vin}/>} 

                    </CCardHeader>
                    <CCardBody>
                        <div className="d-flex">
                            <div className="pr-2">
                                <CSelect value={channel} onChange={updateChannel} className="quickVin-select" size="lg">
                                    <option value="SIMULCAST">MANHEIM</option>
                                    <option value="LiveBlock">ADESA</option>
                                </CSelect>
                            </div>
                            <div className="flex-grow-5 w-100">
                                <CInput id="vin" defaultValue={vin} onChange={updateVin} placeholder="ENTER VIN" size="lg"/>
                            </div>
                            <CButton onClick={()=>{updateVin();setRefresh(refresh+1);}} color="primary" size="lg" className="ml-2">Search</CButton>
                        </div>
                        
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <CRow>
            <CCol className="col-md-10 offset-md-1">
                { !loading && !vehicle.vin && vin ? <h5 style={{color: 'red'}}><em>No Vehicle found!</em></h5> : !loading && vin && vehicle.vin ?  
                <VINDetails vehicle={vehicle} channel={channel}/> : null}
                {loading && <Loader active={true}/>}
            </CCol>
        </CRow>

    </>
    )
}