import React from 'react'
import {CSelect} from '@coreui/react'


export default (props)=>{

    const changeFunc = (e)=>{
        var val = e.target.options[e.target.selectedIndex].value
        var loc = {...props.localFilters}
        loc['CRScore_max'] = val
        props.setLocalFilters(loc)
    }

    var options = []
    for(var i = 5.0; i > 1.0; i-=0.1){
        options.push(
            <option value={i.toFixed(1)} key={i}>{i.toFixed(1)}</option>
        )
    }

    return(
        <CSelect value={props.localFilters['CRScore_max']} onChange={changeFunc} className="mr-2" size="lg">
            <option value="" key="nada">Max CR Score</option>
            {options}
        </CSelect>
    )
}

