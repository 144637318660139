import React from 'react';

import {
    CCard,
    CForm,
    CFormGroup,
    CLabel,
    CInput,
    CInputFile,
    CButton,
    CCardBody,
    CSelect,
    CCardHeader,
    CRow,
    CCol,
    CFormText
} from '@coreui/react';

export default (props)=>{

    return(
        <div className="py-5">
            <CRow>
                <CCol md="4" className="offset-md-4">
                    <CCard>
                        <CCardHeader>Inventory Configuration</CCardHeader>
                        <CCardBody>
                            <CForm>
                                <CFormGroup>
                                    <CLabel>Baseline Days Supply Target</CLabel>
                                    <CInput type="text" value="45" className="col-sm-4"/>
                                </CFormGroup>
                                <CFormGroup>
                                    <CLabel>Default Amber Value for Buyers</CLabel>
                                    <CInput type="text" value="50" className="col-sm-4"/>
                                    <CFormText>When Days Supply reaches this level for a given vehicle, we will warn the buyers to be more selective</CFormText>
                                </CFormGroup>
                                <CFormGroup>
                                    <CLabel>Default Red Value for Buyers</CLabel>
                                    <CInput type="text" value="60" className="col-sm-4"/>
                                    <CFormText>When Days Supply reaches this level for a given vehicle, we will recommend not buying more of this type</CFormText>
                                </CFormGroup>
                                <CFormGroup>
                                    <CLabel>Upload Inventory Allocation</CLabel>
                                    <CInputFile value=""/>
                                </CFormGroup>
                                <CFormGroup>
                                    <CLabel>Segmentation Options</CLabel>
                                    <div className="d-flex align-items-center justify-content-start">
                                    <CSelect className="col-sm-4">
                                    <option value="">Make / Model</option>
                                    <option value="">Make / Model / Trim</option>
                                        <option value="">Price Band</option>
                                        <option value="">Other</option>
                                    </CSelect>
                                    <CButton color="dark" className="ml-2">Download Template</CButton>
                                    </div>
                                    <CFormText>You can overwrite the Target Days Supply, Amber Value and Red Value for each individual segment.<br/>Download the file to retrieve a file of existing values, then upload your changes using the form field above.</CFormText>
                                </CFormGroup>
                                <CButton color="primary" size="lg">Save Changes</CButton>
                            </CForm>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </div>
    )
}