import React, { useState, useEffect } from "react";
import {
  CCard,
  CCardHeader,
  CCardBody
} from "@coreui/react";
import { Comment } from "./Comment/Comment";
import { CommentForm } from "./Comment/CommentForm";
import RunListManager from "../../../services/RunListManager";

export const CommentBox = ({ vin, createdDate, user }) => {
  var RL = new RunListManager();

  const [comments, setComments] = useState([]);
  const [activeComment, setActiveComment] = useState(null);

  const rootComments = comments.filter(
    (comment) => comment.parent === null || comment.parent === 0
  );

  const getReplies = (commentId) => {
    const replies = comments
    .filter((comment) => comment.parent === commentId)
    .sort(
      (a, b) =>
        new Date(a.created_date).getTime() -
        new Date(b.created_date).getTime()
    );
    return replies
  }
    

  const getComments = (vin, createdDate) =>
    RL.fetchCommentsData(vin, createdDate)
      .then((res) => {
        setComments(res.data.appraisal_comments_list);
      })
      .catch((err) => console.log("Error fetching comments", err));

  const addComment = (commentBody, parentId) => {
    RL.saveCommentsData(vin, createdDate, user, commentBody, parentId)
      .then((res) => {
        getComments(vin, createdDate);
        setActiveComment(null);
      })
      .catch((err) => console.log("Error saving comments", err));
  };

  useEffect(() => {
    getComments(vin, createdDate);
  }, []);

  return (
    <CCard>
      <CCardHeader style={{ background: "#00aced", color: "white" }}>
        <h3>Internal Comments on Vehicle</h3>
      </CCardHeader>
      <CCardBody className="pt-1 pb-1">
        {rootComments.map((rootComment) => (
          <Comment
            key={rootComment.seq_no}
            comment={rootComment}
            addComment={addComment}
            activeComment={activeComment}
            setActiveComment={setActiveComment}
            replies={getReplies(rootComment.seq_no)}
            getReplies={() => getReplies}
            canReply={true}
          />
        ))}
        <CommentForm submitLabel="Add Comment" handleSubmit={addComment} />
      </CCardBody>
    </CCard>
  );
};
