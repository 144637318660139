import React, {useState, useEffect} from 'react';
import styled from 'styled-components'
import FormattedNumber from '../../FormattedNumber/FormattedNumber';
import CopyToClipboard from './VehicleData/CopyToClipboard/CopyToClipboard';
const Label = styled.div`
  font-size:0.7em;
  text-transform:uppercase;
  text-align:left;
  color:#6b6b6b;
`

const Value = styled.div`
    font-size:0.9em;
    text-align:left;
    padding:0.4em 0 0.2em;
    color:#2a2a2a;
`

const CarfaxValue = styled.div`
    font-size:0.9em;
    text-align:left;
    padding:0.4em 0;
`

export default ({vehicle, currentIndex, updater, channel})=>{


    return(<>
        <div className="d-flex align-items-center justify-content-between">

            <div>
                <Label>Vehicle</Label>
                <Value>
                    {vehicle.year} {vehicle.make} {vehicle.model} {vehicle.trim} ({vehicle.extendedModel})
                </Value>
            </div>
            <div>
                <Label>Mileage</Label>
                <Value>
                    <FormattedNumber value={vehicle.mileage}/>
                </Value>
            </div>
            <div>
                <Label>Drivetrain</Label>
                <Value>
                    {vehicle.drivetrain}
                </Value>
            </div>
            <div>
                <Label>VIN</Label>
                <Value>
                    {vehicle.vin} <CopyToClipboard vin={vehicle.vin}/>
                </Value>
            </div>
            <div>
                <Label>CR Score</Label>
                <Value>
                    {vehicle.CRScore}
                </Value>
            </div>
        </div>
    </>
    )
}