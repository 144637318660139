import React, {useState} from 'react';
import {CDataTable, CCard, CCardHeader, CCardBody, CCardFooter, CSelect, CButton, CBadge} from '@coreui/react';
import MasterData from '../../data';
import { Breadcrumbs } from 'react-rainbow-components';

var fields = [
    { label:"Segment", key:'slice', _classes:"inventory-label-column"},
    { label:"Total", key:'total', _classes:"current-inventory-summary" },
    { label:"Target", key:'target', _classes:"recent-sales" },
    { label:"Diff", key:'diff', _classes:"recent-sales"},
    { label:"Current Days Supply", key:'days_supply', _classes:"recent-sales"},
    { label:"Target Days Supply", key:'target_days_supply', _classes:"recent-sales"},
    { label:"Sales(45)", key:'sold', _classes:"recent-sales" }, 
    'comp'
]


var data = {
    "Subcompact":{
        "slice":"Subcompact",
        "total":336,
        "target":302,
        "diff":34,
        "days_supply":50,
        "target_days_supply":45,
        "sold":45,
        "comp":17,
        "td_diff":5,
        "children":{
            "Chevrolet":{
                "slice":"Chevrolet",
                "total":10,
                "target":12,
                "diff":-2,
                "days_supply":10,
                "target_days_supply":12,
                "sold":45,
                "comp":17,
                "td_diff":-22,
                "children":{
                    "Volt":{
                        "slice":"Volt",
                        "total":10,
                        "target":12,
                        "diff":-2,
                        "days_supply":10,
                        "target_days_supply":12,
                        "sold":45,
                        "td_diff":-22,
                        "comp":17,
                        "children":{
                            "Hybrid LT":{"slice": "Hybrid LT", "total":7},
                            "Hybrid Premier":{"slice": "Hybrid Premier", "total":9}
                        }
                    },
                    "Bolt":{
                        "slice":"Bolt",
                        "total":10,
                        "target":12,
                        "diff":-2,
                        "days_supply":10,
                        "target_days_supply":12,
                        "sold":45,
                        "td_diff":-22,
                        "comp":17
                    },
                    "Aveo":{
                        "slice":"Aveo",
                        "total":10,
                        "target":12,
                        "diff":-2,
                        "days_supply":10,
                        "target_days_supply":12,
                        "sold":45,
                        "td_diff":-22,
                        "comp":17
                    }
                }
            },
            "Ford":{
                "slice":"Ford",
                "total":10,
                "target":12,
                "diff":-2,
                "days_supply":10,
                "target_days_supply":12,
                "sold":45,
                "comp":17,
                "td_diff":-22,
                "children":{
                    "Festiva":{
                        "slice":"Festiva",
                        "total":10,
                        "target":12,
                        "diff":-2,
                        "days_supply":10,
                        "target_days_supply":12,
                        "sold":45,
                        "td_diff":-22,
                        "comp":17
                    }
                }                
            },
            "Fiat":{
                "slice":"Fiat",
                "total":10,
                "target":12,
                "diff":-2,
                "days_supply":10,
                "target_days_supply":12,
                "sold":45,
                "comp":17,
                "td_diff":-22,
                "children":{
                    "500":{
                        "slice":"500",
                        "total":10,
                        "target":12,
                        "diff":-2,
                        "days_supply":10,
                        "target_days_supply":12,
                        "td_diff":-22,
                        "sold":45,
                        "comp":17
                    }
                }
            },
            "Honda":{
                "slice":"Honda",
                "total":10,
                "target":12,
                "diff":-2,
                "days_supply":10,
                "target_days_supply":12,
                "sold":45,
                "comp":17,
                "td_diff":-22,
                "children":{
                    "500":{
                        "slice":"500",
                        "total":10,
                        "target":12,
                        "diff":-2,
                        "days_supply":10,
                        "target_days_supply":12,
                        "sold":45,
                        "td_diff":-22,
                        "comp":17
                    }
                }
            },
            "Hyundai":{
                "slice":"Hyundai",
                "total":10,
                "target":12,
                "diff":-2,
                "days_supply":10,
                "target_days_supply":12,
                "sold":45,
                "comp":17,
                "td_diff":-22,
                "children":{
                    "500":{
                        "slice":"500",
                        "total":10,
                        "target":12,
                        "diff":-2,
                        "days_supply":10,
                        "target_days_supply":12,
                        "sold":45,
                        "td_diff":-22,
                        "comp":17
                    }
                }
            },
            "Kia":{
                "slice":"Kia",
                "total":10,
                "target":12,
                "diff":-2,
                "days_supply":10,
                "target_days_supply":12,
                "sold":45,
                "comp":17,
                "td_diff":-22,
                "children":{
                    "500":{
                        "slice":"500",
                        "total":10,
                        "target":12,
                        "diff":-2,
                        "days_supply":10,
                        "target_days_supply":12,
                        "sold":45,
                        "td_diff":-22,
                        "comp":17
                    }
                }
            },
            "Mazda":{
                "slice":"Mazda",
                "total":10,
                "target":12,
                "diff":-2,
                "days_supply":10,
                "target_days_supply":12,
                "sold":45,
                "comp":17,
                "td_diff":-22,
                "children":{
                    "500":{
                        "slice":"500",
                        "total":10,
                        "target":12,
                        "diff":-2,
                        "days_supply":10,
                        "target_days_supply":12,
                        "sold":45,
                        "td_diff":-22,
                        "comp":17
                    }
                }
            },
            "Mitsubishi":{
                "slice":"Mitsubishi",
                "total":10,
                "target":12,
                "diff":-2,
                "days_supply":10,
                "target_days_supply":12,
                "sold":45,
                "comp":17,
                "td_diff":-22,
                "children":{
                    "500":{
                        "slice":"500",
                        "total":10,
                        "target":12,
                        "diff":-2,
                        "days_supply":10,
                        "target_days_supply":12,
                        "sold":45,
                        "td_diff":-22,
                        "comp":17
                    }
                }
            },
            "Nissan":{
                "slice":"Nissan",
                "total":10,
                "target":12,
                "diff":-2,
                "days_supply":10,
                "target_days_supply":12,
                "sold":45,
                "comp":17,
                "td_diff":-22,
                "children":{
                    "500":{
                        "slice":"500",
                        "total":10,
                        "target":12,
                        "diff":-2,
                        "days_supply":10,
                        "target_days_supply":12,
                        "sold":45,
                        "td_diff":-22,
                        "comp":17
                    }
                }
            },
            "Suburu":{
                "slice":"Suburu",
                "total":10,
                "target":12,
                "diff":-2,
                "days_supply":10,
                "target_days_supply":12,
                "sold":45,
                "comp":17,
                "td_diff":-22,
                "children":{
                    "500":{
                        "slice":"500",
                        "total":10,
                        "target":12,
                        "diff":-2,
                        "days_supply":10,
                        "target_days_supply":12,
                        "sold":45,
                        "td_diff":-22,
                        "comp":17
                    }
                }
            },
            "Toyota":{
                "slice":"Toyota",
                "total":10,
                "target":12,
                "diff":-2,
                "days_supply":10,
                "target_days_supply":12,
                "sold":45,
                "comp":17,
                "td_diff":-22,
                "children":{
                    "500":{
                        "slice":"500",
                        "total":10,
                        "target":12,
                        "diff":-2,
                        "days_supply":10,
                        "target_days_supply":12,
                        "sold":45,
                        "td_diff":-22,
                        "comp":17
                    }
                }
            },
            "Volkswagen":{
                "slice":"Volkswagen",
                "total":10,
                "target":12,
                "diff":-2,
                "days_supply":10,
                "target_days_supply":12,
                "sold":45,
                "comp":17,
                "td_diff":-22,
                "children":{
                    "500":{
                        "slice":"500",
                        "total":10,
                        "target":12,
                        "diff":-2,
                        "days_supply":10,
                        "target_days_supply":12,
                        "sold":45,
                        "td_diff":-22,
                        "comp":17
                    }
                }
            }
        }
    },
    "Compact":{
        "slice":"Compact",
        "total":691,
        "target":676,
        "diff":15,
        "days_supply":46,
        "target_days_supply":45,
        "sold":45,
        "comp":17,
        "td_diff":1,
        "children":{
            "Volkswagen":{
                "slice":"Volkswagen",
                "total":10,
                "target":12,
                "diff":-2,
                "days_supply":10,
                "target_days_supply":12,
                "sold":45,
                "comp":17,
                "children":{
                    "500":{
                        "slice":"500",
                        "total":10,
                        "target":12,
                        "diff":-2,
                        "days_supply":10,
                        "target_days_supply":12,
                        "sold":45,
                        "comp":17
                    }
                }
            }
        }
    },
    "Mid-size": {
        "slice":"Mid-Size",
        "total":400,
        "target":450,
        "diff":-50,
        "days_supply":40,
        "target_days_supply":45,
        "sold":45,
        "comp":17,
        "td_diff":-5,
        "children":{
            "Volkswagen":{
                "slice":"Volkswagen",
                "total":10,
                "target":12,
                "diff":-2,
                "days_supply":10,
                "target_days_supply":12,
                "sold":45,
                "comp":17,
                "children":{
                    "500":{
                        "slice":"500",
                        "total":10,
                        "target":12,
                        "diff":-2,
                        "days_supply":10,
                        "target_days_supply":12,
                        "sold":45,
                        "comp":17
                    }
                }
            }
        }
    },
    "Full-size":{
        "slice":"Full-Size",
        "total":200,
        "target":429,
        "diff":-229,
        "days_supply":21,
        "target_days_supply":45,
        "td_diff":-24,
        "sold":45,
        "comp":17,
        "_cellClasses":{"td_diff":"table-danger"},
        "children":{
            "Volkswagen":{
                "slice":"Volkswagen",
                "total":10,
                "target":12,
                "diff":-2,
                "days_supply":10,
                "target_days_supply":12,
                "sold":45,
                "comp":17,
                "children":{
                    "500":{
                        "slice":"500",
                        "total":10,
                        "target":12,
                        "diff":-2,
                        "days_supply":10,
                        "target_days_supply":12,
                        "sold":45,
                        "comp":17
                    }
                }
            }
        }
    },
    "SUV":{
        "slice":"SUV",
        "total":451,
        "target":781,
        "diff":-330,
        "days_supply":26,
        "target_days_supply":45,
        "td_diff":-19,
        "_cellClasses":{"td_diff":"table-info"},
        "children":{
            "Volkswagen":{
                "slice":"Volkswagen",
                "total":10,
                "target":12,
                "diff":-2,
                "days_supply":10,
                "target_days_supply":12,
                "sold":45,
                "comp":17,
                "children":{
                    "500":{
                        "slice":"500",
                        "total":10,
                        "target":12,
                        "diff":-2,
                        "days_supply":10,
                        "target_days_supply":12,
                        "sold":45,
                        "comp":17
                    }
                }
            }
        }
    },
    "Truck":{
        "slice":"Truck",
        "total":510,
        "target":1275,
        "diff":-765,
        "days_supply":18,
        "target_days_supply":45,
        "td_diff":-22,
        "_cellClasses":{"td_diff":"table-danger"},
        "children":{
    
            "Ford":{
                "slice":"Ford",
                "total":10,
                "target":12,
                "diff":-2,
                "days_supply":10,
                "target_days_supply":12,
                "sold":45,
                "comp":17,
                "children":{
                    "F-150":{
                        "slice":"F-150",
                        "total":10,
                        "target":12,
                        "diff":-2,
                        "days_supply":10,
                        "target_days_supply":12,
                        "sold":45,
                        "comp":17
                    }
                }
            }
        }
    }
}

var priceData = {
    "Under $10,000":{
        "slice":"Under $10,000",
        "total":130,
        "target":115,
        "diff":15,
        "days_supply":51,
        "target_days_supply":45,
        "sold":45,
        "comp":17,
        "td_diff":6,
        "_cellClasses": {"td_diff":"table-dark text-white"},
        "children":{   
            "Chevrolet":{
                "slice":"Chevrolet",
                "total":10,
                "target":12,
                "diff":-2,
                "days_supply":10,
                "target_days_supply":12,
                "sold":45,
                "comp":17,
                "td_diff":6,
                "children":{
                    "volt":{
                        "slice":"Volt",
                        "total":10,
                        "target":12,
                        "diff":-2,
                        "days_supply":10,
                        "target_days_supply":12,
                        "sold":45,
                        "comp":17
                    },
                    "Bolt":{
                        "slice":"Bolt",
                        "total":10,
                        "target":12,
                        "diff":-2,
                        "days_supply":10,
                        "target_days_supply":12,
                        "sold":45,
                        "comp":17
                    },
                    "Aveo":{
                        "slice":"Aveo",
                        "total":10,
                        "target":12,
                        "diff":-2,
                        "days_supply":10,
                        "target_days_supply":12,
                        "sold":45,
                        "comp":17
                    }
                }
            },
            "Ford":{
                "slice":"Ford",
                "total":10,
                "target":12,
                "diff":-2,
                "days_supply":10,
                "target_days_supply":12,
                "sold":45,
                "comp":17,
                "td_diff":6,
                "children":{
                    "Festiva":{
                        "slice":"Festiva",
                        "total":10,
                        "target":12,
                        "diff":-2,
                        "days_supply":10,
                        "target_days_supply":12,
                        "sold":45,
                        "comp":17
                    }
                }                
            }
        }
    },
    "$10,000-$19,999":{
        "slice":"$10,000-$19,999",
        "total":1800,
        "target":2025,
        "diff":-225,
        "days_supply":40,
        "target_days_supply":45,
        "sold":45,
        "comp":17,
        "td_diff":-5,
        "children":{ 
            "Honda":{
                "slice":"Honda",
                "total":10,
                "target":12,
                "diff":-2,
                "days_supply":10,
                "target_days_supply":12,
                "sold":45,
                "comp":17,
                "children":{
                    "Civic":{
                        "slice":"Civic",
                        "total":10,
                        "target":12,
                        "diff":-2,
                        "days_supply":10,
                        "target_days_supply":12,
                        "sold":45,
                        "comp":17
                    }
                }
            }
        }
    },
    "$20,000-$29,999":{
        "slice":"$20,000-$29,999",
        "total":1200,
        "target":1588,
        "diff":-388,
        "days_supply":34,
        "target_days_supply":45,
        "sold":45,
        "td_diff":-11,
        "_cellClasses": {"td_diff":"table-info"},
        "comp":17,
        "children":{ 
            "BMW":{
                "slice":"BMW",
                "total":10,
                "target":12,
                "diff":-2,
                "days_supply":10,
                "target_days_supply":12,
                "sold":45,
                "comp":17,
                "children":{
                    "3-Series":{
                        "slice":"3-Series",
                        "total":10,
                        "target":12,
                        "diff":-2,
                        "days_supply":10,
                        "target_days_supply":12,
                        "sold":45,
                        "comp":17
                    },
                    "X1":{
                        "slice":"X1",
                        "total":10,
                        "target":12,
                        "diff":-2,
                        "days_supply":10,
                        "target_days_supply":12,
                        "sold":45,
                        "comp":17
                    }
                }
            },
            "Volvo":{
                "slice":"Volvo",
                "total":10,
                "target":12,
                "diff":-2,
                "days_supply":10,
                "target_days_supply":12,
                "sold":45,
                "comp":17,
                "children":{
                    "S60":{
                        "slice":"S60",
                        "total":10,
                        "target":12,
                        "diff":-2,
                        "days_supply":10,
                        "target_days_supply":12,
                        "sold":45,
                        "comp":17
                    }
                }   
            }
        }
    },
    "$30,000+":{
        "slice":"$30,000+",
        "total":800,
        "target":878,
        "diff":-78,
        "days_supply":41,
        "target_days_supply":45,
        "sold":45,
        "td_diff":-4,
        "comp":17,
        "children":{ 
            "BMW":{
                "slice":"BMW",
                "total":10,
                "target":12,
                "diff":-2,
                "days_supply":10,
                "target_days_supply":12,
                "sold":45,
                "comp":17,
                "children":{
                    "3-Series":{
                        "slice":"3-Series",
                        "total":10,
                        "target":12,
                        "diff":-2,
                        "days_supply":10,
                        "target_days_supply":12,
                        "sold":45,
                        "comp":17
                    },
                    "X1":{
                        "slice":"X1",
                        "total":10,
                        "target":12,
                        "diff":-2,
                        "days_supply":10,
                        "target_days_supply":12,
                        "sold":45,
                        "comp":17
                    }
                }
            },
            "Volvo":{
                "slice":"Volvo",
                "total":10,
                "target":12,
                "diff":-2,
                "days_supply":10,
                "target_days_supply":12,
                "sold":45,
                "comp":17,
                "children":{
                    "S60":{
                        "slice":"S60",
                        "total":10,
                        "target":12,
                        "diff":-2,
                        "days_supply":10,
                        "target_days_supply":12,
                        "sold":45,
                        "comp":17
                    }
                }   
            }
        }
    }
}

export default (props)=>{

    const [tree, setTree] = useState([]);
    const [treeType, setTreeType] = useState(0);

    const treeTypes = [data,priceData];

    var fields = [
        { label:"Segment", key:'slice', _classes:"inventory-label-column"},
        { label:"Current", key:'total', _classes:"recent-sales" },
        { label:"Target", key:'target', _classes:"" },
        { label:"Diff", key:'diff', _classes:"recent-sales"},
        { label:"Days Supply", key:'days_supply', _classes:"recent-sales"},
        { label:"Target Days Supply", key:'target_days_supply', _classes:"recent-sales"},
        { label:"Days Supply Deficit", key:'td_diff', _classes:"current-inventory-summary"}
    ]
    var fields2 = [
        { label:"Segment", key:'slice', _classes:"inventory-label-column align-middle"},

        { label:"Current Inventory", key:'total', _classes:"inventory-data-point total align-middle" },
        { label:"Target Days Supply", key:'target_days_supply', _classes:"inventory-data-point target align-middle"},
        { label:"Sales Last 30", key:'ds_30', _classes:"inventory-data-point days_supply target"},
        { label:"Sales Last 45", key:'ds_45', _classes:"inventory-data-point days_supply target"},
        { label:"Sales Last 60", key:'ds_60', _classes:"inventory-data-point days_supply target"}
    ]


    const generateDataRow = (max)=>{
        var obj = {};
        obj.total = Math.round(Math.random()*max);
        obj.target_days_supply = 45;
        obj.ds_30 = 10+Math.round(Math.random()*35);
        obj.ds_45 = 10+Math.round(Math.random()*35);
        obj.ds_60 = 10+Math.round(Math.random()*35);
    
        obj.units_30 = Math.round((obj.total / obj.ds_30)*30);
        obj.units_45 = Math.round((obj.total / obj.ds_45)*45);
        obj.units_60 = Math.round((obj.total / obj.ds_60)*60);

        obj._cellClasses = {};
        obj._cellClasses['ds_30'] = obj.ds_30 < 20 ? "table-danger" : obj.ds_30 < 30 ? "table-info" : "";
        obj._cellClasses['ds_45'] = obj.ds_45 < 20 ? "table-danger" : obj.ds_45 < 30 ? "table-info" : "";
        obj._cellClasses['ds_60'] = obj.ds_60 < 20 ? "table-danger" : obj.ds_60 < 30 ? "table-info" : "";
        return obj;
    }

    const calcItems = (currentTree)=>{
        var t = [...currentTree];
        var obj = {...treeTypes[treeType]};
        var maxNumArray = [500,100,75,25];
        var useMax = 0;
        while(t.length > 0){
            var index = t.shift();
            obj = obj[index].children;
            useMax++;
        }
        var tot = 0;
        var items = Object.keys(obj).map( (i)=>{
            var a = {...obj[i], ...generateDataRow(maxNumArray[useMax])};
            tot += a.total;
            return a;
        });

    
        items.push(
            {
                "slice":"TOTAL",
                "total":2707,
                "target":4058,
                "diff":-1351,
                "days_supply":32,
                "td_diff":-15,
                "target_days_supply":45,
                "sold":45,
                "comp":17,
                "_classes":"table_total",
                ...generateDataRow(4000),
                "_cellClasses":{},
                total:tot
            }
        )
        return items;
    }

    const Breadcrumbs = ()=>{
        return <>
            {tree.map((i,index)=>{
                return <span>- <CButton onClick={()=>{
                    var t = [...tree];
                    setTree(t.slice(0,index+1));
                }} color="secondary" >{i}</CButton></span>
            })}
        </>
        
    }

    const dataTreeSwapper = (e)=>{
        var ind = e.target.selectedIndex;
        setTreeType(ind);
        setTree([]);
    }

    return(
        <CCard>
            <CCardHeader className="d-flex justify-content-between">
                <h3><CButton onClick={()=>{setTree([])}} color="secondary" size="large">Segments</CButton> {Breadcrumbs()}</h3>
                <CSelect className="col-sm-2" onChange={dataTreeSwapper}>
                    <option value="segment">Vehicle Segment</option>
                    <option value="price_band">Price Band</option>
                </CSelect>
            </CCardHeader>
            <CCardBody>
                <CDataTable
                    overTableSlot={<div className="topLabel">Days Supply</div>}
                    outlined
                    hover
                    tableFilter
                    fields={fields2}
                    items={calcItems(tree)}
                    pagination={true}
                    itemsPerPage={15}
                    striped
                    scopedSlots={
                        {
                            "slice":(row)=>{
                                if(row.children){
                                    var drillDown = ()=>{
                                        var t = [...tree];
                                        t.push(row.slice);
                                        setTree(t);
                                    }
                                    return <td className="inventory-label-column align-middle"><a onClick={drillDown} className="table_link">{row.slice}</a></td>
                                }
                                else{
                                    return <td className="inventory-label-column align-middle">{row.slice}</td>
                                }
                            },
                            "ds_30": (item)=>{
                                return <td className={`inventory-data-point days_supply target ${item._cellClasses['ds_30']} align-middle`}>
                                    {item.ds_30} days / {item.total - Math.round((item.total/item.ds_30)*45)}  <span>{item.units_30} sold last 30 days</span>
                                </td>   
                            },
                            "ds_45": (item)=>{
                                return <td className={`inventory-data-point days_supply target ${item._cellClasses['ds_45']} align-middle`}>
                                {item.ds_45} days / {item.total - Math.round((item.total/item.ds_45)*45)}  <span>{item.units_45} sold last 45 days</span>
                            </td> 
                            },
                            "ds_60": (item)=>{
                                return <td className={`inventory-data-point days_supply target ${item._cellClasses['ds_60']} align-middle`}>
                                    {item.ds_60} days / {item.total - Math.round((item.total/item.ds_60)*45)}  <span>{item.units_60} sold last 60 days</span>
                                </td>  
                            }
                        }
                    }
                />
            </CCardBody>
            <CCardFooter>
                <div className="d-flex align-items-center justify-content-end">
                    <div className="inventory_legend_box red mr-2"></div>
                    <div className="inventory_legend_label mr-3">Days Supply Under 20 Days</div>
                    <div className="inventory_legend_box blue mr-2"></div>
                    <div className="inventory_legend_label mr-3">Days Supply Under 30 Days</div>
                </div>
            </CCardFooter>
        </CCard>
    
    
    )
}