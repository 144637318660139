import React, { useState, useEffect, useCallback, useRef } from 'react';
import { 
    CRow, CCol, CCard, CCardHeader, CCardBody, CInput, CButton, CCardFooter,
    CAlert, CCollapse, CDataTable
} from '@coreui/react';
import "@coreui/coreui/dist/css/coreui.css";
import jwt from 'jsonwebtoken';
import { CIcon } from '@coreui/icons-react';
import {cilReload} from '@coreui/icons';
import { Button } from 'semantic-ui-react';
import RunListManager, {getCookie,setCookie} from '../../services/RunListManager';
import {AppraisalQueue} from './AppraisalQueue';
import {validateEmail} from '../../services/AppraisalUtils';
import {ArchiveRecordsView} from '../ArchiveRecordsView';
import OfferModalContainer from '../AppraiserView/OfferModalContainer';
import {OfferSheetContent} from '../OfferSheetContent';
import Error from '../generic/Error/Error';


export const VASDashboard = (props) => {
    var RL = new RunListManager()
    const [vin, setVin] = useState('');
    const [mileage, setMileage] = useState('');
    const [fName, setFName] = useState('');
    const [lName, setLName] = useState('');
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [displayHint, setDisplayHint] = useState(false);
    const [hint, setHint] = useState('');
    const [userName, setUserName] = useState(getCookie('preferredUserName') ? getCookie('preferredUserName') : '');
    const [isUserNameSet, setIsUserNameSet] = useState(0);
    const [isRecordAdded, setIsRecordAdded] = useState('');
    const [viewArchivedRecords, setViewArchivedRecords] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [showPreview, setShowPreview] = useState(false);
    const [failedMessage, setFailedMessage] = useState('');
    
    // const [isDataRetrieved, setIsDataRetrieved] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [showModal, setShowModal] = useState(false);    
    const [searchedVin, setSearchedVin] = useState('');
    const {addError} = Error();
    
    const timerRef = useRef();
    const intervalRef = useRef();

    const [appraisalRecords, setAppraisalRecords] = useState([]);
    const [archiveRecords, setArchiveRecords] = useState([]);

    ////////////////////////////////////////////////////////////
    const fields = [
        {
            key: 'vas_archive',
            label: '',
            _style: { width: '2%' },
            sorter: false,
            filter: false
        },
        { key: 'first_name', label: 'First Name', _style: { width: '8%'} },
        { key: 'last_name', label: 'Last Name', _style: { width: '8%'} },
        { key: 'store_name', label: 'Store', _style: { width: '8%'} },
        { key: 'vin', label: 'VIN', _style: { width: '12%'} },
        { key: 'modified_created_date', label: 'Created', _style: { width: '14%'} },
        { key: 'vas', label: 'Specialist', _style: { width: '10%'} },
        { key: 'appraiser', label: 'Appraiser', _style: { width: '10%'} },
        { key: 'inspection_status', label: 'Inspection', _style: { width: '10%'} },
        { key: 'offer_status', label: 'Appraisal', _style: { width: '10%'} },
        // { key: 'appraisal_status', label: 'Appraisal Status', _style: { width: '10%'} },
        {
        key: 'offer',
        label: '',
        _style: { width: '2%' },
        sorter: false,
        filter: false
        },
    ];

    const archiveFields = [
        {
            key: 'vas_archive',
            label: '',
            _style: { width: '2%' },
            sorter: false,
            filter: false
        },
        { key: 'first_name', label: 'First Name', _style: { width: '8%'} },
        { key: 'last_name', label: 'Last Name', _style: { width: '8%'} },
        { key: 'store_name', label: 'Store', _style: { width: '8%'} },
        { key: 'vin', label: 'VIN', _style: { width: '12%'} },
        { key: 'modified_created_date', label: 'Created', _style: { width: '14%'} },
        { key: 'vas', label: 'Specialist', _style: { width: '10%'} },
        { key: 'appraiser', label: 'Appraiser', _style: { width: '10%'} },
        { key: 'inspection_status', label: 'Inspection', _style: { width: '10%'} },
        { key: 'offer_status', label: 'Appraisal', _style: { width: '10%'} },
        // { key: 'appraisal_status', label: 'Appraisal Status', _style: { width: '10%'} },
        // {
        // key: 'offer',
        // label: 'Offer',
        // _style: { width: '10%' },
        // sorter: false,
        // filter: false
        // },
    ];

    ////////////////////////////////////////////////////////////
    // input fields on change handlers
    const handleVinChange = (e) => {
        const updatedVin = e.target.value;
        if (updatedVin.length > 0) {
            setVin(updatedVin.toUpperCase());
        } else {
            setVin(updatedVin);
        }        
    }

    const handleMileageChange = (e) => {
        const castedMileage = parseInt(e.target.value);

        if(isNaN(castedMileage)) {
            setMileage('');
        } else {
            setMileage(e.target.value);
        }
    };

    const handleNameChange = (e, id) => {
        const updatedName = e.target.value;

        if (id == 'fName') {
            setFName(updatedName)
        } else if (id == 'lName') {
            setLName(updatedName);
        }
    }

    // input validation
    const debounceValidateInput = useCallback(() => {
        const timerHook = setTimeout(() => {
            if (vin.length == 17) {
                if (mileage === '') {
                    // setEnableSubmit(false);
                    setDisplayHint(true);
                    setHint('Enter a valid Mileage');
                } else if (fName === '') {
                    // setEnableSubmit(false);
                    setDisplayHint(true);
                    setHint('Enter a valid first name');
                } else if (lName === '') {
                    // setEnableSubmit(false);
                    setDisplayHint(true);
                    setHint('Enter a valid last name');
                } else {

                }        
            } else {
                // setEnableSubmit(false);
                setDisplayHint(true);
                setHint('Invalid VIN. Must be 17 chars');
            }
        }, 2000);

        return timerHook
    }, [vin, mileage, fName, lName])

    ////////////////////////////////////////////////////////////

    // parse the preferred_username from the auth token
    const getPreferredUserName = () => {
        const intervalHook = setInterval(async () => {
            var rawToken = await getCookie('AD_idToken');
            const decodedToken = jwt.decode(rawToken, {complete: true});

            if (decodedToken && decodedToken.payload && decodedToken.payload.preferred_username && isUserNameSet <= 1) {
                setUserName(decodedToken.payload.preferred_username);
                setCookie('preferredUserName', decodedToken.payload.preferred_username, 20000)
                setIsUserNameSet(prev => prev + 1);
            }
        }, 500);

        return intervalHook;        
    }

    // button click handlers and REST calls
    const getMyAppraisals = () => {
        RL.getAppraisalRecordsbyVAS(userName)
                    .then(res => {
                        if(res && res.data && res.data.appraisal_record_lists) {
                            const recordLists = res.data.appraisal_record_lists;

                            const updatedRecordLists = recordLists.map(record => {
                                let recordObj = {
                                    ...record
                                }

                                const dateTime = new Date(record.created_date);

                                // recordObj['modified_created_date'] 
                                //     = dateTime.getFullYear() + "-" +
                                // ("0" + (dateTime.getMonth()+1)).slice(-2) + "-" +
                                // ("0" + dateTime.getDate()).slice(-2) + "\n" +
                                // ("0" + dateTime.getHours()).slice(-2) + ":" +
                                // ("0" + dateTime.getMinutes()).slice(-2)

                                const hours = parseInt(("0" + dateTime.getHours()).slice(-2));
                                const updatedHours = (hours + 11) % 12 + 1;
                                const suffix = hours > 12 ? 'PM' : 'AM';
                                // const updatedHours = (parseInt(("0" + dateTime.getUTCHours()).slice(-2)) + 11) % 12 + 1;

                                recordObj['modified_created_date'] 
                                    = dateTime.getFullYear() + "-" +
                                ("0" + (dateTime.getMonth()+1)).slice(-2) + "-" +
                                ("0" + dateTime.getDate()).slice(-2) + "\n" +
                                (updatedHours) + ":" +
                                ("0" + dateTime.getMinutes()).slice(-2) + " " + suffix;

                                return recordObj
                            })

                            setAppraisalRecords(updatedRecordLists);
                        } else {
                            setAppraisalRecords([]);
                        }                        
                    })
                    .catch(error => {
                        console.error('Could not retrieve appraisal records for the VAS ' + error);
                    })
    }

    const fetchArchivedRecords = () => {
        RL.fetchAppraisalRecords('vas', userName)
            .then(res => {
                if (res && res.data && res.data.archived_record_lists) {
                    // setArchiveRecords();
                    const recordLists = res.data.archived_record_lists;

                    const updatedRecordLists = recordLists.map(record => {
                        let recordObj = {
                            ...record
                        }

                        const dateTime = new Date(record.created_date);

                        const hours = parseInt(("0" + dateTime.getHours()).slice(-2));
                        const updatedHours = (hours + 11) % 12 + 1;
                        const suffix = hours > 12 ? 'PM' : 'AM';
                        // const updatedHours = (parseInt(("0" + dateTime.getUTCHours()).slice(-2)) + 11) % 12 + 1;

                        recordObj['modified_created_date'] 
                            = dateTime.getFullYear() + "-" +
                        ("0" + (dateTime.getMonth()+1)).slice(-2) + "-" +
                        ("0" + dateTime.getDate()).slice(-2) + "\n" +
                        (updatedHours) + ":" +
                        ("0" + dateTime.getMinutes()).slice(-2) + " " + suffix;

                        return recordObj
                    })
                    setArchiveRecords(updatedRecordLists)
                } else {
                    setArchiveRecords([])
                }
            })
            .catch(err => {
                console.error(err);
                if (err.message == 'Network Error') 
                    addError(err.message);
            })
    };

    const archiveRecordHandler = (vin, input_role, role_value, created_date, toggleValue) => {
        // make a call to the api
        RL.archiveAppraisalRecord(vin, input_role, role_value, created_date, toggleValue)
            .then(res => {
                // invoke getMyAppraisals on succesful return
                if (res.status == 200) 
                    getMyAppraisals();
                    fetchArchivedRecords();
            })
            .catch(err => {
                console.error(err);
            })
    };

    const createAppraisalRecord = (enrichResponse) => {
        RL.createAppraisalRecord(
            vin,
            userName,
            mileage,
            fName,
            lName
        )
            .then(res => {
                // console.log('Successful response');
                // console.log(res);    
                if (res.status == 200) {
                    getMyAppraisals();
                    setIsRecordAdded('success');

                    // set searched vin and reset the input fields
                    setVin('');
                    setMileage('');
                    setFName('');
                    setLName('');

                    // parse the response data and store to state
                    // let responseData = enrichResponse && enrichResponse['data'] ? enrichResponse['data'] : [];

                    // response = responseData;
                }                
            })
            .catch(error => {
                setIsRecordAdded('failed');
                setFailedMessage(`Couldn't add appraisal record. Try again`);
            });
    }

    const onSubmitVINLookup = (e) => {
        // setIsDataRetrieved('loading');
        setShowModal(true);
        setSearchedVin(vin);
        setIsRecordAdded('progress');
        const appraisal=true
        const trim=""

        // make network call to enrich data for requested VIN
        RL.enrichVehicleData(vin, mileage, trim, appraisal)
                .then(response => {
                    // console.log(response);

                    // if (response.status == 200) {
                        const resdata = response.data;
                        let error = false;

                        resdata.map(item => {
                            const mainValue = Object.values(item);
                            const subValue = mainValue[0]['error']
                            if(subValue) {
                                // console.log('entered');
                                error = true;
                                return
                            }
                        })

                        if(!error) {
                            createAppraisalRecord(response);
                        } else {
                            setIsRecordAdded('failed');
                            setFailedMessage(`Couldn't enrich vehicle details. Try again`);
                        }
                    // }                   
                })
                .catch(error => {
                    setIsRecordAdded('failed');
                    if (error.message == 'Network Error') 
                    addError(error.message);
                    else if(error.response.data == 'API call Failed') {
                        setFailedMessage(`The VIN entered is Incorrect`);
                    } else {
                        setFailedMessage(`Technical Error while enriching details. Try Again`);
                    }
                });
    }

    useEffect(() => {
        if (!userName) {
            intervalRef.current = getPreferredUserName();
        }

        if (isUserNameSet >= 1) {
            clearInterval(intervalRef.current);
            setIsUserNameSet(0);
        }
        
        return () => {
            clearInterval(intervalRef.current);
        }
    }, [isUserNameSet, userName])

    useEffect(() => {        
        getMyAppraisals();
        fetchArchivedRecords();
    }, [userName])

    useEffect(() => {
        if(vin == '') {
            setDisplayHint(false);
            setEnableSubmit(false);
            return
        }

        timerRef.current = debounceValidateInput();

        if (vin.length == 17) {
            if (mileage !== '' && fName != '' && lName != '') {
                setEnableSubmit(true);
                setDisplayHint(false);
            } else {
                setEnableSubmit(false);
            }           
        } else {
            setEnableSubmit(false);
        }

        return () => {
            clearTimeout(timerRef.current);
            setDisplayHint(false);
        }
    }, [vin, mileage, fName, lName])

    return (
        <>
            <CRow>
                {/* <CCol className="pt-5 col-md-10 offset-md-1"> */}
                <CCol className="pt-3 col-md-11 mt-3 mb-3" style={{ margin: "0px auto", background: "white" }}>
                    <CCard>
                        <CCardHeader style={{backgroundColor: "#F5F6F7"}}><h3>Appraisal Details</h3></CCardHeader>
                        <CCardBody>
                            <div className='d-flex'>
                                    <div className="flex-grow-5 w-50 mr-2">
                                        <CInput 
                                            onChange={(e) => handleNameChange(e, 'fName')}
                                            placeholder='First Name'
                                            size='lg'
                                            style={{ fontSize: '16px' }}
                                            value={fName}
                                            />
                                    </div>
                                    <div className="flex-grow-5 w-50">
                                        <CInput
                                            onChange={(e) => handleNameChange(e, 'lName')}
                                            placeholder='Last Name'
                                            size='lg'
                                            style={{ fontSize: '16px' }}
                                            value={lName}
                                            />                                
                                    </div>
                                    {/* <CButton
                                        className="ml-2"
                                        color={enableSubmit ? "primary" : "danger"}
                                        disabled={!enableSubmit}
                                        size="lg" 
                                        onClick={onSubmitVINLookup}
                                    >
                                        Submit
                                    </CButton> */}
                            </div>
                            <div className='d-flex mt-3'>
                                    <div className="flex-grow-5 w-50 mr-2">
                                        <CInput 
                                            onChange={handleVinChange}
                                            placeholder='Enter VIN'
                                            size='lg'
                                            style={{ fontSize: '16px' }}
                                            value={vin}
                                            />
                                    </div>
                                    <div className="flex-grow-5 w-50">
                                        <CInput
                                            onChange={handleMileageChange}
                                            placeholder='Enter Mileage'
                                            size='lg'
                                            style={{ fontSize: '16px' }}
                                            value={mileage}
                                            />                                
                                    </div>
                            </div>
                            <div className='mt-3'>
                                <CButton
                                            className="btn-block"
                                            color={enableSubmit ? "primary" : "danger"}
                                            disabled={!enableSubmit}
                                            size="lg" 
                                            onClick={onSubmitVINLookup}
                                        >
                                            Submit
                                </CButton>
                            </div>
                            {
                                displayHint &&
                                <CCardFooter className="text-medium-emphasis offset-md-5">{hint}</CCardFooter>
                            }
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
            {/* Show an alert that the record is being created */}
            {
                isRecordAdded === 'progress' && (
                    <CRow>
                        <CCol className="pt-3 col-md-11 mt-1 mb-1" style={{ margin: "0px auto" }}>
                            <CAlert color="primary">
                                Adding Appraisal Record for VIN {vin}
                            </CAlert>
                        </CCol>
                    </CRow>
                )
            }
            {
                isRecordAdded === 'success' && (
                    <CRow>
                        <CCol className="pt-3 col-md-11 mt-1 mb-1" style={{ margin: "0px auto" }}>
                            <CAlert color="success" closeButton onClose={() => setIsRecordAdded('')}>
                                Success
                            </CAlert>
                        </CCol>
                    </CRow>
                )
            }      
            {
                isRecordAdded === 'failed' && (
                    <CRow>
                        <CCol className="pt-3 col-md-11 mt-1 mb-1" style={{ margin: "0px auto" }}>
                            <CAlert color="danger" closeButton onClose={() => {
                                setIsRecordAdded('');
                                setFailedMessage('');
                            }}>
                                Couldn't create record for VIN {vin}. {failedMessage}
                            </CAlert>
                        </CCol>
                    </CRow>
                )   
            }         
            {/* My Appraisals Queue goes here */}
            <CRow className="mb-5">
                <CCol className="pt-3 col-md-11" style={{ margin: "0px auto", background: "white" }}>
                    <div className="d-flex justify-content-between align-items-center">
                        <h3 className='pb-2'>My Appraisals</h3>
                        <Button 
                            onClick={() => {
                                getMyAppraisals()
                            }}
                        >
                            <CIcon content={cilReload}
                                style={{ 
                                    width: '35px', 
                                    height: '20px', 
                                    padding: '1px',  
                                }} 
                            />
                        </Button>
                    </div> 
                    <AppraisalQueue 
                        appraisalRecords={appraisalRecords}
                        badgeField={[`offer_status`]}
                        dropdownField={`inspection_status`}
                        dropDownoptions={['In Progress', 'Complete', 'Inactive']}
                        fields={fields}
                        recordsPerPage={5}
                        getMyAppraisals={getMyAppraisals}
                        roleType={'VAS'}
                        offerButtons={[
                            {
                                id: 1,
                                text: 'View Offer Sheet',
                                setShowViewModal,
                                setModalContent,
                                setShowPreview
                            },
                        ]}
                        vasRecordsView={true}
                        fetchArchivedRecords={fetchArchivedRecords.bind('vas', userName)}
                    />
                </CCol>
            </CRow>
            {/* Archived Records */}
            <CRow className="mb-5">
                <CCol className="pt-3 col-md-11" style={{ margin: "0px auto", background: "white" }}>
                    {/* <div> */}
                        <Button 
                            className="mb-4" 
                            onClick={() => setViewArchivedRecords(!viewArchivedRecords)}
                            primary
                            // style={{background: '#00aced'}}
                        >
                            <h4>Archived Records</h4>
                        </Button>
                        <CCollapse visible={viewArchivedRecords} style={viewArchivedRecords ? {display: 'block'} : {display: 'none'}}>
                            <CCard className="mt-3">
                                <CCardBody>
                                    <ArchiveRecordsView 
                                        archiveFields={archiveFields}
                                        archiveRecords={archiveRecords}
                                        archiveRecordHandler={archiveRecordHandler}
                                        view={'VAS'}
                                    />
                                </CCardBody>
                            </CCard>
                        </CCollapse>
                    {/* </div> */}
                </CCol>
            </CRow>

            {/* View Customer Offer */}
            {
                (modalContent.action && modalContent.action == 'View') && (
                    <OfferModalContainer show={showPreview} setShow={setShowPreview} item={modalContent.item} saveFileButton={true} >
                        <OfferSheetContent 
                            item={modalContent.item} 
                        />
                    </OfferModalContainer>                  
                )
            }
        </>
    )
}