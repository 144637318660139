import React from 'react'



export default ({vehicle})=>{

    return(
        <div className="buyer_info_popup">
            {vehicle.buyers_dq_timestamp ? 'DQ\'d' : 'Entered'} by {vehicle.review_user.split("@")[0]} on {new Date(vehicle.review_timestamp).toLocaleDateString()}
        </div>
    )
}
