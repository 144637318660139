import React from 'react';
import {CIcon} from '@coreui/icons-react'
import {
    CSidebar, 
    CSidebarBrand, 
    CSidebarNav,
    CSidebarNavItem,
    CSidebarNavTitle,
    CSidebarNavDivider
    } from '@coreui/react';




export default (props)=>{

    const { isAppraiser, userRoles } = props;

    // set user role flags
    const isSuperUser = userRoles.indexOf('SuperUser') > -1 ? true : false;
    const isAppr = userRoles.indexOf('Appraiser') > -1 ? true : false;
    const isVas = userRoles.indexOf('VAS') > -1 ? true : false;
    const isOther = userRoles.indexOf('Other') > -1 ? true : false;

    const getCookie =(name)=> {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    return (
        <>
            {/* {
                sideMenuOptions
            } */}
            <CSidebar show={props.show}>
                <CSidebarBrand>LIVE AUCTION PREVIEW</CSidebarBrand>
                <CSidebarNav>
                    {
                        isOther && (
                            <>
                                <CSidebarNavTitle>Live Auction</CSidebarNavTitle>
                                    <CSidebarNavItem  name="Run List Manager" to={"/"}/>
                                    <CSidebarNavItem  name="First Look" to={"/firstlook/index"}/>
                                <CSidebarNavDivider/>
                            </>
                        )
                    }
                    <CSidebarNavTitle>Appraisal</CSidebarNavTitle>
                        {
                            isVas && (
                                <>
                                    <CSidebarNavItem  name="VAS View" to={"/appraisal/vas"}/>
                                </>
                            )
                        }
                        {
                            isAppr && (
                                <>
                                    <CSidebarNavItem  name="Central Appraiser View" to={"/appraisal/appraiser"}/>
                                    <CSidebarNavItem  name="VIN Lookup" to={"/appraisal/vinlookup"}/>
                                    <CSidebarNavItem  name="Generate Manual Offer" to={"/appraisal/manualappraisal"}/>
                                </>
                            )
                        }
                        {
                            isSuperUser && (
                                <>
                                    <CSidebarNavItem  name="In Store Appraiser" to={"/appraisal/superuser"}/>
                                </>
                            )
                        }

                        {
                            (isSuperUser || isAppr) && ( 
                                <>
                                    <CSidebarNavItem  name="Instant Cash Offer" to={"/appraisal/shift"}/>
                                </>
                            )
                        }

                        {
                            (isSuperUser || isAppr) && ( 
                                <>
                                    <CSidebarNavItem  name="Appraisal Stats" to={"/appraisal/appriasalcount"}/>
                                </>
                            )
                        }
                    <CSidebarNavDivider/>
                    {
                        isOther && (
                            <>
                                <CSidebarNavTitle>Reporting</CSidebarNavTitle>
                                <CSidebarNavItem  name="Download Reports" to={"/reporting/buyers"}/>
                                <CSidebarNavDivider/>
                                <CSidebarNavTitle>Inventory</CSidebarNavTitle>
                                    <CSidebarNavItem  name="Days Supply" to={"/inventory/dashboard"}/>
                                    <CSidebarNavItem  name="Days Supply Chart" to={"/inventory/chart/make"}/>
                                <CSidebarNavDivider/>
                                <CSidebarNavTitle>Pricing</CSidebarNavTitle>
                                    <CSidebarNavItem  name="Auction and Wholesale Price Chart" to={"/pricing/chart"}/>
                                <CSidebarNavDivider/>
                                <CSidebarNavTitle>Feature Flags</CSidebarNavTitle>
                                <CSidebarNavItem  name="Toggle Features" to={"/featureflags"}/>
                                <CSidebarNavDivider/>
                            </>
                        )
                    }
                </CSidebarNav>
            </CSidebar>
        </>
    )
}
