import React from 'react'
import OLOContainer from '../OLOContainer/OLOContainer';



export default ({text, loading})=>{

    return(
        <OLOContainer header="Announcements">
            {loading ? '...' : text}
        </OLOContainer>
    )
}