import React from 'react';
import {CContainer, CRow, CCol, CCard, CCardHeader, CCardBody, CCallout} from '@coreui/react';
import CopyToClipboard from './CopyToClipboard/CopyToClipboard'
import VehicleReturned from './VehicleReturned/VehicleReturned';

export default ({vehicle})=>{


    return(
        <CRow>
        <CCol md="12">
            <CCard> 
                <CCardHeader>{vehicle.year} {vehicle.make} {vehicle.extendedModel} ({vehicle.drivetrain})</CCardHeader>
                <CCardBody>
                 {(vehicle.status_code == 'R') && <VehicleReturned/>}

                    <CRow >
                        <CCol md="12">
                            <CCallout color="info">
                                <small className="text-muted">VIN</small>
                                <br />
                                <strong className="h4">{vehicle.vin} <CopyToClipboard vin={vehicle.vin}/></strong><br/>
                            </CCallout>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
    )
}