import React, {useState, useEffect} from 'react';
import {CarFaxEffect} from './CarfaxEffect';
import {CCard, CCardHeader, CCardBody} from '@coreui/react'
import NeedCarfaxToken from './NeedCarfaxToken/NeedCarfaxToken2';
import CarfaxDetail from './CarfaxDetails/CarfaxDetails2';
import {Loader} from 'semantic-ui-react'

export default (props)=>{

    const [loading, setLoading] = useState(false)
    const [carfax, setCarFax] = useState(false)
    const [loadTrigger, setLoadTrigger] = useState(0);
    const [tokenValid, setTokenValid] = useState(false)

    useEffect( ()=>{
        var ignore = false;
        var tokenString = localStorage.getItem('carfax_access_token');
        var token = {}
        if(tokenString) token = JSON.parse(tokenString)
        var d = new Date();
        
        if(token['accessToken'] && token['expiration'] > d.getTime()){
            setLoading(true);
            setTokenValid(true);
            CarFaxEffect(props.vin, token['accessToken']).then( (resp)=>{
                if(!ignore){

                    if(resp.data.errors || !resp.data.data.dealerReport){
                        setCarFax(false)
                    }else{
                         setCarFax(resp.data.data);
                    }
                    setLoading(false);
                }
            })
        }
        else{
            setTokenValid(false);
        }

        return ()=>{ignore = true}
    },[loadTrigger, setLoading, props.vin] )

    return(<>
                {loading && <Loader inline active/>}
                {(!loading && tokenValid && carfax) && <CarfaxDetail carfax={carfax}/>}
                {(!loading && !tokenValid) && <NeedCarfaxToken vin={props.vin} setLoadTrigger={setLoadTrigger} loadTrigger={loadTrigger}/>}
        </>
    )
}
