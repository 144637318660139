import React, {useState, useEffect} from 'react';
import { CCard, CCardHeader, CCardBody, CDataTable, CButton, CBadge} from '@coreui/react';
import RunListManager from '../../../../services/RunListManager';
import FormattedNumber from '../../../FormattedNumber/FormattedNumber';
import {Form, Loader} from 'semantic-ui-react';
import OLOInventoryTree from './OLOInventoryTree';
import Error from '../../../generic/Error/Error';
import CompFlag from '../../OLOContainer/CompFlag/CompFlag';

export default ({vin, vehicle, channel, source = '', page = '', compFlag})=>{

    const [currentInventory, setCurrentInventory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tree, setTree] = useState([])
    const [inventoryCount, setInventoryCount] = useState(0)
    const [collapsed, setCollapsed] = useState(false);
    const {addError} = Error();

    const iTree = new OLOInventoryTree();

    useEffect( ()=>{
        var ignore = false;
        var RL = new RunListManager();
        setLoading(true);
        setTree([])
        setCurrentInventory([]);
        RL.getStaticVehicleDetail(vin, "inventory", channel, source, page).then( (data)=>{
           if(!ignore){
              // if(data.error) return addError(data.message, 500);
              if (page === 'Appraisal') {
                  const inventory = iTree.formatResults(data.data.matches);
                  setCurrentInventory(inventory)
                  setInventoryCount(data.data.matches.length)
              } else {
                const inventory = iTree.formatResults(data.data.inventory);
                setInventoryCount(data.data.inventory.length)
                setCurrentInventory(inventory);
              }
               setLoading(false)
           }
        }).catch( (e)=>{
            console.log(e); 
         //   addError(e.message, 500);
            setLoading(false)})

        return ()=>{ignore =true;}

    }, [vin, channel])

    useEffect(()=>{
        var t = []
        if(currentInventory[vehicle.year] != undefined){
            t.push(vehicle.year)
        }
        if(t.length > 0){
           var band = iTree.findMileageBand(vehicle.mileage); 
           if(currentInventory[vehicle.year]['children'][band] != undefined){
               t.push(band)
           }
        }
        setTree(t)
    }, [currentInventory, vehicle.mileage, vehicle.year])

    const toggleCollapsed = (e)=>{e.preventDefault();setCollapsed(!collapsed)}

    const addToTree = (val)=>{
        var newTree = [...tree];
        newTree.push(val)
        setTree(newTree)
    }

    const sliceTree = (index)=>{
        var newTree = [...tree]
        newTree = newTree.slice(0,index)
        setTree(newTree)
    }

    return(
        <CCard className={ collapsed ? "animateCard olo-collapse" : "animateCard"}
            style={page==='Appraisal' ? !collapsed ? { height: '100%'} : {} : {}}
        >
            <CCardHeader className="d-flex justify-content-between align-items-center">
            <div className="olo-header">
                    <span>Current Off Lease Only Inventory ({loading ? '...' : inventoryCount})</span>
                    <span>{compFlag && <CompFlag/>}</span>
                </div>
                
                <CButton color="info" onClick={toggleCollapsed}>{collapsed ? 'Expand' : 'Collapse'}</CButton>
            </CCardHeader>

            <CCardBody className={collapsed ? 'collapse' : ''}>

                <div className="d-flex pb-3">
                        <CBadge onClick={()=>{sliceTree(0)}} size="sm" color="secondary">All</CBadge>
                        {tree.map((item)=>{
                            return <CBadge onClick={()=>{sliceTree(1)}} size="sm" color="secondary" className="ml-1">{item}</CBadge>
                        })}
                </div>

                <CDataTable
                    fields={iTree.setFields(tree)}
                    items={iTree.buildTableRows(currentInventory,tree)}
                    loading={loading}
                    sorter
                    pagination
                    itemsPerPage={10}
                    scopedSlots={{
                        'mileage': (i)=>{
                            return <td><FormattedNumber value={i.mileage}/></td>
                        },

                        'current_list_price': (item)=>{
                            return <td><FormattedNumber value={item.current_list_price} price={true}/></td>
                        },
                        
                        'initial_live_date': (item)=>{
                            var daysLive = "Not Live"
                            if(typeof item.initial_live_date === 'number' && isFinite(item.initial_live_date)){
                                daysLive = item.initial_live_date;
                            }
                            return <td>{daysLive}</td>
                        },

                        'extcolor': (item)=>{
                            return <td>
                                {item.extcolor ? item.extcolor : 'Unknown'}
                            </td>
                        },
                        
                        'year': (item)=>{
                            return <td><a onClick={()=>{addToTree(item.year)}} className="olo_link">{item.year}</a></td>
                        },
        
                        'band': (item)=>{
                            return <td><a className="olo_link" onClick={()=>{addToTree(item.band)}}>{item.band}</a></td>
                        },
                        'price': (item)=>{
                            if(isNaN(item.price)) return <td>-</td>;
                            return <td><FormattedNumber value={item.price} price={true}/></td>
                        }
                    }}
                />
            </CCardBody>
        </CCard>
    )
}