import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom';
import {CNavItem, CNavLink} from '@coreui/react'
import RunListManager from '../../../services/RunListManager'

export default (props)=>{
    const {runListId, channel} = useParams();

    const [runlist, setRunlist] = useState({});
    const [loading, setLoading] = useState(false);
    
    useEffect( ()=>{
        var ignore = false;
        var RL = new RunListManager();
        setLoading(true)

        RL.getRunListSummary(runListId, channel).then((response)=>{
            if(!ignore){
                setRunlist(response.data);
                setLoading(false);
            }
        }).catch(()=>{setLoading(false)})

        return ()=>{ignore = true;}
    }, [runListId])

    return <CNavItem>
        <CNavLink to={`/runlist/${channel}/${runListId}/stage`}>{channel} Run List {runListId} ({loading ? "..." : runlist.auction_date})</CNavLink>
    </CNavItem>
}
