import React, {useState, useEffect} from 'react';
import { CDataTable} from '@coreui/react';


export default ({damages, message, loading})=>{

    return  <><CDataTable
                fields={['area', 'estimate', 'description', 'type']}
                items={damages}
                noItemsViewSlot={message}
                loading={loading}
                scopedSlots={
                    {
                        "area": (item)=>{
                            if(item.photoUrl){
                                
                            }
                            return <td>{item.area}</td>
                        }
                    }
                }
            />
        </>
}