import Auth from './Auth';
import axios from 'axios';
import jwt from 'jsonwebtoken';

const API_ENDPOINT_URL = process.env.REACT_APP_API_ENDPOINT || "";
const STATIC_API_ENDPOINT_URL = process.env.REACT_APP_STATIC_API_ENDPOINT || "";
const APPRAISAL_STATIC_API_ENDPOINT_URL = process.env.REACT_APP_APPRAISAL_TOOL_STATIC_API_ENDPOINT || "";
const APPRAISAL_TOOL_ENRICH_ENDPOINT_URL = process.env.REACT_APP_APPRAISAL_TOOL_ENRICH_ENDPOINT || "";
const SHIFT_SERVICE_ENDPOINT_URL = process.env.REACT_APP_SHIFT_SERVICE_ENDPOINT || "";
const SHIFT_SERVICE_API_KEY = process.env.REACT_APP_SHIFT_SERVICE_API_KEY || "";

export default class VehicleManager {
   
    constructor(options){
        this.updater = ()=>{};
        for(var key in options){
            this[key] = options[key]
        }
        this.inventory_settings = null;
        this.on_init = false;
    }


    utcToTZ(here, utc) {
        //since we know OLO is always behind UTC we know if local hr is greater its tomorrow in UTC
        if(here > utc) return (24-here)+utc; 
        return utc-here;
    }

    getRunLists(){
        return this.callApiEndpoint({
            url: API_ENDPOINT_URL+"/runlist",
            method:"GET",
            headers:{}
        })
    }

    createRunList(data){
        //requires auctionDate and channel
        return this.callApiEndpoint({
            url: API_ENDPOINT_URL+"/runlist/create",
            method:"POST",
            headers:{},
            data: data
        })
    }

    getRunListSummary(runListId, channel="SIMULCAST"){
        return this.callApiEndpoint({
            url: API_ENDPOINT_URL+"/runlist/"+runListId+"/summary?channel="+channel,
            method:"GET",
            headers:{}
        })
    }

    getRunListLanes(runListId, conditions, channel="SIMULCAST"){
        var params = {};

        Object.keys(conditions).map((item)=>{
            params[item] = conditions[item];
            if(!params[item]) delete params[item];
            return "";
        })

        console.log("getRunListLanes, params", params);
        //params['limit'] = '50';

        
        return this.callApiEndpoint({
            url: API_ENDPOINT_URL+"/runlist/"+runListId+"/lanes?channel="+channel+"&params="+JSON.stringify(params),
            method:"GET",
            headers:{}
        })
    }

    getVehicles(conditions, channel="SIMULCAST"){
        var params = {};
        
        Object.keys(conditions).map((item)=>{
            params[item] = conditions[item];
            if(params[item] === '' || params[item] == undefined) delete params[item];
            return "";
        })

        params['limit'] = params['vin'] ? '300' : '50';
        delete params['vin'];
        params['sortBy'] = params['sortBy'] || 'runNumber_ASC'
        if(params['auction_lane']) params['auction_lane'] = ''+params['auction_lane'];

        return this.callApiEndpoint({
            url: API_ENDPOINT_URL+"/listings?channel="+channel+"&params="+JSON.stringify(params),
            method:"GET",
            headers:{}
        })
    }

    getVehicleDetail(vin, segment=false, channel="SIMULCAST", auctionDate = false){
        if(channel == "SIMULCAST") return this.getStaticVehicleDetail(vin, segment);
        var endpoint = API_ENDPOINT_URL +"/listing/"+vin;
        var aDate = (auctionDate) ? "&eventDate="+auctionDate : "";
        endpoint += (segment) ? "?action="+segment+"&channel="+channel + aDate : "?channel="+channel + aDate;
        return this.callApiEndpoint({
            url: endpoint,
            method: "GET",
            headers:{}
        })
    }

    getShoppingListDetails(vin){
        var endpoint = APPRAISAL_STATIC_API_ENDPOINT_URL + `/shoppingVehicles?vin=${vin}`;
        return this.callApiEndpoint({
            url: endpoint,
            method: "GET",
            headers: {}
        })
    }

    downloadListingsCSV(auctionDate, channel="SIMULCAST"){

        var endpoint = API_ENDPOINT_URL +"/listings/csv?auction_date="+auctionDate+"&channel="+channel;
        return this.callApiEndpoint({
            url: endpoint,
            method: "GET",
            headers:{}
        })
    }

    downloadBuyersReport(auctionDate){
        var endpoint = API_ENDPOINT_URL +"/listings/buyersReport?auction_date="+auctionDate;
        return this.callApiEndpoint({
            url: endpoint,
            method: "GET",
            headers:{}
        })
    }

    downloadPurchasesReport(auctionDate, channel='SIMULCAST'){
        var endpoint = API_ENDPOINT_URL +"/listings/purchasesReport?auction_date="+auctionDate+'&channel='+channel;
        return this.callApiEndpoint({
            url: endpoint,
            method: "GET",
            headers:{}
        })
    }

    downloadSellersReport(){
        var endpoint = API_ENDPOINT_URL +"/sellersReport"
        return this.callApiEndpoint({
            url: endpoint,
            method: "GET",
            headers:{}
        })
    }

    downloadDQReport(auctionDate, channel='SIMULCAST'){
        var endpoint = API_ENDPOINT_URL +"/dqReport?auction_date="+auctionDate+'&channel='+channel;
        return this.callApiEndpoint({
            url: endpoint,
            method: "GET",
            headers:{}
        })
    }


    getStaticVehicleDetail(vin, segment=false, channel='SIMULCAST', source='', page=''){
        var endpoint = "";
        if(page == "Appraisal" && segment == "sales_history"){
            endpoint = APPRAISAL_STATIC_API_ENDPOINT_URL + `/salesVehicles?vin=${vin}&source=live`            
        } else if(page == "Appraisal" && segment == "comps"){
            endpoint = APPRAISAL_STATIC_API_ENDPOINT_URL + `/competitorVehicles?vin=${vin}&source=live`            
        } else if(page == "Appraisal" && segment == "inventory"){
            endpoint = APPRAISAL_STATIC_API_ENDPOINT_URL + `/inventoryVehicles?vin=${vin}&source=live`            
        } else if(page == "Appraisal" && segment == "blackbook") {
            endpoint = APPRAISAL_STATIC_API_ENDPOINT_URL + `/bbpVehicles?vin=${vin}`
        } else {
            endpoint = STATIC_API_ENDPOINT_URL +"/listing/"+vin;
            endpoint += (segment) ? '?action='+segment+'&channel='+channel : '?channel='+channel;
            endpoint += (source) ? endpoint+'&source='+source : endpoint;
        }

        return this.callApiEndpoint({
            url: endpoint,
            method: "GET",
            headers:{}
        })
    }

    updateVehicleListing(vin, updates, channel="SIMULCAST"){
        var endpoint = API_ENDPOINT_URL + "/listing/"+vin+"/update?channel="+channel;
        return this.callApiEndpoint({
            url: endpoint,
            method:"PUT",
            headers:{},
            data: updates
        })
    }

    getBuyers(){
        return this.callApiEndpoint(
            {
                url: API_ENDPOINT_URL+"/buyers",
                method:"GET",
                headers:{}
            }
        )
    }

    assignLane(data, channel="SIMULCAST") {

        return this.callApiEndpoint({
            url: API_ENDPOINT_URL+"/lanes/assign?channel="+channel,
            method:"POST",
            headers:{},
            data: data
        })
    }

    disqualifyLane(data, channel="SIMULCAST"){
        return this.callApiEndpoint({
            url: API_ENDPOINT_URL+"/lanes/disqualify?channel="+channel,
            method:"POST",
            headers:{},
            data: data
        })
    }

    disqualifyLaneFiltered(data, channel="SIMULCAST"){
        return this.callApiEndpoint({
            url: API_ENDPOINT_URL+"/lanes/disqualify?channel="+channel+"&byFilter=1",
            method:"POST",
            headers:{},
            data: data
        })
    }

    getFilterOptions(data, channel="SIMULCAST"){
        console.log("Filter:", API_ENDPOINT_URL +"/lanes/filterOptions?channel="+channel+"&params="+JSON.stringify(data));

        return this.callApiEndpoint({
            url: API_ENDPOINT_URL +"/lanes/filterOptions?channel="+channel+"&params="+JSON.stringify(data),
            method:"GET",
            headers:{}
        })
    }

    getDaysSupply(data){
       return this.callApiEndpoint({
            url: API_ENDPOINT_URL +"/inventory/days_supply?params="+JSON.stringify(data),
            method:"GET",
            headers:{}
        })
    }

    getDaysSupplyByMakeModel(make, model){
        var url = encodeURI(API_ENDPOINT_URL +"/inventory/days_supply?action=makeModel&make="+make + "&model="+model);
        return this.callApiEndpoint({
            url: url,
            method:"GET",
            headers:{}
        })
    }

    getNetDemand(vin, page){
        let endpoint = "";

        if (page === "Appraisal") {
            endpoint = APPRAISAL_STATIC_API_ENDPOINT_URL + "/inventory/net_demand/" + vin;
        } else {
            endpoint = API_ENDPOINT_URL +"/inventory/net_demand/"+vin;
        }

        return this.callApiEndpoint({
            url: endpoint,
            method:"GET",
            headers:{}
        })
    }

    getUniqueMakes(){
        return this.callApiEndpoint({
            url: API_ENDPOINT_URL +"/timeseries/prices?action=makes",
            method:"GET",
            headers:{}
        })
    }

    getUniqueModelsByMake(make){
        return this.callApiEndpoint({
            url: API_ENDPOINT_URL +"/timeseries/prices?action=models&make=" + make,
            method:"GET",
            headers:{}
        })
    }

    getPricesTimeseries(year, make, model){
        return this.callApiEndpoint({
            url: API_ENDPOINT_URL +"/timeseries/prices?year=" + year + "&make=" + make + "&model=" + model,
            method:"GET",
            headers:{}
        })
    }


    getQuickVIN(vin, channel="SIMULCAST"){
        return this.callApiEndpoint({
            url: API_ENDPOINT_URL +"/quickvin/"+vin+"?channel="+channel,
            method:"GET",
            headers:{}
        })
    }

    getAuctionVehicles(vin, source=""){
        var endpoint = "";
        if(source == "Appraisal"){
            endpoint = APPRAISAL_STATIC_API_ENDPOINT_URL + `/auctionVehicles?vin=${vin}&source=live`            
        } else {
            endpoint =  STATIC_API_ENDPOINT_URL +"/listing/"+vin;
            endpoint += '?action=auctionVehicles';
        }
        return this.callApiEndpoint({
            url: endpoint,
            method: "GET",
            headers:{}
        })
    }    

    async callApiEndpoint(params) {
        var loginRequest = {scopes: ["Files.Read", "openid"] };
        var token;

        var tokenRaw = getCookie('AD_idToken');
        var decodedToken = jwt.decode(tokenRaw);

        try{
            if (tokenRaw) {
                var accounts = Auth.getAllAccounts();
                var useAccount = false;
                accounts = (Array.isArray(accounts)) ? accounts : []; 

                for(var i = 0; i < accounts.length; i++ ) {
                    // if(accounts[i].homeAccountId){

                    if(accounts[i].username == decodedToken.preferred_username){
                        useAccount = accounts[i];
                        break;
                    }
                }
                
                const expirationEpoch = decodedToken && decodedToken.exp ? decodedToken.exp : null;
                const expirationTime = expirationEpoch * 1000;
                const currentTime = new Date().getTime();

                // decode the token and check validity of token
                // validity check - if exp is greater than current time
                if(expirationTime > currentTime) {
                    token = tokenRaw ? {idToken: tokenRaw} : false;
                } else {
                    if(useAccount && !token){
                        token = await Auth.acquireTokenSilent({account: useAccount}); 
                        deleteCookie('AD_idToken');
                        setCookie('AD_idToken', token.idToken, 600);

                        token = { idToken: token.idToken };
                    }
                }

                params.headers = params.headers || {};
                params.headers['Authorization'] = 'Bearer '+ token.idToken;
                params.headers['Content-Type'] = 'application/json';
                return axios(params);

            } else {
                console.log('No token in cookie');
            }
        }catch(err){
           console.log(err);
           deleteCookie('AD_idToken');
           return {error: true, message: "There was an error accessing the data you requested. Please refresh the page and try your request again."}
        }

        window.location = '/';
    }

    initInventorySettings(){
        if (!this.on_init && this.inventory_settings == null) {
            this.on_init = true;
            this.callApiEndpoint({
                url: API_ENDPOINT_URL +"/inventory/settings",
                method:"GET"
            }).then( (data)=>{
                this.inventory_settings = data.data;
                this.on_init = false;
                var makes = data.data.makes_models;
                var makes_models_map = {}
                for(var i=0; i < makes.length; i++) {
                    makes_models_map[makes[i].make] = makes[i].models;
                }
                this.makesModelsMap = makes_models_map;        
                console.log("*** Makes Models Map", makes_models_map);
            }).catch( (e)=>{
                console.log("*** unable to get inventory settings", e);
                this.on_init = false;
            })    
        }
    }

    initBodyStyle(channel, auctionDate){
        var self = this;
        if (!self.on_initBodyStyle) {
            self.on_initBodyStyle = true;
            self.callApiEndpoint({
                url: API_ENDPOINT_URL +"/inventory/settings?bodyStyle=y&channel=" + channel + "&auctionDate=" + auctionDate,
                method:"GET"
            }).then( (data)=>{
                self.body_styles = data.data.body_styles;
                RL_Instance.body_styles = data.data.body_styles;
                self.on_initBodyStyle = false;
            }).catch( (e)=>{
                console.log("*** unable to get body styles", e);
                self.on_initBodyStyle = false;
            })    
        }

    }

    enrichVehicleData(vin, mileage, trim="", appraisal=''){
        return this.callApiEndpoint({
            url: `${APPRAISAL_TOOL_ENRICH_ENDPOINT_URL}?silent=False&appraisal=${appraisal}`,
            method:"POST",
            data: [
                {
                    "vin": vin,
                    "trim": trim    ,
                    "mileage": mileage
                }
            ],
            headers:{}
        })
    }

    getAppraisalReviewMetrics(vin) {
        return this.callApiEndpoint({
            url: `${APPRAISAL_STATIC_API_ENDPOINT_URL}/appraisaltool/${vin}`,
            method:"GET",
            headers:{}
        })
    }

    createAppraisalRecord(vin, vas, mileage, fName, lName) {
        return this.callApiEndpoint({
            url: `${APPRAISAL_STATIC_API_ENDPOINT_URL}/appraisalTool/record/create`,
            method:"POST",
            data: JSON.stringify(
                {
                    "vin": vin,
                     "vas": vas,
                     "appraiser": "",
                     "mileage": mileage,
                      "first_name": fName,
                      "last_name": lName,
                      "source": "manual"
                    //   "email": cEmail,
                    //   "phone": cPhone,
                    //   "jd_power_value": "jd_power_value",
                    //    "kbb_value": "kbb_value",
                    //    "notes": "",
                    //    "salesperson": "salesperson"
                }),
            headers:{}
        })
    }

    getAppraisalRecordsbyVAS(VAS) {
        return this.callApiEndpoint({
            url: `${APPRAISAL_STATIC_API_ENDPOINT_URL}/appraisalTool/record/get/vas/${VAS}`,
            method:"GET",
            headers:{}
        })
    }

    getAppraisalRecordsForAppraiser(start_date='', end_date='', APPRAISER='unclaimed') {
        return this.callApiEndpoint({
            url: `${APPRAISAL_STATIC_API_ENDPOINT_URL}/appraisalTool/record/get/appraiser/${APPRAISER}?start_date=${start_date}&end_date=${end_date}`,
            method:"GET",
            headers:{}
        })
    }

    getShiftAppraisalRecordsForAppraiser(start_date='', end_date='', conditions={"max_mileage": null, "min_mileage": null,"years": null, "lead_type": null, "brand": null, "rooftop": null, "appointment": null, "vehicle_type": null}) {
        var params = {}
        Object.keys(conditions).map((item)=>{
            params[item] = conditions[item];
            if(params[item] === '' || params[item] == undefined) params[item] = null
            return "";
        })
        return this.callApiEndpoint({
            url: `${APPRAISAL_STATIC_API_ENDPOINT_URL}/appraisalTool/record/get/shift?start_date=${start_date}&end_date=${end_date}&params=${JSON.stringify(params)}`,
            method:"GET",
            headers:{}
        })
    }

    getAppraisalRecordsForSuperUser(start_date='', end_date='', store='') {
        return this.callApiEndpoint({
            url: `${APPRAISAL_STATIC_API_ENDPOINT_URL}/appraisalTool/record/get/superUser?start_date=${start_date}&end_date=${end_date}&store=${store}`,
            method:"GET",
            headers:{}
        })
    }

    updateInspectionStatus(vin, vas, created_date, status, user) {
        console.log(user)
        return this.callApiEndpoint({
            url: `${APPRAISAL_STATIC_API_ENDPOINT_URL}/appraisalTool/record/update/${vin}?created_date=${created_date}`,
            method:"PUT",
            data: {
                vas,
                inspection_status_last_edited_by: user,
                inspection_status: status
            },
            headers:{}
        })
    }

    claimAppraisalRecord(vin, created_date, appraiser) {
        return this.callApiEndpoint({
            url: `${APPRAISAL_STATIC_API_ENDPOINT_URL}/appraisalTool/record/update/appraiser/${vin}?created_date=${created_date}`,
            method:"PUT",
            data: {
                appraiser,
            },
            headers:{}
        })
    }

     saveAppraisalOfferToDB(vin, created_date, last_edited_by, odometer, fwd, wheels, seats_stains, transmission, tires,
        seats_rips_tears, carpet, engine, odor, paint, glass, mods, vehicle_features, color, email, phone,
        address, zipcode, appraisal_value, firstName, lastName, notes="", engineType, kbb, e_make,
        e_model, e_modelYear, e_trim, mileage, oilLeaks, brand=null) {
            return this.callApiEndpoint({
                url: `${APPRAISAL_STATIC_API_ENDPOINT_URL}/appraisalTool/record/vehicleCondition/${vin}?created_date=${created_date}&last_edited_by=${last_edited_by}`,
                method:"PUT",
                data: {
                    "vehicle_condition_details": [
                        {"field": "odometer","value": odometer,"status": true},
                        {"field": "t_4wd_awd","value": fwd,"status": true},
                        {"field": "wheels","value": wheels,"status": true},
                        {"field": "seats_stains","value": seats_stains,"status": true},
                        {"field": "transmission","value": transmission,"status": true},
                        {"field": "tires","value": tires,"status": true},
                        {"field": "seats_rips_tears","value": seats_rips_tears,"status": true},
                        {"field": "carpet","value": carpet,"status": true},
                        {"field": "engine","value": engine,"status": true},
                        {"field": "odor","value": odor,"status": true}, 
                        {"field": "paint_body","value": paint,"status": true},
                        {"field": "glass","value": glass,"status": true}, 
                        {"field": "modifications","value": mods,"status": true},
                        {"field":"vehicle_features","value":vehicle_features,"status":true},
                    ],
                    "first_name": firstName, 
                    "last_name": lastName, 
                    "notes": notes,
                    "color": color, 
                    "phone": phone, 
                    "email": email, 
                    "address": address, 
                    "zipcode": zipcode, 
                    "appraisal_value": appraisal_value,
                    "engineType": engineType,
                    "kbb_value": kbb,
                    "e_make": e_make,
                    "e_model": e_model,
                    "e_modelYear": e_modelYear,
                    "e_trim": e_trim,
                    "mileage": mileage,
                    "b_oil_leaks": oilLeaks,
                    "shift_brand": brand
                    // "jd_power_value": jdPower, 
                    // "mmr": mmr,
                },
                headers:{}
            })
    }

    saveShiftOfferToDB(vin, created_date, last_edited_by, odor, b_starting_driving_issues,b_mechanical_issues_warning_lights, t_mechanical_issues_warning_lights,b_loan_lease, t_loan_lease,b_panels_damaged,t_panels_damaged, q_panels_damaged, b_vehicle_accident, t_vehicle_accident,b_hail_dents_on_sides, b_interior_paneling_or_seating_damaged_or_missing_parts, t_interior_paneling_or_seating_damaged_or_missing_parts, b_modifications, modifications,
            color, email, phone, address, zipcode, appraisal_value, firstName, lastName, notes, engineType, kbb, sendinblue_status, worknote_status, worknote_note, event_id, customer_id, shift_id, e_make,
            e_model, e_modelYear, e_trim, mileage) {
            return this.callApiEndpoint({
                url: `${APPRAISAL_STATIC_API_ENDPOINT_URL}/appraisalTool/record/shift/vehicleCondition/${vin}?created_date=${created_date}&last_edited_by=${last_edited_by}`,
                method:"PUT",
                data: {
                    "vehicle_condition_details": [
                        {"field": "mechanical_issues_warning_lights","value": t_mechanical_issues_warning_lights,"status": b_mechanical_issues_warning_lights},
                        {"field": "loan_lease","value": t_loan_lease,"status": b_loan_lease},
                        {"field": "panels_damaged","value": t_panels_damaged,"status": b_panels_damaged},
                        {"field": "vehicle_accident","value": t_vehicle_accident,"status": b_vehicle_accident},
                        {"field": "interior_paneling_or_seating_damaged_or_missing_parts","value": t_interior_paneling_or_seating_damaged_or_missing_parts,"status": b_interior_paneling_or_seating_damaged_or_missing_parts},
                        {"field": "modifications","value": modifications,"status": b_modifications},
                    ],
                    "first_name": firstName, 
                    "last_name": lastName, 
                    "notes":notes,
                    "color": color, 
                    "phone": phone, 
                    "email": email, 
                    "address": address, 
                    "zipcode": zipcode, 
                    "appraisal_value": appraisal_value,
                    "engineType": engineType,
                    "kbb_value": kbb,
                    "sendinblue_status": sendinblue_status,
                    "worknote_status": worknote_status,
                    "worknote_note": worknote_note,
                    "shift_id": shift_id,
                    "event_id": event_id,
                    "customer_id": customer_id,
                    "e_make": e_make,
                    "e_model": e_model,
                    "e_modelYear": e_modelYear,
                    "e_trim": e_trim,
                    "mileage": mileage,
                    "b_starting_driving_issues": b_starting_driving_issues,
                    "q_panels_damaged": q_panels_damaged,
                    "b_hail_dents_on_sides": b_hail_dents_on_sides,
                    "b_odor": odor
                    // "jd_power_value": jdPower, 
                    // "mmr": mmr,
                },
                headers:{}
            })
    }

    sendMailShiftOffer(id,rooftop, brand, vin, year, make, model, mileage, first_name, last_name, email, expiration_date, offer_price, lead_type) {
        return this.callApiEndpoint({
            url: `${SHIFT_SERVICE_ENDPOINT_URL}/sendinblue-send-manual-offer-email-bucket.php`,
            method:"POST",
            data:{
                "id": id,
                "rooftop": rooftop,
                "brand": brand,
                "vin": vin,
                "year": year,   
                "make": make,
                "model": model,
                "mileage": mileage,
                "first_name": first_name,
                "last_name": last_name,
                "email": email,
                "expiration_date": expiration_date,
                "offer_price": offer_price,
                "lead_type": lead_type
            },
            headers:{
                "api_key": SHIFT_SERVICE_API_KEY,
                "service_name": "sib-send-manual-offer"
            }
        })
    }

    sendNoteShiftOffer(id, type, quote, region, brand, rooftop, zipcode, vin, year, make, model, mileage, trim, color, first_name, last_name, phone,
        email, note, expiration_date, offer_price, lead_type) {
        return this.callApiEndpoint({
            url: `${SHIFT_SERVICE_ENDPOINT_URL}/dealersocket-update-worknote-standard.php`,
            method:"POST",
            data:{
                "id": id,
                "type": type,
                "quote_in_cents": quote,
                "region": region,
                "brand": brand,
                "rooftop": rooftop,
                "zip_code": zipcode,
                "vin": vin,
                "year": year,
                "make": make,
                "model": model,
                "mileage": mileage,
                "trim": trim,
                "color": color,
                "first_name": first_name,
                "last_name": last_name,
                "phone": phone,
                "email": email,
                "note":note,
                "expiration_date": expiration_date,
                "offer_price": offer_price,
                "lead_type": lead_type
            },
            headers:{
                "api_key": SHIFT_SERVICE_API_KEY,
                "service_name": "sib-send-manual-offer"
            }
        })
    }

    saveAppraisalValue(vin, created_date, appraisalValue) {
        return this.callApiEndpoint({
            url: `${APPRAISAL_STATIC_API_ENDPOINT_URL}/appraisalTool/record/update/appraisal_value/${vin}?created_date=${created_date}`,
            method:"PUT",
            data: JSON.stringify({
                appraisal_value: appraisalValue,
            }),
            headers:{}
        })
    }

    archiveAppraisalRecord(vin, input_role, role_value, created_date, toggleValue) {
        return this.callApiEndpoint({
            url: `${APPRAISAL_STATIC_API_ENDPOINT_URL}/appraisalTool/record/archive/${vin}/${input_role}/${role_value}?created_date=${created_date}`,
            method:"PUT",
            data: JSON.stringify({
                [input_role == 'vas' ? 'vas_archive' : 'appraiser_archive']: toggleValue,
            }),
            headers:{}
        })  
    }

    fetchAppraisalRecords(input_role, role_value) {
        return this.callApiEndpoint({
            url: `${APPRAISAL_STATIC_API_ENDPOINT_URL}/appraisalTool/record/get/archived-records/${input_role}/${role_value}`,
            method:"GET",
            headers:{}
        })
    }

    saveCommentsData(vin, createdDate, user, commentBody, parentId = 0) {
        return this.callApiEndpoint({
            url:`${APPRAISAL_STATIC_API_ENDPOINT_URL}/appraisalTool/record/save/comment?VIN=${vin}&appraisal_created_date=${createdDate}`,
            method:"POST",
            data: {
                user: user,
                comment: commentBody,
                parent: parentId
            },
            headers:{}
        })
    }

    fetchCommentsData(vin, createdDate) {
        return this.callApiEndpoint({
            url:`${APPRAISAL_STATIC_API_ENDPOINT_URL}/appraisalTool/record/get/comments?vin=${vin}&created_date=${createdDate}`,
            method:"GET",
            headers:{}
        })
    }

    getvAutoLastRefreshTime() {
        return this.callApiEndpoint({
            url: `${APPRAISAL_STATIC_API_ENDPOINT_URL}/appraisalTool/record/get/vautoBatchRun`,
            method:"GET",
            headers:{}
        })
    }

    getStoreBasedPercentage(store) {
        return this.callApiEndpoint({
            url: `${APPRAISAL_STATIC_API_ENDPOINT_URL}/appraisalTool/get/storePercentage/${store}`,
            method:"GET",
            headers:{}
        })
    }
            

}

function getCookie(name) {
    var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
}

function setCookie(name, value, seconds) {
    var d = new Date;
    d.setTime(d.getTime() + seconds*1000);
    document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}

function deleteCookie(name) { setCookie(name, '', -1); }

const RL_Instance = new VehicleManager();
export { RL_Instance, getCookie, setCookie };