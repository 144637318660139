import React from 'react'
import CompFlag from './CompFlag/CompFlag';




export default (props)=>{
    
    var collapsed = props.collapse ? {'padding':0} : {};
    return <div className="olo-component" style={props.style}>
        {props.header && <div className="olo-header">{props.header} {props.compFlag && <CompFlag/>} </div>}
        <div className="olo-body" style={collapsed}>
            {props.children}
        </div>
        {props.footer && <div className="olo-footer">{props.footer}</div>}
    </div>
}