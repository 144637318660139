import React, {useState, useEffect} from 'react';
import {CInput} from '@coreui/react'
import RuneListManager from '../../../../../services/RunListManager'
import Error from '../../../../generic/Error/Error';

export default ({vehicle, currentIndex, updater, channel, className})=>{

    const [notes, setNotes] = useState("");
    const {addError} = Error();

    useEffect( ()=>{
        document.getElementById('buyer_notes').value = vehicle.buyer_notes;

    }, [vehicle.vin])

    const applyNotes = (e)=>{
        vehicle.buyer_notes = e.target.value
        var dq = (vehicle.buyers_dq_timestamp) ? true : false;
        updater(currentIndex,vehicle, false);
        var r = new RuneListManager();
        r.updateVehicleListing(vehicle.vin, {buyer_notes: e.target.value, disqualify: dq}, channel).then((res)=>{
            if(res.error) return addError("Error saving notes: "+ res.message, 500);
        }).catch((er)=>{
            console.log(er);
            addError(er.message, 500);
        })
    }
 
    return(
        <CInput id="buyer_notes" name="buyer_notes" defaultValue={notes} className={className ? className : "flex-grow-1"} onBlur={applyNotes} placeholder="Enter Notes"/>
    )
}