import React from 'react'
import { Text, View, StyleSheet, Link, Font } from '@react-pdf/renderer';
import {DateTime} from 'luxon';
import ITCAvantGardemedium from '../../../assets/fonts/ITC_Avant_Garde_Gothic_Medium.otf';
import ITCAvantGardeBold from '../../../assets/fonts/ITC_Avant_Garde_Gothic_Bold.otf';
import ITCAvantGardeStd from '../../../assets/fonts/ITC_Avant_Garde_Std.ttf';
import ITCAVGLTBook from '../../../assets/fonts/ITC_Avant_Garde_Gothic_LT_Book_Regular.otf';
import { formattedText as formattedAnswer } from '../../../services/utils';

// Register fonts
Font.register(
  { family: 'ITCAVG', src: ITCAvantGardemedium }, 
);

Font.register(
  { family: 'ITCAVGBold', src: ITCAvantGardeBold }
);

Font.register(
  { family: 'ITCAVGStd', src: ITCAvantGardeStd }
);


Font.register(
  { family: 'ITCAVGLTBook', src: ITCAVGLTBook }
);


const styles = StyleSheet.create({
      container: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        // border: '3px dotted red',
        backgroundColor: '#e9e9e9',
        alignItems: 'center',
        // 
        // margin: '1px 0px',
        height: '88vh'
    },
      detailColumn: {
        flexDirection: 'column',
        // flexGrow: 9,
        flex: 0.31,
        // textTransform: 'uppercase',
        // border: '1px solid red',
        padding: '0px 10px',
        justifySelf: 'flex-start',
        alignSelf: 'flex-start',
        width:'100%'
      },
      linkColumn: {
        flexDirection: 'row',
        // flexGrow: 2,
        flex: 0.69,
        alignSelf: 'flex-start',
        justifySelf: 'flex-start',
        // border: '1px solid yellow',
        padding: '2px 10px',
        width:'100%',
        // fontSize: '11px'
      },
      name: {
       
        fontSize: 10,
        margin: '12px 0px',
        // border: '1px dotted green'
        // fontWeight: 'bold'
        // fontFamily: 'ITCAVG',
      },
      detailColumnHeader: {
        width: '100%',
        fontFamily: 'ITCAVGBold',
        margin: '2px 0px'              
      },
    detailColumnText: {fontSize: 10, fontFamily: 'ITCAVGLTBook', margin:'6px 5px 10px 10px', padding: '2px 6px 8px 0px'},      
    assesmentContainer: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      // backgroundColor: 'red',
      alignItems: 'center',
      margin: '0px 0px',      
    },
  });

export const SurveyDetails = ({ item }) => {
  return (
    <>
      <View style={styles.container}>
        <View style={styles.detailColumn}>
          <View
            style={{
              height: "100%",
              flex: "1",
              justifyContent: "flex-start",
              flexDirection: "column",
              padding: "4px 10px",
              marginTop: "15px",
            }}
          >
            <Text style={styles.name}>
              <Text style={styles.detailColumnHeader}>
                {"1."}{" "}
                {
                  "Does the vehicle have any issues that prevent it from starting or driving?"
                }
              </Text>
            </Text>
            <Text style={styles.detailColumnText}>
              {item.b_starting_driving_issues
                ? "Yes"
                : item.b_starting_driving_issues === null
                ? "N.A"
                : "No"}
            </Text>

            <Text style={styles.name}>
              <Text style={styles.detailColumnHeader}>
                {"2."}{" "}
                {
                  "Does the vehicle have any mechanical issues or persistent dashboard warning lights?"
                }
              </Text>
            </Text>
            <Text style={styles.detailColumnText}>
              {item.b_mechanical_issues_warning_lights
                ? `Yes | Issues : ${formattedAnswer(
                    item.t_mechanical_issues_warning_lights
                  )}`
                : item.b_mechanical_issues_warning_lights === null
                ? "N.A"
                : "No"}
            </Text>

            <Text style={styles.name}>
              <Text style={styles.detailColumnHeader}>
                {"3."} {"Does the vehicle have any persistent odors?"}
              </Text>
            </Text>
            <Text style={styles.detailColumnText}>
              {item.b_odor ? "Yes" : item.b_odor == null ? "N.A" : "No"}
            </Text>

            <Text style={styles.name}>
              <Text style={styles.detailColumnHeader}>
                {"4."} {"Does the vehicle have a loan or lease?"}
              </Text>
            </Text>
            <Text style={styles.detailColumnText}>
              {item.b_loan_lease
                ? `Yes | Type : ${item.t_loan_lease}`
                : item.b_loan_lease === null
                ? "N.A"
                : "No"}
            </Text>

            <Text style={styles.name}>
              <Text style={styles.detailColumnHeader}>
                {"5."} {"Does the vehicle have any exterior panel damaged?"}
              </Text>
            </Text>
            <Text style={styles.detailColumnText}>
              {item.b_panels_damaged
                ? `Yes, ${item.q_panels_damaged} panels damaged!
                            Damages:  ${formattedAnswer(
                              item.t_panels_damaged
                            )} `
                : item.b_panels_damaged === null
                ? "N.A"
                : "No"}
            </Text>

            <Text style={styles.name}>
              <Text style={styles.detailColumnHeader}>
                {"6."} {"Has the vehicle been in an accident?"}
              </Text>
            </Text>
            <Text style={styles.detailColumnText}>
              {item.b_vehicle_accident
                ? `Yes, ${item.t_vehicle_accident} times`
                : item.b_vehicle_accident === null
                ? "N.A"
                : "No"}
            </Text>

            <Text style={styles.name}>
              <Text style={styles.detailColumnHeader}>
                {"7."} {"Does the vehicle have hail or dents across the sides?"}
              </Text>
            </Text>
            <Text style={styles.detailColumnText}>
              {item.b_hail_dents_on_sides
                ? "Yes"
                : item.b_hail_dents_on_sides === null
                ? "N.A"
                : "No"}
            </Text>

            <Text style={styles.name}>
              <Text style={styles.detailColumnHeader}>
                {"8."}{" "}
                {
                  "Is the interior paneling or seating damaged or missing parts?"
                }
              </Text>
            </Text>
            <Text style={styles.detailColumnText}>
              {item.b_interior_paneling_or_seating_damaged_or_missing_parts
                ? `Yes
                            Damages : ${formattedAnswer(
                              item.t_interior_paneling_or_seating_damaged_or_missing_parts
                            )}`
                : item.b_interior_paneling_or_seating_damaged_or_missing_parts ===
                  null
                ? "N.A"
                : "No"}
            </Text>
            <Text style={styles.name}>
              <Text style={styles.detailColumnHeader}>
                {"9."} {"Does the vehicle have any after market modifications?"}
              </Text>
            </Text>
            <Text style={styles.detailColumnText}>
              {item.b_modifications ? (
                <>
                  {`Yes
                  Modifications: `}
                  <Text style={styles.detailColumnText}>
                    {formattedAnswer(item.modifications)}
                  </Text>
                </>
              ) : item.b_modifications === null ? (
                "N.A"
              ) : (
                "No"
              )}
            </Text>
          </View>
        </View>
      </View>
    </>
  );
}
