import React, { useState, useEffect } from "react";
import Multiselect from 'multiselect-react-dropdown';

export default (props) => {
  const [selectedValues, setSelectedValues] = useState(false);

  useEffect( ()=>{
    figureOutSelectedValues();      
    },[props.localFilters]);

  const options = [
    {name: "No Accident or Damage Reported", id: 0, key: "0"},
    {name: "Odometer Problem", id: 1, key: "1"},
    {name: "Minor Damage", id: 2, key: "2"},
    {name: "Fire Damage", id: 3, key: "3"},
    {name: "Accident Reported", id: 4, key: "4"},
    {name: "Water Damage", id: 5, key: "5"},
    {name: "Odometer Rollback", id: 6, key: "6"},
    {name: "Very Minor Damage", id: 7, key: "7"},
    {name: "Damage Reported", id: 8, key: "8"},
    {name: "Accident/Minor Damage", id: 9, key: "9"},
    {name: "Major Damage", id: 10, key: "10"},
    {name: "Hail Damage", id: 11, key: "11"},
    {name: "Manufacturer Buyback", id: 12, key: "12"},
    {name: "Accident/No Damage", id: 13, key: "13"},
    {name: "Vandalism Damage", id: 14, key: "14"},
    {name: "Branded Title", id: 15, key: "15"},
  ];

  const getItem = (name) => {
    const rawName = name.replace("'", "");
    for(var i =0; i< options.length; i++) {
      if (options[i].name === rawName) return options[i];
    }
  }

  const figureOutSelectedValues = () => {
    var loc = { ...props.localFilters };
    var carfax_statuses = loc['carfax_status'];
    console.log("figureOutSelectedValues, carfax_statuses ", carfax_statuses);

    if (carfax_statuses && carfax_statuses.length > 0) {
      var allSelected = [];
      var arr = carfax_statuses.split(",");
      for(var i=0; i < arr.length; i++) {
        allSelected.push(getItem(arr[i]));
      }
      setSelectedValues(allSelected);
    } else {
      setSelectedValues([]);
    }

  }

  const syncUpSelection = (selectedList) => {
    var loc = { ...props.localFilters };
    if (selectedList && selectedList.length > 0) {
        var carfax_statuses = "";
        var comma = "";
        for (var i=0;i < selectedList.length; i++) {
          carfax_statuses = carfax_statuses + comma + selectedList[i].name;
          comma=",";
        }
        loc['carfax_status'] = carfax_statuses;
        console.log("set carfax_status", carfax_statuses);
        props.setLocalFilters(loc)  
    } else {
      loc['carfax_status'] = null
      props.setLocalFilters(loc)  

    }

  }

  const onSelect = (selectedList, selectedItem) => {
    console.log("selectedList", selectedList);
    syncUpSelection(selectedList);
  }

  const onRemove = (selectedList, removedItem) => {
    console.log("selectedList", selectedList);
    syncUpSelection(selectedList);
  }

  const css = {
    option: {
      fontSize: "16px"
    },
    inputField: {
      height: "24px",
      fontSize: "16px"
    }
  }

  return (
    <Multiselect 
      options={options}
      selectedValues={selectedValues}
      onSelect = {onSelect}
      onRemove = {onRemove}
      showCheckbox={true}
      placeholder="Carfax Status"
      displayValue="name"
      hidePlaceholder={true}
      showArrow={true}
      style={css}
    />
  );
};
