

export default function OLOSalesHistoryTree(){

}

OLOSalesHistoryTree.prototype = {

    formatResults: function(items){
    
        var mappings = {
        }

        items.forEach((item)=>{
            mappings[item.modelyear] = mappings[item.modelyear] || {count:0, prices:[], children:{}};
            mappings[item.modelyear]['count'] +=1;
            mappings[item.modelyear]['prices'].push(item.cash_price)
            var band = this.findMileageBand(item.mileage)
            mappings[item.modelyear]['children'] = this.addChild(mappings[item.modelyear]['children'],band)
            mappings[item.modelyear]['children'][band]['count'] +=1
            mappings[item.modelyear]['children'][band]['prices'].push(item.cash_price)
            mappings[item.modelyear]['children'][band]['children'].push(item)
        })  
        return mappings;
    },

    findMileageBand: function(mileage){
        if(mileage < 10000) return "Under 10K";
        if(mileage < 20000) return "10K-20K";
        if(mileage < 30000) return "20K-30K";
        if(mileage < 40000) return "30K-40K";
        return "40K+"
    },

    addChild:function(base, child){
        if(!base[child]){
            base[child] = {
                count:0,
                prices:[],
                children:[]
            }
        }
        return base
    },

    buildTableRows: function(mapping,tree){
        var rows = [];
        var keys = ['band', 'store'];
        var depthKey = 'year'
        var mapDepth = mapping

        if(mapping.length < 1) return [];

        tree.forEach( (item)=>{
            depthKey = keys.shift();
            mapDepth = mapDepth[item].children
        })

        if(Array.isArray(mapDepth)) return mapDepth;
        
        var listings = [];
        Object.keys(mapDepth).forEach( (item)=>{
            var listing = {}
            listing[depthKey] = item
            listing['count'] = mapDepth[item].count
            listing['price'] = this.getAverage(mapDepth[item].prices)
            listings.push(listing)
        })
        return listings
    },

    getAverage: function(arr){
        var sum = 0;
        var denominator = 0
        arr.forEach((i)=>{
            //if theres a 0 value, dont include it in the average
            if(i > 0){
                sum += i
                denominator++
            }
          
        })
        return (denominator > 0) ?  Math.round(sum / denominator) : 0;
    },


    setFields: function(tree){
        return this.fieldMap[tree.length]
    },

    fieldMap: [
        [
            {key:"year", label:"Model Year"},
            {key:"count", label:"Vehicle Count"},
            {key:"price", label:"Average Price"}
        ],
        [
            {key:"band", label:"Mileage Band"},
            {key:"count", label:"Vehicle Count"},
            {key:"price", label:"Average Price"}
        ],
        [
            'store', 
            'mileage', 
            'cash_price',
            {label:"Sale Date", key:'sold_date'}, 
            {label:"Warranty", key:"buyers_guide"}, 
            {key:"extcolor", label:"Exterior"}
        ]
    ]

}