export const paintOptions = [
    {label: 'Scratch - 1 Inch',	value: 'Scratch - 1 Inch'},
    {label: 'Scratch - 2 Inches',	value: 'Scratch - 2 Inches'},
    {label: 'Scratch - 3+ Inches',	value: 'Scratch - 3+ Inches'},
    {label: 'Dent - 1 Inch',	value: 'Dent - 1 Inch'},
    {label: 'Dent - 2 Inches', value: 'Dent - 2 Inches'},
    {label: 'Dent - 3+ Inches', value: 'Dent - 3+ Inches'}
];

 export const modificationItem = [
    { label: 'Tires or Rims', value: 'Tires or Rims' },
    {
      label: ' Stereo or Backup Camera',
      value: ' Stereo or Backup Camera'
    },
    {
      label: ' Exhaust or Intake Meets Requirements',
      value: ' Exhaust or Intake Meets Requirements'
    },
    {
      label: ' Exhaust or Intake Does Not Meets Requirements',
      value: ' Exhaust or Intake Does Not Meets Requirements'
    },
    {
      label: ' Lifted Lowered or Leveled Suspension',
      value: ' Lifted Lowered or Leveled Suspension'
    },
    { label: ' Performance Tuning', value: ' Performance Tuning' },
    { label: ' Trailer Hitch', value: ' Trailer Hitch' },
    { label: ' Tinted Windows', value: ' Tinted Windows' },
    {
      label: ' Headlights or Taillights',
      value: ' Headlights or Taillights'
    },
    {
      label: ' Running Boards or Side Steps',
      value: ' Running Boards or Side Steps'
    },
    { label: ' Bumper or Grille', value: ' Bumper or Grille' },
    {
      label: ' Roof Ladder or HeadacheRack',
      value: ' Roof Ladder or HeadacheRack'
    },
    { label: ' Spoiler', value: ' Spoiler' },
    { label: ' Leather Seats', value: ' Leather Seats' },
    {
      label: ' Bed Liner Bed Cover or Camper Shell',
      value: ' Bed Liner Bed Cover or Camper Shell'
    },
    { label: ' Remote Start', value: ' Remote Start' },
    { label: ' Upfitted or Camper', value: ' Upfitted or Camper' },
    { label: ' Wrap or Repaint ', value: ' Wrap or Repaint ' }
  ];

export const interiorDamagesItem = [
    {label: 'Small Parts',	value: 'Small Parts'},
    {label: 'Large Parts',	value: 'Large parts'},
    {label: 'Safety Related Items',	value: 'Safety Related Items'}
];

export const exteriorDamagesItem = [
    {label: 'Dents',	value: 'Dents'},
    {label: 'Dings',	value: 'Dings'},
    {label: 'Major Body',	value: 'Major Body'},
    {label: 'Minor Body',	value: 'Minor Body'},
    {label: 'Fading', value: 'Fading'},
    {label: 'Scratches', value: 'Scratches'},
];

export const mechanicalIssuesItem = [
    {label: 'ABS',	value: 'Abs'},
    {label: 'Airbag',	value: 'Airbag'},
    {label: 'Check Engine Constant',	value: 'Check Engine constant'},
    {label: 'Check Engine Blinking',	value: 'Check Engine blinking'},
    {label: 'Fluid Coolant or Ac', value: 'Fluid Coolant or AC'},
    {label: 'Tire Pressure', value: 'Tire Pressure'}
];

export const glassOptions = [
    {label: 'Chip - 1 Inch'	,	value:'Chip - 1 Inch'},
    {label: 'Chip - 2 Inches'	,	value:'Chip - 2 Inches'},
    {label: 'Chip - 3+ Inches'	,	value:'Chip - 3+ Inches'},
    {label: 'Crack - 1 Inch'	,	value:'Crack - 1 Inch'},
    {label: 'Crack - 2 Inches'	,	value:'Crack - 2 Inches'},
    {label: 'Crack - 3+ Inches'	,	value:'Crack - 3+ Inches'},
    {label: 'Missing - 1 Inch'	,	value:'Missing - 1 Inch'},
    {label: 'Missing - 2 Inches'	,	value:'Missing - 2 Inches'},
    {label: 'Missing - 3+ Inches'	,	value:'Missing - 3+ Inches'},
];

export const vehicleFeaturesOptions = [
    {label: 'Leather Seats ' , value: 'Leather Seats '},
    {label: 'Cloth Seats',value: 'Cloth Seats'},
    {label: 'Suede Seats ',value: 'Suede Seats '},
    {label: 'Navigation ',value:'Navigation '},
    {label:'Sunroof ',value:'Sunroof '},
    {label: 'Panoramic Roof ',value:'Panoramic Roof '},
    {label:'Power Locks ',value:'Power Locks '},
    {label: 'Power Windows ',value:'Power Windows '},
    {label:'Apple Car Play ',value:'Apple Car Play '},
    {label: 'Turbo Charged Engine ',value:'Turbo Charged Engine '},
    {label:'Run Flat Tires ',value:'Run Flat Tires '},
    {label: 'Automated Cruise Control ',value:'Automated Cruise Control '},
    {label:'Cruise Control ',value:'Cruise Control '},
    {label: 'Hardtop Convertible ',value:'Hardtop Convertible '},
    {label:'Softtop Convertible ',value:'Softtop Convertible '},
    {label: 'Traction Control ',value:'Traction Control '},
    {label:'Alloy Wheels ',value:'Alloy Wheels '},
    {label: '20in Wheels ',value:'20in Wheels '},
    {label:'Memory Seats',value:'Memory Seats'},
    {label: 'Bluetooth Technology ',value:'Bluetooth Technology '},
    {label:'Premium Sound ',value:'Premium Sound '},
    {label: 'Premium Package',value:'Premium Package'},
    {label:'Sport Package ',value:'Sport Package '},
    {label: 'Luxury Package',value:'Luxury Package'},
    {label:'Parking Assist ',value:'Parking Assist '}
];