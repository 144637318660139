import React, {useState, useEffect} from 'react';
import {CSelect} from '@coreui/react';
import {useLocation} from 'react-router-dom';
import RunListManager from '../../../services/RunListManager';
import {createBrowserHistory} from 'history';

export default (props) => {
    const location = useLocation();
    const [lanes, setLanes] = useState([]);
    const [dropdownTitle, setDropdownTitle] = useState("Lane (Previewed/Total) | Buyer");
    const history = createBrowserHistory({forceRefresh:true});

    useEffect( ()=>{
        var filterString = localStorage.getItem('laneFilters');
        var savedFilters = (filterString) ? JSON.parse(filterString) : false

        var RL = new RunListManager();

        let paths = location.pathname.split('/');
        let newRunListId = paths[3];
        let newChannel = paths[2];
        RL.getRunListLanes(newRunListId, savedFilters, newChannel).then( (data)=>{
            if(data.error) return;
            //console.log("lanes=", data.data.lanes);
            var oLanes = data.data.lanes;
            var lst = [];

            var maxLengthLane = 0;
            var maxLengthBuyer = 0;

            for(var i=0; i < oLanes.length; i++) {
                var lane = oLanes[i];
                lane.url = getUrl(lane, newChannel, newRunListId);
                if (newChannel==="SIMULCAST") {
                    lane.display = lane.auction_location + ' ' + parseInt(lane.auction_lane) + ' (' + lane.reviewed_vehicles + "/" + lane.vehicles + ') ';
                } else {
                    var startTime = lane.auctionStartDate + '+00:00';
                    var d = new Date(startTime);
                    var startTime = d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

                    lane.display = lane.auction_location + ' ' + lane.auction_lane + ' - ' + startTime + ' (' + lane.reviewed_vehicles + "/" + lane.vehicles + ') ';
                }
                if (lane.display.length > maxLengthLane) maxLengthLane = lane.display.length;

            }

            if (maxLengthBuyer===0) {
                maxLengthBuyer = 7;
            }

            var ddTitle = "Lane (Previewed/Total Vehicles)" + addSpaces(maxLengthLane-33) + " - Assigned Buyer";
            setDropdownTitle(ddTitle);

            for(var i=0; i < oLanes.length; i++) {
                var lane = oLanes[i];
                if (lane.buyer_name.trim().length > 0) {
                    lane.display += ' - ' + lane.buyer_name
                }
                lst.push(lane);
            }

            if (newChannel==="SIMULCAST") {
                lst.sort(function (a, b) {
                    return a.auction_location.localeCompare(b.auction_location) || parseInt(a.auction_lane) - parseInt(b.auction_lane);
                });
            } else {
                lst.sort(function (a, b) {
                    return a.auction_location.localeCompare(b.auction_location) || a.auction_lane.localeCompare(b.auction_lane);
                });                
            }
          setLanes(lst);
        }).catch( (e)=>{
            console.log(e);
        })

    }, [location])

    const addSpaces =(numSpace)=> {
        var spaces = "";
        for (var i = 0; i<numSpace; i++) {
            //spaces += "&nbsp;";
            spaces += '\u00A0';
        }
        return spaces;
    }
    const getUrl =(selectedLane, channel, runListId)=> {
      var link = `/runlist/${channel}/${runListId}/review?auction_date=${selectedLane.auction_date}&auctionLocation=${selectedLane.auction_location}&auction_lane=${selectedLane.auction_lane}&sortBy=make_ASC,model_ASC,year_DESC,CRScore_DESC`

      link += (channel == 'LiveBlock') ? `&start_time=${selectedLane.auctionStartDate}` : '';

      var filterString = localStorage.getItem('laneFilters');
      var savedFilters = (filterString) ? JSON.parse(filterString) : false
      if (savedFilters) {
        for(var key in savedFilters){
          link += (savedFilters[key]) ?  '&'+key+'='+savedFilters[key] : '';
        }
      }
      return link;
    }

    const handleChange = (item)=> {
        console.log("handle change", item);
    }
    return(
    <CSelect onChange={
        (e)=>{ 
            var item = e.target.options[e.target.selectedIndex].value;
            console.log("selected", item);
            if (item.length > 10)  history.replace(item);    

        }}>
    <option value="0" key="0">Switch Lane</option>
    <option disabled key="-1">{dropdownTitle}</option>

    {lanes && lanes.map( (m, k)=>{
        return <option value={m.url} key={k}>{m.display}</option>
    })}
    </CSelect>

    )
}