import React , {useState, useEffect} from 'react'
import {
    CDropdownToggle,
    CDropdown,
    CDropdownMenu,
    CDropdownItem,
  } from "@coreui/react";

//   Assumptions:
// If year 2019 - 2023 and is also <= 60K in mileage, then it is retail
// If vehicle is year 2017 or 2018 and is also <= 100K in mileage, then it is retail
// If mileage is > 100K miles, then it is wholesale
// If year is older then 2017, then it is wholesale.

// if Retail then ( year = 2017, 2018 and mileage <= 100000) || (year = 2019, 2020, 2021, 2022, 2023 and mileage <= 60000)
// If wholesale then (year > 2017) || (mileage> 100000) 

const dropdownOptions = [
    {
       label: "Vehicle Type",
       value: ''
    },
    {
      label: "Wholesale",
      value: "Wholesale"
    },
    {
      label: "Retail",
      value: "Retail"
    }
  ];

export default (props)=>{

    const [vehicleTypeSelection, setvehicleTypeSelection] = useState('')

    useEffect(() => {
        var loc = { ...props.localFilters };
        if(loc["vehicle_type"] === null || loc["vehicle_type"] === '') setvehicleTypeSelection(dropdownOptions[0].label)
    })

   

    const changeFunc = (e) => {
        var val = e.target.innerText;
        setvehicleTypeSelection(val);
      let vehicle_type = dropdownOptions.filter((item) => {
        if (item.label === val) return item;
      });
      vehicle_type = vehicle_type[0].value
      var loc = { ...props.localFilters };
      loc["vehicle_type"] = vehicle_type;
      props.setLocalFilters(loc);
    };

    
    return(
        <CDropdown>
        <CDropdownToggle
          caret
          color="white"
          style={{
            border: "1px solid lightgray",
            padding: "5.25px 10.5px",
            height: "35.88px",
            maxWidth: "125px",
            color: "gray",
            fontSize: "12.5px",
            fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
            fontWeight: "700",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {vehicleTypeSelection === '' ? 'Vehicle Type' : vehicleTypeSelection}
        </CDropdownToggle>
        <CDropdownMenu onClick={(e) => changeFunc(e)}>
          {dropdownOptions.map((item) => (
            <CDropdownItem>{item.label}</CDropdownItem>
          ))}
        </CDropdownMenu>
      </CDropdown>
       
    )
}