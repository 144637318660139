import React, {useState, useEffect} from 'react';
import { CCard, CCardHeader, CCardBody, CDataTable, CButton, CBadge} from '@coreui/react';
import RunListManager from '../../../../services/RunListManager';
import FormattedNumber from '../../../FormattedNumber/FormattedNumber';
import {Form, Loader} from 'semantic-ui-react';
import CompetitorTree from './CompetitorTree';
import Error from '../../../generic/Error/Error';

export default ({vin , vehicle, channel})=>{

    const [currentInventory, setCurrentInventory] = useState({});
    const [tree, setTree] = useState([])
    const [loading, setLoading] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const {addError} = Error();
    var cTree = new CompetitorTree();

    //THIS EFFECT LOADS THE NEW DATA FOR EACH VEHICLE
    useEffect( ()=>{
        var ignore = false;
        var RL = new RunListManager();
        setLoading(true);
        setTree([])
        setCurrentInventory([]);
        RL.getStaticVehicleDetail(vin, "comps", channel).then( (data)=>{
           if(!ignore){
             //  if(data.error) return addError(data.message, 500);
               var compData = cTree.formatResults(data.data.comps);
               setCurrentInventory(compData);
               setLoading(false)
           }
        }).catch( (e)=>{
            console.log(e); 
         //   addError(e.message, 500);
            setLoading(false)})

        return ()=>{ignore =true;}

    }, [vin, channel])

    // THIS EFFECT DRILLS DOWN INTO THE TREE TO THE DEEPEST DEPTH 
    // THAT MATCHES THE CURRENT VEHICLE BEING REVIEWED 
    // FOR EXAMPLE, FOR A 2018 WITH 23000 MILES, IT WILL DRILL INTO 2018, THEN 20-30k mileage band
    useEffect(()=>{
        var t = []
        var band = cTree.findMileageBand(vehicle.mileage); 
        if(currentInventory[band] != undefined){
            t.push(band)
        }
        if(t.length > 0){
           if(currentInventory[band]['children'][vehicle.year] != undefined){
               t.push(vehicle.year)
           }
        }
        setTree(t)
    }, [currentInventory, vehicle.mileage, vehicle.year])

    const toggleCollapsed = (e)=>{e.preventDefault();setCollapsed(!collapsed)}

    const addToTree = (val)=>{

        var newTree = [...tree];
        newTree.push(val)
        setTree(newTree)
    }

    const sliceTree = (index)=>{
        var newTree = [...tree]
        newTree = newTree.slice(0,index)
        setTree(newTree)
    }
    
    return(
        <>
                <div className="d-flex pb-3">
                    <CBadge onClick={()=>{sliceTree(0)}} size="sm" color="secondary">All</CBadge>
                    {tree.map((item, index)=>{
                        return <CBadge onClick={()=>{sliceTree(1)}} size="sm" color="secondary" className="ml-1" key={index}>{item}</CBadge>
                    })}
                </div>

                <CDataTable
                    fields={cTree.setFields(tree)}
                    items={cTree.buildTableRows(currentInventory, tree)}
                    loading={loading}
                    sorter
                    pagination
                    itemsPerPage={10}
                    sorterValue={{ column: 'current_list_price', asc: 'true' }}
                    scopedSlots={{
                        'mileage': (i)=>{
                            return <td><FormattedNumber value={i.mileage}/></td>
                        },

                        'current_list_price': (item)=>{
                            return <td><FormattedNumber value={item.current_list_price} price={true}/></td>
                        },
                        'price': (item)=>{
                            if(isNaN(item.price)) return <td>-</td>;
                            return <td><FormattedNumber value={item.price} price={true}/></td>
                        },

                        'band': (item)=>{
                            return <td><a className="olo_link" onClick={()=>{addToTree(item.band)}}>{item.band}</a></td>
                        },
                        'year': (item)=>{
                            return <td><a className="olo_link" onClick={()=>{addToTree(item.year)}}>{item.year}</a></td>
                        },
                        'competitor_vin': (item)=>{
                            return <td>
                                {item.competitor_vin}
                                <div><small>{item.competitorModelYear} / <FormattedNumber value={item.mileage}/> miles</small></div>
                            </td>
                        }
                    }}
                />
        </>
    )
}
