import React from 'react';
import Error from '../Error/Error';
import {CModal, CModalHeader, CModalBody, CModalFooter, CButton} from '@coreui/react';

export default ()=>{
    const {error, removeError} = Error();

    const handleClick = ()=>{
        // deleteCookie('AD_idToken');
        removeError();
        if (error.message == 'Network Error'){
            window.location.reload(false);
        }
    }

    function setCookie(name, value, seconds) {
        var d = new Date;
        d.setTime(d.getTime() + seconds*1000);
        document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
    }
    
    function deleteCookie(name) { setCookie(name, '', -1); }

    return(
        <CModal show={!!error} onClose={handleClick}>
            <CModalHeader closeButton>API Error</CModalHeader>
            <CModalBody>
                {error && error.message}
            </CModalBody>
            <CModalFooter>
                <CButton color="secondary" onClick={handleClick}>Close</CButton>
            </CModalFooter>
        </CModal>
    )
}