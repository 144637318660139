import { PDFViewer } from '@react-pdf/renderer'
import React from 'react'
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { SurveyDetails } from './SurveyDetails';
import ITCAvantGardeBold from '../../../assets/fonts/ITC_Avant_Garde_Gothic_Bold.otf';
import ITCAvantGardeStd from '../../../assets/fonts/ITC_Avant_Garde_Std.ttf';
import ITCAvantGardemedium from '../../../assets/fonts/ITC_Avant_Garde_Gothic_Medium.otf';
import { formatPhoneNumber } from "../../../services/utils";


Font.register(
  { family: 'ITCAVG', src: ITCAvantGardemedium }, 
);

Font.register(
    { family: 'ITCAVGBold', src: ITCAvantGardeBold }
  );

  Font.register(
    { family: 'ITCAVGStd', src: ITCAvantGardeStd }
  );
  
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'col',
      backgroundColor: 'white',
      width: '500'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    },
    headerBackground: {
      flex: 3, 
    }
  
  });


const Header = ({item}) => (
    <View 
        style={{flexDirection: 'column', height: '100px', position: 'relative', backgroundColor: '#4B0082'}}
    >
      <View style={{
              height: '90%',
              width:'50%',
              position: 'absolute',
              marginLeft: '30px',
              marginRight: 'auto',
              left: 0,
              top: 0,
              textAlign: 'left',
              top: '5%',
              justifyContent: 'center'
            }}>
          <Text 
              style={{fontFamily: 'ITCAVGBold', 
                fontSize: '10px', color: 'white', marginTop: '0px', padding: '5px'
              }}>
              
              {`Vehicle Online Survey - ${item.vin}`}
             
              
            </Text>
          <Text 
              style={{fontFamily: 'ITCAVGBold', 
                fontSize: '10px', color: 'white', marginTop: '0px', padding: '5px'
              }}>
              
              {`${item.e_make} ${item.e_model} ${item.e_modelYear} ${item.e_trim}`}
             
              
            </Text>
          <Text 
              style={{fontFamily: 'ITCAVGBold', 
                fontSize: '10px', color: 'white', marginTop: '0px', padding: '5px'
              }}>
              
              {`Mileage: ${item.mileage}`}
             
              
            </Text>
          <Text 
              style={{fontFamily: 'ITCAVGBold', 
                fontSize: '10px', color: 'white', marginTop: '0px', padding: '5px'
              }}>
              
              {`Color: ${item.color}`}
             
              
            </Text>
        </View>
        <View style={{
              height: '90%',
              width:'50%',
              position: 'absolute',
              marginRight: 'auto',
              top: 0,
              right: 0,
              textAlign: 'left',
              top: '5%',
            }}>
          <Text 
              style={{fontFamily: 'ITCAVGBold', 
                fontSize: '10px', color: 'white', marginTop: '0px', padding: '5px'
              }}>
              {`Customer's Name: ${item.first_name} ${item.last_name}`}
            </Text>
          <Text 
              style={{fontFamily: 'ITCAVGBold', 
                fontSize: '10px', color: 'white', marginTop: '0px', padding: '5px'
              }}>
              {`Email: ${item.email}`}
            </Text>
          <Text 
              style={{fontFamily: 'ITCAVGBold', 
                fontSize: '10px', color: 'white', marginTop: '0px', padding: '5px'
              }}>
              {`Phone: ${formatPhoneNumber(item.phone)}`}
            </Text>
          
        </View>
    </View>
);



export const DocFormat = ({item}) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <Header item = {item}/>
            <SurveyDetails item={item} />

        </Page>
    </Document>
    
);


export const SurveyContent = ({item}) => 
   (
    <div>
        <PDFViewer showToolbar={false} style={{ width: '100%', height: '75vh', backgroundColor: 'white'}} >
            <DocFormat item={item} />
        </PDFViewer>
    </div>
  )

