import React, {useState} from 'react'
import {CCard, CCardHeader, CCardBody, CButton} from '@coreui/react'
import DatePicker from "react-datepicker";
import {Loader} from 'semantic-ui-react';
import RunListManager from '../../../services/RunListManager';
import Error from '../../generic/Error/Error';
import "react-datepicker/dist/react-datepicker.css";


export default ()=>{
    var today = new Date()
    const [loading, setLoading] = useState(null);
    const [datePicker, setDatePicker] = useState(new Date(today.getTime() - 86400000))
    const {addError} = Error();
    
    const executeDownload = ()=>{
        setLoading(true)
        var RL = new RunListManager();
        const padNum = (i)=>{ return (i < 10) ? "0"+i : i;}
        var reportDate = datePicker.getFullYear() + "-"+padNum(datePicker.getMonth()+1) +"-"+padNum(datePicker.getDate());

        RL.downloadBuyersReport(reportDate).then( (data)=>{
            if(data.error) return addError(data.message, 500);
            saveData(data.data, "manheim_simulcast_"+reportDate+".csv")
            setLoading(false);
        }).catch((e)=>{
            console.log(e);
            addError(e.message, 500);
            setLoading(false);})
    }
    
    const saveData = (function () {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        return function (data, fileName) {
            const blob = new Blob([data], {type: "octet/stream"}),
                url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
        };
    }());

    return(<CCard>
        <CCardHeader>Manheim Simulcast Report Generator</CCardHeader>
        <CCardBody>
            <p>Use the field below to download the Manheim Simulcast report for a specific auction day.</p>
            <DatePicker selected={datePicker} onChange={date=> setDatePicker(date)} wrapperClassName="runlist-datepicker-wrapper"  />
            <br/><br/>
            <CButton color="info" size="large" onClick={executeDownload}>Download Report</CButton>
            &nbsp;&nbsp;{loading && <Loader inline={true} active />}
        </CCardBody>
    </CCard>)

    
}