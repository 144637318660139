import React, {useState, useEffect} from 'react'
import DataField from '../DataField/DataField'



export default ({yipitMarketPrice, yipitSampleSize})=>{
    const addCommas = (num)=>{
        var formattedNum = num.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return formattedNum
    }

    return<>
        {(yipitMarketPrice > 0) && <div className="bsrpHover">
            <DataField 
                value={yipitMarketPrice} 
                label="Yipit Market Price" 
                number={true} 
                price={true} border={true}
            />
            <div className="bsrpDetails">
                <h4>Market Price Details</h4>
                <b>90% of Market Price:</b> ${addCommas(yipitMarketPrice*0.90)}<br/>
                <b>95% of Market Price:</b> ${addCommas(yipitMarketPrice*0.95)}<br/>
                <br/>
                <p>This Market Price is based on {yipitSampleSize} sample{yipitSampleSize > 1 && 's'}  with the same Make/Model/Year/Trim. This does not take into account mileage.</p>
            </div>
        </div> }
        {!yipitMarketPrice && <DataField value="N/A" label="Yipit Market Price" number={false} price={false} border={true}/> }
    </>
    
}
