import React, { useState, useEffect } from 'react'
import {
    CDropdownToggle,
    CDropdown,
    CDropdownMenu,
    CDropdownItem,
  } from "@coreui/react";

const dropdownOptions = [
    {
       label: "Select Miles",
       indication:null,
       value: ''
    },
    {
      label: "60,000 and under",
      indication: 'max',
      value: "60000",
    },
    {
      label: "60,000 and over",
      indication: 'min',
      value: "60000",
    },
  ];

export default (props)=>{

    const [mileageSelection, setMileageSelection] = useState('')

    useEffect(() => {
      var loc = { ...props.localFilters };
      if((loc["min_mileage"] === null || loc["min_mileage"] === '') && (loc["max_mileage"] === null || loc["max_mileage"] === '')) setMileageSelection('')
  })

    const changeFunc = (e) => {
        var val = e.target.innerText;
        setMileageSelection(val);
      let mileage = dropdownOptions.filter((item) => {
        if (item.label === val) return item;
      });
      var loc = { ...props.localFilters };
      let mileageVal;
      if(mileage[0].indication === 'max'){
        mileageVal = mileage[0].value
        loc["max_mileage"] = mileageVal;
        loc["min_mileage"] = null;
      } else if(mileage[0].indication === 'min'){
        mileageVal = mileage[0].value
        loc["min_mileage"] = mileageVal;
        loc["max_mileage"] = null;
      } else{
        loc["max_mileage"] = null;
        loc["min_mileage"] = null;
      }
      props.setLocalFilters(loc);
    };
    
    return(
        <CDropdown>
      <CDropdownToggle
        caret
        color="white"
        style={{
          border: "1px solid lightgray",
          padding: "5.25px 10.5px",
          height: "35.88px",
          maxWidth: "125px",
          color: "gray",
          fontSize: "12.5px",
          fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
          fontWeight: "700",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {mileageSelection === '' ? 'Select Miles' : mileageSelection}
      </CDropdownToggle>
      <CDropdownMenu onClick={(e) => changeFunc(e)}>
        {dropdownOptions.map((item) => (
          <CDropdownItem>{item.label}</CDropdownItem>
        ))}
      </CDropdownMenu>
    </CDropdown>
    )
}




                                   

