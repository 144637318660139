import React from 'react'
import {CInput} from '@coreui/react';


export default (props)=>{

    const changeFunc = (e)=>{
        var val = e.target.value
        var loc = {...props.localFilters}
        loc['min_mileage'] = val
        props.setLocalFilters(loc)
    }

    return(
        <CInput
        style={{maxWidth:'105px', height: '38px'}}
            type="number"
            value={props.localFilters['min_mileage'] === null ? '' : props.localFilters['min_mileage']}
            id="mileage"
            name="min_mileage"
            placeholder="Min Miles"
            onChange = {(e => changeFunc(e))}
        />
       
    )
}




                                   

