import React from "react";
import { parseUserFromEmail } from "../../../../services/utils";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { CommentForm } from "./CommentForm";

TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo("en-US");

export const Comment = ({
  comment,
  replies,
  canReply,
  addComment,
  activeComment,
  setActiveComment,
  parentId = null,
}) => {
  const { seq_no, user, comments, created_date } = comment;

  const userName = parseUserFromEmail(user).trim();
  const nameInitials =
    userName.split(" ").length === 1
      ? userName[0]
      : userName.split(" ")[0][0] + userName.split(" ")[1][0];
  const passedTime = timeAgo.format(Date.parse(created_date));

  const isReplying =
    activeComment &&
    activeComment.id === seq_no &&
    activeComment.type === "replying";

  const replyId = parentId ? parentId : seq_no;

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          alignItems: "center",
          maxHeight: "200px",
          margin: "10px 5px",
        }}
      >
        <div
          style={{
            width: "50px",
            height: "50px",
            backgroundColor: "black",
            color: "white",
            fontWeight: 600,
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "5px 10px",
            borderRadius: "35px",
          }}
        >
          {nameInitials}
        </div>
        <div style={{ margin: "5px 17px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              maxWidth: "500px",
            }}
          >
            <span style={{ fontSize: "16px", maxWidth: "200px" }}>
              <strong>{userName}</strong>
            </span>
            <span style={{ margin: "0 10px" }}>
              <em>{passedTime}</em>
            </span>
          </div>
          <div style={{ maxWidth: "400px" }}>{comments}</div>
          {canReply && <div
            style={{ color: "gray", cursor: "pointer" }}
            onClick={() => setActiveComment({ id: seq_no, type: "replying" })}
          >
            Reply
          </div>}
        </div>
      </div>
      <div style={{ margin: "8px 25px" }}>
        {replies.length > 0 &&
          replies.map((reply) => (
            <Comment
              key={reply.seq_no}
              comment={reply}
              replies={[]}
              addComment={addComment}
              setActiveComment={setActiveComment}
              activeComment={activeComment}
              parentId={reply.seq_no}
              canReply={false}
            />
          ))}
        {isReplying && (
          <CommentForm
            submitLabel="Reply"
            handleSubmit={(bodyText) => addComment(bodyText, replyId)}
            hasCancelButton={true}
            handleCancel={() => setActiveComment(null)}
          />
        )}
      </div>
    </>
  );
};
