import React from 'react'
import {CSelect} from '@coreui/react'


export default (props)=>{

    const changeFunc = (e)=>{
        var val = e.target.options[e.target.selectedIndex].value
        var loc = {...props.localFilters}
        loc['mmr_max'] = val
        props.setLocalFilters(loc)
    }
    const formatter = (num)=>{
        var n = parseFloat(num);
        if(isNaN(n)) return num;
        var formattedNum = n.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return "$"+formattedNum
    }
    
     var options = []
    for(var i = 99000 ; i > 5000; i-=1000){
        options.push(
            <option value={i.toFixed(0)} key={i}>{formatter(i)}</option>
        )
    }



    return(
        <CSelect value={props.localFilters['mmr_max']} onChange={changeFunc} className="mr-2" size="lg">
            <option value="" key="nada">Max MMR</option>
            {options}
        </CSelect>
    )
}

