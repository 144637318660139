import React, {useState, useEffect, useRef} from 'react';
import {CBadge} from '@coreui/react';
import {DateTime} from 'luxon';
import {validateEmail} from "./AppraisalUtils";


export const parseTimerValues = (item, startingField, trackingField) => {
    var createdDate;
    var currentDate;

    if(trackingField == 'appraisal_completion_date'  && startingField != 'created_date') {
        createdDate = DateTime.fromISO(item.appraisal_claim_date, { zone: "America/Chicago", setZone: true });
    } else {
        createdDate = DateTime.fromISO(item.created_date, { zone: "America/Chicago", setZone: true });
    }

    if (trackingField) {
        if (trackingField == 'appraisal_completion_date') {
            if (item['offer_status'] == 'Complete') {
                currentDate = DateTime.fromISO(item[trackingField], { zone: "America/Chicago", setZone: true });
            } else {
                currentDate = DateTime.now().setZone("America/Chicago");
            }                
        } else if (trackingField == 'inspection_complete_date') {
            if (item[trackingField]) {
                currentDate =  DateTime.fromISO(item[trackingField], { zone: "America/Chicago", setZone: true });
            } else {
                currentDate = DateTime.now().setZone("America/Chicago");
            }
        }
    } else {
        currentDate = DateTime.fromObject({}, {zone: "America/Chicago"});
    }

    const diff = currentDate.diff(createdDate, [
        "hours",
        "minutes"
      ]);

    // setTimerDiff(diff.toObject());
    // return diff;
    
    return diff.toObject();
}


const capitalizeFirst = (str) => {
    let name = '';
    name = str.split(" ");
    if (name.length > 1){
        for (let i = 0; i < name.length; i++){
            if(name[i][0])
                name[i] = name[i][0].toUpperCase() + name[i].substring(1).toLowerCase()
        }
        return(name.join(" "))
    } else {
        name = str[0].toUpperCase() + str.substring(1).toLowerCase();
        return(name)
    }  
}

export const formattedText = (answer) => {
    if(answer === "") return 'N.A'
    return capitalizeFirst(answer.replace(/_/g, " ")).replace(/O/g,"o")
}

export const formatTextBlock = (text) => {
    return capitalizeFirst(text.replace(/_/g, " "));
}

export const pickInitials = (text) => {
    if(text){
        let textArr;
        let abbr="";
        textArr =  text.split("_");
        for(let i = 0; i< textArr.length; i++){
            abbr += textArr[i][0]
        } 
        return abbr.toUpperCase();
    }
}

export const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

export const parseUserFromEmail = (vas) => {

    let parsedUserName = '';

    // add logic for parsing name from email
    if(validateEmail(vas)) {
        const emailName = vas.split('@')[0];
        const namesArray = emailName.split('.');                            

        namesArray.forEach(element => {
            parsedUserName += element.charAt(0).toUpperCase() + element.slice(1) + " ";
        })
    } else {
        parsedUserName = vas;
    }

    return parsedUserName;
}