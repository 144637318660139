import React from 'react';
import { CRow, CCol} from '@coreui/react';
import RadialCard from './RadialCard/RadialCard';
import SaleInventoryTrend from './SaleInventoryTrend/SaleInventoryTrend';
import TopSegments from './TopSegments/IntegratedTS';
import InventoryAge from './InventoryAge/InventoryAge';



export default (props)=>{

    return (
    <div className="py-3">
        <CRow>
            <CCol md="10" className="offset-md-1">

            </CCol>
        </CRow>
        <CRow>
            <CCol md="10" className="offset-md-1">
                <TopSegments />
            </CCol>
        </CRow>
    </div>
    )
}

/*

                <div className="d-flex align-items-stretch justify-content-between">
                    <SaleInventoryTrend/>
                    <InventoryAge/>
                    <RadialCard 
                        title="Days Supply Target"
                        amount={78}
                        label="At or Above Target Day Supply"
                        destination="/"
                        variant="brand"
                    />
                    <RadialCard 
                        title="Pricing Adjustments"
                        amount={49}
                        label="Vehicles Priced Within Range"
                        destination="/"
                        variant="warning"
                    />
                </div>


            <CRow>
                <CCol md={6}>
                </CCol>
                <CCol md={3}>

                </CCol>
                <CCol md={3}>

                </CCol>
            </CRow>
*/