import React, {useState, useEffect} from 'react';
import { CCard, CCardHeader, CCardBody, CDataTable, CButton, CBadge} from '@coreui/react';
import RunListManager from '../../../../services/RunListManager';
import FormattedNumber from '../../../FormattedNumber/FormattedNumber';
import {Form, Loader} from 'semantic-ui-react';
import CompetitorTree from './CompetitorTree';
import Error from '../../../generic/Error/Error';
import CompFlag from '../../OLOContainer/CompFlag/CompFlag';

export default ({vin, vehicle, channel, source = '', page = '', compFlag})=>{

    const [currentInventory, setCurrentInventory] = useState({});
    const [tree, setTree] = useState([])
    const [loading, setLoading] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const {addError} = Error();
    var cTree = new CompetitorTree();

    useEffect( ()=>{
        var ignore = false;
        var RL = new RunListManager();
        setLoading(true);
        setTree([])
        setCurrentInventory([]);
        RL.getStaticVehicleDetail(vin, "comps", channel, source, page).then( (data)=>{
           if(!ignore){
             //  if(data.error) return addError(data.message, 500);
             if (page === 'Appraisal') {
                var compData = cTree.formatResults(data.data.matches);
                setCurrentInventory(compData);
            } else {
                var compData = cTree.formatResults(data.data.comps);
                setCurrentInventory(compData);
            }
            setLoading(false)
           }
        }).catch( (e)=>{
            console.log(e); 
         //   addError(e.message, 500);
            setLoading(false)})

        return ()=>{ignore =true;}

    }, [vin, channel])


    useEffect(()=>{
        var t = []
        var band = cTree.findMileageBand(vehicle.mileage); 
        if(currentInventory[band] != undefined){
            t.push(band)
        }
        if(t.length > 0){
            if(currentInventory[band]['children'][vehicle.year] != undefined){
                t.push(vehicle.year)
            }
        }
        setTree(t)
    }, [currentInventory, vehicle.mileage, vehicle.year])



    const toggleCollapsed = (e)=>{e.preventDefault();setCollapsed(!collapsed)}

    const addToTree = (val)=>{

        var newTree = [...tree];
        newTree.push(val)
        setTree(newTree)
    }

    const sliceTree = (index)=>{
        var newTree = [...tree]
        newTree = newTree.slice(0,index)
        setTree(newTree)
    }

    return(
        <CCard className={ collapsed ? "animateCard olo-collapse" : "animateCard"} 
            style={page==='Appraisal' ? !collapsed ? { height: '100%'} : {} : {}}
        >
            <CCardHeader className="d-flex justify-content-between align-items-center">
                <div className="olo-header">
                    <span>Competitor Data</span>
                    <span>{compFlag && <CompFlag/>}</span>
                </div>
                
                <CButton color="info" onClick={toggleCollapsed}>{collapsed ? 'Expand' : 'Collapse'}</CButton>
            </CCardHeader>

            <CCardBody className={collapsed ? 'collapse' : ''}>
                <div className="d-flex pb-3">
                    <CBadge onClick={()=>{sliceTree(0)}} size="sm" color="secondary">All</CBadge>
                    {tree.map((item)=>{
                        return <CBadge onClick={()=>{sliceTree(1)}} size="sm" color="secondary" className="ml-1">{item}</CBadge>
                    })}
                </div>

                <CDataTable
                    fields={cTree.setFields(tree)}
                    items={cTree.buildTableRows(currentInventory, tree)}
                    loading={loading}
                    sorter
                    pagination
                    itemsPerPage={10}
                    sorterValue={{ column: 'current_list_price', asc: 'true' }}
                    tableFilter
                    scopedSlots={{
                        'mileage': (i)=>{
                            return <td><FormattedNumber value={i.mileage}/></td>
                        },

                        'current_list_price': (item)=>{
                            return <td><FormattedNumber value={item.current_list_price} price={true}/></td>
                        },
                        'price': (item)=>{
                            if(isNaN(item.price)) return <td>-</td>;
                            return <td><FormattedNumber value={item.price} price={true}/></td>
                        },

                        'band': (item)=>{
                            return <td><a className="olo_link" onClick={()=>{addToTree(item.band)}}>{item.band}</a></td>
                        },
                        'year': (item)=>{
                            return <td><a className="olo_link" onClick={()=>{addToTree(item.year)}}>{item.year}</a></td>
                        },
                        'competitor_vin': (item)=>{
                            return <td>
                                {item.competitor_vin}
                                <div><small>{item.competitorModelYear} / <FormattedNumber value={item.mileage}/> miles</small></div>
                            </td>
                        }
                    }}
                    noItemsViewSlot='      ** No items or competitor data not available'
                />
            </CCardBody>
        </CCard>
    )
}
