import React, { useState, useEffect, useCallback, useRef } from 'react';
import {useParams} from 'react-router-dom';
import { CRow, CCol, CCard, CCardHeader, CCardBody, CInput, CButton, CCardFooter, CAlert } from '@coreui/react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Loader} from 'semantic-ui-react';
import RunListManager, { RL_Instance } from '../../services/RunListManager';
import Error from '../generic/Error/Error';

import { ReviewMetrics } from './ReviewMetrics/ReviewMetrics';
import { VINDetailsContainer } from './VINDetailsContainer'
import {LoadingScreen} from './LoadingScreen';

export const AppraisalTool = (props) => {
    var RL = new RunListManager();
    const params = useParams();
    const [vin, setVin] = useState('');
    const [mileage, setMileage] = useState('');
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [displayHint, setDisplayHint] = useState(false);
    const [hint, setHint] = useState('');
    const [hasDataEnriched, setHasDataEnriched] = useState(false);
    const [enrichResponse, setEnrichResponse] = useState(false);
    const [isDataRetrieved, setIsDataRetrieved] = useState('');
    const [enrichError, setEnrichError] = useState(false);
    const [failedMessage, setFailedMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [searchedVin, setSearchedVin] = useState('');
    const [isVinFromParams, setIsVinFromParams] = useState(false);
    const [metrics, setMetrics] = useState({});
    const [atMetrics, setAtMetrics] = useState({});
    const [appraisalValue, setAppraisalValue] = useState('');
    const [createdDate, setCreatedDate] = useState('');
    const [vinBackup, setVinBackup] = useState('');
    const [appraisalValueSaved, setAppraisalValueSaved] = useState(false);
    const [isMetricsRetrieved, setIsMetricsRetrieved] = useState(false);
    const {addError} = Error();
    
    const timerRef = useRef();

    // on change handlers
    const handleVinChange = (e) => {
        const updatedVin = e.target.value;
        setVin(updatedVin);
    }

    const handleMileageChange = (e) => {
        const castedMileage = parseInt(e.target.value);

        if(isNaN(castedMileage)) {
            setMileage('');
        } else {
            setMileage(e.target.value);
        }
    };

    const handleAppraisalValueChange = (e) => {
        const castedAppraisalValue = parseInt(e.target.value);

        if(isNaN(castedAppraisalValue)) {
            setAppraisalValue('');
        } else {
            setAppraisalValue(e.target.value);
        }
    }

    const onSubmitVINLookup = (e) => {
        setIsDataRetrieved('loading');
        setShowModal(true);
        setSearchedVin(vin);

        // make network call to enrich data for requested VIN
        RL.enrichVehicleData(vin, mileage)
                .then(response => {
                    // fetch the vehicle metrics
                    fetchVehicleMetrics(vin);

                    // set searched vin and reset the input fields
                    setVin('');
                    setMileage('');

                    // received response from enrich API
                    setHasDataEnriched(true);

                    // parse the response data and store to state
                    let responseData = response && response['data'] ? response['data'] : [];

                    response = responseData;

                    // go ahead and make a call to other APIs or SPs
                    setIsDataRetrieved('loaded');
                    setEnrichResponse(response);
                })
                .catch(error => {
                    setEnrichError(true);
                    setHasDataEnriched(false);
                    setIsDataRetrieved('');
                    if (error.message == 'Network Error') 
                    addError(error.message);
                    else if(error.response.data == 'API call Failed') {
                        setFailedMessage(`The VIN entered is Incorrect`);
                    } else {
                        setFailedMessage(`Technical Error while enriching details. Try Again`);
                    }
                });
    }

    const saveAppraisalValue = () => {
        RL_Instance.saveAppraisalValue(vinBackup, createdDate, appraisalValue)
            .then(data => {
                console.log(data);
                setAppraisalValueSaved(true);
                props.getMyAppraisals();
            })
            .catch(err => {
                console.log(err);
            })
    }

    // retrieve vehicle metrics
    const fetchVehicleMetrics = (vin) => {
        RL_Instance.getAppraisalReviewMetrics(vin)
            .then(res => {
                if (res.status === 200) {
                    setMetrics(res.data);
                    setAtMetrics(res.data)
                    setIsMetricsRetrieved(true);
                }
            })
            .catch(error => {
                console.error('Could not retrieve review metrics for specified VIN ' + error);
            })
    }

    // input validation
    const debounceValidateInput = useCallback(() => {
        const timerHook = setTimeout(() => {
            if (vin.length == 17) {
                if (mileage === '') {
                    // setEnableSubmit(false);
                    setDisplayHint(true);
                    setHint('Enter a valid Mileage');
                }           
            } else {
                // setEnableSubmit(false);
                setDisplayHint(true);
                setHint('Invalid VIN. Must be 17 chars');
            }
        }, 2000);

        return timerHook
    }, [vin, mileage])

    useEffect(() => {
        // if(params && params.vin) {
        //     setVin(params.vin);
        //     setVinBackup(params.vin);
        //     setMileage(params.mileage ? params.mileage : 1);
        //     setCreatedDate(params.created_date ? params.created_date : '')
        //     setIsVinFromParams(true);
        // }

        if(props && props.vin) {
            setVin(props.vin);
            setVinBackup(props.vin);
            setMileage(props.mileage ? props.mileage : 1);
            setCreatedDate(props.created_date ? props.created_date : '')
            setIsVinFromParams(true);
            if (props.appraisalValue) {
                setAppraisalValue(props.appraisalValue);
            }
        }
    }, [])

    useEffect(() => {
        if (isVinFromParams) {
            onSubmitVINLookup();
            setIsVinFromParams(false);
        }
    }, [isVinFromParams])

    useEffect(() => {
        if(vin == '') {
            setDisplayHint(false);
            setEnableSubmit(false);
            return
        }

        timerRef.current = debounceValidateInput();

        if (vin.length == 17) {
            if (mileage !== '') {
                setEnableSubmit(true);
                setDisplayHint(false);
            } else {
                setEnableSubmit(false);
                // setDisplayHint(true);
                // setHint('Enter a valid Mileage');
            }           
        } else {
            setEnableSubmit(false);
            // setDisplayHint(true);
            // setHint('Invalid VIN. Must be 17 chars');
        }

        return () => {
            // console.log('Clearing previous timer');
            clearTimeout(timerRef.current);
            setDisplayHint(false);
        }
    }, [vin, mileage])

    return (
        <>
            <CRow>
                <CCol className="pt-5 col-md-10 offset-md-1">
                    <CCard>
                        <CCardHeader>Appraisal Tool: {searchedVin}</CCardHeader>
                        <CCardBody>
                            {
                                props.view == `Appraiser` && (
                                    <div className='d-flex'>
                                        <div className="flex-grow-5 w-25 mr-2">
                                            <CInput 
                                                onChange={handleAppraisalValueChange}
                                                placeholder='Enter Appraisal Value'
                                                size='lg'
                                                style={{ fontSize: '16px' }}
                                                value={appraisalValue}
                                                disabled={appraisalValueSaved}
                                            />
                                        </div>
                                        <CButton
                                            className="ml-2"
                                            color={"primary"}
                                            disabled={appraisalValue == '' || appraisalValueSaved ? true : false}
                                            size="lg"
                                            onClick={saveAppraisalValue}
                                        >
                                            Save Appraisal Value
                                        </CButton>
                                    </div>
                                )
                            }
                        {
                            props.view != `Appraiser` &&
                            (
                                <>
                                    <div className='d-flex'>
                                        <div className="flex-grow-5 w-75 mr-2">
                                            <CInput 
                                                onChange={handleVinChange}
                                                placeholder='Enter VIN'
                                                size='lg'
                                                style={{ fontSize: '16px' }}
                                                value={vin}
                                            />
                                        </div>
                                        <div className="flex-grow-5 w-25">
                                            <CInput
                                                onChange={handleMileageChange}
                                                placeholder='Enter Mileage'
                                                size='lg'
                                                style={{ fontSize: '16px' }}
                                                value={mileage}
                                            />                                
                                        </div>
                                        <CButton
                                            className="ml-2"
                                            color={enableSubmit ? "primary" : "danger"}
                                            disabled={!enableSubmit}
                                            size="lg" 
                                            onClick={onSubmitVINLookup}
                                        >
                                            Submit
                                        </CButton>
                                    </div>
                                    {
                                        displayHint &&
                                        <CCardFooter className="text-medium-emphasis offset-md-5">{hint}</CCardFooter>
                                    }
                                </>
                            )
                        }
                        </CCardBody>
                    </CCard>
                    {/* <CCol className="pt-5 pb-2 col-md-10 offset-md-1">
                            
                    </CCol> */}
                </CCol>
            </CRow>
            {/* {
                !(isDataRetrieved == 'loaded') && isDataRetrieved == 'loading' &&
                <LoadingScreen show={showModal} toggleModal={setShowModal} />
            } */}
            
            {
                isDataRetrieved == 'loading' && <Loader active={true}/>
            }
                        
            {
                hasDataEnriched && isDataRetrieved == 'loaded' && (
                    <>
                        {/* Review Metrics */}
                        {
                            isMetricsRetrieved ? 
                                Object.keys(metrics).length > 0 ? (
                                <ReviewMetrics vin={searchedVin} metrics={metrics} /> 
                            ) : (
                                <CRow>
                                    <CCol className="pt-5 col-md-10 offset-md-1">
                                        <CCard className="pt-5 pb-5">
                                            <p style={{margin: 'auto'}}>Invalid VIN. No metrics retrieved for VIN</p>
                                        </CCard>
                                    </CCol>
                                </CRow> 
                            )
                            : <Loader active={true}/>
                        }
                        

                        {/* Vehicle Details, Auction History & Recent Sales */}
                        {
                            isMetricsRetrieved ?
                            Object.keys(atMetrics).length > 0 ? (
                                <VINDetailsContainer vin={searchedVin} atMetrics={atMetrics} />
                            ) : (
                                <CRow>
                                    <CCol className="pt-5 col-md-10 offset-md-1">
                                        <CCard className="pt-5 pb-5">
                                            <p style={{margin: 'auto'}}>Invalid VIN. No vehicle details retrieved for VIN</p>
                                        </CCard>
                                    </CCol>
                                </CRow> 
                            ) 
                            : <Loader active={true}/>
                        }
                        
                        {/* <br />
                        <br /> */}
                    </>
                )
            }
            {
                enrichError && (
                    <CRow>
                        <CCol className="pt-2 col-md-10 offset-md-1" style={{ margin: "0px auto" }}>
                            <CAlert color="danger" closeButton onClose={() => {
                                setHasDataEnriched(false);
                                setFailedMessage('');
                            }}>
                                Couldn't enrich record for VIN {vin}. {failedMessage}
                            </CAlert>
                        </CCol>
                    </CRow>
                )
            }
        </>
    )
}