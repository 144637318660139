import React, {useState} from 'react'
import { CProgress, CProgressBar } from '@coreui/react'


export default (props)=>{

    const [showMarket, setShowMarket] = useState(false)

    const PriceToMarket = (bsrp, market)=>{
        return Math.round( (bsrp/market)*100);
    }

    const barColor = (bsrp, market)=>{

        var priceToMarket = PriceToMarket(bsrp, market)
        
        if(priceToMarket > 100) return 'danger';
        if(priceToMarket > 96) return 'warning'
        return 'success'
    }

    return <div className="d-flex flex-column">
        <div className="d-flex justify-content-between">
            <div className="olo-control-label">BSRP to Market
            </div>
        </div>
        <div className="pt-2">
            <CProgress>
                <CProgressBar color={barColor(props.bsrp, props.market)} value={PriceToMarket(props.bsrp, props.market)}>{PriceToMarket(props.bsrp, props.market)}%</CProgressBar>    
            </CProgress>                        
        </div>
    </div>

}