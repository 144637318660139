import React, { useState, useEffect } from "react";
import Multiselect from 'multiselect-react-dropdown';


export default (props)=>{
    const [check, setCheck] = useState(0);
    const [selectedValues, setSelectedValues] = useState([]);
    const [options, setOptions] = useState([]);

    const defaultOptions = [
        {name: "red", id: 0, key: "0", daySupply_min: 51, daySupply_max: 99999},
        {name: "yellow", id: 1, key: "1", daySupply_min: 46, daySupply_max: 50},
        {name: "green", id: 2, key: "2", daySupply_min: 1, daySupply_max: 45},
      ];

    useEffect( ()=>{
        console.log("Do once");
      figureOutSelectedValues();      
      },[check,props.localFilters]);
  
      const figureOutSelectedValues = () => {
        var loc = { ...props.localFilters };
        var daySupply_min = loc['daySupply_min'];
        var daySupply_max = loc['daySupply_max'];

        var allSelected = [];
        if (!isNaN(daySupply_min)) {
            if (daySupply_max) {
                //  if ((daySupply_min >=45 && daySupply_min <50) && (daySupply_max >45 && daySupply_max <=50)) {
                //   allSelected.push(defaultOptions[1])
                // } else if ((daySupply_min >=51) && (daySupply_max >51 && daySupply_max <= 99999)) {
                //   allSelected.push(defaultOptions[0])
                // }
                // else if ((daySupply_min >=1 && daySupply_min <45) && (daySupply_max >1 && daySupply_min <=45)) {
                //     allSelected.push(defaultOptions[2])
                // } 
                if ((daySupply_min ==46 &&  daySupply_max ==50)) {
                  allSelected.push(defaultOptions[1])
                } else if ((daySupply_min ==51 && daySupply_max == 100)) {
                  allSelected.push(defaultOptions[0])
                }
                else if ((daySupply_min ==1 && daySupply_max ==45)) {
                    allSelected.push(defaultOptions[2])
                }
                
            }
        }
        setSelectedValues(allSelected);
        setOptions(defaultOptions);
        console.log("figureOutSelectedValues, daySupply_min, daySupply_max  ", daySupply_min, daySupply_max, allSelected);
    
      }
    
      const syncUpSelection = (selectedList) => {
        var loc = { ...props.localFilters };
        if (selectedList && selectedList.length > 0) {
            var daySupply_min = 51;
            var daySupply_max = 45;
            for (var i=0; i< selectedList.length;i++) {
                var sel = selectedList[i];
                if (sel.daySupply_min < daySupply_min) {
                    daySupply_min = sel.daySupply_min;
                }
                if (sel.daySupply_max > daySupply_max) {
                    daySupply_max = sel.daySupply_max;
                }
            }
            loc['daySupply_min'] = daySupply_min;
            loc['daySupply_max'] = daySupply_max;
            console.log("set daySupply_min daySupply_max", daySupply_min, daySupply_max);
            props.setLocalFilters(loc)  
        } else {
            loc['daySupply_min'] = null
            loc['daySupply_max'] = null    
        }
        props.setLocalFilters(loc)  
    
      }
    
      const onSelect = (selectedList, selectedItem) => {
        console.log("selectedList", selectedList);
        syncUpSelection(selectedList);
      }
    
      const onRemove = (selectedList, removedItem) => {
        console.log("selectedList", selectedList);
        syncUpSelection(selectedList);
      }
    
      const css = {
        option: {
          fontSize: "16px"
        },
        inputField: {
          height: "24px",
          fontSize: "16px"
        }
      }

    return (
        <Multiselect 
            options={options}
            selectedValues={selectedValues}
            onSelect = {onSelect}
            onRemove = {onRemove}
            showCheckbox={true}
            placeholder="Days Supply Color"
            displayValue="name"
            hidePlaceholder={true}
            showArrow={true}
            style={css}
            isObject={true}
        />
        );
}

