import React, {useState, useEffect} from 'react';
import {CNav, CNavItem, CNavLink, CButton, CInput, CRow, CCol, CFormGroup, CLabel, CSelect} from '@coreui/react';
import Valuations from './Valuations/Valuations';
import styled from 'styled-components';
import RunListManager from '../../../services/RunListManager';
import cleanUpdate from 'immutability-helper';
import FormattedNumber from '../../FormattedNumber/FormattedNumber';
import ReviewControls from './ReviewControls/ReviewControls';

const Wrapper = styled.div`
    position:absolute;
    height:100%;
    width:100%;
    padding:12em 1em 1em;
    top:0;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar{
      width:4px;
      background:#cccccc;
    }

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
`

export default ({vehicle, currentIndex, updater, channel})=>{


    const openCarfax = (e)=>{
        e.preventDefault();
        const carfaxid= 'DUW2t0ohVsDu2H230pMZmw2x9ArIo3bd';
        const returnUrl = encodeURIComponent('http://localhost:3000/carfax.html');
        const nonce = makeid(12);
        var url =`https://auth.carfax.com/authorize?client_id=${carfaxid}&redirect_uri=${returnUrl}
&state=${nonce}&response_type=token&audience=https%3A%2F%2Fconnect.carfax.com&scope=offline_access`
        window.open(url, 'carfaxWindow');
    }

    function makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     }

    return(<>
        <div className="p-3 bg-white d-flex w-100 justify-content-left shadow-sm border-bottom-1 position-absolute zTop">
            <ReviewControls vehicle={vehicle} currentIndex={currentIndex} updater={updater} channel={channel}/>
        </div>
        <Wrapper>
            <Valuations vehicle={vehicle} channel={channel} />
        </Wrapper>
    </>
    )
}