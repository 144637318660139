

export default function BidHistoryTree(){

}

BidHistoryTree.prototype = {

    formatResults: function(items){
        var mappings = {
            "Unknown Miles":{count:0, prices:[], children:{}},
            "Under 10K":{count:0, prices:[], children:{}},
            "10K-20K": {count:0, prices:[], children:{}},
            "20K-30K": {count:0, prices:[], children:{}},
            "30K-40K":{count:0, prices:[], children:{}},
            "40K+":{count:0, prices:[], children:{}}
        }
        var cr_bands = {
            "Unknown CR": {count:0, prices:[], children:{}},
            "2.0 - 2.5": {count:0, prices:[], children:{}},
            "2.5 - 3.0": {count:0, prices:[], children:{}},
            "3.0 - 3.5": {count:0, prices:[], children:{}},
            "3.5 - 4.0": {count:0, prices:[], children:{}},
            "4.0+": {count:0, prices:[], children:{}}
        }
        items.forEach((item)=>{
            var crBand = this.findCRBand(item.vehicleGrade);
            var band = this.findMileageBand(item.mileage)
            item.crBand = crBand;
            item.band = band;
            if (item.sold_price) {
                item.sold_price =  parseInt(item.sold_price);
                if (item.sold_price > 0) {
                    mappings[crBand] = mappings[crBand] || {count:0, prices:[], children:{}};
                    cr_bands[crBand]['count'] +=1;
                    cr_bands[crBand]['prices'].push(item.sold_price)
                    cr_bands[crBand]['children'] = this.addChild(cr_bands[crBand]['children'],item.band)
                    cr_bands[crBand]['children'][item.band]['count'] +=1
                    cr_bands[crBand]['children'][item.band]['prices'].push(item.sold_price)
                    cr_bands[crBand]['children'][item.band]['children'].push(item)
    
                }
            }
        })  
        //console.log("cr_bands", cr_bands);
        return cr_bands;
    },

    findMileageBand: function(mileage){
        if (!mileage || mileage.length==0) return "Unknown Miles";
        if(mileage < 10000) return "Under 10K";
        if(mileage < 20000) return "10K-20K";
        if(mileage < 30000) return "20K-30K";
        if(mileage < 40000) return "30K-40K";
        return "40K+"
    },

    findCRBand: function(cr){
        if (!cr || cr.length==0) return "All";
        //console.log("CR", cr);
        if(cr < 2.5) return "2.0 - 2.5";
        if(cr < 3) return "2.5 - 3.0";
        if(cr < 3.5) return "3.0 - 3.5";
        if(cr < 4) return "3.5 - 4.0";
        return "4.0+"
    },

    addChild:function(base, child){
        if(!base[child]){
            base[child] = {
                count:0,
                prices:[],
                children:[]
            }
        }
        return base
    },

    buildTableRows: function(mapping,tree){
        var keys = ['band', 'Buyer'];
        var depthKey = 'crBand'
        var mapDepth = mapping

        if(mapping.length < 1) return [];

        tree.forEach( (item)=>{
            depthKey = keys.shift();
            mapDepth = mapDepth[item].children
        })

        if(Array.isArray(mapDepth)) {
            //console.log("array mapDepth", mapDepth);
            return mapDepth;
        }
        
        //console.log("mapDepth", mapDepth);
        var listings = [];
        Object.keys(mapDepth).forEach( (item)=>{
            if (mapDepth[item].count > 0) {
                var listing = {}
                listing[depthKey] = item
                listing['count'] = mapDepth[item].count
                listing['price'] = this.getAverage(mapDepth[item].prices);
                //listing['crBand'] = item.crBand;
                listings.push(listing)        
            }
        })
        return listings
    },

    getAverage: function(arr){
        var sum = 0;
        var denominator = 0
        arr.forEach((i)=>{
            //if theres a 0 value, dont include it in the average
            if(i > 0){
                sum += i
                denominator++
            }
          
        })
        return (denominator > 0) ?  Math.round(sum / denominator) : 0;
    },


    setFields: function(tree){
        return this.fieldMap[tree.length]
    },

    fieldMap: [
        [
            {key:"crBand", label:"Condition Report"},
            {key:"count", label:"Vehicle Count"},
            {key:"price", label:"Average Price"}
        ],
        [
            {key:"band", label:"Mileage Band"},
            {key:"count", label:"Vehicle Count"},
            {key:"price", label:"Average Price"}
        ],
        [
            {key:'auction_timestamp', label:'Sold Date'},
            {key:'sold_price', label:"Price Sold"},
            {key:'auction_source', label:'Auction Source'},
            {key:'auction_vin', label:'VIN'},
            {key:'Buyer', label:"Buyer"}
        ]
    ]
}