import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import MSALProvider from './components/MSALProvider/MSALProvider';
import ErrorProvider from './ErrorProvider';
import ErrorModal from './components/generic/ErrorModal/ErrorModal';
import App from './App';
import {
  BrowserRouter as Router, 
} from 'react-router-dom';

const styleLink = document.createElement("link");
styleLink.rel = "stylesheet";
styleLink.href = "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
document.head.appendChild(styleLink);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <MSALProvider>
        <ErrorProvider>
          <App />
          <ErrorModal />
        </ErrorProvider>
      </MSALProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

