import React from 'react'
import {CSelect} from '@coreui/react'


export default (props)=>{

    const changeFunc = (e)=>{
        var val = e.target.options[e.target.selectedIndex].value
        var loc = {...props.localFilters}
        loc['year_min'] = val
        props.setLocalFilters(loc)
    }

    var options = []
    var maxYear = new Date().getFullYear() + 2;

    for(var i = maxYear; i > 2015; i-=1){
        options.push(
            <option value={i} key={i}>{i}</option>
        )
    }

    return(
        <CSelect value={props.localFilters['year_min']} onChange={changeFunc} className="mr-2" size="lg" key='year_min'>
            <option value="" key="nada">Min Year</option>
            {options}
        </CSelect>
    )
}

