import React, {useState} from 'react';
import {CCard, CCardHeader,CCardBody, CButton, CListGroup, CListGroupItem} from '@coreui/react'
import DatePicker from "react-datepicker";
import RunListManager from '../../../services/RunListManager';
import {Loader} from 'semantic-ui-react';

import "react-datepicker/dist/react-datepicker.css";


export default (props)=>{
    var today = new Date()
    const [creatorLoading, setCreatorLoading] = useState(null);
    const [datePicker, setDatePicker] = useState(new Date(today.getTime() + 86400000))
    const createRunList = (channel)=>{
        const padNum = (i)=>{ return (i < 10) ? "0"+i : i;}
        var newRunListDate = datePicker.getFullYear() + "-"+padNum(datePicker.getMonth()+1) +"-"+padNum(datePicker.getDate());

        setCreatorLoading(channel);
        var RL = new RunListManager();
        RL.createRunList({auctionDate: newRunListDate, channel:channel}).then( (response)=>{
            props.advance(channel, response.data.runlist_id)
        }).catch((e)=>{setCreatorLoading(null);console.log(e);});

    }

    return(
        <CCard>
        <CCardHeader>
            <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="col-md-4">
                    <h3 className="flex-grow-1 p-0 m-0">{props.venue}</h3>
                </div>
                <div className="d-flex justify-content-between align-items-center col-md-8">
                <div className="runlistLabel">CREATE RUN LIST</div>
                    <div>
                        <DatePicker minDate={new Date()} selected={datePicker} onChange={date=> setDatePicker(date)} wrapperClassName="runlist-datepicker-wrapper" />
                    </div>
                    <CButton color="primary" onClick={()=>{createRunList(props.channel)} }>
                        Create
                        {creatorLoading == props.channel && <Loader inline active />}
                    </CButton>
                </div>
            </div>
        </CCardHeader>
        <CCardBody>
            {props.loading && <div className="align-center py-3"><Loader inline active/></div>}
            <CListGroup>
                {!props.loading &&
                    props.runLists[props.channel].map((item,k)=>{
                        return <CListGroupItem key={item.id} action onClick={()=>{
                            props.advance(props.channel, item.id)
                        }}>
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <h4 className="p-0 m-0">{item.auction_date}</h4>
                            <CButton color="info">View</CButton>
                        </div>  
                        </CListGroupItem>
                    })
                }
            </CListGroup>
        </CCardBody>
    </CCard>
    )
}