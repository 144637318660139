//import { getStyle, hexToRgba } from '@coreui/utils';
import {RL_Instance} from '../../../../services/RunListManager';
import {freeSet} from '@coreui/icons'

const InventoryHelper = {
    thresholds: [
        {"min": 0, "cardClass": "", cardMessage:"Net Demand can't be calculated for this vehicle" },
        {"min": 0, "cardClass": "bg-success", cardMessage:"Matches Inventory Need" },
        {"min": 45, "cardClass": "bg-warning", cardMessage:"Be Selective" },
        {"min": 50, "cardClass": "bg-danger", cardMessage:"Does Not Match Current Inventory Needs" }
    ],
    percent_change_buckets: [
        {"min": -9999999.99, "max": -15.0,  "trend_icon": freeSet.cilChevronDoubleDown, "tip": "Significant decrease - 15% or more in last 14 days" },
        {"min": -15.0, "max": -10.0, "trend_icon": freeSet.cilArrowThickBottom, "tip": "Mild decrease - 10 to 15% in last 14 days" },
        {"min": -10.0, "max": 10.0, "trend_icon": freeSet.cilSwapHorizontal, "tip": "Neutral - between -10 to 10% in last 14 days" },
        {"min": 10.0, "max": 15.0, "trend_icon": freeSet.cilArrowThickTop, "tip": "Mild increase - 10 to 15% in last 14 days" },
        {"min": 15.0, "max": 999999999.9, "trend_icon": freeSet.cilChevronDoubleUp, "tip": "Significant increase - 15% or more in last 14 days" }
    ],

    hasSettingsData() {
        if (RL_Instance.inventory_settings && RL_Instance.inventory_settings.luxury_makes) {
            return true;
        }
        return false;
    },

    getThresholdSettings(make) {
        var makes = RL_Instance.inventory_settings.luxury_makes;
        var vehicle_class = "Standard";
        make = make.toUpperCase();
        for(var i=0; i < makes.length; i++) {
            if (makes[i].make === make) {
                vehicle_class = "Luxury";
                break;
            }
        }
        var settings = RL_Instance.inventory_settings.settings;
        var setting = settings[0];
        for(var i=0; i<settings.length;i++) {
            if (settings[i].vehicle_class === vehicle_class) {
                setting = settings[i];
                break;
            }
        }
        return setting;
    },

    updateThresholdsFromSettings(s) {
        /* Sample setting
        settings_type: 1
        vehicle_class: "Luxury"
        vehicle_class_id: 2
        inline_min: 0
        inline_max: 60
        inline_color_hex: "#2eb85c"
        moderate_min: 60
        moderate_max: 65
        moderate_color_hex: "#f9b115"
        excess_min: 65
        excess_max: 999999999
        excess_color_hex: "#e55353"
        */
       var thresholds = this.thresholds;

       thresholds[1].min = s.inline_min;
       thresholds[1].cardColor = s.inline_color;

       thresholds[2].min = s.moderate_min;
       thresholds[2].cardColor = s.moderate_color;

       thresholds[3].min = s.excess_min;
       thresholds[3].cardColor = s.excess_color;
    },

    locateDaysSupplyMessageThreshold(netDemand, make){
        var liveSupply = netDemand.LiveInventory + netDemand.NumInPrep;
        var current_days_supply = liveSupply/( ((liveSupply+1)**netDemand.NetDemand)-1);
        current_days_supply = Math.round(current_days_supply);
        
        //return default when days supply is unknown
        if(!netDemand.NetDemand || isNaN(current_days_supply) || current_days_supply < 1) {
            this.thresholds[0].trend = this.percent_change_buckets[2];
            return this.thresholds[0];
        }

        if(RL_Instance.inventory_settings) {
            var setting = this.getThresholdSettings(make);
            this.updateThresholdsFromSettings(setting);
            var thresholds = this.thresholds;
            var t = thresholds[0]
            for(var i = 0; i < thresholds.length ; i++){
                if(thresholds[i].min > current_days_supply) break;
                t = thresholds[i];
            }
        

            //calculate the number of vehicles to achieve 45 days supply if the current supply is less than 45 days
            var vehicleNeeds = t.min===0 ? this.calculateMaxDaysSupply(netDemand.NetDemand, liveSupply, setting.inline_max)-liveSupply : 0;
            var supply_now = netDemand.LiveInventory + netDemand.NumInPrep; 
            var supply_week_ago = netDemand.LiveInventoryWeekAgo + netDemand.NumInPrepWeekAgo; 
            var percent_change = supply_now === 0 ? 0: (supply_week_ago - supply_now) * 100.0 / supply_now;

            var trend = this.percent_change_buckets[2]; //default is neutral
            for(var i = 0; i < this.percent_change_buckets.length ; i++){
                if(this.percent_change_buckets[i].min < -10.0 && this.percent_change_buckets[i].min >= percent_change && percent_change < this.percent_change_buckets[i].max) {
                    trend = this.percent_change_buckets[i];
                    break;
                }
                if(this.percent_change_buckets[i].max > 10.0 && this.percent_change_buckets[i].min < percent_change && percent_change <= this.percent_change_buckets[i].max) {
                    trend = this.percent_change_buckets[i];
                    break;
                }
            }

            t.current_days_supply = current_days_supply;
            t.live_supply = liveSupply;
            t.vehicle_needs = vehicleNeeds;
            t.percent_change = percent_change;
            t.trend = trend;
            t.max_days = setting.inline_max;
            return t;
        }

        return this.thresholds[0];
    },
    calculateMaxDaysSupply(netDemand, liveSupply, maxDays) {
        //formula: daysSupply = liveSupply/( ((liveSupply+1)**netDemand)-1);
        var start = liveSupply + 1;
        for(var s=start;s< 200; s++) {
            var daysSupply = s/( ((s+1)**netDemand)-1);
            if (daysSupply >= maxDays) {
                return s;
            }
        }
    }
}

export default InventoryHelper;
