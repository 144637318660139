import React,{useState, useEffect} from 'react';
import {Loader} from 'semantic-ui-react';
import RunListManager from '../../../../../services/RunListManager';
import Error from '../../../../generic/Error/Error';

export default (props)=>{

    const [profitTime, setProfitTime] = useState([]);
    const [prediction, setPrediction] = useState("");
    const [loading, setLoading] = useState(false);
    const {addError} = Error();

    useEffect( ()=>{
        var ignore = false;
        var RL = new RunListManager();
        setLoading(true);
        setProfitTime("");

        RL.getVehicleDetail(props.vin, "profitTime", "", props.auctionDate).then( (data)=>{
            if(!ignore){
                if(data.error) return addError(data.message, 500);
                console.log("data.data.profit_times", data.data.profit_times);
                setProfitTime(data.data.profit_times);
                setLoading(false)
            }
        }).catch( (e)=>{
            console.log(e);
            addError(e.message, 500);
            setLoading(false)
        })

        return ()=>{ignore =true;}

    }, [props.vin])

    const showPrediction = (maxBid)=>{
        //console.log("Recalculating showPrediction", maxBid, profitTime);
        if (maxBid && profitTime.length > 0) {

            var maxBidNum = maxBid;
            if (!Number.isFinite(maxBid)) {
                maxBidNum = parseInt(maxBid);
            }

            for(var i=0; i< profitTime.length; i++) {
                console.log(profitTime[i].price, maxBidNum);
                if (profitTime[i].price >= maxBidNum) return profitTime[i].pt_class;
            }
            return profitTime[profitTime.length-1].pt_class;

        }
        return ""
    }


    return(
        <>
           {loading && <center><Loader inline active /></center>}
           {!loading && showPrediction(props.maxBid)}
        </>
    )

}