import React, {useState, useEffect} from 'react';
import ImageGallery from 'react-image-gallery';
import {Loader} from 'semantic-ui-react';
import RunListManager from '../../../services/RunListManager';
import Error from '../Error/Error';

export default ({vin, channel, auctionDate, outsideLoading=false, outsideLoadingFlag, outsideImages})=>{


    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const {addError} = Error();
    
    useEffect(()=>{
        if (outsideLoading) {
            console.log("setting from outside");
        } else {
            var ignore = false;
            setLoading(true)
            setImages([]);
            var RL = new RunListManager();
            RL.getVehicleDetail(vin, 'listing', channel, auctionDate).then( (data)=>{
                if(data.error) return addError(data.message, 500);
                if(!ignore && data.data.listing.images){   
                    var newImages =  data.data.listing.images.map((item)=>{
                        return {
                            original: item.largeUrl,
                            thumbnail: item.smallUrl
                        }
                    }) 
                    if(!ignore) setImages(newImages)
                }
                if(!ignore) setLoading(false);
            }).catch( (e)=>{
                console.log(e); 
                setLoading(false)
            });
    
            return ()=>{ignore = true;}
        }
    }, [vin])

   

 
    return <div className="mb-3">
        {( (images.length > 0 && !loading) || (outsideLoading && outsideImages.length > 0 && !outsideLoadingFlag)) && 
            <ImageGallery 
                items={outsideLoading ? outsideImages: images}  
                showThumbnails={true} 
                thumbnailPosition="left" 
                showPlayButton={false} 
                slideDuration={10}
                showNav={true} 
                useBrowserFullscreen={false}
        />}
        {(loading || outsideLoadingFlag) && <Loader inline={true} active/>}

    </div>;
    
}