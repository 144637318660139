import React, {useEffect, useState} from 'react';
import { CRow, CCol, CCard, CCardHeader, CCardBody } from '@coreui/react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MarketPrice from '../../Main/VehicleDetail/ReviewControls/MarketPrice/MarketPrice';
import DataField from '../../Main/VehicleDetail/ReviewControls/DataField/DataField';
import Blackbook from '../../Main/VehicleDetail/Valuations/BlackBook/BlackBook';
import { RL_Instance } from '../../../services/RunListManager';
import {Loader} from 'semantic-ui-react';

export const ReviewMetrics = (props) => {
    const {metrics} = props;

    return (
        <CRow>
            <CCol className=" pb-2 col-md-10 offset-md-1">
                <CCard>
                    <CCardHeader>Vehicle Details</CCardHeader>
                    <CCardBody>
                        {
                            Object.keys(metrics).length === 0 && <Loader inline={true} active/>
                        }
                        {
                            Object.keys(metrics).length > 0 && (
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center justify-content-center w-30">
                                        <MarketPrice yipitMarketPrice={metrics.yipitMarketPrice} yipitSampleSize={metrics.yipitSampleSize} />
                                        <DataField value={metrics.mileage} label={"mileage"} number={true} price={false}/>
                                        {/* <DataField value={4.8} label={"CR Score"} number={true} price={false} decimal={1} border={true}/> */}
                                        <DataField value={metrics.adjMmr} label="Adj. MMR" number={true} price={true} border={true}/>

                                        <DataField 
                                            value={<Blackbook 
                                                vin={props.vin}
                                                channel={`Manheim`} 
                                                source={'live'} 
                                                page={"Appraisal"}
                                            />} 
                                            label="Black Book" 
                                            number={true} 
                                            price={true} 
                                            border={true}
                                        />
                                        {/* <DataField value={800} label="Shipping Cost" number={true} price={true} border={true}/> */}
                                        <DataField value={metrics.style} label="Drivetrain" number={false} price={false} border={true}/>       
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between w-40" style={{ marginLeft: '-80px' }}>
                                        <h4>{`${metrics.model_year} ${metrics.make} ${metrics.model} ${metrics.series}`}</h4>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between w-30" style={{ marginRight: '20px' }}>
                                        <h4>{metrics.vin}</h4>
                                    </div>
                                </div>
                            )
                        }                        
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    )
};