import React from 'react';
import { CRow, CCol } from '@coreui/react';
import BidHistory from '../../Main/VehicleDetail/BidHistory/BidHistory';
import OLOSalesHistory from '../../Main/VehicleDetail/OLOSalesHistory/OLOSalesHistory';
import VehicleData from '../../Main/VehicleDetail/VehicleData/VehicleData';
import Carfax from '../../Main/VehicleDetail/CarFax/CarFax';
import InventoryManagement from '../../Main/VehicleDetail/InventoryManagement/InventoryManagement';
import CompetitorData from '../../Main/VehicleDetail/CompetitorData/CompetitorData';
import OLOInventory from '../../Main/VehicleDetail/OLOInventory/OLOInventory'
import { ShoppingIndicator } from '../../Main/VehicleDetail/ShoppingIndicator/ShoppingIndicator';

export const VINDetailsContainer = (props) => {
    const {atMetrics} = props;
    return (
        <>
            <CRow>
                <div className="pb-2 d-flex col-md-10 offset-md-1 justify-content-center">  
                    <CCol xs={3} style={{ padding: 1 }}>
                        <VehicleData 
                            vehicle={{ year: atMetrics.model_year, make: atMetrics.make, extendedModel: atMetrics.model, 
                            drivetrain: atMetrics.series, vin: atMetrics.vin  }}
                        />
                        <Carfax vin={props.vin} />
                    </CCol>
                    <CCol  style={{ padding: 1 }}>
                        <BidHistory vin={props.vin} vehicle={{}} source={"Appraisal"} compFlag={true} />
                    </CCol>
                    <CCol style={{ padding: 1 }} >
                        <OLOSalesHistory vin={props.vin} vehicle={{}} channel={`Manheim`} source={'live'} page={"Appraisal"} compFlag={true}/>
                    </CCol>
                </div>
            </CRow>
            <CRow>
                <div className="pt-2 mb-4 d-flex col-md-10 offset-md-1 justify-content-center">  
                    <CCol xs={3} style={{ padding: 1 }}>
                        <InventoryManagement vin={props.vin} make={atMetrics.make} page={"Appraisal"} />
                        {atMetrics.shopping_item === 1 ? (
                        <ShoppingIndicator available={atMetrics.shopping_item} comments={atMetrics.comments} rank={atMetrics.vehicle_rank} make={atMetrics.make} model={atMetrics.model}
                        />
                        ) : null}
                    </CCol>
                    <CCol  style={{ padding: 1 }}>
                        <CompetitorData vin={props.vin} vehicle={{}} source={'live'} page={"Appraisal"} compFlag={true}/>
                    </CCol>
                    <CCol style={{ padding: 1 }} >
                        <OLOInventory vin={props.vin} vehicle={{}} channel={`Manheim`} source={'live'} page={"Appraisal"} compFlag={true}/>
                    </CCol>
                </div>
            </CRow>
        </>
    )
}