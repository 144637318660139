import React, {useState, useEffect, useRef} from 'react';
import {CBadge} from '@coreui/react';
import {DateTime} from 'luxon';

export const Timer = ({ item, trackingField='', startingField='' }) => {
    const timerHookRef = useRef();

    const [timerDiff, setTimerDiff] = useState(0);
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const parseTimerValues = (item) => {
        var createdDate;
        var currentDate;

        if(trackingField == 'appraisal_completion_date'  && startingField != 'created_date') {
            createdDate = DateTime.fromISO(item.appraisal_claim_date, { zone: "America/Chicago", setZone: true });
        } else {
            createdDate = DateTime.fromISO(item.created_date, { zone: "America/Chicago", setZone: true });
        }

        if (trackingField) {
            if (trackingField == 'appraisal_completion_date') {
                if (item['offer_status'] == 'Complete') {
                    currentDate = DateTime.fromISO(item[trackingField], { zone: "America/Chicago", setZone: true });
                } else {
                    currentDate = DateTime.now().setZone("America/Chicago");
                }                
            } else if (trackingField == 'inspection_complete_date') {
                if (item[trackingField]) {
                    currentDate =  DateTime.fromISO(item[trackingField], { zone: "America/Chicago", setZone: true });
                } else {
                    currentDate = DateTime.now().setZone("America/Chicago");
                }
            }
        } else {
            currentDate = DateTime.fromObject({}, {zone: "America/Chicago"});
        }

        const diff = currentDate.diff(createdDate, [
            "years",
            "months",
            "days",
            "hours",
            "minutes",
            "seconds"
          ]);

        setTimerDiff(diff.toObject());
    }

    useEffect(() => {
        timerHookRef.current = setInterval(() => {
            parseTimerValues(item)
        }, 1);

        return () => {
            clearInterval(timerHookRef.current);
        }
    }, [item])

    useEffect(() => {     
        setDays(timerDiff.days);   
        setHours(timerDiff.hours);
        setMinutes(timerDiff.minutes);
        setSeconds(timerDiff.seconds);
    }, [timerDiff])

    if (hours < 0) {
        return (
            <>
                <CBadge color='secondary'>
                    Loading Timer
                </CBadge>
            </>
        )
    }

    return (
        <>
            <CBadge 
                color={item[trackingField] ? 'secondary' : hours > 0 ? 'danger' : 
                    minutes <= 20 ? 'success' : (minutes > 20 && minutes <= 40) ? 'warning' : 'danger'} 
                    // shape="pill" 
                    style={{
                        padding: "5.25px 10px", 
                        height: "24px",
                        width: "91.3px",
                        fontSize: "12.5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
            >
                <span>+{(24*days)+hours}:{minutes >= 10 ? minutes : `0${minutes}`}</span>
            </CBadge>
        </>
    )
}