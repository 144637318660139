import React from 'react'
import {CSelect} from '@coreui/react'


export default (props)=>{
    const changeFunc = (e)=>{
        var val = e.target.options[e.target.selectedIndex].value
        var loc = {...props.localFilters}
        loc['shoppingList'] = val
        props.setLocalFilters(loc)
    }

    return(
        <CSelect value={props.localFilters['shoppingList']} onChange={changeFunc} className="mr-2" size="lg">
            <option value="">Shopping List</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
        </CSelect>
    )
}