import React, {useState, useEffect} from 'react';
import {CTooltip} from '@coreui/react';
import { CIcon } from '@coreui/icons-react';
import {cibCircle} from '@coreui/icons';
import RunListManager from '../../services/RunListManager';
import {DateTime} from 'luxon';


export const LastRefreshTime = ({refreshProp}) => {
    var RL = new RunListManager();

    const [lastRefreshed, setLastRefreshed] = useState(null);
    const [lastRunStatus, setLastRunStatus] = useState('');

    const getLastRunTime = () => {
        RL.getvAutoLastRefreshTime()
                    .then(res => {
                        const refreshDate = res && res.data ? res.data.appraisal_batch_run_log_batch_run_time : null;
                        const runStatus = res && res.data ? res.data.appraisal_batch_run_log_status : '';

                        if(refreshDate) {
                            const formattedDate = new Date(refreshDate);

                            let formattedHours = formattedDate.getHours();
                            const formatAMPM = formattedHours >= 12 && formattedHours <= 23 ? 'PM' : 'AM';
                            formattedHours = formattedHours >= 13 && formattedHours <= 23 ? formattedHours - 12 : formattedHours;

                            let formattedMinutes = formattedDate.getMinutes();
                            formattedMinutes = formattedMinutes < 10 ? `0` + formattedMinutes : formattedMinutes;

                            const formattedTime = formattedHours + `:` + formattedMinutes + 
                                ` ${formatAMPM}`;

                            setLastRefreshed(formattedTime);
                        }
                        
                        setLastRunStatus(runStatus);
                    })
                    .catch(error => {
                        console.error('Failed to update the Inspection Status ' + error);
                    })
    }

    useEffect(() => {
        getLastRunTime();
    }, [refreshProp])

    return (
        <>
            <p style={{margin:'10px 20px'}}>
                <CTooltip
                    content={`
                        The last refresh time indicates the last time the Vauto Appraisal data was refreshed to the system. A green circle represents a succesful update. 
                        A red circle indicates a failure. If you do see a failure, please reach out for assistance
                    `}
                >
                    <CIcon 
                    content={cibCircle} 
                    style={{
                        background: `${lastRunStatus == true ? 'green' : 'red'}`,
                        borderRadius: '16px',
                        margin: '0px 5px',
                        color: 'transparent',
                    }}  
                    />
                </CTooltip>
                    Last Refresh: 
                    <span style={{ margin: '0px 2px', fontWeight: 'bold' }}>
                        {lastRefreshed ? `${lastRefreshed}` : 'Loading'}
                    </span>
                {/* {lastRefreshed && Object.keys(lastRefreshed).length > 0 ? 
                    lastRefreshed.appraisal_batch_run_log_batch_run_time: 'Loading'} */}
            </p>
        </>
    )
}