import React, {useState, useEffect} from 'react';
import { CCard, CCardHeader, CCardBody, CDataTable, CButton} from '@coreui/react';
import RunListManager from '../../../../services/RunListManager';
import {Loader} from 'semantic-ui-react';
import Error from '../../../generic/Error/Error';
import OLOContainer from '../../OLOContainer/OLOContainer'

export default ({vin, channel, auctionDate})=>{

    const [tires, setTires] = useState([]);
    const [loading, setLoading] = useState(false)
    const [collapsed, setCollapsed] = useState(false);
    const {addError} = Error();

    useEffect( ()=>{
        var ignore = false;
        if(collapsed) return;
        var RL = new RunListManager();
        setLoading(true);
        setTires([]);
        RL.getVehicleDetail(vin, "tires", channel, auctionDate).then( (data)=>{
            if(!ignore){
                if(data.error) return addError(data.message, 500);
                setTires(data.data.tires);
                setLoading(false)
            }
        }).catch( (e)=>{
            console.log(e); 
            addError(e.message, 500);
            setLoading(false)
        })
        return ()=>{ignore =true;}
    }, [vin, collapsed])

    const toggleCollapsed = (e)=>{e.preventDefault();setCollapsed(!collapsed)}

    const fields = {
        "SIMULCAST": ['depth', 'manufacturer', 'size', 'rimDiameter'],
        "LiveBlock": ['treadDepth', 'tireMakerName','tireLocation', 'tireCondition']
    }

    return <OLOContainer header="Tires">
            <CDataTable
                fields={fields[channel]}
                items={tires}
                loading={loading}
                scopedSlots={
                    {
                        'depth': (item)=>{
                            return <td>
                                {item.depth ? item.depth : ''}
                            </td>
                        },
                        'manufacturer': (item)=>{
                            return <td>
                                {item.manufacturer ? item.manufacturer : ''}
                            </td>
                        },
                        'size': (item)=>{
                            return <td>
                                {item.size ? item.size : ''}
                            </td>
                        },
                        'rimDiameter': (item)=>{
                            return <td>
                                {item.rimDiameter ? item.rimDiameter : ''}
                            </td>
                        },
                        'treadDepth': (item)=>{
                            return <td>
                                {item.treadDepth ? item.treadDepth : ''}
                            </td>
                        },
                        'tireMakerName': (item)=>{
                            return <td>
                                {item.tireMakerName ? item.tireMakerName : ''}
                            </td>
                        },
                        'tireLocation': (item)=>{
                            return <td>
                                {item.tireLocation ? item.tireLocation : ''}
                            </td>
                        },
                        'tireCondition': (item)=>{
                            return <td>
                                {item.tireCondition ? item.tireCondition : ''}
                            </td>
                        }

                    }
                }
            />

        </OLOContainer>

}