import React from 'react'
import {
    CCard,
    CCardBody,
    CDataTable,
    CBadge,
    CInput
} from '@coreui/react';



const data = [
    {
        'location':'WPB',
        "recent_sales": 5,
        'active':'10',
        'in_prep': '5',
        'total':'15'
    },
    {
        'location':'MIA',
        "recent_sales": 5,
        'active':'3',
        'in_prep': '6',
        'total':'9'
    },
    {
        'location':'ORL',
        "recent_sales": 5,
        'active':'7',
        'in_prep': '5',
        'total':'12'
    },
    {
        'location':'BRO',
        "recent_sales": 5,
        'active':'3',
        'in_prep': '2',
        'total':'5'
        },
        {
        'location':'TOTAL',
        "recent_sales": 20,
        'active':'23',
        'in_prep': '18',
        'total':'41',
        '_classes':'table-dark'
    }
]

export default ({edit})=>{

    return(
        <CCard className="w-100">
            <CCardBody>
                <div className="d-flex justify-content-between p-3">
                    <div>
                        <div className="inventory_label">Make / Model</div>
                            <div className="inventory_value">Audi Q5</div>
                    </div>
                    <div>
                            <div className="inventory_label">Current Day Supply</div>
                            <div className="inventory_value">46 days</div>
                            <div className="pt-3"><CBadge color="success">On Target</CBadge></div>
                        </div>
                        <div>
                            <div className="inventory_label">Target</div>
                            <div className="inventory_value">{edit ? <CInput value="45"/>:'45 days'}</div>
                        </div>
                        <div>
                            <div className="inventory_label">Amber For Buyers At:</div>
                            <div className="inventory_value">{edit ? <CInput value="50"/>:'50 days'}</div>
                        </div> 
                        <div>
                            <div className="inventory_label">Red For Buyers At:</div>
                            <div className="inventory_value">{edit ? <CInput value="60"/>:'60 days'}</div>
                        </div>
                </div>
            </CCardBody>
        </CCard>
    )
}