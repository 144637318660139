import React, { useState, useEffect } from "react";
import {
  CCard,
  CCardHeader,
  CCardBody,
  CCardFooter,
  CButton,
  CDataTable,
  CBadge,
  CSelect,
  CTooltip,
  CWidgetBrand
} from "@coreui/react";
import { useHistory } from "react-router-dom";
import RunListManager from "../../../services/RunListManager";
import update from "immutability-helper";
import { isNumber } from "lodash";
import FormattedNumber from '../../FormattedNumber/FormattedNumber';
import CIcon from '@coreui/icons-react'
import {freeSet} from '@coreui/icons'
import Error from "../../generic/Error/Error";

var fields = [
  {
    label: "Make",
    key: "dimension1",
    _classes: "inventory-label-column make align-middle",
  },
  {
    label: "Model",
    key: "dimension2",
    _classes: "inventory-label-column model align-middle",
  },
  {
    label: "Live / In Prep",
    key: "LiveInventory",
    _classes: "inventory-data-point total align-middle",
  },
  {
    label: "Total Inventory",
    key: "NumInPrep",
    _classes: "inventory-data-point total align-middle",
  },
  {
    label: "Current Days Supply",
    key: "NetDemand",
    _classes: "inventory-data-point total align-middle",
  },
  {
    label: "30 Days Supply",
    key: "Supply30Day",
    _classes: "inventory-data-point days_supply target",
  },
  {
    label: "45 Days Supply",
    key: "Supply45Day",
    _classes: "inventory-data-point days_supply target",
  },
  {
    label: "60 Days Supply",
    key: "Supply60Day",
    _classes: "inventory-data-point days_supply target",
  },
];

var newVal = '';
var items = [];

export default (props) => {
    const history = useHistory();

    const { addError } = Error();

    const [inventory, setInventory] = useState([]);
    const [sortBy, setSortBy] = useState("LiveInventory_DESC");
    const [totalLiveInventory, setTotalLiveInventory] = useState(0)
    const [totalInPrepInventory, setTotalInPrepInventory] = useState(0)
    const [loading, setLoading] = useState(false);
    
    var rl = new RunListManager();

    useEffect(() => {
      var ignore = false;
      
      var d = new Date();
      var dateStr = formatDate(d);
      setLoading(true);
      rl.getDaysSupply({ sortBy: sortBy, date: dateStr })
        .then((data) => {
          if (!ignore) {
            if (data.error) return addError(data.message, 500);
              setInventory(data.data.days_supply);
              var liveInv = 0;
              var prepInv = 0;
              console.log("Inventory Data is:", data.data.days_supply);
              for (let i = 0; i < data.data.days_supply.length; i++) {
                liveInv =
                  liveInv + data.data.days_supply[i].LiveInventory;
                prepInv =
                  prepInv + data.data.days_supply[i].NumInPrep;
              }
              setTotalLiveInventory(liveInv);
              setTotalInPrepInventory(prepInv);
            setLoading(false);
          }
        })
        .catch(function (e) {
          console.log(e);
          addError(e.message, 500);
        });
    }, [sortBy]);


    let updateInventory = (currentSortBy) => {
      console.log(currentSortBy);
      console.log('test')
      return;

    };

    const LiveInventory = (currentSortBy) => {
        return <label>{totalLiveInventory}</label>;
    }

    const formatDate = (d) => {
      var dStr = d.getFullYear() + "-";
      var mn = d.getMonth();
      dStr += mn < 9 ? "0" + (mn + 1) : mn + 1;
      dStr += "-";
      var day = d.getDate();
      dStr += day < 10 ? "0" + day : day;
      return dStr;
    };

    const currentDaySupply = (netDemand, inv) => {
      var current_days_supply = inv / ((inv + 1) ** netDemand - 1);
      return Math.round(current_days_supply);
    };

    const changeSort = (e) => {
        console.log("changeSort called");
      newVal = e.target.options[e.target.selectedIndex].value;
      var splitVal = newVal.split("_");
      if (splitVal[0] == "DaySupply") {
        dsSort(splitVal[1]);
      } else {
          setSortBy(newVal);
        }
        
    };

    const dsSort = (direction) => {
      var sortCoefficient = direction == "DESC" ? 1 : -1;
      var newList = update(inventory, {
        $apply: (item) => {
          var newItems = item.map((i, index) => {
            i["days_supply"] = currentDaySupply(i.NetDemand, i.LiveInventory);
            return i;
          });
          newItems.sort((a, b) => {
            if (a["days_supply"] > b["days_supply"]) {
              return sortCoefficient * -1;
            }
            if (a["days_supply"] < b["days_supply"]) {
              return sortCoefficient;
            }
            return 0;
          });
          return [...newItems];
        },
      });
        setInventory(newList);
    };

    return (
      <div>
        {/* <CWidgetBrand
          color="twitter"
          rightHeader={<FormattedNumber value={totalLiveInventory}/>}
          rightFooter="Live Inventory"
          leftHeader={<FormattedNumber value={totalInPrepInventory}/>}
          leftFooter="In Prep"

        >
          <CIcon content={freeSet.cilCarAlt} size="3xl" className="my-3 mr-2"/> 
          <span className="font-weight-bold h5 mb-0 pb-0">Current Inventory</span>
        </CWidgetBrand> */}
        <section>
          <CCard>
            <CCardHeader>
              <div className="d-flex justify-content-between">
                <div>Days Supply by Make/Model</div>
                <div>
                  <CSelect defaultValue={sortBy} onChange={changeSort}>
                    <option value="LiveInventory_DESC">
                      Inventory High to Low
                    </option>
                    <option value="LiveInventory_ASC">
                      Inventory Low to High
                    </option>
                    <option value="DaySupply_DESC">
                      Days Supply Largest to Smallest
                    </option>
                    <option value="DaySupply_ASC">
                      Days Supply Smallest to Largest
                    </option>
                  </CSelect>
                </div>
              </div>
            </CCardHeader>
            <CCardBody>
              <CDataTable
                overTableSlot={
                  <div className="topLabel">Vehicles Required For:</div>
                }
                hover
                fields={fields}
                items={inventory}
                striped
                pagination
                itemsPerPage={15}
                tableFilter
                loading={loading}
                scopedSlots={{
                    model: (item) => {

                    return (
                      <td className="inventory-label-column model align-middle">
                        {item.model}
                      </td>
                    );
                  },

                  LiveInventory: (item) => {
                    //THIS IS SHOW BOTH LIVE AND IN PREP IN THE TABLE
                    return (
                      <td className="inventory-data-point total align-middle">
                        {item["LiveInventory"]} / {item["NumInPrep"]}
                      </td>
                    );
                  },

                  NumInPrep: (item) => {
                    //THIS KEY IS SHOWING TOTAL IN THE TABLE
                    var total = item["LiveInventory"] + item["NumInPrep"];
                    return (
                      <td className="inventory-data-point total align-middle">
                        {total}
                      </td>
                    );
                  },

                  NetDemand: (item) => {
                    var ds = currentDaySupply(
                      item.NetDemand,
                      item.LiveInventory + item.NumInPrep
                    );
                    var className = "";
                    if (isNumber(ds)) {
                      className = ds < 30 ? "blue" : "";
                      className = ds < 20 ? "red" : className;
                    }

                    if (item.LiveInventory < 1) {
                      return (
                        <td
                          className={`inventory-label-column total align-middle`}
                        >
                          <CTooltip content={"Current Inventory is 0"}>
                            <div>0</div>
                          </CTooltip>
                        </td>
                      );
                    }
                    if (item.NetDemand == null) {
                      return (
                        <td
                          className={`inventory-label-column total align-middle`}
                        >
                          <CTooltip content={"Net Demand Can't Be Calculated"}>
                            <div>{"Unknown"}</div>
                          </CTooltip>
                        </td>
                      );
                    }
                    return (
                      <td
                        className={`inventory-label-column ${className} total align-middle`}
                      >
                        {ds}
                      </td>
                    );
                  },

                  Supply30Day: (item) => {
                    var ds = isNaN(item["Supply30Day"])
                      ? "-"
                      : Math.round(item["Supply30Day"]);
                    return (
                      <td
                        className={`inventory-data-point days_supply target align-middle`}
                      >
                        {ds}
                      </td>
                    );
                  },
                  Supply45Day: (item) => {
                    var ds = isNaN(item["Supply45Day"])
                      ? "-"
                      : Math.round(item["Supply45Day"]);
                    return (
                      <td
                        className={`inventory-data-point days_supply target align-middle`}
                      >
                        {ds}
                      </td>
                    );
                  },
                  Supply60Day: (item) => {
                    var ds = isNaN(item["Supply60Day"])
                      ? "-"
                      : Math.round(item["Supply60Day"]);
                    return (
                      <td
                        className={`inventory-data-point days_supply target align-middle`}
                      >
                        {ds}
                      </td>
                    );
                  },
                }}
              />
            </CCardBody>
            <CCardFooter className="d-flex justify-content-end">
              <div className="d-flex align-items-center justify-content-start">
                <div className="inventory_legend_box red mr-2"></div>
                <div className="inventory_legend_label mr-3">
                  Days Supply Under 20 Days
                </div>
                <div className="inventory_legend_box blue mr-2"></div>
                <div className="inventory_legend_label mr-3">
                  Days Supply Under 30 Days
                </div>
              </div>
            </CCardFooter>
          </CCard>
        </section>
      </div>
    );
};
