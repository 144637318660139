import React, {useState, useEffect} from 'react';
import { CContainer, CRow, CCol, CCard, CCardHeader, CCardBody, CSelect} from '@coreui/react';
import RunListManager from '../../../services/RunListManager';
import { getStyle, hexToRgba } from '@coreui/utils'
import {useLocation} from 'react-router-dom';
import InventoryChart from './ChartSegment/InventoryChart';
import ChartHelper from './ChartSegment/ChartHelper';
import {useHistory} from 'react-router-dom';

export default (props)=>{
    const location = useLocation();
    const [runOnce] = useState(0);
    const [chartData, setChartData] = useState([]);
    const [vehicleModels, setVehicleModels] = useState([]);
    const [vehicleMakes, setVehicleMakes] = useState([]);
    const [make, setMake] = useState("BMW");
    const [upperXAxis, setUpperXAxis] = useState(5);
    const [allData, setAllData] = useState([]);
    const [yHighlights, setYHighlights] = useState([]);
    const [hasChartData, setHasChartData] = useState(false);

    const history = useHistory();

    useEffect(()=>{
        let makeContext = ChartHelper.setMakeFromLocation(location, setMake);

        var rl = new RunListManager();
        rl.getDaysSupply({limit:5000, "sortBy": 'LiveInventory_DESC', date:ChartHelper.formatDate(new Date())}).then( (data)=>{
            setAllData(data);
            ChartHelper.reshapeDataByModel(data, makeContext, setVehicleMakes, setVehicleModels, setChartData, setHasChartData, setUpperXAxis, setYHighlights);
        }).catch(function(e){
            console.log(e);
        })
    },[runOnce])

    const yAxisLabeler =(value, index, values)=> {
        return vehicleModels[vehicleModels.length-value];
    }

    const tooltipsTitler =(tooltipItem, data)=> {
        return vehicleModels[vehicleModels.length-tooltipItem[0].yLabel];
    }
    const tooltipsLabeler =(tooltipItem, data)=> {
        return tooltipItem.xLabel;
    }
    const chartsClickConsumer =(evt, chart)=> {
        var activePoint = chart.getElementsAtEvent(evt);
        if (activePoint && activePoint.length > 0) {
            //console.log("charts clicked activePoint=", activePoint, vehicleModels[activePoint._index+1]);
            let make = vehicleModels[activePoint[0]._index+1];
            if (!make) make = vehicleModels[vehicleModels.length-1];
            //console.log(make, value);
            history.push(`/inventory/chart/model?make=${make}`);    
        } else {
            var mouseY;
			var e = evt.originalEvent || evt;
			var canvas = evt.currentTarget || evt.srcElement;
			var boundingRect = canvas.getBoundingClientRect();
			if (e.touches){
				mouseY = e.touches[0].clientY - boundingRect.top;
			}
			else{
				mouseY = e.clientY - boundingRect.top;
			}

            
            let yAxes = chart.scales['y-axis-1'];
            let value = Math.round(yAxes.getValueForPixel(mouseY));

            let labelHalfHeight = (yAxes._gridLineItems[1].y1 - yAxes._gridLineItems[0].y1)/2;
            let yPos = yAxes._gridLineItems.ticksLength - value;
            if (yPos > 0 && yPos < (yAxes._gridLineItems.ticksLength-1)) {
                if ((yAxes._gridLineItems[yPos-1].y1 + labelHalfHeight) > mouseY) yPos = yPos -1;
            }

            if (yPos >= vehicleModels.length) {
                yPos = vehicleModels.length -1;
            }
            if (yPos >=0 && yPos < vehicleModels.length) {
                let model = vehicleModels[yPos];
                if (!model) model = vehicleModels[vehicleModels.length-1];
                //console.log(make, value);
                history.push(`/inventory/chart/year?make=${make}&model=${model}`);    
            }    
        }
    }

    return (
        <CContainer fluid className="fullHeight">
            <CRow className="fullHeight">
                <CCol>
                    <CCard className="fullHeight">
                        <CCardHeader>
                            <div className="d-flex justify-content-between">
                                <div>Live Inventory and In Prep and 45 days supply for <b>{make}</b></div>
                                <div>
                                    <CSelect onChange={
                                            (e)=>{ 
                                                var make = e.target.options[e.target.selectedIndex].value;
                                                ChartHelper.reshapeDataByModel(allData, make, setVehicleMakes, setVehicleModels, setChartData, setHasChartData, setUpperXAxis, setYHighlights);
                                                setMake(make);
                                            }}>
                                        <option value="0">Select Make</option>
                                        {vehicleMakes && vehicleMakes.map( (m, k)=>{
                                            return <option value={m} key={m}>{m}</option>
                                        })}
                                    </CSelect>
                                </div>
                            </div>
                        </CCardHeader>
                        <CCardBody>
                            <div className="chart_legends">
                                <span className="chart_legends_live_inventory"></span> Live Inventory and In Prep &nbsp;&nbsp;
                                <span className="chart_legends_days_supply"></span> 45 Days Supply
                            </div>
                            { hasChartData && 
                            <InventoryChart
                                    chartData={chartData}
                                    yHighlights={yHighlights}
                                    yAxisLabeler={yAxisLabeler}  
                                    tooltipsTitler={tooltipsTitler}
                                    chartsClickConsumer={chartsClickConsumer}
                                    maxYAxis={vehicleModels.length}
                                    maxXAxis={upperXAxis}
                                    longestYLabel={ChartHelper.longestModel}
                                    yAxisLabel={make + ' Models'} />
                                }
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </CContainer>
    )
}