import React from 'react'
import FormattedNumber from '../../../../FormattedNumber/FormattedNumber'
import styled from 'styled-components'
import { Form } from 'semantic-ui-react'

const Item = styled.div`
  font-weight:900;
  font-size:14px;
  color:#686868;
  padding:0 10px 3px;
  white-space: nowrap;
  text-align:center;
`

const ItemLabel = styled.div`
    font-size:12px;
    color:#686868;
    font-weight:500;
    padding: 0 4px;
    text-align:center;
    white-space: nowrap;
`


export default (props)=>{

    return(
        <div className={props.border && ''} style={props.style}>
            <Item>
                {props.number && <FormattedNumber value={props.value} price={props.price} decimal={props.decimal}/>}
                {!props.number && props.value}
            </Item>
            <ItemLabel>
                {props.label}
            </ItemLabel>
        </div>
    )
}

