import React, {useState, useEffect} from 'react';
import {CRow, 
    CCol, 
    CForm, 
    CFormGroup, 
    CCard, 
    CCardBody, 
    CCardHeader, 
    CCardFooter,
    CButton, 
    CLabel, 
    CInput, 
    CFormText,
    CSelect
} from "@coreui/react";
import RunListManager from '../../../services/RunListManager';
import updater from 'immutability-helper';
import Error from '../../generic/Error/Error';


export default ({settings, setSettings, setFilterPane, setCurrentIndex, channel})=>{
    const [makes, setMakes] = useState({});
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(false);
    const {addError} = Error();
    
    useEffect( ()=>{
        var ignore = false;
        var rl = new RunListManager();
        setLoading(true);

        rl.getFilterOptions({
            auction_location: settings.auctionLocation,
            auction_date: settings.auction_date,
            auction_lane: settings.auction_lane,
            start_time: settings.start_time
        }, channel).then( (res)=>{
            if(!ignore){
                if(res.error) return addError(res.message, 500);
                setMakes(res.data.makes);
                setLoading(false);
            }
        }).catch((e)=>{
            console.log(e);
            addError(e.message, 500);
            if(!ignore) setLoading(false);
        })
        return ()=>{ignore = true;}
    }, [settings.auctionLocation, settings.auction_lane, settings.auction_date])


    useEffect(()=>{
        setFilters(settings);
    }, [settings])

    const selectChange = (field)=>{
        return (e)=>{
            var val = e.target.options[e.target.selectedIndex].value;
            var newFilters = {...filters};
            newFilters[field] = val;
            if(field == 'make') newFilters['model'] = '';
            setFilters(newFilters);
        }
    }    

    const fieldChange = (field)=>{

        return (e)=>{
            var val = e.target.value;
            var newFilters = {...filters};
            newFilters[field] = val;
            setFilters(newFilters);
        }

    }

    const updateFilters = ()=>{
        var newSettings = updater(settings, {$apply: (u)=>{
           u = {...filters};
           return {...u}
        }})
        setSettings(newSettings);
        setCurrentIndex(-1); //hides the detail panel until the new listings load
        setFilterPane(false);
    }

    const clearFilters = ()=>{
        var newFilters = updater(filters, {$apply: (u)=>{
            u['make'] = '';
            u['model'] = '';
            u['year_min'] = '';
            u['year_max'] = '';
            u['CRScore_min'] = '';
            u['CRScore_max'] = '';
            u['mmr_min'] = '';
            u['mmr_max'] = '';
            u['frameDamage'] = '';
            u['accidentStatus'] = '';
            u['structuralDamage'] = '';
            u['daySupply_min'] = '';
            u['daySupply_max'] = '';
            u['trim'] = '';
            u['drivetrain'] = '';
            u['shoppingList'] = '';
            u['mileage_min'] = '';
            u['mileage_max'] = '';
            return {...u}
         }})
        var items = document.getElementsByClassName("filterField");
        for(var i = 0 ; i < items.length; i++){
            items[i].setAttribute('value', '')
        }
        setFilters(newFilters);
        updateFilters();
    }
    
    return(
        <CRow>
            <CCol md="10" lg="6" className="offset-md-1 offset-lg-3 py-5">
                <CCard>
                    <CCardHeader>
                        Filter Listings
                    </CCardHeader>
                    <CCardBody>
                        <CForm>
                            {
                            /*
                            <CFormGroup>
                                <CLabel>List Sorting</CLabel>
                                <CSelect value={filters['sortBy']} onChange={selectChange('sortBy')}>
                                    <option value="make_ASC,model_ASC,year_DESC,CRScore_DESC">Make/Model A-Z and Year/CR 9-0</option>
                                    <option value="make_DESC,model_DESC,year_DESC,CRScore_DESC">Make/Model Z-A and Year/CR 9-0</option>
                                    <option value="">Run Number</option>
                                </CSelect>
                            </CFormGroup>
                            */
                            }
                            <CFormGroup>
                                <CLabel>Select Make</CLabel>
                                <CSelect value={filters['make']} onChange={selectChange('make')}>
                                    <option value="">{loading ? 'Loading...' :'Select Make'}</option>
                                    {Object.keys(makes).map( (item)=>{
                                        return <option value={item}>{item}</option>
                                    })}
                                </CSelect>
                            </CFormGroup>
                            <CFormGroup>
                                <CLabel>Select Model</CLabel>
                                <CSelect value={filters['model']} onChange={selectChange('model')}>
                                    <option value="">{loading ? 'Loading...' :'Select Model'}</option>
                                    {(filters['make'] && makes[filters['make']]) && makes[filters['make']].map( (item)=>{
                                        return <option value={item}>{item}</option>
                                    })}
                                </CSelect>
                                <CFormText>{!filters['make'] && 'Select a Make to populate Model options'}</CFormText>
                            </CFormGroup>
                            <CFormGroup>
                                <CLabel>Mileage Filter</CLabel>
                                <CRow>
                                    <CCol md="6">
                                        <CInput defaultValue={filters['mileage_min']} onBlur={fieldChange('mileage_min')} name="mileage_min" className="filterField"/>
                                        <CFormText >Min Mileage</CFormText>
                                    </CCol>
                                    <CCol md="6">
                                        <CInput defaultValue={filters['mileage_max']} onBlur={fieldChange('mileage_max')} name="mileage_max" className="filterField"/>
                                        <CFormText >Max Mileage</CFormText>
                                    </CCol>
                                </CRow>
                            </CFormGroup>
                            <CFormGroup>
                                <CLabel>Year Filter</CLabel>
                                <CRow>
                                    <CCol md="6">
                                        <CInput defaultValue={filters['year_min']} onBlur={fieldChange('year_min')} name="year_min" className="filterField"/>
                                        <CFormText >Min Year</CFormText>
                                    </CCol>
                                    <CCol md="6">
                                        <CInput defaultValue={filters['year_max']} onBlur={fieldChange('year_max')} name="year_max" className="filterField"/>
                                        <CFormText >Max Year</CFormText>
                                    </CCol>
                                </CRow>
                            </CFormGroup>
                            <CFormGroup>
                                <CLabel>CR Score</CLabel>
                                <CRow>
                                    <CCol md="6">
                                        <CInput defaultValue={filters['CRScore_min']} onBlur={fieldChange('CRScore_min')} name="CR_min" className="filterField"/>
                                        <CFormText >Min CR Score</CFormText>
                                    </CCol>
                                    <CCol md="6">
                                        <CInput defaultValue={filters['CRScore_max']} onBlur={fieldChange('CRScore_max')} name="CRScore_max" className="filterField"/>
                                        <CFormText>Max CR Score</CFormText>
                                    </CCol>
                                </CRow>
                            </CFormGroup>
                            <CFormGroup>
                                <CLabel>MMR</CLabel>
                                <CRow>
                                    <CCol md="6">
                                        <CInput defaultValue={filters['mmr_min']} onBlur={fieldChange('mmr_min')} name="mmr_min" className="filterField"/>
                                        <CFormText >Min MMR</CFormText>
                                    </CCol>
                                    <CCol md="6">
                                        <CInput defaultValue={filters['mmr_max']} onBlur={fieldChange('mmr_max')} name="mmr_max" className="filterField"/>
                                        <CFormText>Max MMR</CFormText>
                                    </CCol>
                                </CRow>
                            </CFormGroup>
                            <CFormGroup>
                                <CLabel>Frame Damage / Accident Status</CLabel>
                                <CRow>
                                    <CCol md="6">
                                         <CSelect value={filters['frameDamage']} onChange={selectChange('frameDamage')}>
                                            <option value="">Any</option>
                                            <option value="1">Frame Damage</option>
                                            <option value="0">No Frame Damage</option>
                                         </CSelect>
                                    </CCol>
                                    <CCol md="6">
                                         <CSelect value={filters['accidentStatus']} onChange={selectChange('accidentStatus')}>
                                            <option value="">Any</option>
                                            <option value="1">Previous Accident</option>
                                            <option value="0">No Previous Accident</option>
                                         </CSelect>
                                    </CCol>
    
                                </CRow>
                            </CFormGroup>
                            <CFormGroup>
                                <CLabel>Days Supply</CLabel>
                                <CRow>
                                    <CCol md="6">
                                        <CInput defaultValue={filters['daySupply_min']} onBlur={fieldChange('daySupply_min')} name="daySupply_min" className="filterField"/>
                                        <CFormText >Min Days Supply</CFormText>
                                    </CCol>
                                    <CCol md="6">
                                        <CInput defaultValue={filters['daySupply_max']} onBlur={fieldChange('daySupply_max')} name="daySupply_max" className="filterField"/>
                                        <CFormText>Max Days Supply</CFormText>
                                    </CCol>
                                </CRow>
                            </CFormGroup>
                            <CFormGroup>
                                <CLabel>Trim Filter / Structural Damage</CLabel>
                                <CRow>
                                    <CCol md="6">
                                        <CInput defaultValue={filters['trim']} onBlur={fieldChange('trim')} name="trim" className="filterField"/>
                                    </CCol>
                                    <CCol md="6">
                                         <CSelect value={filters['structuralDamage']} onChange={selectChange('structuralDamage')}>
                                            <option value="">Any</option>
                                            <option value="1">Structural Damage</option>
                                            <option value="0">No Structural Damage</option>
                                         </CSelect>
                                         <CFormText >Structural Damage</CFormText>

                                    </CCol>
                                </CRow>
                            </CFormGroup>
                            <CFormGroup>
                                <CLabel>Drivetrain Filter / Shopping List</CLabel>
                                <CRow>
                                    <CCol md="6">
                                         <CSelect value={filters['drivetrain']} onChange={selectChange('drivetrain')}>
                                         {channel === "LiveBlock" ? (
                                                <>
                                                <option value="">Select Drivetrain</option>
                                                <option value="0">2 Wheel Drive</option>
                                                <option value="1">4 Wheel Drive</option>
                                                <option value="2">All Wheel Drive</option>
                                                <option value="3">Front Wheel Drive</option>
                                                <option value="4">Rear Wheel Drive</option>
                                                </>
                                            ) : (
                                                <>
                                                <option value="">Select Drivetrain</option>
                                                <option value="0">2 Wheel Drive</option>
                                                <option value="1">4 Wheel Drive</option>
                                                <option value="2">All Wheel Drive</option>
                                                </>
                                            )}
                                         </CSelect>
                                         <CFormText >Select Drivetrain</CFormText>
                                    </CCol>
                                    <CCol md="6">
                                         <CSelect value={filters['shoppingList']} onChange={selectChange('shoppingList')}>
                                            <option value="">Shopping List</option>
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                         </CSelect>
                                         <CFormText >Shopping List</CFormText>

                                    </CCol>
                                </CRow>
                            </CFormGroup>
                        </CForm>
                    </CCardBody>
                    <CCardFooter>
                        <CButton color="primary" size="large" onClick={updateFilters}>Apply</CButton>
                        &nbsp;&nbsp;
                        <CButton color="danger" size="large" onClick={clearFilters}>Clear Filters</CButton>
                    </CCardFooter>
                </CCard>
            </CCol>
        </CRow>
    )
}


   /* const switchAction = (e,v)=>{

    }*/