import React from 'react';
import {CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem} from '@coreui/react'

export const InspectionDropDown = (props) => {
    const {currentSelection, onChangeHandler, placement, dropDownoptions = []} = props;

    return (
        <div>
          <CDropdown 
            // className="mt-2"
          >
            <CDropdownToggle caret 
            style={{
              padding: "5.25px 10.5px", 
              height: "24px",
              width: "91.3px",
              fontSize: "12.5px",
              fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
              fontWeight: "700",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
          }}
                color={currentSelection == 'In Progress' ? 'info' : currentSelection == 'Complete' ? 'success' : 'secondary'}
            >
              {currentSelection}
            </CDropdownToggle>
            <CDropdownMenu 
                onClick={(e) => onChangeHandler(e.target.innerText)}
                placement={placement}
            >
                {
                    dropDownoptions.map(item => (
                        <CDropdownItem>{item}</CDropdownItem>
                    ))
                }
            </CDropdownMenu>
          </CDropdown>
        </div>
      )
}