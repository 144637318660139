import React, {useState, useEffect} from 'react';
import {CContainer, CRow, CCol} from '@coreui/react'
import MasterView from './MasterView/MasterView2';
import FilterPane from './FilterPane/FilterPane';
import {decodeLocation} from './MainEffect';
import RunListManager from '../../services/RunListManager'
import {useLocation, useParams} from 'react-router-dom';
import update from 'immutability-helper';
import Error from '../generic/Error/Error';
import NewPreview from './NewPreview/NewPreview'


export default (props)=>{
    const location = useLocation();
    const [vehicles, setVehicles] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [totalListings, setTotalListings] = useState(0);
    const [settings, setSettings] = useState({});
    const [loading, setLoading] = useState(false);
    const [filterPane, setFilterPane] = useState(false);
    const decodedLocation = decodeLocation(location.search);
    const {channel} = useParams()
    const {addError} = Error();

    useEffect( ()=>{
        var ignore = false;
        var RL = new RunListManager();
          
        var vin = decodedLocation['vin']
        setLoading(true);     

        RL.getVehicles(settings, channel).then( (data)=>{
            if(data.error) return addError(data.message, 500);
            var filteredVehicles = update(data.data.vehicles, {$apply: (v)=>{
                if(!vin) return v;
                var found = false;
                var newV = [];
                for(var i = 0; i < v.length ; i++){
                    if(v[i]['vin'] == vin){
                        found = true;
                        newV = v.slice(i);
                        break;
                    }
                }
                return found ? newV  : v;
            }})

            if(!ignore){
                setVehicles(filteredVehicles);
                setTotalListings(data.data.total_count);
                setCurrentIndex(0);
                setLoading(false);
            }

        }).catch((e)=>{
            console.log(e); 
            addError(e.message, 500);
            setLoading(false)});
        return ()=>{ignore = true;}
    }, [settings, channel])

    useEffect( ()=>{
        var filters = {...decodedLocation, ...settings}
        setSettings(filters);
    }, [])

    const setCurrentIndexWrapper =(data)=> {
        setCurrentIndex(data);
        var field = document.getElementById('bsrp')
        if(field) field.focus();
    }
    
    return(
        <CContainer fluid className="fullHeight">
            <CRow className="fullHeight" gutters={false}>
                <CCol lg="2" md="3"  className="fullHeight whitebg relativeBox">
                    <MasterView
                        loading={loading}
                        vehicles={vehicles}
                        currentIndex={currentIndex}
                        setCurrentIndex={setCurrentIndexWrapper}
                        settings={settings}
                        setSettings={setSettings}
                        totalListings={totalListings}
                        filterPane={filterPane}
                        setFilterPane={setFilterPane}
                        channel={channel}
                    />
                </CCol>
                <CCol lg="10" md="9" >
                        {filterPane && 
                        <FilterPane 
                            setFilterPane={setFilterPane} 
                            settings={settings} 
                            setSettings={setSettings} 
                            setCurrentIndex={setCurrentIndex}
                            channel={channel}
                        />}
                        {(vehicles[currentIndex] && !filterPane) && <>
                            <NewPreview
                                currentIndex={currentIndex}
                                channel={channel}
                                vehicle={vehicles[currentIndex]}
                                updater={(ind, item, increment = true)=>{
                                    var newList = update(vehicles, {$apply: (l)=>{
                                        l[ind] = {...item};
                                        return [...l];
                                    }} )
                                    setVehicles(newList);
                                    //move to the next listing
                                    if(increment && (ind+1) < newList.length){
                                        setCurrentIndex(ind+1);
                                        var bsrp = document.getElementById('bsrp')
                                        if(bsrp) bsrp.focus();
                                    }
                
                                }}
                            />
                        </>}
                </CCol>
            </CRow>
        </CContainer>
    )
}