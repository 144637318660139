import React, {useState, useEffect} from 'react'
import {CInput} from '@coreui/react'




export default (props)=>{

    const updateTrim = function(e){
        var trim = document.getElementById('trim_filter');
        var loc = {...props.localFilters}
        loc['trim'] = trim.value
        props.setLocalFilters(loc)
    }

    return(
        <div className="pr-1 flex-grow-1">
            <CInput defaultValue={props.localFilters['trim']} id="trim_filter" size="lg" placeholder="Filter by Trim" onChange={updateTrim}/>
        </div>
    )
}