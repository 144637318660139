import RunListManager from '../../services/RunListManager'


export function decodeLocation(loc) {
    var values = {};
    var filterableFields = ['offset', 'auction_date', 'limit','sortBy', 'make', 'model','mileage_min', 'mileage_max','CRScore_min', 'CRScore_max', 'year_min','year_max','auctionLocation','pickupLocation','pickupLocation_State', 'isReviewed','hasBuyerMaxPrice','isDisqualified','disqualifiedBy','buyerUrgency','auction_lane', 'vin', 'mmr_min', 'mmr_max', 'frameDamage', 'start_time', 'accidentStatus', 'daySupply_min', 'daySupply_max', 'trim', 'structuralDamage', 'drivetrain','shoppingList', 'bodyStyle'];

    for(var i = 0 ; i < filterableFields.length; i++){
        var val = getUrlParameter(loc, filterableFields[i]);
        if(val) values[filterableFields[i]] = val;
    }

    return values;
}


function getUrlParameter(str, name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(str);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

