import React, {useState, useEffect} from 'react';
import { CContainer, CRow, CCol, CCard, CCardHeader, CCardBody, CCardFooter, CButton, CDataTable, CBadge
} from '@coreui/react';
import RunListManager from '../../../services/RunListManager';
import {useHistory} from 'react-router-dom';
import InventoryChart from './ChartSegment/InventoryChart';
import ChartHelper from './ChartSegment/ChartHelper';
//import { color } from 'chart.js/helpers';

export default (props)=>{
    const [runOnce] = useState(0);
    const [hasChartData, setHasChartData] = useState(false);
    const [chartData, setChartData] = useState([]);
    const [vehicleMakes, setVehicleMakes] = useState([]);
    const [upperXAxis, setUpperXAxis] = useState(800);
    const [yHighlights, setYHighlights] = useState([]);

    const history = useHistory();

    useEffect(()=>{
        var rl = new RunListManager();
        rl.getDaysSupply({limit:5000, "sortBy": 'LiveInventory_DESC', date:ChartHelper.formatDate(new Date())}).then( (data)=>{
            ChartHelper.reshapeDataByMake(data, setVehicleMakes, setChartData, setHasChartData, setUpperXAxis, setYHighlights);
        }).catch(function(e){
            console.log(e);
        })
    },[runOnce])

    const yAxisLabeler =(value, index, values)=> {
        return vehicleMakes[vehicleMakes.length-value];
    }
    const tooltipsTitler =(tooltipItem, data)=> {
        return vehicleMakes[vehicleMakes.length-tooltipItem[0].yLabel];
    }
    const tooltipsLabeler =(tooltipItem, data)=> {
        return tooltipItem.xLabel + ' vehicles - ' + data.datasets[tooltipItem.datasetIndex].label;
    }
    const chartsClickConsumer =(evt, chart)=> {
        var activePoint = chart.getElementsAtEvent(evt);
        if (activePoint && activePoint.length > 0) {
            //console.log("charts clicked activePoint=", activePoint, vehicleMakes[activePoint._index+1]);
            let make = vehicleMakes[activePoint[0]._index+1];
            if (!make) make = vehicleMakes[vehicleMakes.length-1];
            //console.log(make, value);
            history.push(`/inventory/chart/model?make=${make}`);    
        } else {
            var mouseY;
			var e = evt.originalEvent || evt;
			var canvas = evt.currentTarget || evt.srcElement;
			var boundingRect = canvas.getBoundingClientRect();
			if (e.touches){
				mouseY = e.touches[0].clientY - boundingRect.top;
			}
			else{
				mouseY = e.clientY - boundingRect.top;
			}

            
            let yAxes = chart.scales['y-axis-1'];
            let value = Math.round(yAxes.getValueForPixel(mouseY));

            let labelHalfHeight = (yAxes._gridLineItems[1].y1 - yAxes._gridLineItems[0].y1)/2;
            let yPos = yAxes._gridLineItems.ticksLength - value;
            if (yPos > 0 && yPos < (yAxes._gridLineItems.ticksLength-1)) {
                if ((yAxes._gridLineItems[yPos-1].y1 + labelHalfHeight) > mouseY) yPos = yPos -1;
            }

            if (yPos >= vehicleMakes.length) {
                yPos = vehicleMakes.length -1;
            }
            if (yPos >=0 && yPos < vehicleMakes.length) {
                let make = vehicleMakes[yPos];
                if (!make) make = vehicleMakes[vehicleMakes.length-1];
                //console.log(make, value);
                history.push(`/inventory/chart/model?make=${make}`);    
            }    
        }
    }

    return (
        <CContainer fluid className="fullHeight">
            <CRow className="fullHeight">
                <CCol>
                    <CCard className="fullHeight">
                    <CCardHeader>
                        <div>Live Inventory and In Prep and 45 days supply for All Makes</div>
                        <small>(Click on the graph to drill down on models)</small>
                    </CCardHeader>
                    <CCardBody>
                        <div className="chart_legends">
                            <span className="chart_legends_live_inventory"></span> Live Inventory and In Prep &nbsp;&nbsp;
                            <span className="chart_legends_days_supply"></span> 45 Days Supply
                        </div>
                        { hasChartData && 
                        <InventoryChart
                                chartData={chartData}
                                yHighlights={yHighlights}
                                yAxisLabeler={yAxisLabeler}  
                                tooltipsTitler={tooltipsTitler} 
                                chartsClickConsumer={chartsClickConsumer}
                                maxYAxis={vehicleMakes.length}
                                maxXAxis={upperXAxis}
                                longestYLabel={ChartHelper.longestMake}
                                yAxisLabel={'Make'} />
                            }
                    </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </CContainer>
    )
}