import React from 'react';



export default (props)=>{
    const formatter = (num)=>{
        var n = parseFloat(num);
        var fixed = props.decimal ? props.decimal : 0
        if(isNaN(n)) return num;
        var formattedNum = n.toFixed(fixed).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        var finalValue = (props.price) ? "$"+formattedNum : formattedNum;
        return (props.label) ? finalValue +" "+props.label : finalValue
     }

    return <>{formatter(props.value)}</>
}