import React , {useState, useEffect} from 'react'
import {
    CDropdownToggle,
    CDropdown,
    CDropdownMenu,
    CDropdownItem,
  } from "@coreui/react";

const dropdownOptions = [
    {
       label: "Select Years",
       indication:null,
       value: ''
    },
    {
      label: "5years or newer",
      indication: "recent",
      value: ""
    },
    {
      label: "6years or older",
      indication: "older",
      value: ""
    },
    {
      label: "10years or newer",
      indication: "less-recent",
      value: ""
    }
  ];

export default (props)=>{

    const [yearsSelection, setYearsSelection] = useState('')

    useEffect(() => {
        var loc = { ...props.localFilters };
        if(loc["years"] === null || loc["years"] === '') setYearsSelection('')
    })

    useEffect(() => {
        var maxYear = new Date().getFullYear();
        dropdownOptions.map(item => {
            if(item.label === '5years or newer'){
                var options = []
                for(var i = maxYear; i >= maxYear - 5; i-=1){
                    options.push(i)
                }
                item.value = options.toString()
            } else if(item.label === '6years or older'){
                var options = []
                for(var i = maxYear - 6; i >= 2015; i-=1){
                    options.push(i)
                }
                item.value = options.toString()
            } else if(item.label === '10years or newer'){
                var options = []
                for(var i = maxYear; i >= maxYear - 10; i-=1){
                    options.push(i)
                }
                item.value = options.toString()
            } else item.value = null
        })
    },[])

    const changeFunc = (e) => {
        var val = e.target.innerText;
        setYearsSelection(val);
      let years = dropdownOptions.filter((item) => {
        if (item.label === val) return item;
      });
      var loc = { ...props.localFilters };
      let yearsVal;
      if(years[0].indication === 'recent'){
        yearsVal = years[0].value
        loc["years"] = yearsVal;
      } else if(years[0].indication === 'older'){
        yearsVal = years[0].value
        loc["years"] = yearsVal;
      }else if(years[0].indication === 'less-recent'){
        yearsVal = years[0].value
        loc["years"] = yearsVal;
      } else{
        loc["years"] = null;
      }
      props.setLocalFilters(loc);
    };


    
    return(
        <CDropdown>
        <CDropdownToggle
          caret
          color="white"
          style={{
            border: "1px solid lightgray",
            padding: "5.25px 10.5px",
            height: "35.88px",
            maxWidth: "125px",
            color: "gray",
            fontSize: "12.5px",
            fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
            fontWeight: "700",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {yearsSelection === '' ? 'Select Years' : yearsSelection}
        </CDropdownToggle>
        <CDropdownMenu onClick={(e) => changeFunc(e)}>
          {dropdownOptions.map((item) => (
            <CDropdownItem>{item.label}</CDropdownItem>
          ))}
        </CDropdownMenu>
      </CDropdown>
       
    )
}