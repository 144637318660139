import React, {useState, useEffect} from 'react';
import { CCard, CCardHeader, CCardBody, CDataTable, CButton} from '@coreui/react';
import RunListManager from '../../../../services/RunListManager';
import {Loader} from 'semantic-ui-react';
import ManheimDamages from './ManheimDamages/ManheimDamages';
import AdesaDamages from './AdesaDamages/AdesaDamages';
import Error from '../../../generic/Error/Error';

export default ({vin, crURL, crAPIEligible, channel, auctionDate})=>{

    const [damages, setDamages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const {addError} = Error();

    useEffect( ()=>{
        var ignore = false;
        var RL = new RunListManager();
        setLoading(true);
        setDamages([]);
        RL.getVehicleDetail(vin, "damages", channel, auctionDate).then( (data)=>{
            if(!ignore){
                if(data.error) return addError(data.message, 500);
                setDamages(data.data.damages);
                setLoading(false)
            }
        }).catch( (e)=>{
            console.log(e);
            addError(e.message, 500);
            setLoading(false)
        })
        return ()=>{ignore =true;}
    }, [vin, channel, auctionDate])

    const toggleCollapsed = (e)=>{e.preventDefault();setCollapsed(!collapsed)}

    const showMessage = ()=>{
        if(loading) return <div>Loading...</div>
        var obj =  <div>{crAPIEligible ? 'No Damages' : 'Condition Report Unavailable'}</div>
        return obj;
    }

    return <>
            <div className="d-flex justify-content-end p-2">
                <a href={crURL} target="_blank">Open Condition Report</a>
            </div>
            {channel == "SIMULCAST" && <ManheimDamages damages={damages} message={showMessage()} loading={loading}/> }
            {channel == "LiveBlock" && <AdesaDamages damages={damages} message={showMessage()} loading={loading}/> }

    </>
}