import React, {useState} from 'react';
import { components } from "react-select";
import { default as ReactSelect } from "react-select";

export const colourOptions = [
    { value: "ocean1", label: "Ocean" },
    { value: "blue", label: "Blue" },
    { value: "purple", label: "Purple" },
    { value: "red", label: "Red" },
    { value: "orange", label: "Orange" },
    { value: "yellow", label: "Yellow" },
    { value: "green", label: "Green" },
    { value: "forest", label: "Forest" },
    { value: "slate", label: "Slate" },
    { value: "silver", label: "Silver" }
  ];

const MultiSelectOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};


export const MultiSelectDropdown = (props) => {
    // const [optionSelected, setOptionSelected] = useState(null);

    // const handleChange = (selected) => {
    //     setOptionSelected(selected);    
    //   };

    return (
        <span
          class="d-inline-block"
          data-toggle="popover"
          data-trigger="focus"
          data-content="Please selecet account(s)"
          style={{width: '80%', margin: '5px 0px'}}
        >   
          <strong>{props.label}:</strong>
          <ReactSelect
            options={props.options}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
                MultiSelectOption
            }}
            onChange={props.handleChange}
            allowSelectAll={true}
            value={props.optionSelected}
          />
        </span>
      );
}
