import React from 'react'
import {
    CCard,
    CCardHeader,
    CCardBody,
    CDataTable
} from '@coreui/react';



const data = [
    {
        'trim': '2.0T Premium Quattro',
        'location':'WPB',
        'vin':'1AV3QDDVSFDS2',
        'price': '21000',
        'mileage':'5,210',
        'target_price': '$21,100',
        'live_date':'11/19/2020'
    },
    {
        'trim': '2.0T Premium Quattro',
        'location':'WPB',
        'vin':'1AV3QDDVSFDS2',
        'price': '21000',
        'mileage':'5,210',
        'target_price': '$21,100',
        'live_date':'11/19/2020'
    },
    {
        'trim': '2.0T Premium Quattro',
        'location':'WPB',
        'vin':'1AV3QDDVSFDS2',
        'price': '21000',
        'mileage':'5,210',
        'target_price': '$21,100',
        'live_date':'11/19/2020'
    },
    {
        'trim': '2.0T Premium Quattro',
        'location':'WPB',
        'vin':'1AV3QDDVSFDS2',
        'price': '21000',
        'mileage':'5,210',
        'target_price': '$21,100',
        'live_date':'11/19/2020'
    },
    {
        'trim': '2.0T Premium Quattro',
        'location':'WPB',
        'vin':'1AV3QDDVSFDS2',
        'price': '21000',
        'mileage':'5,210',
        'target_price': '$21,100',
        'live_date':'11/19/2020'
    },
    {
        'trim': '2.0T Premium Quattro',
        'location':'WPB',
        'vin':'1AV3QDDVSFDS2',
        'price': '21000',
        'mileage':'5,210',
        'target_price': '$21,100',
        'live_date':'11/19/2020'
    },
    {
        'trim': '2.0T Premium Quattro',
        'location':'WPB',
        'vin':'1AV3QDDVSFDS2',
        'price': '21000',
        'mileage':'5,210',
        'target_price': '$21,100',
        'live_date':'11/19/2020'
    }
]

export default (props)=>{

    return(
        <CCard className="w-100">
            <CCardHeader>Inventory Summary</CCardHeader>
            <CCardBody>
                <div className="p-1">
                    <CDataTable
                        fields={['trim','location','vin','price','mileage','target_price','live_date']}
                        items={data}
                    />
                </div>
            </CCardBody>
        </CCard>
    )
}