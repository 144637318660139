import React, { useState } from "react";
import {
  CButton,
  CInput,
} from "@coreui/react";

export const CommentForm = ({ submitLabel, handleSubmit, hasCancelButton=false, handleCancel}) => {

  const [bodyText, setBodyText] = useState("");

  const onSubmit = (event) => {
    event.preventDefault();
    handleSubmit(bodyText)
    setBodyText("");
  }

  return (
    <form onSubmit={onSubmit} style={{ display: "flex", flexDirection: "row", margin: "10px 0" }}>
      <CInput
        type="text"
        placeholder={"Add your comment"}
        value={bodyText}
        disabled={false}
        style={{ background: "white" }}
        onChange={(e) => setBodyText(e.target.value)}
      />
      <CButton
        type="submit"
        style={{
          minWidth: "130px",
          margin: "0 5px",
          border: "none",
          borderRadius: "4px",
          fontWeight: 600,
          backgroundColor: "#00aced",
        }}
        disabled = {bodyText.length == 0}
        color="dark"
        shape="square"
        
      >
        {submitLabel}
      </CButton>
      {hasCancelButton &&  (
        <CButton
        style={{
          minWidth: "100px",
          margin: "0 5px",
          border: "none",
          borderRadius: "4px",
          fontWeight: 600,
          backgroundColor: "#00aced",
        }}
        color="dark"
        shape="square"
        onClick={handleCancel}
      >
          Cancel
        </CButton>
      )}
    </form>
  );
};
