import React, { useState, useEffect} from "react";
import {
  CDropdownToggle,
  CDropdown,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";

const dropdownOptions = [
    {
       label: "All Locations",
       value: null
    },
    {
      label: "Bradenton",
      value: "bradenton"
    },
    {
      label: "Broward",
      value: "broward"
    },
    {
      label: "Houston",
      value: "houston"
    },
    {
      label: "Katy",
      value: "katy"
    },
    {
      label: "Miami",
      value: "miami"
    },
    {
      label: "Orlando",
      value: "orlando"
    },
    {
      label: "West Palm Beach",
      value: "west_palm_beach"
    }
  ];

export default (props) => {

    const [rooftopSelection, setRooftopSelection] = useState(dropdownOptions[0].label)

    useEffect(() => {
      var loc = { ...props.localFilters };
      if(loc["rooftop"] === null || loc["rooftop"] === '') setRooftopSelection(dropdownOptions[0].label)
  })

  const changeFunc = (e) => {
      var val = e.target.innerText;
      setRooftopSelection(val);
    let rooftop = dropdownOptions.filter((item) => {
      if (item.label === val) return item;
    });
    rooftop = rooftop[0].value
    var loc = { ...props.localFilters };
    loc["rooftop"] = rooftop;
    props.setLocalFilters(loc);
  };

  return (
    <CDropdown>
      <CDropdownToggle
        caret
        color="white"
        style={{
          border: "1px solid lightgray",
          padding: "5.25px 10.5px",
          height: "35.88px",
          width: "auto",
          color: "gray",
          fontSize: "12.5px",
          fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
          fontWeight: "700",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* {rooftop === '' ? 'All Locations' : rooftop} */}
        {rooftopSelection}
      </CDropdownToggle>
      <CDropdownMenu onClick={(e) => changeFunc(e)}>
        {dropdownOptions.map((item) => (
          <CDropdownItem>{item.label}</CDropdownItem>
        ))}
      </CDropdownMenu>
    </CDropdown>
  );
};
