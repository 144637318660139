import React, {useState, useEffect} from 'react';
import { Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import {DateTime} from 'luxon';
import DealOfYear from '../../assets/images/dealer_of_the_year.png';
import FiveStarDealer from '../../assets/images/five_star_dealer.png';
import APlus from '../../assets/images/a_plus_rating.png';
import KBB from '../../assets/images/kbb-logo.png';
import ITCAvantGardemedium from '../../assets/fonts/ITC_Avant_Garde_Gothic_Medium.otf';
import ITCAvantGardeBold from '../../assets/fonts/ITC_Avant_Garde_Gothic_Bold.otf';
import ITCAvantGardeStd from '../../assets/fonts/ITC_Avant_Garde_Std.ttf';
import ITCAVGLTBook from '../../assets/fonts/ITC_Avant_Garde_Gothic_LT_Book_Regular.otf';
import  RunListManager  from '../../services/RunListManager';

// Register font
Font.register(
    { family: 'ITCAVG', src: ITCAvantGardemedium }, 
);

Font.register(
    { family: 'ITCAVGBold', src: ITCAvantGardeBold }
);

Font.register(
    { family: 'ITCAVGStd', src: ITCAvantGardeStd }
  );
  
  
  Font.register(
    { family: 'ITCAVGLTBook', src: ITCAVGLTBook }
  );

const styles = StyleSheet.create({
    appraisalContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        backgroundColor: 'white',
        alignItems: 'center',
    },
    appraisalDetailColumn: {
        flexDirection: 'column',
        flex: 6,
        maxWidth: '315px',
        alignSelf: 'flex-start',
        padding: '10px',
        textAlign: 'center'
      },
      appraisalLinkColumn: {
        flexDirection: 'column',
        flex: 4,
        alignSelf: 'flex-start',
        justifySelf: 'flex-start',
        padding: '10px'
      },
      kbbLogo: {
        flexDirection: 'column',
        flex: 1,
        alignSelf: 'flex-start',
        justifySelf: 'flex-start',
        paddingRight: '15px'
      },
      validityContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        backgroundColor: '#922486',
        alignItems: 'center',
        margin: '0px 0px',
        color: 'white',
    },
      validityDetailColumn: {
        flexDirection: 'column',
        flex: 5,
        alignSelf: 'flex-start',
        padding: '4px 10px',
        textAlign: 'flex-end',
      },
      validityLinkColumn: {
        flexDirection: 'column',
        flex: 6,
        alignSelf: 'flex-start',
        justifySelf: 'flex-start',
        padding: '4px 10px',
        textAlign: 'flex-start',
      },

      actionContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        backgroundColor: 'white',
        alignItems: 'center',
        maxHeight: '80px',
        margin: '4px 0px',
    },
      actionDetailColumn: {
        flexDirection: 'column',
        flex: 6,
        maxWidth: '305px',
        alignSelf: 'flex-start',
        borderRight: '1px solid #dfdfdf',
        padding: '0px 5px 0px 22px'
      },
      actionLinkColumn: {
        flexDirection: 'column',
        flex: 4,
        alignSelf: 'flex-start',
        justifySelf: 'flex-start',
        padding: '0px 16px 0px 14px',
      },
      certContainer:  {
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: '#e9e9e9',
        alignItems: 'center',
        maxHeight: '49px',
        margin: '4px 0px',
        padding: '4px 0px'
    },
});

export const AppraisalValue = (props) => {

    var RL = new RunListManager();

    const {item, isShiftData, isCarSquad} = props;
    const { store } = props.item;
    const [ discountPercentage, setDiscountPercentage ] = useState(0.06);
    const [expiryDate, setExpiryDate] = useState('');

    const getStoreDiscountPercentage = () => {
        RL.getStoreBasedPercentage(store).then(res => {
            if(res.data.get_store_based_computation[0]){
                let percentage = res.data.get_store_based_computation[0].percentage;
                let discountUnit = percentage/100;
                setDiscountPercentage(discountUnit)
            }
        }).catch(err => {
            if(err.response.status === 404 ) setDiscountPercentage(0.06)
            else console.log(err)
        })
    }

    const marketValuation = (item) => {
        return(
            <View>
                <Text 
                    style={{fontSize: '10px', margin: '10px 0px', fontFamily: 'ITCAVGBold', textTransform: 'uppercase', borderTop: '1px solid black', paddingTop: '10px' }}
                >
                    Market Valuations
                </Text>
                <Text style={{fontSize: '9px', fontFamily: 'ITCAVGLTBook'}}>
                    KBB Fair Market Value:  {
                        item.kbb_value 
                    } 
                </Text>
            </View>
        )
    }

    const kbbLogo = () => {
        return(
            <View style={styles.kbbLogo}>
                <Image src={KBB} style={{height: '60px', width: '54px', margin: '0px 10px 2px 5px'}}/>
            </View>
        )
    }

    useEffect(() => {
        getStoreDiscountPercentage();
    },[store])

    useEffect(() => {
        let dt = 
        isShiftData == true ? DateTime.fromISO(item.last_updated_date, { zone: "America/Chicago", setZone: true }) : DateTime.fromISO(item.appraisal_completion_date, { zone: "America/Chicago", setZone: true })
        var updatedDt = dt.plus({ days: 7 });

        setExpiryDate(`${updatedDt.month}/${updatedDt.day}/${updatedDt.year}`); 
    }, [item])

    return (
        <>
            <View style={styles.appraisalContainer}>
                <View style={styles.appraisalDetailColumn}>
                    <View style={{ borderRight: '1px solid #dfdfdf'}}>
                        <Text style={{fontSize: '28px', fontFamily: 'ITCAVGBold', textTransform: 'uppercase'}}>
                            Your Offer:
                        </Text>
                        <View 
                            style={{
                                border: '2px solid black', height: '75px', width: '270px',
                                backgroundColor: '#dfdfdf', margin: '2px auto', maxWidth: '270px'}}
                        >
                            <Text 
                                style={{
                                    fontSize: '40px', 
                                    fontFamily: 'ITCAVGBold', 
                                    paddingBottom: '4px', 
                                    borderBottom: '1px dotted black',
                                }}
                            >
                                ${item.appraisal_value && parseInt(item.appraisal_value).toLocaleString()}
                            </Text>
                        </View>
                        <Text 
                            style={{
                                fontSize: '10px', 
                                fontFamily: 'ITCAVGBold',
                                margin: '4px 0px' 
                            }}
                        >
                            This offer is valid until close of business on {expiryDate}
                        </Text>
                        <Text 
                            style={{
                                fontSize: '10px', 
                                fontFamily: 'ITCAVGBold',
                                margin: '5px 3px' 
                            }}
                        >
                            You could be eligible for ${Math.round((item.appraisal_value * discountPercentage))} in savings if you trade your vehicle in and purchase a new one.
                        </Text>
                    </View>
                </View>
                <View style={styles.appraisalLinkColumn}> 
                    <Text 
                        style={{fontSize: '10px', margin: '10px 0px', fontFamily: 'ITCAVGBold', textTransform: 'uppercase'}}
                    >
                        Comments
                    </Text>
                    <Text style={{fontSize: '9px', fontFamily: 'ITCAVGLTBook'}}>
                        {
                            item.notes && item.notes.length > 0? item.notes : 'No comments added by the appraiser.'
                        } 
                    </Text>
                    {
                        (marketValuation(item))&& item.kbb_value.length > 0 ? (marketValuation(item)): '' 
                    }
                </View>
                {
                    (kbbLogo())&& item.kbb_value.length > 0 ? (kbbLogo()): ''
                }
            </View>
            <View style={{flexDirection: 'row',
                        justifyContent: 'flex-start',
                        backgroundColor:  isCarSquad ? '#47389d': '#922486',
                        alignItems: 'center',
                        margin: '0px 0px',
                        color: 'white',}}>
                <View style={styles.validityDetailColumn}>
                    <Text style={{textAlign: 'right', marginLeft: '35px', fontSize: '11px', fontFamily: 'ITCAVGLTBook'}}>
                        This offer is valid 7 days and might change if you wait too long
                    </Text>
                </View>
                <View style={styles.validityLinkColumn}>
                    <Text style={{fontFamily: 'ITCAVGBold', fontSize: '24px'}}>
                        TAKE ACTION TODAY!
                    </Text>
                </View>
            </View>

            {/* Action */}
            <View style={styles.actionContainer}>
                <View style={styles.actionDetailColumn}>
                    <Text style={{fontFamily: 'ITCAVGBold', color: isCarSquad ? '#47389d': '#922486', textAlign: 'left'}}>
                        WHAT'S NEXT?
                    </Text>
                    <View>
                        <Text style={{fontSize: '11px', fontFamily: 'ITCAVGLTBook'}}>
                            Bring this offer to our store + the following items:                        
                        </Text>
                        <View style={{ flexDirection: "row", fontSize: '9px'}}>
                            <View style={{ flexDirection: "column", margin: '4px 0px' }}>
                                <View style={{ flexDirection: "row", fontFamily: 'ITCAVGLTBook' }}>
                                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                                    <Text>Title</Text>
                                </View>
                                <View style={{ flexDirection: "row", fontFamily: 'ITCAVGLTBook' }}>
                                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                                    <Text>Valid Registration</Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: "column", margin: '4px 0px', marginLeft: '20px' }}>
                                <View style={{ flexDirection: "row", fontFamily: 'ITCAVGLTBook' }}>
                                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                                    <Text>Valid ID</Text>
                                </View>
                                <View style={{ flexDirection: "row", fontFamily: 'ITCAVGLTBook' }}>
                                    <Text style={{ marginHorizontal: 8 }}>•</Text>
                                    <Text>All Keys</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={styles.actionLinkColumn}>
                    <Text style={{fontSize: '10px', fontFamily: 'ITCAVGLTBook'}}>
                        Have an extra key for your vehicle? {"\n"}Bring it in and we will give you an additional
                    </Text>
                    <Text style={{fontFamily: 'ITCAVGBold', color:  isCarSquad ? '#47389d': '#922486', textAlign: 'left'}}>
                        $100 on top of your Offer
                    </Text>
                </View>
            </View>

            {/* No Haggling */}
            <Text style={{fontFamily: 'ITCAVGBold', fontSize: '12px', margin: '4px auto'}}>
                Get a check immediately! No haggling back and forth. The best offer paid instantly.
            </Text>
            
            {/* Certifications */}
            <View style={styles.certContainer}>
                <Image src={DealOfYear} style={{height: '40px', width: '32px', marginRight: '10px'}} />
                <Image src={FiveStarDealer} style={{height: '25px', width: '80px', marginRight: '10px'}} />
                <Image src={APlus} style={{height: '35px', width: '55px'}} />
            </View>
        </>
    )
}