import React from 'react'

export default ({ damage, closeFunc})=>{

    return<>
    <div class="d-flex justify-content-end mb-2 align-items-center">
        <div class="flex-fill text-left">
            {damage.item}
        </div>
        <div><a onClick={closeFunc} class="btn btn-secondary">Back to List</a></div>
    </div>
    <img src={damage.imageHref} className="d-block w-100"/>

    </>
}