import React from 'react';
import {CDataTable, CTooltip} from '@coreui/react';
import { CIcon } from '@coreui/icons-react';
import { cilInfo } from '@coreui/icons';
import {validateEmail} from '../../services/AppraisalUtils';
import {Timer} from '../VAS_Dashboard/AppraisalQueue/Timer';

export const ArchiveRecordsView = (props) => {

    const {archiveFields, archiveRecords, archiveRecordHandler} = props;

    return (
        <>
            <CDataTable
                // addTableClasses={styles.tableclass}
                // border
                // items={usersData}
                fields={archiveFields}
                items={archiveRecords}
                // itemsPerPageSelect
                itemsPerPage={5}         
                pagination
                // sorter
                // striped
                // tableFilter={{ label: 'Search by Name: ' }}
                scopedSlots = {{
                    'store_name':
                        (item) => {
                            return(
                                <td>
                                    <h6>{(!item.store)? 'N.A': item.store}</h6>
                                </td>
                            )
                        },
                    'vin': 
                        (item) => {
                            return (
                                <td>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <CTooltip
                                                content={`${item.e_modelYear} ${item.e_make} ${item.e_model} ${item.e_trim}`}
                                            >
                                                <CIcon content={cilInfo} className="info" />
                                            </CTooltip>
                                        </div>
                                        <div className="ml-1">
                                            <h6 className="vin m-0">{item.vin}</h6>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <h6
                                        style={{ color: "gray" }}
                                        className="ml-4 mt-2"
                                        >{`${item.e_modelYear} ${item.e_make} ${item.e_model}`}</h6>
                                    </div>
                                </td>
                            )
                        },
                        'vas':
                        (item) => {
                            const { vas } = item;

                            let parsedUserName = '';

                            // add logic for parsing name from email
                            if(validateEmail(vas)) {
                                const emailName = vas.split('@')[0];
                                const namesArray = emailName.split('.');                            

                                namesArray.forEach(element => {
                                    parsedUserName += element.charAt(0).toUpperCase() + element.slice(1) + " ";
                                })
                            } else {
                                parsedUserName = vas;
                            }
                            
                                
                            return (
                                <td>
                                    <h6>{parsedUserName}</h6>
                                </td>
                            )
                        },
                        'appraiser':
                        (item) => {
                            const { appraiser } = item;

                            let parsedUserName = '';

                            // add logic for parsing name from email
                            if(validateEmail(appraiser)) {
                                const emailName = appraiser.split('@')[0];
                                const namesArray = emailName.split('.');                            

                                namesArray.forEach(element => {
                                    parsedUserName += element.charAt(0).toUpperCase() + element.slice(1) + " ";
                                    // parsedUserName += element + " ";
                                })
                            } else {
                                parsedUserName = appraiser;
                            }
                            
                                
                            return (
                                <td>
                                    <h6>{parsedUserName}</h6>
                                </td>
                            )
                        },
                        'modified_created_date':
                            (item, index)=>{
                                // const {hh, mm, ss} = parseTimerValues(item);

                                return (
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className='mt-2'>
                                                <h6>{item.modified_created_date.substr(0,10)}</h6>
                                            </div>
                                            <div className='ml-2'>
                                                <Timer item={item} key={`index_${item.vin}`} 
                                                    startingField={'created_date'}
                                                    trackingField={'appraisal_completion_date'} 
                                                />
                                            </div>
                                        </div>
                                    </td>
                                )
                            },
                    [props.view == 'VAS' ? 'vas_archive' : 'appraiser_archive']:
                        (item, index) => {
                            return (
                            <td>
                                <CTooltip content={"Un-Archive"}>
                                    <div className='archiveTtip'>
                                        <input 
                                            type="checkbox" 
                                            checked={props.view == 'VAS' ? item.vas_archive ? true : false : item.appraiser_archive ? true : false}
                                            onChange={(e) => {
                                                const role = props.view == 'VAS' ? 'vas' : 'appraiser';
                                                const user = props.view == 'VAS' ? item.vas : item.appraiser;
                                                const value = props.view == 'VAS' ? !item.vas_archive : !item.appraiser_archive

                                                archiveRecordHandler(item.vin, role, user, item.created_date, value)
                                            }}
                                            style={{
                                                height:"17px",
                                                width:"17px"
                                            }}
                                        />
                                    </div>
                                </CTooltip>
                            </td>)
                    }
                }}
            />
        </>
    )
}