import React from 'react'
import {CInput} from '@coreui/react';


export default (props)=>{

    const changeFunc = (e)=>{
        var val = e.target.value
        var loc = {...props.localFilters}
        loc['mileage_max'] = val
        props.setLocalFilters(loc)
    }

    return(
        <CInput
            size="lg"
            type="number"
            value={props.localFilters['mileage_max'] === null ? '' : props.localFilters['mileage_max']}
            id="mileage"
            name="mileage_max"
            placeholder="Max Mileage"
            onChange = {(e => changeFunc(e))}
        />
       
    )
}




                                   

