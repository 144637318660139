import React, {useState, useEffect} from 'react';
import {CListGroup, CButton} from '@coreui/react';
import styled from 'styled-components';
import MasterCardList from './MasterCardList/MasterCardList';
import {Loader} from 'semantic-ui-react';
import Paginate from './Paginate/Paginate';
import updater from 'immutability-helper';

const Wrapper = styled.div`
    position:absolute;
    height:100%;
    width:100%;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar{
      width:4px;
      background:#cccccc;
    }

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
`


export default ({currentIndex, vehicles, setCurrentIndex, loading, settings, setSettings, totalListings,filterPane, setFilterPane, channel})=>{
    const [filters, setFilters] = useState({});
    useEffect(()=>{
        setFilters(settings);
    }, [settings])

    const sortClick = (val)=> {
        var newFilters = {...filters};
        newFilters['sortBy'] = val;
        setFilters(newFilters);

        var newSettings = updater(settings, {$apply: (u)=>{
        u = {...newFilters};
        return {...u}
        }})
        setSettings(newSettings);
}

    return(
        <Wrapper>
            <div className="my-2 d-flex w-100 align-items-center justify-content-end px-3">
                <div className="mr-auto text-left">
                {totalListings} Total Listings 
                </div>
                <CButton color="dark" onClick={()=>{setFilterPane(!filterPane)}}>{filterPane ? "Close Filters" : "Filters"}</CButton>
            </div>
            <div className="my-2 d-flex w-100 align-items-center justify-content-end px-3">
                <input type="radio" value="0" name="sort" defaultChecked onClick={()=>{sortClick('make_ASC,model_ASC,year_DESC,CRScore_DESC')}} />&nbsp;Make,Model,Year & CR &nbsp;&nbsp;
                <input type="radio" value="1" name="sort" onClick={()=>{sortClick('')}}/>&nbsp;Run #
            </div>
            <CListGroup>
                <center>{loading && <Loader inline={true} active/>}</center>
                {!loading && 
                    <MasterCardList 
                        currentIndex={currentIndex} 
                        vehicles={vehicles} 
                        setCurrentIndex={setCurrentIndex}
                        channel={channel}
                     />}
            </CListGroup>
            <Paginate settings={settings} setSettings={setSettings} totalListings={totalListings}/>
        </Wrapper>
    )   
}

//                
