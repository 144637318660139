import React from 'react'
import {CSelect} from '@coreui/react'
import {RL_Instance} from '../../../../services/RunListManager';


export default (props)=>{

    const changeFunc = (e)=>{
        var val = e.target.options[e.target.selectedIndex].value
        var loc = {...props.localFilters}
        loc['model'] = val
        props.setLocalFilters(loc)
    }

    return(
        <CSelect value={props.localFilters['model']} onChange={changeFunc} size="lg">
            <option value="">Model</option>
            { (props.localFilters['make'] && RL_Instance.makesModelsMap[props.localFilters['make']]) &&
            RL_Instance.makesModelsMap[props.localFilters['make']].map((item)=>{
                return <option value={item}>{item}</option>
            })}
        </CSelect>
    )
}

