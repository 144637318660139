import React, {useState, useEffect} from 'react';
import {CCard, CCardHeader, CCardFooter, CCardBody,CRow, CCol, CInput, CButton} from '@coreui/react';



export default (props)=>{

    const [retailPrice, setRetailPrice] = useState(0);
    const [buyerMaxBid, setBuyerMaxBid] = useState(0);
    const [reconCost, setReconCost] = useState(0);
    const [transport, setTransport] = useState(0);
    const [grossMargin, setGrossMargin] = useState(0);
    const [fees, setFees] = useState(320)

    useEffect( ()=>{
        if(props.retailPrice == 0){
            setRetailPrice(0);
        }
    }, [props.vin, props.retailPrice])

    useEffect( ()=>{
        var x = parseInt(props.retailPrice);
        if(isNaN(x)) x= 0
        setRetailPrice(x);
    }, [props.retailPrice])

    useEffect( ()=>{
        var x = parseInt(props.reconCost);
        if(isNaN(x)) x= 0
        setReconCost(x);
    }, [props.reconCost])

    useEffect( ()=>{
        var x = parseInt(props.transport);
        if(isNaN(x)) x= 0
        setTransport(x);
    }, [props.transport])

    useEffect( ()=>{
        var x = parseInt(props.buyerMaxBid);
        if(isNaN(x)) x= 0
        setBuyerMaxBid(x);
    }, [props.buyerMaxBid])

    useEffect(()=>{
        var gm = retailPrice - (buyerMaxBid + reconCost + transport + fees);
        if(isNaN(gm)) gm = 0;
        setGrossMargin(gm);
    },[retailPrice, buyerMaxBid, reconCost, transport, fees])


    const addCommas = (num)=>{
        var numStr = num+'';
        if(numStr.length < 4) return numStr;
        return numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return(
        <div className="fegCalculatorWrapper">
            <CCard className="bg-dark">
                <CCardHeader>Frontend Gross Calculator</CCardHeader>
                <CCardBody>
                    <CRow>
                        <CCol className="col-md-8 py-2">Retail Price</CCol>
                        <CCol className="col-md-4 py-2"><CInput name="retail_price" value={addCommas(retailPrice)} onChange={(e)=>{
                            var val = e.target.value.replace(/\D/gi, '');
                            if(val == '') val = 0;
                            setRetailPrice(parseInt(val))
                        }}/></CCol>
                    </CRow>
                    <CRow>
                        <CCol className="col-md-8 py-2">Buyer Max Bid</CCol>
                        <CCol className="col-md-4 py-2"><CInput name="retail_price" value={addCommas(buyerMaxBid)}
                        onChange={(e)=>{
                            var val = e.target.value.replace(/\D/gi, '');
                            if(val == '') val = 0;
                            setBuyerMaxBid(parseInt(val))                        }}
                        /></CCol>
                    </CRow>
                    <CRow>
                        <CCol className="col-md-8 py-2">Recon Cost</CCol>
                        <CCol className="col-md-4 py-2"><CInput name="retail_price" value={addCommas(reconCost)} 
                        onChange={(e)=>{
                            var val = e.target.value.replace(/\D/gi, '');
                            if(val == '') val = 0;
                            setReconCost(parseInt(val))                        }}
                        /></CCol>
                    </CRow>
                    <CRow>
                        <CCol className="col-md-8 py-2">Transport</CCol>
                        <CCol className="col-md-4 py-2"><CInput name="retail_price" value={addCommas(transport)}
                        onChange={(e)=>{
                            var val = e.target.value.replace(/\D/gi, '');
                            if(val == '') val = 0;
                            setTransport(parseInt(val))                        }}                        
                        /></CCol>
                    </CRow>
                    <CRow>
                        <CCol className="col-md-8 py-2">Auction Fees</CCol>
                        <CCol className="col-md-4 py-2"><CInput name="retail_price" value={addCommas(fees)}
                        onChange={(e)=>{
                            var val = e.target.value.replace(/\D/gi, '');
                            if(val == '') val = 0;
                            setFees(parseInt(val))                        }}                        
                        /></CCol>
                    </CRow>
                    <CRow>
                        <CCol className="col-md-8 py-2">Gross Margin</CCol>
                        <CCol className="col-md-4 py-2">
                            <CInput name="retail_price" value={addCommas(grossMargin)} valid={(grossMargin > 0)} readOnly/>
                            
                            </CCol>
                    </CRow>
                    <CRow>
                        <CCol>
                            <CButton color="light" onClick={()=>{props.closeCalculator()}}>Close Calculator</CButton>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
        </div>
    )

}