import React from 'react'
import {CTooltip} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {freeSet} from '@coreui/icons'


export default ({notes})=>{
    

    return <>
        {notes && <CTooltip content={notes}><CIcon content={freeSet.cilNotes} size="sm"/></CTooltip>}
    </>
}
