import React from 'react'
import {CButton, CCol, CRow} from '@coreui/react'

export default ({carfax})=>{

    const {accident, owner, serviceRecord, useType} = carfax['dealerReport']['fourPillars']

    return(
        <div>
            <CRow style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '8px'

            }}>
                <CCol>
                    <img style={{margin: '5px auto'}} src={accident['iconUrl']} className="carfax-icon"/>
                    <div className='textIcon'>
                        {accident['iconText']}
                    </div>
                </CCol>
                <CCol>
                    <img style={{margin: '5px auto'}} src={owner['iconUrl']} className="carfax-icon"/>
                    <div className="textIcon">
                        {owner['iconText']}
                    </div>
                </CCol>
                <CCol>
                    <img style={{margin: '5px auto'}} src={serviceRecord['iconUrl']} className="carfax-icon"/>
                    <div className="textIcon">
                        {serviceRecord['recordCount']} Service History Record{serviceRecord['recordCount']!= 1 && 's'}
                    </div>
                </CCol>
                <CCol>
                    <img style={{margin: '5px auto'}} src={useType['iconUrl']} className="carfax-icon"/>
                    <div className="textIcon">
                        {useType['iconText']}
                    </div>
                </CCol>
            </CRow>
            <div className="text-center">
                <CButton color="success" href={carfax['dealerReport']['carfaxLink']['url']} target="_blank">Full CARFAX Report</CButton>
            </div>
        </div>
    )
}