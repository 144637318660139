import React, {Children, useState, useEffect, isValidElement, cloneElement} from 'react';
import jwt from 'jsonwebtoken';
import Auth from '../../services/Auth';
import LoggedOut from '../LoggedOut/LoggedOut';
import {setCookie, getCookie} from '../../services/RunListManager';
import {useHistory} from 'react-router-dom';

export default (props)=>{

    const history = useHistory();
    const [acct, setAcct] = useState(null)
    const [disabled, setDisabled] = useState(true)
    const [accessToken, setAccessToken]= useState(false)

    var rawToken = getCookie('AD_idToken');
    const decodedToken = jwt.decode(rawToken, {complete: true});

    let tokenPayload = {};
    if(decodedToken && decodedToken.payload) {
        tokenPayload = decodedToken.payload;
    }

    useEffect( ()=>{
        var checkIfLoggedIn = async ()=>{

            try {
                if(!acct){
                    var accounts = Auth.getAllAccounts();
                    var useAccount = false;
                    accounts = (Array.isArray(accounts)) ? accounts : [];
                    for(var i = 0; i < accounts.length; i++ ) {
                        // if(accounts[i].homeAccountId){
                        if(accounts[i].username == tokenPayload.preferred_username){
                            useAccount = accounts[i];
                            break;
                        }
                    }
                    if(useAccount){
                        var a = await Auth.acquireTokenSilent({account: useAccount});
                        setTimeout( ()=>{
                          setAcct(a);
                          setCookie('AD_idToken', a.idToken);
                          setDisabled(false);
                        },1000);
                    }
                    else{
                        setDisabled(false);
                    }
                }
            } catch (err) {
                console.log('err');
                console.log(err);
                setDisabled(false);
            }
        }
        checkIfLoggedIn();
    },[acct] )

    useEffect(() => {
        var rawToken = getCookie('AD_idToken');

        if (rawToken) setAccessToken(true);
    })

    const doLogin = async ()=>{
        var loginRequest = {
            scopes: ["Files.Read", "openid"],
            redirectUri: process.env.REACT_APP_REDIRECT_URL
        };
        try {
            var a = await Auth.loginPopup(loginRequest);
            setAcct(a);
            setCookie('AD_idToken', a.idToken);
            
            // redirect to home page on login
            history.push('/');           
        }
        catch(err){
            console.log('login error');
            console.log(err);
        }
    }

    const childrenWithTokens = Children.map(props.children, (item)=>{
        return (isValidElement(item)) ? cloneElement(item, {acct: acct}) : item;
    })
    
    return <>
            {acct && accessToken ?  childrenWithTokens : <LoggedOut loginFunc={doLogin} disabled={disabled} />}
    </>

}
