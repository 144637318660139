import React from 'react'
import {CSelect} from '@coreui/react'


export default (props)=>{

    const changeFunc = (e)=>{
        var val = e.target.options[e.target.selectedIndex].value
        var loc = {...props.localFilters}
        loc['frameDamage'] = val
        props.setLocalFilters(loc)
    }

    return(
        <CSelect value={props.localFilters['frameDamage']} onChange={changeFunc} className="mr-2" size="lg">
            <option value="">Frame Status&nbsp;&nbsp;&nbsp;&nbsp;</option>
            <option value="1">Frame Damage</option>
            <option value="0">No Frame Damage</option>
        </CSelect>
    )
}

