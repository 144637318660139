import React, {useState, useEffect}from 'react'
import {CRow,CCol, CButton, CCard, CCardBody, CContainer, CSpinner} from '@coreui/react';
import {DateTime} from 'luxon';
import jwt from 'jsonwebtoken';
import RunListManager, {getCookie} from '../../services/RunListManager'
import Error from '../generic/Error/Error';

export const ManualCount = () => {

    var RL = new RunListManager();
    const [userName, setUserName] = useState('');
    const {addError} = Error();
    const [countloading, setCountLoading] = useState(false);
    const [icountloading, setICountLoading] = useState(false);
    const [mikeDayCount, setMikeDayCount] = useState(0);
    const [mikeWeekCount, setMikeWeekCount] = useState(0);
    const [tobyDayCount, setTobyDayCount] = useState(0);
    const [tobyWeekCount, setTobyWeekCount] = useState(0);
    const [johnDayCount, setJohnDayCount] = useState(0);
    const [johnWeekCount, setJohnWeekCount] = useState(0);
    const [imikeDayCount, setIMikeDayCount] = useState(0);
    const [imikeWeekCount, setIMikeWeekCount] = useState(0);
    const [itobyDayCount, setITobyDayCount] = useState(0);
    const [itobyWeekCount, setITobyWeekCount] = useState(0);
    const [ijohnDayCount, setIJohnDayCount] = useState(0);
    const [ijohnWeekCount, setIJohnWeekCount] = useState(0);

    const getPreferredUserName = () => {
        var rawToken = getCookie('AD_idToken');
        const decodedToken = jwt.decode(rawToken, {complete: true});

        if (decodedToken && decodedToken.payload && decodedToken.payload.preferred_username) {
            setUserName(decodedToken.payload.preferred_username);
        }
    }

    const manualoffers = [
        {
            "appraiser" : "Mike Quinn",
            "todays_offers" : mikeDayCount,
            "weekly_offers": mikeWeekCount
        },{
            "appraiser" : "Tobin Leuterio",
            "todays_offers" : tobyDayCount,
            "weekly_offers": tobyWeekCount
        },{
            "appraiser" : "John Schnitzlein",
            "todays_offers" : johnDayCount,
            "weekly_offers": johnWeekCount
        }
    ]

    const instantoffers = [
        {
            "appraiser" : "Mike Quinn",
            "todays_offers" : imikeDayCount,
            "weekly_offers": imikeWeekCount
        },{
            "appraiser" : "Tobin Leuterio",
            "todays_offers" : itobyDayCount,
            "weekly_offers": itobyWeekCount
        },{
            "appraiser" : "John Schnitzlein",
            "todays_offers" : ijohnDayCount,
            "weekly_offers": ijohnWeekCount
        }
    ]

    const getShiftAppraisalsCount = () => {
        setMikeDayCount(0)
        setMikeWeekCount(0)
        setTobyDayCount(0)
        setTobyWeekCount(0)
        setJohnDayCount(0)
        setJohnWeekCount(0)
        RL.getShiftAppraisalRecordsForAppraiser()
                    .then(res => {
                        if(res && res.data && res.data.shift_appraisal_record_lists) {
                            const recordLists = res.data.shift_appraisal_record_lists;

                            const updatedRecordLists = recordLists.map(record => {
                                let recordObj = {
                                    ...record
                                }

                                var tDate = DateTime.now().setZone("America/Chicago").toFormat("yyyy-MM-dd")
                                var wDate = DateTime.now().minus({days: 7}).setZone("America/Chicago").toFormat("yyyy-MM-dd")
                                if(recordObj['shift_lead_type'] == 'manual_quote' && recordObj['appraisal_last_edited_by'] == 'Mike.Quinn@offleaseonly.com' && recordObj['appraisal_last_edited_on'].split('T')[0] == tDate){
                                    setMikeDayCount(mikeDayCount => mikeDayCount + 1)
                                }
                                if(recordObj['shift_lead_type'] == 'manual_quote' && recordObj['appraisal_last_edited_by'] == 'Mike.Quinn@offleaseonly.com' && recordObj['appraisal_last_edited_on'].split('T')[0] <= tDate && recordObj['appraisal_last_edited_on'].split('T')[0] >= wDate){
                                    setMikeWeekCount(mikeWeekCount => mikeWeekCount + 1)
                                }
                                if(recordObj['shift_lead_type'] == 'manual_quote' && recordObj['appraisal_last_edited_by'] == 'Tobin.Leuterio@offleaseonly.com' && recordObj['appraisal_last_edited_on'].split('T')[0] == tDate){
                                    setTobyDayCount(tobyDayCount => tobyDayCount + 1)
                                }
                                if(recordObj['shift_lead_type'] == 'manual_quote' && recordObj['appraisal_last_edited_by'] == 'Tobin.Leuterio@offleaseonly.com' && recordObj['appraisal_last_edited_on'].split('T')[0] <= tDate && recordObj['appraisal_last_edited_on'].split('T')[0] >= wDate){
                                    setTobyWeekCount(tobyWeekCount => tobyWeekCount + 1)
                                }
                                if(recordObj['shift_lead_type'] == 'manual_quote' && recordObj['appraisal_last_edited_by'] == 'John.Schnitzlein@offleaseonly.com' && recordObj['appraisal_last_edited_on'].split('T')[0] == tDate){
                                    setJohnDayCount(johnDayCount => johnDayCount + 1)
                                }
                                if(recordObj['shift_lead_type'] == 'manual_quote' && recordObj['appraisal_last_edited_by'] == 'John.Schnitzlein@offleaseonly.com' && recordObj['appraisal_last_edited_on'].split('T')[0] <= tDate && recordObj['appraisal_last_edited_on'].split('T')[0] >= wDate){
                                    setJohnWeekCount(johnWeekCount => johnWeekCount + 1)
                                }
                                return recordObj
                            })
                        } 
                        setCountLoading(false)                     
                    })
                    .catch(error => {
                        console.error('Could not retrieve shift appraisals' + error);
                        addError(error.message)
                        setCountLoading(false)
                    })
    }
    
    const getShiftAppraisalsInstantCount = () => {
        setIMikeDayCount(0)
        setIMikeWeekCount(0)
        setITobyDayCount(0)
        setITobyWeekCount(0)
        setIJohnDayCount(0)
        setIJohnWeekCount(0)
        RL.getShiftAppraisalRecordsForAppraiser()
                    .then(res => {
                        if(res && res.data && res.data.shift_appraisal_record_lists) {
                            const recordLists = res.data.shift_appraisal_record_lists;

                            const updatedRecordLists = recordLists.map(record => {
                                let recordObj = {
                                    ...record
                                }

                                var tDate = DateTime.now().setZone("America/Chicago").toFormat("yyyy-MM-dd")
                                var wDate = DateTime.now().minus({days: 7}).setZone("America/Chicago").toFormat("yyyy-MM-dd")
                                if(recordObj['shift_lead_type'] == 'instant_quote' && recordObj['appraisal_last_edited_by'] == 'Mike.Quinn@offleaseonly.com' && recordObj['appraisal_last_edited_on'].split('T')[0] == tDate){
                                    setIMikeDayCount(imikeDayCount => imikeDayCount + 1)
                                }
                                if(recordObj['shift_lead_type'] == 'instant_quote' && recordObj['appraisal_last_edited_by'] == 'Mike.Quinn@offleaseonly.com' && recordObj['appraisal_last_edited_on'].split('T')[0] <= tDate && recordObj['appraisal_last_edited_on'].split('T')[0] >= wDate){
                                    setIMikeWeekCount(imikeWeekCount => imikeWeekCount + 1)
                                }
                                if(recordObj['shift_lead_type'] == 'instant_quote' && recordObj['appraisal_last_edited_by'] == 'Tobin.Leuterio@offleaseonly.com' && recordObj['appraisal_last_edited_on'].split('T')[0] == tDate){
                                    setITobyDayCount(itobyDayCount => itobyDayCount + 1)
                                }
                                if(recordObj['shift_lead_type'] == 'instant_quote' && recordObj['appraisal_last_edited_by'] == 'Tobin.Leuterio@offleaseonly.com' && recordObj['appraisal_last_edited_on'].split('T')[0] <= tDate && recordObj['appraisal_last_edited_on'].split('T')[0] >= wDate){
                                    setITobyWeekCount(itobyWeekCount => itobyWeekCount + 1)
                                }
                                if(recordObj['shift_lead_type'] == 'instant_quote' && recordObj['appraisal_last_edited_by'] == 'John.Schnitzlein@offleaseonly.com' && recordObj['appraisal_last_edited_on'].split('T')[0] == tDate){
                                    setIJohnDayCount(ijohnDayCount => ijohnDayCount + 1)
                                }
                                if(recordObj['shift_lead_type'] == 'instant_quote' && recordObj['appraisal_last_edited_by'] == 'John.Schnitzlein@offleaseonly.com' && recordObj['appraisal_last_edited_on'].split('T')[0] <= tDate && recordObj['appraisal_last_edited_on'].split('T')[0] >= wDate){
                                    setIJohnWeekCount(ijohnWeekCount => ijohnWeekCount + 1)
                                }
                                return recordObj
                            })
                        }      
                        setICountLoading(false)                
                    })
                    .catch(error => {
                        console.error('Could not retrieve shift appraisals' + error);
                        addError(error.message)
                        setCountLoading(false)
                    })
    }

    useEffect(() => {
        getPreferredUserName();
    })

    useEffect(() => {
        setCountLoading(true);
        setICountLoading(true);
        getShiftAppraisalsCount();
        getShiftAppraisalsInstantCount();
    },[])

    useEffect(() => {
            const shiftCountInterval = setInterval(() => {
                setCountLoading(true)
                getShiftAppraisalsCount();
                getShiftAppraisalsInstantCount();
                }, 3600000);
                return () => {
                    clearInterval(shiftCountInterval);    
            }
        })

    return (
        <>
            <CRow>
        <CCol className="p-3 col-md-11 mt-5" style={{ marginLeft: "80px", background: "white", maxWidth: "400px" }}>
          <CCard
            style={{
              overflowX: "auto",
              fontSize: "11px",
              minHeight: "138px",
              maxHeight: "140px",
              marginBottom: "2px"
              // paddingBottom: "10px !important",
            }}
          >
            <CCardBody>
                {
                    countloading?<CContainer style={{display: "flex", justifyContent: "center", alignItems: "center", minHeight: "120px"}}><CSpinner /></CContainer>:
                    
                    <CContainer>
                        <b style={{ fontSize: "15px", marginBottom: '10px' }}>Shift Manual Offers Given</b>
                    <CRow>
                      <CCol md={4}>
                        <b>Appraiser</b>
                      </CCol>
                      <CCol md={4}><b>Today</b></CCol>
                      <CCol md={4}><b>WTD</b></CCol>
                    </CRow>
                      {
                        manualoffers.map( (item)=> {
                          return (
                            <CRow>
                              <CCol md={4}>
                                {item['appraiser']}
                              </CCol>
                              <CCol md={4}>{item['todays_offers']}</CCol>
                              <CCol md={4}>{item['weekly_offers']}</CCol>
                            </CRow>
                          )
      
                        })
                      }
                    </CContainer>
                }
            </CCardBody>
          </CCard>
          <div style={{ fontSize: "12px"}}><b>Updates once an hour</b></div>
          
        </CCol>
        <CCol className="p-3 col-md-11 mt-5" style={{ marginLeft: "80px", background: "white", maxWidth: "400px" }}>
          <CCard
            style={{
              overflowX: "auto",
              fontSize: "11px",
              minHeight: "138px",
              maxHeight: "140px",
              marginBottom: "2px"
              // paddingBottom: "10px !important",
            }}
          >
            <CCardBody>
                {
                    icountloading?<CContainer style={{display: "flex", justifyContent: "center", alignItems: "center", minHeight: "120px"}}><CSpinner /></CContainer>:
                    
                    <CContainer>
                        <b style={{ fontSize: "15px", marginBottom: '10px' }}>Shift Instant Offers Adjusted</b>
                    <CRow>
                      <CCol md={4}>
                        <b>Appraiser</b>
                      </CCol>
                      <CCol md={4}><b>Today</b></CCol>
                      <CCol md={4}><b>WTD</b></CCol>
                    </CRow>
                      {
                        instantoffers.map( (item)=> {
                          return (
                            <CRow>
                              <CCol md={4}>
                                {item['appraiser']}
                              </CCol>
                              <CCol md={4}>{item['todays_offers']}</CCol>
                              <CCol md={4}>{item['weekly_offers']}</CCol>
                            </CRow>
                          )
      
                        })
                      }
                    </CContainer>
                }
            </CCardBody>
          </CCard>
          <div style={{ fontSize: "12px"}}><b>Updates once an hour</b></div>
          
        </CCol>
        </CRow>
        </>
    )
}