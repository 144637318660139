/* eslint-disable */
import React, {useState, useEffect} from 'react';
import { CChart } from '@coreui/react-chartjs'
import RunListManager from '../../../services/RunListManager';
import { CContainer, CRow, CCol, CCard, CCardHeader, CCardBody, CSelect, CButton} from '@coreui/react';

const _chart = {};
//export default (props)=>{
export default (props)=>{
    const [makes, setMakes] = useState([]);
    const [models, setModels] = useState([]);
    const [years, setYears] = useState([]);
    const [hasMakesData, setHasMakesData] = useState(false);
    const [hasYear, setHasYear] = useState(false);
    const [hasModel, setHasModel] = useState(false);
    const [datasets, setDatasets] = useState({"NumCars": [], "AvgSoldPrice": [], "Ten": [], "TwentyFive": [], "Fifty": [], "BotTwentyFive": [], "BotTen": []});
    const [xlabels, setXlabels] = useState([]);    
    const [retrieving, setRetrieving] = useState(false);

    const [startDate, setStartDate] = useState("");    
    const [endDate, setEndDate] = useState("");    

    const [hasChartData, setHasChartData] = useState(false);
    const [make, setMake] = useState("");
    const [model, setModel] = useState("");
    const [year, setYear] = useState(0);
    const [yearText, setYearText] = useState("");
    const [maxY2, setMaxY2] = useState(300);

    const defaultLegendClickHandler = Chart.defaults.global.legend.onClick;

    useEffect( ()=>{
      if (hasChartData) return;
      var RL = new RunListManager();

      RL.getUniqueMakes().then( (data)=>{
        console.log("data", data);
        //if(data.error) return;
          var newMakes = data.data.makes;
          setMakes(newMakes);
          setHasMakesData(true);
      }).catch( (e)=>{
          console.log(e);
      })

    }, [hasMakesData])


    const getModels = (make)=> {
      var RL = new RunListManager();

      RL.getUniqueModelsByMake(make).then( (data)=>{
        console.log("data", data);
        //if(data.error) return;
          var newModels = data.data.models;
          setModels(newModels);
      }).catch( (e)=>{
          console.log(e);
      })
    }

    const setTextInputRef = (element)=> {
        console.log("setTextInputRef", element);
    }

    const getYears = ()=> {
      var dt = new Date();
      var nowYear = dt.getFullYear();
      setYears([nowYear-5, nowYear-4, nowYear-3, nowYear-2, nowYear-1, nowYear]);
    }

    const getPrices = (year, make, model)=> {
      setRetrieving(true);
      var RL = new RunListManager();

      RL.getPricesTimeseries(year, make, model).then( (data)=>{
        console.log("prices data", data);
        var dateMap = {};
        var auction = data.data.auction;
        var numCars = [];
        var soldPrices = [];
        var numSold = [];
        var hi = auction.length;
        var maxDt = new Date('1971-01-01');
        var minDt = new Date('2060-01-01');
        var maxNumCars = 0;
        for(var i=0;i<hi;i++) {
          var wk = auction[i];
          var parts = wk.auction_week.split('-');
          var dt = new Date(parts[0], parts[1] - 1, parts[2]); 
          if (dt < minDt) minDt = dt;
          if (dt > maxDt) maxDt = dt;
          dateMap[dt] = dt;
          numCars.push({t: dt, y2: wk.NumCars});
          numSold.push({t: dt, y2: wk.NumSold});
          soldPrices.push({t: dt, y: wk.SoldPrice});
        }

        var wholesale = data.data.wholesale;
        var nums = [];
        var tens = [];
        var twentyFives = [];
        var seventys = [];
        var ninetys = [];
        var avgPrices = [];
        hi = wholesale.length;
        for(var i=0;i<hi;i++) {
          var wk = wholesale[i];
          var parts = wk.date.split('-');
          var dt = new Date(parts[0], parts[1] - 1, parts[2]); 
          if (dt < minDt) minDt = dt;
          if (dt > maxDt) maxDt = dt;
          if (maxNumCars < wk.num) maxNumCars = wk.num;
          dateMap[dt] = dt;
          nums.push({t: dt, y: wk.num});
          tens.push({t: dt, y: wk.Price10});
          twentyFives.push({t: dt, y: wk.Price25});
          seventys.push({t: dt, y: wk.Price75});
          ninetys.push({t: dt, y: wk.Price90});
          avgPrices.push({t: dt, y: wk.AvgPrice});
        }

        var oloInventory = data.data.olo_inventory;
        var oloNums = [];
        var oloAvgPrices = [];
        hi = oloInventory.length;
        for(var i=0;i<hi;i++) {
          var wk = oloInventory[i];
          var parts = wk.date.split('-');
          var dt = new Date(parts[0], parts[1] - 1, parts[2]); 
          if (dt < minDt) minDt = dt;
          if (dt > maxDt) maxDt = dt;
          dateMap[dt] = dt;
          oloNums.push({t: dt, y: wk.num});
          oloAvgPrices.push({t: dt, y: wk.AvgPrice});
        }



        var data = {
          "NumCars": nums, 
          "AvgSoldPrice": soldPrices, 
          "OloNumCars": oloNums, 
          "OloAvgPrice": oloAvgPrices, 
          "Ten": tens, 
          "TwentyFive": twentyFives, 
          "AvgPrice": avgPrices, 
          "SeventyFive": seventys, 
          "Ninety": ninetys
        };
        console.log("datasets", data);
        if (minDt.getDay() > 0) {
          minDt.setDate(minDt.getDate()+(minDt.getDay() * -1));
        }
        if (maxDt.getDay() > 0) {
          maxDt.setDate(maxDt.getDate()+(7 - maxDt.getDay()));
        }
        console.log("range", minDt.toISOString(), maxDt.toISOString());
        
        setStartDate(minDt.toISOString());
        setEndDate(maxDt.toISOString());
        setDatasets(data);
        setMaxY2(maxNumCars);
        setRetrieving(false);
        setHasChartData(true);
      }).catch( (e)=>{
          console.log(e);
      })
    }
/*
        var data = {
          "NumCars": nums, 
          "AvgSoldPrice": soldPrices, 
          "Ten": tens, 
          "TwentyFive": twentyFives, 
          "AvgPrice": avgPrices, 
          "SeventyFive": seventys, 
          "Ninety": ninetys
        };


*/
  const line = {
        //labels: xlabels,
        datasets: [
          {
            label: 'Total Number of Cars on Market',
            yAxisID: 'y2',
            fill: false,
            borderColor: 'rgba(180,180,255,1)',
            data: datasets["NumCars"],
          },
          {
            label: 'Auction Avg Sold Price',
            yAxisID: 'y1',
            fill: false,
            pointRadius: 8,
            borderColor: 'rgba(0,0,0,1.0)',
            //borderCapStyle: 'circle',
            data: datasets["AvgSoldPrice"],
          },
          {
            label: 'OLO Avg Price',
            yAxisID: 'y1',
            fill: false,
            pointRadius: 0.1,
            borderColor: 'rgba(128,0,128,1.0)',
            data: datasets["OloAvgPrice"],
          },
          {
            label: 'OLO Inventory',
            yAxisID: 'y2',
            fill: false,
            pointRadius: 0.1,
            borderColor: 'rgba(75,0,130,1.0)',
            data: datasets["OloNumCars"],
          },
          {
            label: 'Avg Market Retail Price',
            yAxisID: 'y1',
            fill: false,
            pointRadius: 0.1,
            backgroundColor: 'rgba(128,0,0,1.0)',
            borderColor: 'rgba(139,0,0,1.0)',
            data: datasets["AvgPrice"],
          },
          {
            label: '75%-90%',
            yAxisID: 'y1',
            fill: '+1',
            pointRadius: 0.1,
            lineTension: 0.1,
            backgroundColor: 'rgba(237,212,212,0.4)',
            borderCapStyle: 'butt',
            data: datasets["Ninety"],
          },
          {
            label: '_',
            yAxisID: 'y1',
            fill: false,
            pointRadius: 0.1,
            lineTension: 0.1,
            backgroundColor: 'rgba(237,212,212,0.1)',
            borderColor: 'rgba(237,212,212,0.1)',
            borderCapStyle: 'butt',
            data: datasets["SeventyFive"],
          },
          {
            label: 'Mid 50% Band',
            yAxisID: 'y1',
            fill: '+1',
            pointRadius: 0.1,
            lineTension: 0.1,
            backgroundColor: 'rgba(215,162,162,0.4)',
            borderCapStyle: 'butt',
            data: datasets["SeventyFive"],
          },
          {
            label: '_',
            yAxisID: 'y1',
            fill: false,
            pointRadius: 0.1,
            lineTension: 0.1,
            backgroundColor: 'rgba(215,162,162,0.1)',
            borderColor: 'rgba(215,162,162,0.1)',
            borderCapStyle: 'butt',
            data: datasets["TwentyFive"],
          },
          {
            label: '10%-20%',
            yAxisID: 'y1',
            fill: '+1',
            pointRadius: 0.1,
            lineTension: 0.1,
            backgroundColor: 'rgba(237,212,212,0.4)',
            borderColor: 'rgba(237,212,212,0.4)',
            borderCapStyle: 'butt',
            data: datasets["TwentyFive"],
          },
          {
            label: '_',
            yAxisID: 'y1',
            fill: false,
            pointRadius: 1,
            lineTension: 0.1,
            backgroundColor: 'rgba(237,212,212,0.4)',
            borderColor: 'rgba(237,212,212,0.4)',
            borderCapStyle: 'butt',
            data: datasets["Ten"],
          },
        ],
      };

    const options = {
        tooltips: {
          enabled: false
        },
        legend: {
          labels: {
              filter: function(item, chart) {
                // Logic to remove a particular legend item goes here
                return !item.text.includes('_');
              }
          },
          onClick: function (e, legendItem) {
            var index = legendItem.datasetIndex;
            let ci = this.chart;
            console.log("ci index", index);
            var ds = ci.getDatasetMeta(index);
            ds.hidden = ds.hidden === null ? !ci.data.datasets[index].hidden : null;
            if (index > 4) {
              var ds_bottom = ci.getDatasetMeta(index+1);
              ds_bottom.hidden = ds_bottom.hidden === null ? !ci.data.datasets[index+1].hidden : null;  
            }
            ci.update();
            //defaultLegendClickHandler(e, legendItem);
            /*
            [
                ci.getDatasetMeta(0),
                ci.getDatasetMeta(1)
            ].forEach(function(meta) {
                meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
            });
            ci.update();
            */            
          }
        },
        title: {
          display: true,
          position: 'top',
          text: 'Competition Prices and Sold Price for ' + yearText + ' ' + make + ' ' + model
        },
      maintainAspectRatio: false,
        spanGaps: false,
        elements: {
            line: {
                tension: 0.000001
            }
        },
        scales: {
              xAxes: [
                {
                  type: "time",
                  distribution: 'linear',
                  time: {
                    unit: "month",
                    displayFormats: {
                      day: 'YYYY-MM-DD'
                    },
                    stepSize: 1
                  },
                  ticks: {
                    max: endDate,
                    min: startDate,
                  }
                }
              ],
              yAxes: [
              {
                id: 'y1',
                name: 'y1',
                type: 'linear',
                display: true,
                position: 'left',
                scaleLabel: {
                  display: true,
                  labelString: "Average Price",
                  fontStyle: 'italic'
                }                
              },
              {
                id: 'y2',
                name: 'y2',
                type: 'linear',
                display: true,
                gridLines: {display: false,color: 'transparent'},
                position: 'right',
                ticks: {
                  beginAtZero: true,
                  max: maxY2,
                  min: 0,
              },
                scaleLabel: {
                  display: true,
                  labelString: "Number of Vehicles",
                  fontStyle: 'italic',
                  fontColor: 'rgba(180,180,255,1)'
                }
              }
            ],       
        }      
    }
    
    const loadLabels = ()=> {
      //console.log("loadLabels", _chart);
    }
   return (
    <CContainer fluid className="fullHeight">
    <CRow className="fullHeight">
        <CCol>
            <CCard className="fullHeight">
                <CCardHeader>
                  <CRow>
                    <CCol>Price Trend Chart</CCol>
                    <CCol>
                      <CSelect onChange={
                                    (e)=>{ 
                                        var newMake = e.target.options[e.target.selectedIndex].value;
                                        setHasChartData(false);
                                        setHasModel(false);
                                        setYears([]);
                                        setHasYear(false);
                                        setMake(newMake);
                                        getModels(newMake);  
                                    }}>
                                <option value="0">Select Make</option>
                                {makes && makes.map( (m, k)=>{
                                    return <option value={m} key={m}>{m}</option>
                                })}
                      </CSelect>
                    </CCol>
                    <CCol>
                        <CSelect onChange={
                                    (e)=>{ 
                                        var newModel = e.target.options[e.target.selectedIndex].value;
                                        if (newModel=="0") {
                                          setModel(newModel);
                                          setHasModel(false);  
                                        } else {
                                          setHasChartData(false);
                                          setModel(newModel);
                                          if (!hasYear) {
                                            getYears();
                                          }
                                          setHasModel(true)  
                                        }
                                    }}>
                                <option value="0">Select {make} Model</option>
                                {models && models.map( (m, k)=>{
                                    return <option value={m} key={m}>{m}</option>
                                })}
                        </CSelect>
                    </CCol>
                    <CCol>
                      <CSelect onChange={
                                    (e)=>{ 
                                        var newYear = e.target.options[e.target.selectedIndex].value;
                                        setHasChartData(false);
                                        setHasYear(false);
                                        setYear(newYear);
                                        setHasYear(true);

                                        if (newYear > 0) {
                                          setYearText(newYear.toString());
                                        } else {
                                          setYearText("");
                                        }
                                    }}>
                                <option value="0">All Years</option>
                                {years && years.map( (m)=>{
                                    return <option value={m} key={m}>{m}</option>
                                })}
                      </CSelect>
                    </CCol>
                    <CCol>
                      {
                        hasModel && <CButton onClick={()=>{getPrices(year, make, model)}} color="success">Show Chart</CButton>
                      }
                    </CCol>

                  </CRow>
                </CCardHeader>
                <CCardBody>
                    { !hasChartData && retrieving &&
                      <div>Retrieving price data for {yearText } {make} {model} ......</div>

                    }

                    { hasChartData && 
                        <CChart type="line" datasets={line.datasets} options={options} {...{ style: {height: '95%', width: '95%'}}}  />

                    }
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
</CContainer>


    )
}