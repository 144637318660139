import React, {useState, useEffect} from 'react'
import {RL_Instance} from '../../../../services/RunListManager';
import Multiselect from 'multiselect-react-dropdown';

export default (props)=>{
    const [check, setCheck] = useState(0);
    const [hasData, setHasData] = useState(false);
    const [selectedValues, setSelectedValues] = useState([]);
    const [options, setOptions] = useState([]);

    useEffect(()=>{
        if (RL_Instance.body_styles) {
            var raws = RL_Instance.body_styles;
            var newOptions = [];
            for(var i=0;i<raws.length;i++) {
                var style = raws[i];
                newOptions.push({name: style, id: i, key: i.toString()})
            }
            console.log("newOptions", newOptions);
            setOptions(newOptions);
            figureOutSelectedValues(newOptions);      
            setHasData(true);
        } else {
            setTimeout(tryOneMoreCheck, 1000);
        }
    },[check]);

    const getItem = (name, newOptions) => {
        const rawName = name.replace("'", "");
        for(var i =0; i< newOptions.length; i++) {
          if (newOptions[i].name == rawName) return newOptions[i];
        }
      }
    
    const figureOutSelectedValues = (newOptions) => {
        var loc = { ...props.localFilters };
        var bodyStyles = loc['bodyStyle'];
        console.log("figureOutSelectedValues, bodyStyle ", bodyStyles);
    
        if (bodyStyles && bodyStyles.length > 0) {
          var allSelected = [];
          var arr = bodyStyles.split(",");
          for(var i=0; i < arr.length; i++) {
            var item = getItem(arr[i], newOptions);
            if (item) {
                allSelected.push(item);
            }
          }
          setSelectedValues(allSelected);
        } else {
          setSelectedValues([]);
        }
      }
    

    const tryOneMoreCheck =()=> {
        console.log("body_styles", RL_Instance.body_styles);
        setCheck(check + 1);
    }

    const css = {
        option: {
          fontSize: "16px"
        },
        inputField: {
          height: "24px",
          fontSize: "16px"
        }
      }
    
      const syncUpSelection = (selectedList) => {
        var loc = { ...props.localFilters };
        if (selectedList && selectedList.length > 0) {
            var bodyStyles = "";
            var comma = "";
            for (var i=0;i < selectedList.length; i++) {
              bodyStyles = bodyStyles + comma + selectedList[i].name;
              comma=",";
            }
            loc['bodyStyle'] = bodyStyles;
            console.log("set bodyStyle", bodyStyles);
            props.setLocalFilters(loc)  
        } else {
          loc['bodyStyle'] = null
          props.setLocalFilters(loc)  
    
        }
      }
    
      const onSelect = (selectedList, selectedItem) => {
        console.log("selectedList", selectedList);
        syncUpSelection(selectedList);
      }
    
      const onRemove = (selectedList, removedItem) => {
        console.log("selectedList", selectedList);
        syncUpSelection(selectedList);
      }
    

    return(
        <Multiselect 
        options={options}
        selectedValues={selectedValues}
        onSelect = {onSelect}
        onRemove = {onRemove}
        showCheckbox={true}
        placeholder="Body Style"
        displayValue="name"
        hidePlaceholder={true}
        showArrow={true}
        style={css}
      />

    )
}

