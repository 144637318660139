import React, {useState, useEffect} from 'react';
import { CDataTable} from '@coreui/react';
import DamageImage from '../DamageImage/DamageImage'
import DamageNote from '../DamageNote/DamageNote';

//5YFBURHE6JP797377
export default ({damages, message, loading})=>{
    const [damageImg, setDamageImg] = useState(false)
    useEffect(()=>{
        setDamageImg(false)
    }, [damages])

    const showDamageImg = (img)=>{
        setDamageImg(img)
    }

    return <>
        {!damageImg && <CDataTable 
            fields={['item', 'severity', 'damage']}
            items={damages}
            noItemsViewSlot={message}
            loading={loading}
            scopedSlots={{
                "item": (row)=>{
                    if(row.imageHref){
                        return <td><a onClick={()=>{showDamageImg(row)}} className="link">{row.item}</a>
                        &nbsp;<DamageNote notes={row.notes}/></td>
                    }
                    else{
                        return <td>{row.item}</td>
                    }
                }
            }}
        />}
        {damageImg && <DamageImage damage={damageImg}  closeFunc={()=>{setDamageImg(false)}}/>}
    </>
}