import React, {useState, useEffect, useMemo, useCallback} from 'react';
import RunListManager from '../../../../services/RunListManager';
import cleanUpdate from 'immutability-helper';
import FormattedNumber from '../../../FormattedNumber/FormattedNumber';
import{ CButton, CInput, CForm, CBadge, CTooltip} from '@coreui/react'
import PriceSlider from '../PriceSlider/PriceSlider'
import BuyerNotes from './BuyerNotes/BuyerNotes';
import ReviewHistoryToolTip from './ReviewHistoryToolTip/ReviewHistoryToolTip';
import ModelInputToolTip from './ModelInputsToolTip/ModelInputsToolTip.js'
import Calculator from './Calculator/Calculator';
import Error from '../../../generic/Error/Error';
import PreviewTick from '../../../../services/PreviewTicker';
import Blackbook from '../Valuations/BlackBook/BlackBook';
import CopyToClipboard from '../VehicleData/CopyToClipboard/CopyToClipboard';
import BSRPForm from './bsrpField/bsrpField';
import PriceToMarket from './PriceToMarket/PriceToMarket';
import MarketPrice from './MarketPrice/MarketPrice';
import DataField from './DataField/DataField';
import ProfitTime from '../Valuations/ProfitTime/ProfitTime';


export default ({vehicle, updater, currentIndex, channel})=>{
    const [priceField, setPriceField] = useState('');
    const [bsrpField, setBSRPField] = useState('');
    const [rbook, setRbook] = useState('');
    const [days_supply, setDaysSupply] = useState('');
    const [market_days, setMarketDays] = useState('');
    const {addError} = Error();
    const [runOnce] = useState(0);
    const [ticker, setTicker] = useState({showTick: true, tickColor: 'primary', tickDisplay:'00:00'});
    const [bsrpVal, setBsrpVal] = useState('');
    const [bymVal, setBymVal] = useState('');

    useEffect(() => {
        PreviewTick.setTickListener(tickerCallback);
    },[runOnce]);
    
    useEffect( ()=>{
        var currentPrice = (vehicle.buyers_max_price) ? vehicle.buyers_max_price : '';
        var bsrp = (vehicle.buyer_sugg_retail_price) ? vehicle.buyer_sugg_retail_price : '';
        var vauto_rbook = (vehicle.vauto_rbook) ? vehicle.vauto_rbook : 'N/A';
        var vauto_days_supply = (vehicle.vauto_days_supply)? vehicle.vauto_days_supply : 'N/A'
        var vauto_market_days = (vehicle.vauto_market_days) ? vehicle.vauto_market_days : 'N/A'
        setPriceField(currentPrice)
        setBSRPField(bsrp)
        setRbook(vauto_rbook)
        setDaysSupply(vauto_days_supply)
        setMarketDays(vauto_market_days)
        setBsrpVal(localStorage.getItem('bsrpPer'))
        setBymVal(localStorage.getItem('bymPer'));
        PreviewTick.startVehiclePreview();
    }, [vehicle.vin])

    const tickerCallback =(data)=> {
        setTicker(data);
    }

    const bsrpToRbook = (v, rBook)=>{
        let mbsrp = Math.round( (v/100)*rBook);
        setBSRPField(mbsrp);
    }

    const bymToRbook = (v, rBook)=>{
        let mbym = Math.round( (v/100)*rBook);
        setPriceField(mbym);
    }

  const bsrpValueChanged = useCallback((val) => {
    setBsrpVal(val);
    localStorage.setItem('bsrpPer', val)
  });

  const bymValueChanged = useCallback((val) => {
    setBymVal(val);
    localStorage.setItem('bymPer', val)
  });

  const bsrpProps = useMemo(
    () => ({
      min: 80,
      max: 110,
      value: bsrpVal,
      step: 1,
      label: 'BSRP',
      onChange: (e) => bsrpValueChanged(e),
    }),
    [bsrpVal]
  );

    const bymProps = useMemo(
        () => ({
          min: 80,
          max: 110,
          value: bymVal,
          step: 1,
          label: 'BYM',
          onChange: (e) => bymValueChanged(e),
        }),
        [bymVal]
      );
  
    const markDQ = ()=>{
        var RL = new RunListManager();
        RL.updateVehicleListing(vehicle.vin, {reviewed:true, disqualify:true}, channel).then( (data)=>{
            if(data.error) return addError('Error Disqualifying Vehicle: ' + data.message, 500);
        }).catch( (e)=>{
            console.log(e);
            addError(e.message, 500);
        })
        var v = cleanUpdate(vehicle, {$apply:(item)=>{
            item.review_timestamp = new Date();
            item.buyers_dq_timestamp = new Date();
            return item;
        }})
        updater(currentIndex, v);
    }
    
    const markReviewed = (priceField, bsrpField)=>{
        var RL = new RunListManager();
        var update = {reviewed: true, disqualify: false};
        if(priceField > 0 ) update['maxBidPrice'] = priceField; 
        if(bsrpField > 0) update['buyer_sugg_retail_price'] = bsrpField;
        if(vehicle.yipitMarketPrice) update['yipitMarketPrice'] = vehicle.yipitMarketPrice;
        RL.updateVehicleListing(vehicle.vin, update, channel).then( (data)=>{
            if(data.error) return addError('Error Updating Listing: ' + data.message, 500);
        }).catch( (e)=>{
            console.log(e);
            addError(e.message, 500);
        });
        var v = cleanUpdate(vehicle, {$apply:(item)=>{
            item.review_timestamp = new Date();
            item.review_user ="me"
            item.buyers_dq_timestamp = null;
            if(update['maxBidPrice']) item.buyers_max_price = update['maxBidPrice']
            if(update['buyer_sugg_retail_price']) item.buyer_sugg_retail_price = update['buyer_sugg_retail_price']
            return item;
        }})
        updater(currentIndex, v);
    }


    const renderMMRTooltip = (vehicle)=> {
        console.log("vehicle.bestMatch", vehicle.bestMatch);
        if ( (vehicle.adjMmr && vehicle.adjMmr > 0) && 
            (!vehicle.bestMatch || vehicle.bestMatch==="0") ) {
            return (
                <div className="mmrFlag">
                <CTooltip content={"No best match for this vehicle and Adj. MMR displayed is from the lowest trim"}>
                    <span className="mmrQuestionMark">?</span>
                </CTooltip>
            </div>        
            );
        } else {
            return "";
        }
    }
        
    
    return(<div className="d-flex flex-column w-100">            
        <div className="d-flex align-items-center justify-content-start w-100">
            
            { (rbook > 0) && <PriceToMarket bsrp={bsrpField} market={rbook}/>}

            <div className="suggested-price">    
                <div className="d-flex flex-column">
                    <div className="olo-price-label">BSRP:</div>
                    <CForm 
                        onSubmit={(e)=>{
                            e.preventDefault();
                            markReviewed(priceField, bsrpField);
                        }}
                        inline
                    >
                            <div>                   
                                <CInput 
                                    id="bsrp" placeholder="Enter BSRP" value={bsrpField} 
                                    onChange={
                                        (e)=>{
                                            e.preventDefault();
                                            var newValue = e.target.value.replace(/\D/gi, '');
                                            setBSRPField(newValue);
                                    }}
                                    autoComplete="off"
                                    className="olo-price-field"
                                />
                            </div>
                        </CForm>
                </div>
            </div>
            <div className="suggested-price">    
                <div className="d-flex flex-column">
                    <div className="olo-price-label d-flex">
                        Buyer Max Bid: 
                        {(vehicle.review_timestamp && vehicle.review_user) &&
                            <div className="buyer_info">
                                <span className="buyer_hover">?</span>
                                <ReviewHistoryToolTip vehicle={vehicle}/>
                            </div>
                        }
                       
                    </div>
                    <CForm 
                        onSubmit={(e)=>{
                            e.preventDefault();
                            markReviewed(priceField, bsrpField);
                        }}
                        inline
                    >
                            <div>                   
                                <CInput 
                                    id="price" placeholder="Max Price" value={priceField} 
                                    onChange={
                                        (e)=>{
                                            e.preventDefault();
                                            var newValue = e.target.value.replace(/\D/gi, '');
                                            setPriceField(newValue);
                                    }}
                                    autoComplete="off"
                                    className="olo-price-field"
                                />
                            </div>
                        </CForm>
                </div>  
            </div>  
            <div>
                <div className="olo-price-label">&nbsp;</div>
                <CButton color="success" onClick={()=>{markReviewed(priceField, bsrpField)}} >Accept</CButton>
                <CButton color="danger" onClick={markDQ} className="mx-2" >Disqualify</CButton>
            </div>
            <div className="flex-grow-1">
                <div className="olo-price-label">Buyer Notes:</div>
                <BuyerNotes vehicle={vehicle} updater={updater} currentIndex={currentIndex} channel={channel}/>
            </div>
            <div className="px-1">
            { ticker.showTick && <h3><CBadge color={ticker.tickColor} shape="pill">{ticker.tickDisplay}</CBadge></h3>}

            <div className="olo-price-label"></div>
                <Calculator 
                    buyerMaxBid={priceField} 
                    retailPrice={vehicle.ExpectedRetailPrice} 
                    transport={vehicle.ExpectedTransportationCost}
                    reconCost={vehicle.ExpectedReconCost}
                    vin={vehicle.vin}
                />
            </div>
        </div>
        <div className="d-flex align-items-center justify-content-start w-100" style={{'paddingLeft': '117px', 'paddingTop': '12px' }}>
            <div className='range-slider'>
                {(rbook > 0) && <PriceSlider {...bsrpProps} classes="additional-css-classes" />} {(rbook > 0) &&<CButton color="primary" size='sm' onClick={() => {bsrpToRbook(bsrpVal, rbook)}}>BSRP</CButton>}
            </div>
            <div className='range-slider' style={{'paddingLeft': '8px'}}>
                {(rbook > 0) && <PriceSlider {...bymProps} classes="additional-css-classes" />}
                {(rbook > 0) && <CButton color="primary" size='sm' onClick={() => {bymToRbook(bymVal, rbook)}}>BYM</CButton>}
            </div>  
        </div>
        <div className="d-flex align-items-center justify-content-between w-100 pt-3">
        <MarketPrice yipitMarketPrice={vehicle.yipitMarketPrice} yipitSampleSize={vehicle.yipitSampleSize} />
        <DataField value={vehicle.mileage} label={"mileage"} number={true} price={false}/>
        <DataField value={vehicle.CRScore} label={"CR Score"} number={true} price={false} decimal={1} border={true}/>
        <DataField value={vehicle.adjMmr} label="Adj. MMR" number={true} price={true} border={true}/>
        {renderMMRTooltip(vehicle)}

        <DataField value={<Blackbook vin={vehicle.vin}/>} label="Black Book" number={true} price={true} border={true}/>
        <DataField value={rbook} label={"Vauto RBook"} number={true} price={true}/>
        <DataField value={days_supply} label={"Vauto Days Supply"} number={true} price={false} border={true}/>
        <DataField value={market_days} label=" Vauto 'Like Mine' Days Supply" number={true} price={false} border={true}/>
        <DataField value={'FL-$' + vehicle.ShippingCost + '/TX-$' + vehicle.houstonShippingCost} label="Shipping Cost" number={true} price={true} border={true}/>
        <DataField value={<ProfitTime vin={vehicle.vin} auctionDate={vehicle.auction_date} maxBid={priceField}/>} label="PT Prediction" number={false} price={false} border={true}/>
        <DataField value={vehicle.drivetrain} label="Drivetrain" number={false} price={false} border={true}/>                           
        </div>
        </div>
    )

}