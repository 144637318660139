import React, {useState, useEffect, useCallback} from 'react';
import jwt from 'jsonwebtoken';
import Sidebar from './components/Sidebar/Sidebar';
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import Main from './components/Main/Main';
import Main2 from './components/Main/Main2';
import Stage from './components/Stage/Stage';
import InventoryDashboard from './components/InventoryManagement/Dashboard/Dashboard';
import InventoryMakeChart from './components/InventoryManagement/Dashboard/MakeChart';
import InventoryModelChart from './components/InventoryManagement/Dashboard/ModelChart';
import InventoryYearChart from './components/InventoryManagement/Dashboard/YearChart';
import InventoryList from './components/InventoryManagement/InventoryList/InventoryList';
import InventoryDetail from './components/InventoryManagement/InventoryDetail/InventoryDetail';
import InventoryConfig from './components/InventoryManagement/Configuration/Configuration';
import Pricing from './components/InventoryManagement/Pricing/Pricing';
import Reporting from './components/Reporting/Reporting';
import FeatureFlags from './components/FeatureFlags/FeatureFlags';
import QuickVIN from './components/QuickVIN/QuickVIN';
import {RL_Instance, getCookie} from './services/RunListManager';
import WholesaleAuctionChart from './components/Pricing/Charts/WholesaleAuctionChart';
import { AppraisalTool } from './components/AppraisalTool/AppraisalTool'
import {VASDashboard} from './components/VAS_Dashboard';
import {AppraiserView} from './components/AppraiserView';
import CustomerOffer from './components/CustomerOffer';

import {
  BrowserRouter as Router, 
  Route, 
  Switch, 
  Redirect
} from 'react-router-dom';
import { SuperUSerView } from './components/SuperUserView';
import { Shift } from './components/Shift/Shift';
import { ManualAppraisal } from './components/ManualAppraisal';
import { ManualCount } from './components/Shift/ManualCount';


function App(props) {
  const [sidebar, setSideBar] = useState(false)
  const [isAppraiser, setIsAppraiser] = useState('');
  const [usGroups, setUsGroups] = useState({});
  const [userRoles, setUserRoles] = useState([]);
  // RL_Instance.initInventorySettings();

  function getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(';');
      for(var i=0;i < ca.length;i++) {
          var c = ca[i];
          while (c.charAt(0)==' ') c = c.substring(1,c.length);
          if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
      }
      return null;
  }

  const checkAppraiser = (intervalHandle) => {
    var rawToken = getCookie('AD_idToken');
    const decodedToken = jwt.decode(rawToken, {complete: true});
    const userGroups = decodedToken && decodedToken.payload && decodedToken.payload.groups ?
      decodedToken.payload.groups : [];

    if (Object.values(userGroups).length > 0) {
      setUsGroups(usGroups => Object.values(userGroups));
      clearInterval(intervalHandle);
    }
  }

  const ProtectedNonAppraiserRoute = useCallback(({component: RouteComponent, ...props}) => {
    return (
      <Route {...props}>
            {userRoles.indexOf('Other') > -1 ? <RouteComponent {...props} /> : 
             userRoles.indexOf('SuperUser') > -1 ? <Redirect to={"/appraisal/superuser"} /> :
             userRoles.indexOf('Appraiser') > -1 ? <Redirect to={"/appraisal/appraiser"} /> :
             userRoles.indexOf('VAS') > -1 ? <Redirect to={"/appraisal/vas"} /> : <Redirect to={"/"} />}
      </Route>
    )
  }, [userRoles])

  // const ProtectedNonAppraiserRoute = useCallback(({component: RouteComponent, ...props}) => {
  //   return (
  //     <Route {...props}>
  //           {isAppraiser === 'Other' ? <RouteComponent {...props} /> : 
  //            isAppraiser === 'Appraiser' ? <Redirect to={"/appraisal/appraiser"} /> :
  //            isAppraiser === 'VAS' ? <Redirect to={"/appraisal/vas"} /> : <Redirect to={"/"} />}
  //     </Route>
  //   )
  // }, [isAppraiser])

  useEffect(() => {
    const intervalHandle = setInterval(() => {  
      if (Object.values(usGroups).length <= 0) {
        checkAppraiser(intervalHandle);
      } 
    }, 200);  
  }, [])

  // useEffect(() => {
  //   if (Object.values(usGroups).length > 0) {
  //     if (Object.values(usGroups).indexOf(process.env.REACT_APP_APPRAISER_GROUP_ID) > -1 && Object.values(usGroups).length == 2) {
  //       setIsAppraiser('Appraiser');
  //     } else if (Object.values(usGroups).indexOf(process.env.REACT_APP_VAS_GROUP_ID) > -1 && Object.values(usGroups).length == 1) {
  //       setIsAppraiser('VAS');
  //     } else {
  //       setIsAppraiser('Other');
  //     }
  //   }
  // }, [usGroups])

  useEffect(() => {
    if (Object.values(usGroups).length > 0) {
      const usGroupIDsArray = Object.values(usGroups);
      let userRoleArrayTemp = [];

      usGroupIDsArray.map(usGroupId => {
        if (usGroupId === process.env.REACT_APP_SUPERUSER_GROUP_ID) {
          userRoleArrayTemp.push('SuperUser');
        } else if (usGroupId === process.env.REACT_APP_APPRAISER_GROUP_ID) {
          userRoleArrayTemp.push('Appraiser');
        } else if (usGroupId === process.env.REACT_APP_VAS_GROUP_ID) {
          userRoleArrayTemp.push('VAS');
        } else if (usGroupId === process.env.REACT_APP_CTSAUCTION_API_USER_GROUP_ID){
          userRoleArrayTemp.push('Other');
        }
      })

      setUserRoles([...userRoleArrayTemp]);
    }
  }, [usGroups])

  // useEffect(() => {
  //   if (isAppraiser === 'Other') {
  //     RL_Instance.initInventorySettings();
  //   }
  // }, [isAppraiser])

  useEffect(() => {
    if (userRoles.indexOf('Other') > -1) {
      RL_Instance.initInventorySettings();
    }
  }, [userRoles])

  return(
    <>
      {
        userRoles.length > 0 &&
          (
              <>
                <div className="c-app c-default-layout">
                    <Router>
                    <Sidebar show={sidebar} isAppraiser={isAppraiser} userRoles={userRoles} />
                    <div className="c-wrapper">
                      <Header setSideBar={setSideBar} sidebar={sidebar} />
                      <div className="c-body">
                        <Switch>
                          <ProtectedNonAppraiserRoute exact path="/"
                            component={Home} 
                            // component={!isAppraiser ?  <Home/> : <Redirect to={"/appraisal/vinlookup"} />} 
                          />
                          <ProtectedNonAppraiserRoute exact path="/runlist/:channel/:runListId/review"
                            component={getCookie('oldPreview') == 1 ? Main : Main2} /> 
                          <ProtectedNonAppraiserRoute exact path="/runlist/:channel/:runListId/stage" component={Stage} />
                          <ProtectedNonAppraiserRoute exact path="/inventory/dashboard" component={InventoryDashboard} />
                          <ProtectedNonAppraiserRoute exact path="/inventory/chart/make" component={InventoryMakeChart} />
                          <ProtectedNonAppraiserRoute exact path="/inventory/chart/model" component={InventoryModelChart} />
                          <ProtectedNonAppraiserRoute exact path="/inventory/chart/year" component={InventoryYearChart} />
                          <ProtectedNonAppraiserRoute exact path="/inventory/list" component={InventoryList} />
                          <ProtectedNonAppraiserRoute exact path="/inventory/detail/:make/:model" component={InventoryDetail} />
                          <ProtectedNonAppraiserRoute exact path="/inventory/config" component={InventoryConfig} />
                          <ProtectedNonAppraiserRoute exact path="/inventory/pricing" component={Pricing} />
                          <ProtectedNonAppraiserRoute exact path="/reporting/buyers" component={Reporting} />
                          <ProtectedNonAppraiserRoute exact path="/featureflags" component={FeatureFlags} />
                          <ProtectedNonAppraiserRoute exact path="/firstlook/index" component={QuickVIN} />
                          <ProtectedNonAppraiserRoute exact path="/pricing/chart" component={WholesaleAuctionChart} />
                          {/* <Route
                            // exact 
                            path="/appraisal/vinlookup/:vin/:mileage/:created_date"
                          >
                            <AppraisalTool view={`Appraiser`} />
                          </Route> */}
                          <Route
                            // exact 
                            path="/appraisal/vinlookup"
                            >
                            <AppraisalTool />
                          </Route>
                          <Route exact path="/appraisal/vas">
                            <VASDashboard />
                          </Route>
                          {/* <ProtectedNonAppraiserRoute exact path="/appraisal/vas" component={VASDashboard} /> */}
                          {/* <ProtectedNonAppraiserRoute exact path="/appraisal/appraiser" component={AppraiserView} /> */}
                          <Route exact path="/appraisal/appraiser">
                            <AppraiserView />
                          </Route>
                          <Route exact path="/appraisal/manualappraisal">
                            <ManualAppraisal isShiftData = {false}/>
                            {/* <CustomerOffer /> */}
                          </Route>
                          <Route exact path="/appraisal/superuser">
                            <SuperUSerView />
                          </Route>
                          <Route exact path="/appraisal/shift">
                            <Shift usgroup={userRoles}/>
                          </Route>
                          <Route exact path="/appraisal/appriasalcount">
                            <ManualCount />
                          </Route>
                        </Switch>
                      </div>
                    </div>
                  </Router>
              </div>
            </>
          )
        }
      </>
    )
}

export default App;