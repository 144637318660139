import React from 'react';
import {CListGroupItem, CBadge} from '@coreui/react';
import FormattedNumber from '../../../../FormattedNumber/FormattedNumber';

export default ({vehicle, active, clickAction, channel})=>{

    return(
        <CListGroupItem action className={ (active) ? 'olo-master-row-selected' : 'olo-master-row'} onClick={clickAction}>
            {vehicle.review_timestamp &&
                <div className="d-flex w-100 justify-content-between align-items-center">
                    <div className="flex-fill">
                        <CBadge className="olo-badge">Reviewed</CBadge>
                        {vehicle.buyers_dq_timestamp && <CBadge  className="olo-badge danger ml-1">TEMP DQ</CBadge>}
                    </div>
                    <div className="olo-master-price">
                        <FormattedNumber value={vehicle.buyers_max_price} price={true}/>
                    </div>
                </div>
            }
            
            <div className="py-2">
                <b>{vehicle.year} {vehicle.make} {vehicle.model} ({vehicle.extendedModel})</b>
            </div>
            <div className="mb-1 d-flex w-100 justify-content-between align-items-center">
               {vehicle.auctionLocation} - Lane {vehicle.auction_lane} ({vehicle.runNumber})<br/>
               <FormattedNumber value={vehicle.mileage}/> mi / {vehicle.CRScore} CR<br/>
            </div>
        </CListGroupItem>
    )
}