import React from 'react'
import {CCard, CCardHeader,CCardBody} from '@coreui/react'



export default (props)=>{
    return (
        <CCard>
        <CCardHeader>Announcements</CCardHeader>
        <CCardBody>
            {(props.announcements && props.announcements != 'None') && props.announcements}
        </CCardBody>
    </CCard>
    )
}