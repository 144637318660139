import React from "react";
import { CCard, CCardHeader, CCardBody } from "@coreui/react";

export const ShoppingIndicator = (props) => {
  const {rank, comments, make, model } = props;

  return (
    <div>
      <CCard>
        <CCardHeader color="success" style={{ color: 'white', fontWeight: '600'}}>
            OLO Shopping List
        </CCardHeader>
        <CCardBody>
            <div style={{color: 'gray', fontSize: '14px'}}>Vehicle Rank : {rank}</div>
          <div className="mt-4" style={{fontWeight: '600'}}>{`${make} ${model} is in shopping list`}</div>
          <div className="mt-2"> Comments:
            <span
               style={{ fontStyle: "italic", color: "gray", fontSize: "12px", marginLeft:'5px' }}
            >
             {comments
                ? comments
                : "No comments available for this vehicle"}
            </span>
          </div>
        </CCardBody>
      </CCard>
    </div>
  );
};