import React, {useState, useEffect, useRef} from 'react';
import {CRow,CCol,CButton, CCard, CCardHeader, CCardBody, CCollapse
} from '@coreui/react';
import { CIcon } from '@coreui/icons-react';
import {cilReload} from '@coreui/icons';
import DatePicker from "react-datepicker";
import RunListManager, {getCookie} from '../../services/RunListManager';
import { AppraisalQueue } from '../VAS_Dashboard/AppraisalQueue';
import jwt from 'jsonwebtoken';
import { Button } from 'semantic-ui-react';
import CustomerOffer from '../CustomerOffer';
import CustomerPreviewModal from '../CustomerPreviewModal';
import OfferModalContainer from './OfferModalContainer';
import {AppraisalTool} from '../AppraisalTool/AppraisalTool';
import {ArchiveRecordsView} from '../ArchiveRecordsView';
import {OfferSheetContent} from '../OfferSheetContent';
import Error from '../generic/Error/Error';
import {DateTime} from 'luxon';
import {LastRefreshTime} from '../LastRefreshTime';

export const AppraiserView = (props) => {
    var RL = new RunListManager();
    const {addError} = Error();
    const initialStartdt = DateTime.now().setZone("America/Chicago").minus({days: 7}).toJSDate();
    const initialEndDt = DateTime.now().setZone("America/Chicago").toJSDate();
    const currentDt = DateTime.now().setZone("America/Chicago").toJSDate();
    const [unclaimedLoading, setUnclaimedLoading] = useState(false)

    const unclaimedAppraisalFields = [
        { key: 'first_name', label: 'First Name', _style: { width: '8%'} },
        { key: 'last_name', label: 'Last Name', _style: { width: '8%'} },
        { key: 'store_name', label: 'Store', _style: { width: '8%'} },
        { key: 'vin', label: 'VIN', _style: { width: '12%'} },
        { key: 'modified_created_date', label: 'Created', _style: { width: '14%'} },
        { key: 'vas', label: 'Specialist', _style: { width: '10%'} },
        { key: 'appraiser', label: 'Appraiser', _style: { width: '10%'} },
        { key: 'inspection_status', label: 'Inspection', _style: { width: '10%'} },
        { key: 'offer_status', label: 'Appraisal', _style: { width: '10%'} }
    ];

    const myAppraisalFields = [
        {
            key: 'appraiser_archive',
            label: '',
            _style: { width: '2%' },
            sorter: false,
            filter: false
        },
        { key: 'first_name', label: 'First Name', _style: { width: '8%'} },
        { key: 'last_name', label: 'Last Name', _style: { width: '8%'} },
        { key: 'store_name', label: 'Store', _style: { width: '8%'} },
        { key: 'vin', label: 'VIN', _style: { width: '12%'} },
        { key: 'modified_created_date', label: 'Created', _style: { width: '14%'} },
        { key: 'vas', label: 'Specialist', _style: { width: '10%'} },
        { key: 'appraiser', label: 'Appraiser', _style: { width: '10%'} },
        { key: 'inspection_status', label: 'Inspection', _style: { width: '10%'} },
        { key: 'offer_status', label: 'Appraisal', _style: { width: '8%'} },
        // { key: 'appraisal_status', label: 'Appraisal Status', _style: { width: '10%'} },
        { key:'comment_indicator', label:'', _style:{ width: '2%'}},
        {
        key: 'offer',
        label: '',
        _style: { width: '4%' },
        sorter: false,
        filter: false
        }
    ];

    const archiveFields = [
        {
            key: 'appraiser_archive',
            label: '',
            _style: { width: '2%' },
            sorter: false,
            filter: false
        },
        { key: 'first_name', label: 'First Name', _style: { width: '8%'} },
        { key: 'last_name', label: 'Last Name', _style: { width: '8%'} },
        { key: 'store_name', label: 'Store', _style: { width: '8%'} },
        { key: 'vin', label: 'VIN', _style: { width: '12%'} },
        { key: 'modified_created_date', label: 'Created', _style: { width: '14%'} },
        { key: 'vas', label: 'Specialist', _style: { width: '10%'} },
        { key: 'appraiser', label: 'Appraiser', _style: { width: '10%'} },
        { key: 'inspection_status', _style: { width: '10%'} },
        { key: 'offer_status', label: 'Appraisal Status', _style: { width: '10%'} },
        // { key: 'appraisal_status', label: 'Appraisal Status', _style: { width: '10%'} },
        // {
        // key: 'offer',
        // label: 'Offer',
        // _style: { width: '10%' },
        // sorter: false,
        // filter: false
        // },
    ];

    const [startDate, setStartDate] = useState(initialStartdt);
    const [endDate, setEndDate] = useState(initialEndDt);
    const [unclaimedRecords, setUnclaimedRecords] = useState([]);
    const [myAppraisalRecords, setMyAppraisalRecords] = useState([]);
    const [shiftVins, setShiftVins] = useState([]);
    const [unclaimedVins, setUnclaimedVins] = useState([]);
    const [claimedVins, setclaimedVins] = useState([]);
    const [userName, setUserName] = useState('');
    const [isDataRetrieved, setIsDataRetrieved] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [showPreview, setShowPreview] = useState(false);
    const [viewVehicleDetails, setViewVehicleDetails] = useState({ show: false, vehicle: {} });
    const [viewArchivedRecords, setViewArchivedRecords] = useState(false);
    const [archiveRecords, setArchiveRecords] = useState([]);

    let timerRef = useRef();

    const fetchArchivedRecords = () => {
        RL.fetchAppraisalRecords('appraiser', userName)
            .then(res => {
                if (res && res.data && res.data.archived_record_lists) {
                    // setArchiveRecords();
                    const recordLists = res.data.archived_record_lists;

                    const updatedRecordLists = recordLists.map(record => {
                        let recordObj = {
                            ...record
                        }

                        const dateTime = new Date(record.created_date);

                        const hours = parseInt(("0" + dateTime.getHours()).slice(-2));
                        const updatedHours = (hours + 11) % 12 + 1;
                        const suffix = hours > 12 ? 'PM' : 'AM';
                        // const updatedHours = (parseInt(("0" + dateTime.getUTCHours()).slice(-2)) + 11) % 12 + 1;

                        recordObj['modified_created_date'] 
                            = dateTime.getFullYear() + "-" +
                        ("0" + (dateTime.getMonth()+1)).slice(-2) + "-" +
                        ("0" + dateTime.getDate()).slice(-2) + "\n" +
                        (updatedHours) + ":" +
                        ("0" + dateTime.getMinutes()).slice(-2) + " " + suffix;

                        return recordObj
                    })
                    setArchiveRecords(updatedRecordLists)
                } else {
                    setArchiveRecords([])
                }
            })
            .catch(err => {
                console.error(err);
            })
    };

    const archiveRecordHandler = (vin, input_role, role_value, created_date, toggleValue) => {
        // make a call to the api
        RL.archiveAppraisalRecord(vin, input_role, role_value, created_date, toggleValue)
            .then(res => {
                // invoke getMyAppraisals on succesful return
                if (res.status == 200) 
                    getMyAppraisals();
                    fetchArchivedRecords();
            })
            .catch(err => {
                console.error(err);
            })
    };

    // parse the preferred_username from the auth token
    const getPreferredUserName = () => {
        var rawToken = getCookie('AD_idToken');
        const decodedToken = jwt.decode(rawToken, {complete: true});

        if (decodedToken && decodedToken.payload && decodedToken.payload.preferred_username) {
            setUserName(decodedToken.payload.preferred_username);
        }
    }

    const getShiftAppraisals = () => {             
        RL.getShiftAppraisalRecordsForAppraiser()
                    .then(res => {
                        if(res && res.data && res.data.shift_appraisal_record_lists) {
                            const recordLists = res.data.shift_appraisal_record_lists;
                            const updatedVinsLists = recordLists.map(record => {
                                return {'vin': record.vin, 'leadType': record.shift_lead_type}
                            })
                            setShiftVins(updatedVinsLists);
                        } else {
                            setShiftVins([]);
                        }                        
                    })
                    .catch(error => {
                        console.error('Could not retrieve shift appraisals' + error);
                        addError(error.message)
                    })
    }

    // button click handlers and REST calls
    const getUnclaimedAppraisals = () => {
        RL.getAppraisalRecordsForAppraiser()
                    .then(res => {
                        if(res && res.data && res.data.appraisal_record_lists) {
                            const recordLists = res.data.appraisal_record_lists;

                            const updatedRecordLists = recordLists.map(record => {
                                let recordObj = {
                                    ...record
                                }

                                const dateTime = new Date(record.created_date);

                                // recordObj['modified_created_date'] 
                                //     = dateTime.getUTCFullYear() + "-" +
                                // ("0" + (dateTime.getUTCMonth()+1)).slice(-2) + "-" +
                                // ("0" + dateTime.getUTCDate()).slice(-2) + " " +
                                // ("0" + dateTime.getUTCHours()).slice(-2) + ":" +
                                // ("0" + dateTime.getUTCMinutes()).slice(-2)

                                const hours = parseInt(("0" + dateTime.getHours()).slice(-2));
                                const updatedHours = (hours + 11) % 12 + 1;
                                const suffix = hours > 12 ? 'PM' : 'AM';
                                // const updatedHours = (parseInt(("0" + dateTime.getUTCHours()).slice(-2)) + 11) % 12 + 1;

                                recordObj['modified_created_date'] 
                                    = dateTime.getFullYear() + "-" +
                                ("0" + (dateTime.getMonth()+1)).slice(-2) + "-" +
                                ("0" + dateTime.getDate()).slice(-2) + "\n" +
                                (updatedHours) + ":" +
                                ("0" + dateTime.getMinutes()).slice(-2) + " " + suffix;

                                return recordObj
                            })
                            const updatedVinsLists = recordLists.map(record => {
                                return record.vin
                            })
                            setUnclaimedLoading(false);
                            setUnclaimedRecords(updatedRecordLists);
                            setUnclaimedVins(updatedVinsLists);
                        } else {
                            setUnclaimedLoading(false)
                            setUnclaimedRecords([]);
                            setUnclaimedVins([]);
                        }                        
                    })
                    .catch(error => {
                        console.error('Could not retrieve unclaimed appraisals' + error);
                        addError(error.message)
                    })
    }

    const getMyAppraisals = (start_date, end_date) => {
        RL.getAppraisalRecordsForAppraiser(start_date, end_date, userName)
                    .then(res => {
                        if(res && res.data && res.data.appraisal_record_lists) {
                            const recordLists = res.data.appraisal_record_lists;

                            const updatedRecordLists = recordLists.map(record => {
                                let recordObj = {
                                    ...record
                                }

                                const dateTime = new Date(record.created_date);

                                // recordObj['modified_created_date'] 
                                //     = dateTime.getUTCFullYear() + "-" +
                                // ("0" + (dateTime.getUTCMonth()+1)).slice(-2) + "-" +
                                // ("0" + dateTime.getUTCDate()).slice(-2) + " " +
                                // ("0" + dateTime.getUTCHours()).slice(-2) + ":" +
                                // ("0" + dateTime.getUTCMinutes()).slice(-2)

                                const hours = parseInt(("0" + dateTime.getHours()).slice(-2));
                                const updatedHours = (hours + 11) % 12 + 1;
                                const suffix = hours > 12 ? 'PM' : 'AM';
                                // const updatedHours = (parseInt(("0" + dateTime.getUTCHours()).slice(-2)) + 11) % 12 + 1;

                                recordObj['modified_created_date'] 
                                    = dateTime.getFullYear() + "-" +
                                ("0" + (dateTime.getMonth()+1)).slice(-2) + "-" +
                                ("0" + dateTime.getDate()).slice(-2) + "\n" +
                                (updatedHours) + ":" +
                                ("0" + dateTime.getMinutes()).slice(-2) + " " + suffix;

                                return recordObj
                            })
                            const updatedVinsLists = recordLists.map(record => {
                                return record.vin
                            })
                            setMyAppraisalRecords(updatedRecordLists);
                            setclaimedVins(updatedVinsLists)
                        } else {
                            setMyAppraisalRecords([]);
                            setclaimedVins([]);
                        }                        
                    })
                    .catch(error => {
                        console.error('Could not retrieve appraisals records for current user' + error);
                    })
    }

    // parse username from token
    useEffect(() => {
        getPreferredUserName();
    },[])

    // fetch appraisals records
    useEffect(() => {
        if (userName) {
            getShiftAppraisals();
            setUnclaimedLoading(true);
            getUnclaimedAppraisals();
            getMyAppraisals();
            fetchArchivedRecords();

            const unclaimedApprInterval = setInterval(() => {
                getUnclaimedAppraisals();
                getMyAppraisals((startDate.getFullYear() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getDate()), (endDate.getFullYear() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getDate()));
            }, 10000);

            return () => {
                clearInterval(unclaimedApprInterval);
            }
        }        
    }, [userName, startDate, endDate]) 
    
    return (
        <>
            {/* Unclaimed Appraisals */}
            <CRow>
                <CCol className="pt-3 col-md-11 mt-5" style={{ margin: "0px auto", background: "white" }}>
                    <div className="d-flex justify-content-between align-items-center">
                        <h3 className='pb-2'>Unclaimed Appraisals</h3>
                        <div>
                            <LastRefreshTime 
                                refreshProp={unclaimedRecords}
                             />
                            <Button 
                                onClick={() => {
                                    getUnclaimedAppraisals();
                                }}
                            >
                                <CIcon content={cilReload}
                                    style={{ 
                                        width: '35px', 
                                        height: '20px', 
                                        padding: '1px',  
                                    }} 
                                />
                            </Button>
                        </div>
                    </div>                    
                    <AppraisalQueue 
                        appraisalRecords={unclaimedRecords}
                        unclaimedLoading = {unclaimedLoading}
                        badgeField={[`inspection_status`, `offer_status`]}
                        search={true}
                        recordsPerPage={10}
                        dropdownField={`appraiser`}
                        dropDownoptions={[userName]}
                        fields={unclaimedAppraisalFields}
                        shiftvins={shiftVins}
                        unclaimedvins={unclaimedVins}
                        getMyAppraisals={getMyAppraisals}
                        getUnclaimedAppraisals={getUnclaimedAppraisals}
                        roleType={"Appraiser"}
                    />
                </CCol>
            </CRow>
            {/* My Appraisals */}
            <CRow>
                <CCol className="pt-3 col-md-11 mt-5 mb-5" style={{ margin: "0px auto", background: "white" }}>
                    <div className="d-flex justify-content-between align-items-center">
                        <h3 className='pb-2'>My Appraisals</h3>
                        <div className="d-flex justify-content-between align-items-center col-md-8">
                            <div className="runlistLabel">From Date</div>
                            <div>
                                <DatePicker maxDate={currentDt} selected={startDate} onChange={date=> setStartDate(date)} wrapperClassName="runlist-datepicker-wrapper" />
                            </div>
                            <div className='endDate'>To Date</div>
                            <div>
                                <DatePicker maxDate={currentDt} selected={endDate} onChange={date=> setEndDate(date)} wrapperClassName="runlist-datepicker-wrapper" />
                            </div>
                            <CButton color="primary" onClick={()=>{getMyAppraisals((startDate.getFullYear() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getDate()), (endDate.getFullYear() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getDate()))} }>
                                GET
                            </CButton>
                            <div className='ml-3'>
                                <Button 
                                    onClick={() => {
                                        getMyAppraisals();
                                        setStartDate(initialStartdt);
                                        setEndDate(initialEndDt);
                                    }}
                                >
                                    <CIcon content={cilReload}
                                        style={{ 
                                            width: '35px', 
                                            height: '20px', 
                                            padding: '1px',  
                                        }} 
                                    />
                                </Button>
                            </div>
                        </div>
                    </div>
                    <AppraisalQueue 
                        appraisalRecords={myAppraisalRecords}
                        badgeField={[`offer_status`]}
                        dropdownField={`offer_status`}
                        search={true}
                        recordsPerPage={5}
                        fields={myAppraisalFields}
                        offerButtons={[
                            {
                                id: 1,
                                text: 'Generate Offer Sheet',
                                setShowViewModal,
                                setModalContent,
                                setShowPreview
                            },
                            {
                                id: 2,
                                text: 'Edit Offer Sheet',
                                setShowViewModal,
                                setModalContent
                            }
                        ]}
                        appraiserRecordsView={true}
                        shiftvins={shiftVins}
                        unclaimedvins={claimedVins}
                        setViewVehicleDetails={setViewVehicleDetails}
                        getMyAppraisals={getMyAppraisals}
                        fetchArchivedRecords={fetchArchivedRecords.bind('appraiser', userName)}
                    />
                </CCol>
            </CRow>
            <OfferModalContainer show={viewVehicleDetails.show} setShow={() => setViewVehicleDetails({show: false, vehicle: {}})}>
                {
                    viewVehicleDetails && viewVehicleDetails.show && (
                        <AppraisalTool 
                            vin={viewVehicleDetails.vehicle.vin}
                            mileage={viewVehicleDetails.vehicle.mileage}
                            created_date={viewVehicleDetails.vehicle.created_date}
                            view={`Appraiser`}
                            appraisalValue={viewVehicleDetails.vehicle.appraisal_value ? viewVehicleDetails.vehicle.appraisal_value : ''}
                            getMyAppraisals={getMyAppraisals}
                        />
                    )
                }
            </OfferModalContainer>
            {/* <CustomerPreviewModal 
                    show={true}
                    setShow={setShowViewModal}
                /> */}
            {/* Archived Records */}
            {
                (modalContent.action && modalContent.action == 'View') && (
                    <OfferModalContainer show={showPreview} setShow={setShowPreview} item={modalContent.item} saveFileButton={true}>
                        <OfferSheetContent 
                            item={modalContent.item} 
                        />
                    </OfferModalContainer>
                    
                )
            }
            <OfferModalContainer show={showViewModal} setShow={setShowViewModal} setModalContent={setModalContent}>
            
            {
                (modalContent.action && modalContent.action == `Generate`) && (
                    <>
                        <CustomerOffer
                            modalViewContent={modalContent.item}
                            action={`Generate`}
                            user={userName}
                            setShowViewModal={setShowViewModal}
                            getMyAppraisals={getMyAppraisals}
                        />
                    </>
                )
            }
            {
                (modalContent.action && modalContent.action == `Edit`) && (
                    // <div>
                    //     Edit content
                    // </div>
                    <CustomerOffer
                        modalViewContent={modalContent.item}
                        user={userName}
                        action={`Edit`}
                        setShowViewModal={setShowViewModal}
                        getMyAppraisals={getMyAppraisals}
                    />
                )
            }
            </OfferModalContainer>
            <CRow className="mb-5">
                <CCol className="pt-3 col-md-11" style={{ margin: "0px auto", background: "white" }}>
                    {/* <div> */}
                        <Button 
                            className="mb-4"
                            onClick={() => setViewArchivedRecords(!viewArchivedRecords)}
                            primary
                            // style={{background: '#00aced', color: '#fff', cursor: 'pointer'}}
                        >
                            <h4>Archived Records</h4>
                        </Button>
                        <CCollapse visible={viewArchivedRecords} style={viewArchivedRecords ? {display: 'block'} : {display: 'none'}}>
                            <CCard className="mt-3">
                                <CCardBody>
                                    {/* <CDataTable
                                        // addTableClasses={styles.tableclass}
                                        border
                                        // items={usersData}
                                        fields={archiveFields}
                                        items={archiveRecords}
                                        itemsPerPageSelect
                                        itemsPerPage={5}         
                                        pagination
                                        // sorter
                                        striped
                                        tableFilter={{ label: 'Search by Name: ' }}
                                        scopedSlots = {{
                                            'appraiser_archive':
                                                (item, index) => {
                                                    return (<td>
                                                        <input 
                                                            type="checkbox" 
                                                            checked={item.appraiser_archive ? true : false}
                                                            onChange={(e) => {
                                                                archiveRecordHandler(item.vin, 'appraiser', item.appraiser, item.created_date, !item.appraiser_archive)
                                                            }}
                                                        />
                                                        
                                                    </td>)
                                            }
                                        }}
                                    /> */}
                                    <ArchiveRecordsView 
                                        archiveFields={archiveFields}
                                        archiveRecords={archiveRecords}
                                        archiveRecordHandler={archiveRecordHandler}
                                    />
                                </CCardBody>
                            </CCard>
                        </CCollapse>
                    {/* </div> */}
                </CCol>
            </CRow>
        </>
    )
}