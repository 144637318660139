import React from 'react'
import {CInput} from '@coreui/react';


export default (props)=>{

    const changeFunc = (e)=>{
        var val = e.target.value
        var loc = {...props.localFilters}
        loc['max_mileage'] = val
        props.setLocalFilters(loc)
    }

    return(
        <CInput
            style={{maxWidth:'105px', height: '38px', padding: 'auto 2px'}}
            type="number"
            value={props.localFilters['max_mileage'] === null ? '' : props.localFilters['max_mileage']}
            id="max_mileage"
            name="max_mileage"
            placeholder="Max Miles"
            onChange = {(e => changeFunc(e))}
        />
       
    )
}




                                   

