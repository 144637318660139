import React from 'react';
import {CHeader, CToggler, CNav, CNavItem, CNavLink} from '@coreui/react';
import {Route,Switch} from 'react-router-dom'
import HeaderLink from './HeaderLink/HeaderLink'
import {useLocation} from 'react-router-dom';
import SwitchLane from '../Main/SwitchLane/SwitchLane';

export default (props)=>{
    const location = useLocation();

    const toggleSidebar = ()=>{
        props.setSideBar(!props.sidebar);
    }

    const canShowDisplayLanes = ()=> {
      return (location.pathname.indexOf("/review") > 0 && location.search.indexOf("auction_date=") > 0);
    }

    return(
    <CHeader className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <CToggler
          inHeader
          className="ml-md-3"
          onClick={toggleSidebar}
        />
        <CNav>
          <CNavItem>
            <CNavLink to="/">Home</CNavLink>
          </CNavItem>
          <Switch>
            <Route path="/runlist/:channel/:runListId">
              <HeaderLink/>
            </Route>
            <Route path="/inventory/chart">
              <CNavItem>
                <CNavLink to="/inventory/chart/make">Inventory Chart</CNavLink>
              </CNavItem>
            </Route>
            <Route path="/inventory">
              <CNavItem>
                <CNavLink to="/inventory/dashboard">Inventory Management</CNavLink>
              </CNavItem>
            </Route>
            <Route path="/reporting/buyers">
              <CNavItem>
                <CNavLink to="/reporting/buyers">Reporting</CNavLink>
              </CNavItem>
            </Route>
          </Switch>
        </CNav>
      </div>
      
      <div className="d-inline p-1">
        {canShowDisplayLanes() &&
        <div className="float-left">
          <SwitchLane />
        </div>
        }
        <small className="float-left p-2">v3.12</small>

      </div>
     
    </CHeader>)
}